import { gql } from '@apollo/client';

export const CORE_INGREDIENT_FIELDS = gql`
  fragment CoreIngredient on Ingredient {
    id
    name
    number
    isVirtual
    meatClassification
  }
`;

export const SHOP_INGREDIENTS = gql`
  fragment ShopIngredients on ShopIngredientConnection {
    totalCount
    edges {
      cursor
      node {
        id
        _id
        container
        containerSize
        foodName
        number
        name
        ingredient {
          id
          name
          number
          shoppingCartItems {
            totalCount
          }
        }
      }
    }
  }
`;
