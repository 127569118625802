import { gql } from '@apollo/client';
import { IQuantity, IRelation, IUserRecipeBaseWeight } from './get-recipe';
import { CORE_RECIPE_FIELDS } from './fragments/recipe';
import { CORE_USER_WEIGHTS } from './fragments/user-weights';
import { CORE_QUANTITY_FIELDS } from './fragments/quantities';

interface IUserCollectionsCount {
  totalCount: number;
}

export interface IRecipe {
  _id: number;
  id: string;
  title: string;
  number?: string;
  hasImage: boolean;
  imageId?: string;
  isSAP?: boolean;
  collections: IUserCollectionsCount;
  userWeights: IRelation<IUserRecipeBaseWeight>;
  quantities: IRelation<IQuantity>;
}

export interface IResponse {
  response: {
    totalCount: number;
    items: Array<{
      cursor: string;
      node: IRecipe;
    }>;
  };
}

export interface IVariables {
  cursor?: string;
  hasImage?: boolean;
  itemsPerPage: number;
  q?: string;
  number?: string;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
  ownedByUser?: boolean;
  wasPublishedInPast?: boolean;
  isPublished?: boolean;
  isSAP: boolean;
  isVisibleToAnon?: boolean;
  ingredientNumber?: string;
}

export const GET_RECIPE_CARDS = gql`
  ${CORE_RECIPE_FIELDS}
  ${CORE_QUANTITY_FIELDS}
  ${CORE_USER_WEIGHTS}
  query recipeCards(
    $q: String
    $number: String
    $hasImage: Boolean
    $itemsPerPage: Int!
    $cursor: String
    $ownedByUser: Boolean
    $orderBy: RecipeFilter_orderBy
    $wasPublishedInPast: Boolean
    $isPublished: Boolean
    $isSAP: Boolean
    $isVisibleToAnon: Boolean
    $ingredientNumber: String
  ) {
    response: recipes(
      title: $q
      number: $number
      hasImage: $hasImage
      first: $itemsPerPage
      after: $cursor
      exists: {
        user: $ownedByUser
        publishedAt: $wasPublishedInPast
        isSAP: $isSAP
      }
      orderBy: [$orderBy]
      isPublished: $isPublished
      isVisibleToAnon: $isVisibleToAnon
      quantities_ingredient_name: $q
      quantities_ingredient_number: $ingredientNumber
    ) {
      totalCount
      items: edges {
        cursor
        node {
          ...CoreRecipeFields
          quantities {
            ...CoreQuantityFields
          }
          userWeights(first: 1) {
            ...CoreUserWeights
          }
        }
      }
    }
  }
`;
