import i18next from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';
import translationsEN from '../translations/en';
import translationsDE from '../translations/de';
import { DEFAULT_LANGUAGE } from './constants';

export const i18n = i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      de: translationsDE,
      en: translationsEN,
    },
  });
