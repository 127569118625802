// for legacy mobile ios 12+
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRule } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';

export async function polyfillNumberFormat(locale: 'en' | 'de') {
  if (shouldPolyfillCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }

  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');

    if (locale === 'en') {
      await import(`@formatjs/intl-pluralrules/locale-data/en`);
    } else {
      await import(`@formatjs/intl-pluralrules/locale-data/de`);
    }
  }

  if (shouldPolyfillPluralRule(locale)) {
    await import('@formatjs/intl-pluralrules/polyfill');
  }

  if (shouldPolyfillNumberFormat(locale)) {
    await import('@formatjs/intl-numberformat/polyfill');

    if (locale === 'en') {
      await import(`@formatjs/intl-numberformat/locale-data/en`);
    } else {
      await import(`@formatjs/intl-numberformat/locale-data/de`);
    }
  }
}
