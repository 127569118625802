import { gql } from '@apollo/client';
import { CORE_NEWS_ITEM_FIELDS } from './fragments/news-item';

export interface ISearchVariables {
  cursor?: string;
  itemsPerPage: number;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
}

export interface INewsItemsResponse {
  response: {
    totalCount: number;
    items: Array<{
      cursor: string;
      node: INewsItem;
    }>;
  };
}

export interface INewsItem {
  content: string;
  id: string;
  _id: number;
  imageId?: string;
  hasImage: boolean;
  subtitle: string;
  title: string;
}

export const GET_NEWS = gql`
  ${CORE_NEWS_ITEM_FIELDS}
  query news(
    $cursor: String
    $itemsPerPage: Int!
    $orderBy: NewsItemFilter_orderBy
  ) {
    response: newsItems(
      after: $cursor
      first: $itemsPerPage
      orderBy: [$orderBy]
    ) {
      totalCount
      items: edges {
        cursor
        node {
          ...CoreNewsItemFields
        }
      }
    }
  }
`;
