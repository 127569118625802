import React, { FunctionComponent, useCallback, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FetchResult, gql, DataProxy, useApolloClient } from '@apollo/client';
import ReactGA from 'react-ga4';
import {
  GET_SHOPPING_CART_ITEMS,
  IDeleteResponse,
  IResponse,
} from '../../../gql/get-shopping-cart-items';
import { handleError } from '../../../lib/handleError';
import { IIngredientRow } from '../interfaces';

const deleteItemCacheUpdate = (
  cache: DataProxy,
  result: FetchResult<IDeleteResponse>,
) => {
  const cachedData = cache.readQuery<IResponse>({
    query: GET_SHOPPING_CART_ITEMS,
  });

  if (!cachedData || !result || !result.data) {
    return;
  }

  const removedItemIds = Object.values(result.data).map(
    (item) => item.shoppingCartItem.id,
  );

  const remainingItems = cachedData.shoppingCartItems.edges.filter((item) =>
    removedItemIds.find((id) => id !== item.node.id),
  );

  const removedItems = cachedData.shoppingCartItems.edges.filter((item) =>
    removedItemIds.find((id) => id === item.node.id),
  );

  cache.writeQuery({
    data: {
      shoppingCartItems: {
        ...cachedData.shoppingCartItems,
        edges: remainingItems,
      },
    },
    query: GET_SHOPPING_CART_ITEMS,
  });

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#remove_from_cart
  ReactGA.event('remove_from_cart', {
    event_category: 'Shopping',
    items: removedItems.map(({ node }) => ({
      item_id: node.ingredient.number, // dimension11
      item_name: node.ingredient.name,
      quantity: node.quantity,
    })),
  });
};

export const DeleteIngredientButton: FunctionComponent<{
  record: IIngredientRow;
}> = ({ record }) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);

    try {
      await client.mutate({
        mutation: gql`mutation deleteShoppingCartItem{
          ${record.containers.map(
            (item, index) => `
            delete${index}: deleteShoppingCartItem(input: {
              id: "${item.id}",
            }) { shoppingCartItem { id } }
          `,
          )}
        }`,
        update: deleteItemCacheUpdate,
      });
    } catch (error) {
      handleError(error);
    }

    setLoading(false);
  }, [client, record.containers]);

  return (
    <Button
      loading={loading}
      onClick={onClick}
      shape="circle"
      icon={<DeleteOutlined />}
    />
  );
};
