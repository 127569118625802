import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from 'react';
import { Col, Pagination, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { defaultGutterPixelSize, SmallButtonCol } from '../../../lib/styles';
import { useRapsPagination } from '../hooks/usePagination';
import { ResponsiveCardGrid } from '../../../components/ResponsiveCardGrid';

type IPaginatedResultList = {
  actionButton?: ReactElement;
  searchParam?: string;
  disablePagination?: boolean;
  totalCount: number;
  itemLength: number;
};

export const PaginatedResultList: FunctionComponent<
  PropsWithChildren<IPaginatedResultList>
> = ({ children, actionButton, itemLength, totalCount, disablePagination }) => {
  const { t } = useTranslation();
  const {
    current,
    itemsPerPage,
    q: searchTerm,
    onChange,
  } = useRapsPagination();

  const infoText = useMemo(() => {
    // in search, pagination is disabled and only 4 items are shown
    if (disablePagination) {
      return t('search.showSingleResultInfo', {
        num: itemLength,
        total: totalCount,
      });
    }

    const pageStart = (current - 1) * itemsPerPage + 1;
    const pageEnd = Math.min(pageStart + itemsPerPage - 1, totalCount || 0);

    if (pageStart === pageEnd) {
      return t('search.showSingleResultInfo', {
        num: pageEnd,
        total: totalCount,
      });
    }

    return t('search.showMultiResultInfo', {
      pageStart,
      pageEnd,
      total: totalCount,
    });
  }, [current, disablePagination, itemLength, itemsPerPage, t, totalCount]);

  if (!itemLength) {
    return (
      <Row
        gutter={defaultGutterPixelSize}
        justify="space-between"
        align="middle"
      >
        <Col style={{ maxWidth: '100%' }}>
          {searchTerm ? (
            <Trans i18nKey="search.noResultsFor" values={{ searchTerm }} />
          ) : (
            t('search.noResults')
          )}
        </Col>
        {actionButton && <Col>{actionButton}</Col>}
      </Row>
    );
  }

  return (
    <>
      <Row
        gutter={defaultGutterPixelSize}
        justify="space-between"
        align="middle"
      >
        <Col style={{ maxWidth: '100%' }}>
          {itemLength ? (
            <h4>
              {infoText}
              {searchTerm && (
                <span css={{ marginInlineStart: defaultGutterPixelSize / 4 }}>
                  {t('search.for')} <strong>{searchTerm}</strong>
                </span>
              )}
            </h4>
          ) : (
            <h4>
              {t('search.loadPage')} {current}
              &hellip;
            </h4>
          )}
        </Col>

        {actionButton && <SmallButtonCol>{actionButton}</SmallButtonCol>}
      </Row>

      <ResponsiveCardGrid>{children}</ResponsiveCardGrid>

      {!disablePagination && totalCount && (
        <Row
          justify="end"
          style={{
            marginTop: defaultGutterPixelSize,
            marginBottom: defaultGutterPixelSize,
          }}
        >
          <Pagination
            onChange={onChange}
            pageSize={itemsPerPage}
            total={totalCount}
            current={current}
            showSizeChanger={false}
            hideOnSinglePage
            responsive
          />
        </Row>
      )}
    </>
  );
};
