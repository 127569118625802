import React, { FunctionComponent, ReactElement } from 'react';
import styled from '@emotion/styled';
import { Carousel } from 'antd';
import { type Settings as CarouselSettings } from '@ant-design/react-slick';
import { rapsRed } from '../../../theme/customVariables';

const CustomCarousel = styled(Carousel)`
  &.slick-slider {
    margin: 0 -8px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    float: none;
  }

  .slick-slide > div {
    padding: 0 8px; // the carousel cant handle "gap" css property :(
  }

  .slick-track:before,
  .slick-track:after {
    display: none;
  }

  .slick-dots {
    position: static;
    padding: 16px;
  }

  .slick-dots > li,
  .slick-dots > li.slick-active {
    width: 27px;
  }

  .slick-dots > li > button,
  .slick-dots > li.slick-active > button {
    height: 8px;
    width: 27px;
    background-color: ${rapsRed};
    margin-right: 5px;
  }
`;

interface ISearchResultCarousel {
  items: ReactElement[];
}

function getSettings(itemLength: number): CarouselSettings {
  return {
    draggable: true,
    dots: true,
    infinite: false,
    centerPadding: '24px',
    slidesToShow: 4,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          ...(itemLength > 3 && {
            centerMode: true,
            infinite: true,
          }),
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          ...(itemLength > 2 && {
            centerMode: true,
            infinite: true,
          }),
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          ...(itemLength > 1 && {
            centerMode: true,
            infinite: true,
          }),
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
}

export const SearchResultCarousel: FunctionComponent<ISearchResultCarousel> = ({
  items,
}) => {
  const settings = getSettings(items.length);

  return <CustomCarousel {...settings}>{items}</CustomCarousel>;
};
