import React, { FunctionComponent, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import { useApolloClient, ApolloQueryResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../../../components/Loading';
import {
  GET_FULL_RECIPES,
  IResponse,
  IVariables,
} from '../../../../../gql/get-full-recipes';
import { IRecipe } from '../../../../../gql/get-recipe';
import { IRecipe as IImageRecipe } from '../../../../../gql/get-recipe-cards';
import { apiUrls } from '../../../../../lib/apiUrls';
import { HttpError } from '../../../../../lib/HttpError';
import { downloadPdf } from '../../../../../lib/downloadPdf';
import {
  prepareRecipesForExport,
  ICollectionExportData,
} from '../../../utils/exportedRecipeData';
import { createPdfFileName } from '../../../../../lib/pdf';

type IPrintButtonProps = {
  collectionId: string;
  collectionCategory: string;
  title: string;
  collectionImageRecipe: IImageRecipe | null;
};

const ModalContent = styled.div`
  text-align: center;
`;

let timeoutId: number;

// FIXME: this has the potential to be a memory leak due to not managing cancellation
const pollExportedPdf = async (
  collectionTitle: string,
  filename: string,
  resetAllStates: VoidFunction,
) => {
  const response = await fetch(apiUrls.checkCollectionExportReady(filename), {
    method: 'GET',
  });

  const data = (await response.json()) as { remotePath: string | null };

  if (timeoutId) {
    window.clearTimeout(timeoutId);
  }

  if (data.remotePath == null) {
    timeoutId = window.setTimeout(
      () => pollExportedPdf(collectionTitle, filename, resetAllStates),
      7500,
    );
  } else {
    resetAllStates();
    downloadPdf(collectionTitle, data.remotePath);
  }
};

const printPreparedCollection = async (
  collectionExportData: ICollectionExportData,
  resetAllStates: VoidFunction,
  handleNetworkError: (error: unknown) => void,
) => {
  const { title, category } = collectionExportData.collectionData;
  const fileName = createPdfFileName([category, title], 'pdf');

  try {
    const response = await fetch(apiUrls.exportCollection(fileName), {
      body: JSON.stringify(collectionExportData),
      method: 'POST',
    });

    if (!response.ok) {
      throw new HttpError(response);
    }

    const filename = await response.text();

    await pollExportedPdf(title, filename, resetAllStates);
  } catch (error) {
    handleNetworkError(error);
  }
};

export const PrintButton: FunctionComponent<IPrintButtonProps> = ({
  collectionId,
  title,
  collectionCategory,
  collectionImageRecipe,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prepareRecipesComplete, setPrepareRecipesComplete] = useState(false);
  const [fetchRecipesComplete, setFetchRecipesComplete] = useState(false);

  const resetAllStates = useCallback(() => {
    setLoading(false);
    setIsModalOpen(false);
    setFetchRecipesComplete(false);
    setPrepareRecipesComplete(false);
  }, []);

  const handlePrintClick = useCallback(async () => {
    setLoading(true);
    setIsModalOpen(true);
    let cursor: string | undefined;
    let fetchedAllRecipes = false;
    let collectionTotalCount = 0;
    let collectionRecipes: { cursor: string; node: IRecipe }[] = [];

    try {
      do {
        const queryResponse: ApolloQueryResult<IResponse> =
          // eslint-disable-next-line no-await-in-loop
          await client.query<IResponse, IVariables>({
            query: GET_FULL_RECIPES,
            fetchPolicy: 'network-only',
            variables: {
              collectionId: Number(collectionId),
              cursor: cursor || undefined,
              isSAP: false,
              itemsPerPage: 50,
              orderBy: {
                title: 'ASC',
              },
              withUser: true,
            },
          });

        const { items: recipes, totalCount } = queryResponse.data.response;

        collectionTotalCount = totalCount;

        if (collectionRecipes.length < collectionTotalCount) {
          cursor = recipes.at(-1)?.cursor;
          collectionRecipes = collectionRecipes.concat(recipes);
        }

        fetchedAllRecipes = collectionRecipes.length === collectionTotalCount;
      } while (!fetchedAllRecipes);
    } catch (e) {
      notification.error({
        description: t('common.error.unknown.description'),
        message: t('common.error.unknown.message'),
      });

      return;
    }

    if (collectionRecipes.length < collectionTotalCount) {
      setIsModalOpen(false);
      setLoading(false);

      return;
    }

    setFetchRecipesComplete(true);

    const collectionData = {
      title,
      category: collectionCategory,
      recipeCount: collectionTotalCount,
    };

    const exportData = prepareRecipesForExport(
      collectionRecipes,
      collectionImageRecipe,
      collectionData,
      t,
    );

    const handleNetworkError = (error: unknown) => {
      if (error instanceof HttpError && error.status === 401) {
        notification.error({
          description: t('login.error.description'),
          message: t('login.error.message'),
        });
      } else {
        notification.error({
          description: t('common.error.unknown.description'),
          message: t('login.error.message'),
        });
      }
    };

    setPrepareRecipesComplete(true);

    await printPreparedCollection(
      exportData,
      resetAllStates,
      handleNetworkError,
    );
  }, [
    client,
    collectionCategory,
    collectionId,
    collectionImageRecipe,
    resetAllStates,
    t,
    title,
  ]);

  return (
    <>
      <Button
        disabled={loading}
        icon={<PrinterOutlined />}
        onClick={handlePrintClick}
        title={t('common.print')}
      >
        {t('common.print')}
      </Button>
      <Modal
        destroyOnClose
        footer={null}
        maskClosable={false}
        title={t('collections.print.modalTitle')}
        open={isModalOpen}
      >
        <ModalContent>
          <p>
            {fetchRecipesComplete
              ? t('collections.print.fetchRecipesComplete')
              : t('collections.print.fetchingFullRecipes')}
          </p>
          {fetchRecipesComplete && (
            <p>
              {prepareRecipesComplete
                ? t('collections.print.prepareRecipesComplete')
                : t('collections.print.preparingRecipes')}
            </p>
          )}
          <p>
            {fetchRecipesComplete &&
              prepareRecipesComplete &&
              t('collections.print.generatingCollectionExport')}
          </p>
          <Loading />
        </ModalContent>
      </Modal>
    </>
  );
};
