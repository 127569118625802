import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorUnknownPage } from '../../components/ErrorUnknownPage';
import { LoadingPage } from '../../components/LoadingPage';
import { MessagePage } from '../../components/MessagePage';
import { apiUrls } from '../../lib/apiUrls';
import { handleExpiredToken } from '../../lib/handleExpiredToken';
import { handleAxiosResponse } from '../../lib/handleFetch';
import { usePageTitle } from '../../hooks/usePageTitle';

const ERROR_NOTFOUND = 'notfound';
const ERROR_UNKNOWN = 'unknown';

const ActivatePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { activationToken } = useParams();
  const [errorStatus, setErrorStatus] = useState('');

  usePageTitle(t('pageTitles.activate'));

  useEffect(() => {
    (async () => {
      const response = await axios
        .post(apiUrls.users.activate(activationToken || ''), {})
        .catch(async (e: AxiosError) => {
          if (e.response?.status === 401) {
            await handleExpiredToken(client, true, true);
          } else if (e.response?.status === 404) {
            setErrorStatus(ERROR_NOTFOUND);
          } else {
            setErrorStatus(ERROR_UNKNOWN);
          }
        });

      if (response) {
        await handleAxiosResponse(response);
      }

      notification.success({
        description: t('activate.description'),
        message: t('activate.message'),
      });

      navigate('/');
    })();
  }, [activationToken, client, navigate, t]);

  if (errorStatus === ERROR_NOTFOUND) {
    return (
      <MessagePage
        message={t('activate.error.message')}
        description={t('activate.error.description')}
      />
    );
  }

  if (errorStatus === ERROR_UNKNOWN) {
    return <ErrorUnknownPage />;
  }

  return <LoadingPage text={t('activate.loading')} />;
};

export default ActivatePage;
