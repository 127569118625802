import { css, keyframes, ThemeProvider } from '@emotion/react';
import { ThemeConfig } from 'antd';
import { grey } from '@ant-design/colors';
import {
  dinOffcPro,
  rapsRed,
  rapsRedActive,
  rapsRedHover,
} from './customVariables';
import { blue, gold, green, red } from './color/colors';
import { textColor } from './variables';

const theme: ThemeConfig = {
  token: {
    colorPrimary: rapsRed,
    colorInfo: blue,
    colorSuccess: green,
    colorError: red,
    colorHighlight: red,
    colorWarning: gold,
    colorLink: rapsRed,
    colorLinkHover: rapsRedHover,
    colorLinkActive: rapsRedActive,
    fontFamily: `${dinOffcPro}, sans-serif`,
  },
  components: {
    Button: {
      controlOutlineWidth: 0,
    },
    Card: {
      colorTextHeading: textColor,
      colorTextDescription: grey[4], // fix contrast
    },
  },
};

export { css, keyframes, ThemeProvider, theme };
