import React, { FunctionComponent, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { App, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { prepareRecipeForExport } from '../../../../../../lib/prepareRecipeForExport';
import { IRecipeResponse } from '../../../../../../gql/create-recipe';
import {
  IUpdateRAPSRecipeVariables,
  UPDATE_RAPS_RECIPE,
} from '../../../../../../gql/update-raps-recipe';
import { apiUrls } from '../../../../../../lib/apiUrls';
import { HttpError } from '../../../../../../lib/HttpError';
import type { IRecipe } from '../../../../../../gql/get-recipe';
import { useExportContext } from '../../../../../../context/ExportContext';

interface IMailButton {
  recipe: IRecipe;
}

export const MailButton: FunctionComponent<IMailButton> = ({ recipe }) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const { declaration, ingredients, isTotal } = useExportContext();

  const handleEmailClick = useCallback(async () => {
    const exportData = prepareRecipeForExport(
      recipe,
      declaration,
      ingredients,
      isTotal,
      t,
      true,
    );

    try {
      if (!recipe.isSAP) {
        await client.mutate<IRecipeResponse, IUpdateRAPSRecipeVariables>({
          mutation: UPDATE_RAPS_RECIPE,
          variables: {
            input: {
              id: recipe.id,
              isSAP: true,
            },
          },
        });
      }

      const response = await fetch(apiUrls.emailRecipe, {
        body: JSON.stringify(exportData),
        method: 'POST',
      });

      if (!response.ok) {
        throw new HttpError(response);
      }

      notification.success({
        description: t('recipe.emailSuccess.description'),
        message: t('recipe.emailSuccess.message'),
      });

      if (!recipe.isSAP) {
        navigate('/manage-recipes');
      }
    } catch (error) {
      if (error instanceof HttpError && error.status === 401) {
        notification.error({
          description: t('login.error.description'),
          message: t('login.error.message'),
        });
      } else {
        notification.error({
          description: t('common.error.unknown.description'),
          message: t('login.error.message'),
        });
      }
    }
  }, [
    client,
    declaration,
    ingredients,
    isTotal,
    navigate,
    notification,
    recipe,
    t,
  ]);

  return (
    <Button onClick={handleEmailClick} icon={<MailOutlined />}>
      {t('recipe.emailButton')}
    </Button>
  );
};
