import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Col, Layout, Row, theme } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as RapsLogo } from '../assets/img/raps-logo.svg';
import {
  layoutImageHeightNoUnit,
  layoutImageWidthNoUnit,
} from '../theme/variables';
import { LayoutContainer } from './LayoutContainer';
import { NavigationTopBar } from './Navigation/NavigationTopBar';
import { UserTopBar } from './Navigation/UserTopBar';
import { rapsRed } from '../theme/customVariables';

const HeaderRow = styled(Row)`
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
`;

const TopBarCol = styled(Col)`
  height: 100%;
  margin-right: auto;
`;

interface IProps {
  loggedInUser?: {
    firstName: string;
    lastName: string;
  };
}

export const Header: FunctionComponent<IProps> = ({ loggedInUser }) => {
  const { token } = theme.useToken();

  return (
    <Layout.Header
      id="header"
      css={{
        background: token.colorWhite,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        borderBottom: '1px solid rgba(0,0,0,0.2)',
        height: 'min-content',
      }}
      style={{
        // iOS 11.2+ - ensure safe areas for OS elements
        padding:
          'env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left)',
      }}
    >
      <LayoutContainer>
        <HeaderRow justify="space-between" css={{ gap: 24 }}>
          <Col>
            <Link to="/">
              <RapsLogo
                fill={rapsRed}
                height={layoutImageHeightNoUnit}
                width={layoutImageWidthNoUnit}
                title="Logo"
                css={{ display: 'block' }}
              />
            </Link>
          </Col>
          <TopBarCol>
            <NavigationTopBar />
          </TopBarCol>
          <Col>
            <UserTopBar loggedInUser={loggedInUser} />
          </Col>
        </HeaderRow>
      </LayoutContainer>
    </Layout.Header>
  );
};
