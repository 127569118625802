import React from 'react';
import styled from '@emotion/styled';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { layoutBodyBackground } from '../theme/variables';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${layoutBodyBackground};
  margin: auto;
`;

export const NoImageIcon = () => (
  <StyledFontAwesomeIcon icon={faImage} size="5x" style={{ width: 'auto' }} />
);
