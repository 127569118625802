const translationsDE = {
  translation: {
    raps: 'RAPS',
    pageTitles: {
      home: '$t(raps) Rezepte',
      search: '$t(raps) - Suche',
      searchResult: '$t(raps) - Suchergebnisse für: {{searchTerm}}',
      categories: '$t(raps) - Kategorien',
      category: '$t(raps) - Kategorie: {{category}}',
      collections: '$t(raps) - Sammlungen',
      collection: '$t(raps) - Sammlung: {{collection}}',
      managedCollection: '$t(raps) Meine Sammlung: {{collection}}',
      login: '$t(raps) - Login',
      faq: '$t(raps) - FAQ',
      contact: '$t(raps) - Kontakt',
      licenses: '$t(raps) - Lizenzen',
      imprint: '$t(raps) - Impressum',
      termsOfUse: '$t(raps) - Benutzungsbedingungen',
      privacyPolicy: '$t(raps) - Datenschutz',
      newsDetail: '$t(raps) - News: {{title}}',
      newsOverview: '$t(raps) - Aktuelle News',
      newsCreate: '$t(raps) - Neue News anlegen',
      newsEdit: '$t(raps) - News bearbeiten: {{title}}',
      recipeCreate: '$t(raps) - Neues Rezept anlegen',
      recipeOwn: '$t(raps) - Meine Rezepte',
      recipeDetail: '$t(raps) - Rezept: {{title}} {{number}}',
      recipeEdit: '$t(raps) - Rezept bearbeiten: {{title}} {{number}}',
      recipeCopy: '$t(raps) - Rezept kopieren: {{title}} {{number}}',
      shop: '$t(raps) - Shop',
      shoppingCart: '$t(raps) - Warenkorb',
      recipeSAPRecipes: '$t(raps) - Erstellte RAPS Rezepte',
      managePublications: '$t(raps) - Rezeptfreigabe',
      activate: '$t(raps) - Benutzer aktivieren',
      register: '$t(raps) - Benutzer registrieren',
      contactForm: '$t(raps) - Kontaktformular',
      forgotPassword: '$t(raps) - Passwort vergessen',
      resetPassword: '$t(raps) - Passwort zurücksetzen',
      error404: '$t(raps) - 404',
    },
    activate: {
      description: 'Sie können sich jetzt anmelden',
      error: {
        description:
          'Dieser Aktivierungslink ist ungültig. Möglicherweise wurde er bereits genutzt.',
        message: 'Ungültiger Aktivierungslink',
      },
      loading: 'Benutzer wird aktiviert …',
      message: 'Benutzer erfolgreich aktiviert',
    },
    anonymousUserNotice: {
      callToAction: 'Jetzt anmelden',
      notice: `Entdecken Sie die Vielfalt der RAPS Rezepte – Nutzen Sie die gesamte Performance der Rezeptplattform und 
      begeistern Sie Ihre Kunden mit neuen Ideen!`,
    },
    brand: 'my<strong>RAzept</strong> | Rezeptplattform',
    categories: {
      detailHeading: 'Kategorie',
    },
    collections: {
      addNewRecipe: 'Neues Rezept anlegen',
      addToRecipeCollection: 'Rezeptsammlung hinzufügen',
      closeAndSaveModal: 'Speichern & Schließen',
      createNewCollection: 'Rezeptsammlung anlegen',
      deleteConfirmTitle: 'Möchten Sie die Sammlung wirklich löschen?',
      detailHeading: 'Rezeptsammlung',
      editTitle: 'Sammlungsname bearbeiten',
      heading: 'Rezeptsammlungen',
      image: {
        change: {
          reset: {
            description: 'Das Rezeptbild wird wieder automatisch ausgewählt',
            message: 'Automatische Rezeptbildauswahl aktiviert!',
          },
          success: {
            description:
              'Dieses Rezeptbild wurde als Sammlungsbild festgelegt.',
            message: 'Titelbild ausgewählt!',
          },
        },
      },
      limitReached: `Maximale Größe erreicht. <br />Bitte beachten Sie, dass einer Sammlung nur 100 Rezepte zugeordnet 
werden können.`,
      new: {
        error: {
          description: `Beim Speichern der Rezeptsammlung ist ein Fehler aufgetreten. Möglicherweise existiert bereits 
          eine Rezeptsammlung mit diesem Titel.`,
          message: 'Rezeptsammlung konnte nicht gespeichert werden',
        },
        form: {
          name: {
            placeholder: 'Titel der Rezeptsammlung',
            required:
              'Der Name der Rezeptsammlung muss mindestens 3 Zeichen lang sein',
          },
        },
        success: {
          description:
            'Die Rezeptsammlung "{{ name }}" wurde erfolgreich angelegt',
          message: 'Rezeptsammlung angelegt',
        },
      },
      print: {
        fetchRecipesComplete:
          'Laden der Rezepte für den Sammlungsdruck abgeschlossen',
        fetchingFullRecipes: 'Vollständige Rezeptdaten werden geladen',
        generatingCollectionExport: 'Export der Sammlung wird generiert',
        modalTitle: 'Sammlung drucken',
        prepareRecipesComplete:
          'Vorbereitung der Rezepte für den Sammlungsdruck abgeschlossen',
        preparingRecipes: 'Rezept werden für Export vorbereitet',
      },
      private: 'Meine Rezeptsammlungen',
      public: 'RAPS Rezeptsammlungen',
      publishAnonConfirmDescription:
        'Nach Freigabe ist diese Sammlung auch für nicht-angemeldete Nutzer sichtbar.',
      publishAnonTooltipContent: `Diese Einstellung steuert die Sichtbarkeit der Rezeptsammlung für anonyme Nutzer. 
      Diese Einstellung hat nur dann eine Auswirkung wenn die Rezeptsammlung zusätzlich für alle Nutzer freigegeben ist.`,
      publishAnonTooltipTitle: 'Sichtbarkeit für anonyme Nutzer',
      publishConfirmDescription:
        'Nach Freigabe ist die Sammlung auch für RAPS Kunden sichtbar.',
      publishConfirmTitle: 'Möchten Sie diese Sammlung wirklich freigeben?',
      publishGlobalTooltipContent: `Diese Einstellung steuert die Sichtbarkeit der Rezeptsammlung für Nutzer der RAPS 
      Rezepte Platform. Rezeptsammlungen die nicht sichtbar gemacht werden, können ausschließlich von Administratoren 
      eingesehen werden.`,
      publishGlobalTooltipTitle: 'Sichtbarkeit für alle Nutzer',
      removePrompt:
        'Soll dieses Rezept wirklich aus der Sammlung entfernt werden?',
      removePromptTitle: 'Löschen bestätigen',
      retrievalFailed:
        'Die Liste von Rezepten für diese Rezeptsammlung konnte nicht abgerufen werden',
      tabHeadings: {
        myCollections: 'Meine Rezeptsammlungen',
        myRecipes: 'Meine Rezepte',
        publicCollections: 'RAPS Rezeptsammlungen',
        rapsRecipes: 'Erstellte RAPS Rezepte',
      },
      unpublishAnonConfirmDescription:
        'Nach Bestätigung ist die Sammlung nur noch für angemeldete Nutzer sichtbar.',
      unpublishConfirmDescription:
        'Nach Bestätigung ist die Sammlung nur noch für Administratoren sichtbar.',
      unpublishConfirmTitle:
        'Möchten Sie die Freigabe für diese Sammlung aufheben?',
    },
    common: {
      cancel: 'Abbrechen',
      categories: 'Kategorien',
      collections: 'Sammlungen',
      decimalSeparator: ',',
      delete: 'Löschen',
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      remove: 'Entfernen',
      reset: 'Zurücksetzen',
      update: 'Aktualisieren',
      print: 'Drucken',
      noItems: 'Keine Einträge vorhanden',
      error: {
        fallback: {
          title: 'Ein unvorhergesehener Fehler ist aufgetreten.',
          description:
            'Wenn Sie dieses Problem weiterhin haben, kontaktieren Sie uns bitte.',
        },
        401: {
          anonymousUser: {
            description:
              'Bitte melden Sie sich an, um Zugriff auf diese Funktionalität zu erhalten.',
            message: 'Anmeldung nötig',
          },
          description: 'Bitte melden Sie sich erneut an.',
          message: 'Sitzung abgelaufen',
        },
        403: {
          description: 'Sie besitzen nicht ausreichend Rechte.',
          message: '403 Forbidden',
        },
        404: {
          anonymousUser: {
            directionText: 'Bitte gehen sie zurück auf die',
            or: 'oder zum',
            sorryText:
              'Hoppla – hier ist etwas schiefgelaufen oder die Seite existiert nicht mehr!',
            startPage: 'Startseite',
          },
          description: 'Diese Seite wurde nicht gefunden',
          message: '404',
        },
        emailAlreadyInUse: 'Diese E-Mail wird bereits verwendet',
        inputRequired: 'Bitte geben Sie einen gültigen Wert ein',
        invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        maxValue: '{{ name }} muss kleiner oder gleich {{ value }} sein',
        minValue: '{{ name }} muss größer oder gleich {{ value }} sein',
        noNetwork: {
          description: `Es konnten keine Daten geladen werden. Stellen Sie sicher, dass eine Internetverbindung besteht 
          und probieren Sie es erneut.`,
          message: 'Netzwerkfehler',
        },
        numberRequired: '{{ name }} muss eine Zahl sein',
        selectFromOptions: 'Bitte wählen Sie aus den verfügbaren Optionen.',
        unknown: {
          description:
            'Es ist ein unbekannter Fehler aufgetreten, es könnte helfen die Aktion erneut auszuführen.',
          message: 'Unbekannter Fehler',
        },
      },
      goBack: 'Zurück',
      loadMore: 'Mehr laden',
      logout: 'Logout',
      myRecipes: 'Meine Rezepte',
      news: 'Aktuelle Themen',
      no: 'Nein',
      noImage: 'Kein Bild verfügbar',
      privacyPolicy: 'Datenschutz',
      recipePlatform: 'Rezeptplattform',
      sapRecipes: 'Erstellte RAPS Rezepte',
      save: 'Speichern',
      search: 'Suche',
      shop: 'Shop',
      yes: 'Ja',
    },
    contact: {
      title: 'Kontakt',
    },
    faq: {
      error: 'FAQ-Liste kann nicht geladen werden.',
      longTitle: 'Häufig gestellte Fragen (FAQ)',
      shortTitle: 'FAQ',
    },
    forgotPassword: {
      description: `Wir haben, sofern es ein Benutzerkonto zu dieser E-Mail-Adresse gibt, eine E-Mail mit einem Link 
      zum Zurücksetzen Ihres Passwortes geschickt. Dieser ist nach Erhalt 24 Stunden gültig.`,
      email: 'E-Mail',
      message: 'E-Mail verschickt!',
      resetPassword: {
        description:
          'Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit Ihren neuen Anmeldedaten einloggen.',
        message: 'Passwortänderung erfolgreich!',
        password: 'Neues Passwort',
        passwordInvalid: 'Das Passwort muss aus mindestens 8 Zeichen bestehen',
        passwordRepeat: 'Neues Passwort bestätigen',
        repeatPassword: 'Passwort bestätigen',
        repeatPasswordWrong: 'Die Passwörter stimmen nicht überein',
      },
      title: 'Passwort vergessen?',
    },
    header: {
      login: 'Login',
      register: 'Registrieren',
    },
    imprint: {
      title: 'Impressum',
    },
    ingredient: {
      add: 'Zutat hinzufügen',
      declaration: 'Deklaration',
      declarationPlaceholder:
        'z.B. Blätterteig (WEIZEN-Mehl, Butter (MILCH und MILCHERZEUGNISSE), Salz, Zucker)',
      modal: {
        RAPSIngredients: 'RAPS Zutaten',
        allIngredients: 'Alle Zutaten',
        or: 'ODER',
        otherIngredients: 'Sonstige Zutaten',
        quidIngredients: 'QUID Fl. Zutaten',
        searchHint:
          'Suchen Sie in unserer umfangreichen Datenbank nach Zutaten oder legen Sie eine neue Zutat an!',
        searchNotFoundHint:
          'Ihre Suche hat zu keinem Ergebnis geführt. Versuchen Sie einen anderen Suchbegriff oder legen Sie die Zutat neu an.',
        searchPlaceHolder: 'Zutaten durchsuchen...',
      },
      new: 'Neue Zutat anlegen',
      newPlaceholder: 'Name der Zutat',
      noIngredientsYet: 'Noch keine Zutaten',
      search: 'Suche nach Zutaten',
      shoppingCart: 'Warenkorb',
    },
    licenses: {
      error: 'Es ist ein Fehler aufgetreten',
      link: 'hier',
      loading: 'Lade Lizenzen',
      title: 'Lizenzen',
    },
    login: {
      email: {
        error: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        placeholder: 'E-Mail',
      },
      error: {
        description:
          'E-Mail oder Passwort falsch oder Account noch nicht aktiviert',
        message: 'Login fehlgeschlagen',
      },
      forgotPasswordLink: 'Passwort vergessen?',
      loginButton: 'Login',
      password: {
        error: 'Bitte geben Sie Ihr Passwort ein',
        placeholder: 'Passwort',
      },
      registerLink: 'Oder registrieren Sie sich!',
    },
    modal: {
      cancel: 'Abbrechen',
      ok: 'Bestätigen',
      transferModal: {
        addonBefore: 'Kundennummer',
        usersFetchError: 'Beim Abrufen der Benutzer ist ein Fehler aufgetreten',
        placeholder: 'Kundennummer suchen',
      },
      transferRecipe: {
        triggerButton: 'Rezept übertragen',
        title: 'Rezept übertragen',
        notFound: 'Rezept konnte nicht gefunden werden',
        success:
          'Das Rezept wurde erfolgreich an den ausgewählten Benutzer übertragen',
        error: 'Beim Übertragen des Rezepts ist ein Fehler aufgetreten',
      },
      transferMyCollection: {
        triggerButton: 'Sammlung übertragen',
        title: 'Rezeptsammlung "{{ name }}" übertragen',
        placeholder: 'Suchen Sie nach einer Accountnummer',
        success:
          'Die Sammlung wurde erfolgreich an den ausgewählten Benutzer übertragen',
        error: `Beim Übertragen der Rezeptsammlung ist ein Fehler aufgetreten. Möglicherweise existiert bereits 
          eine Rezeptsammlung mit diesem Titel.`,
      },
    },
    news: {
      content: 'Inhalt',
      createNews: 'Neuigkeit anlegen',
      deleteConfirmNews: 'Möchten Sie diese Neuigkeit wirklich löschen?',
      deleteImageContent: 'Möchten Sie das Bild wirklich löschen?',
      deleteImageTitle: 'Bild löschen',
      editNews: 'Neuigkeit bearbeiten',
      moreNews: 'Mehr Aktuelles',
      readButton: 'Lesen',
      subtitle: 'Untertitel',
      title: 'Titel',
    },
    nutritionalInformation: {
      LRA_NUTRITIONS_FAT: 'Fett',
      LRA_NUTRITIONS_SALT: 'Salz',
      LRA_NUTRITIONS_SATURATED_FA: 'Davon gesättigte Fettsäuren',
      LRA_NUTRTIONS_CARBOHYDRATES: 'Kohlenhydrate',
      LRA_NUTRTIONS_KCAL: 'Energie',
      LRA_NUTRTIONS_KJ: 'Energie',
      LRA_NUTRTIONS_PROTEINE: 'Eiweiß',
      LRA_NUTRTIONS_SUGARS: 'Davon Zucker',
      header: 'Nährwerte pro 100 g',
      weightLossInfo: {
        description:
          'Die Nährwertangaben beziehen sich auf einen Gewichtsverlust von {{weightLoss}}%.',
        message: 'Nährwertangaben',
      },
    },
    overview: 'Übersicht',
    privacyPolicy: {
      gaOptOut: {
        description: `Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden 
        Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei der zukünftigen Nutzung von 
        myRAzept verhindert:`,
        disableGA: 'Google Analytics deaktivieren',
        message: 'Opt-Out aus Google Diensten',
        notification: {
          disabledGAMessage: 'Google Analytics deaktiviert',
          status: {
            gaDisabledStatus:
              'Momentan werden keine Daten von Google Analystics erfasst. Sie sind erfolgreich abgemeldet.',
            gaEnabledStatus:
              'Momentan werden Daten von Google Analystics erfasst.',
            title: 'Status: ',
          },
        },
      },
      title: 'Datenschutz',
    },
    publishing: {
      tabHeadings: {
        revoked: 'Freigabe zurückgezogen',
        unpublished: 'Freigabe ausstehend',
      },
      title: 'Rezeptfreigabe',
    },
    recipe: {
      label: 'Rezept',
      addButton: 'Anlegen',
      additiveMaterialHeading: 'Einlagematerial',
      author: 'Verfasser',
      baseMaterialHeading: 'Grundmaterial',
      baseWeightLabel: 'Gesamtmaterial',
      baseWeight: '{{weight}} $t(recipe.baseWeightLabel)',
      baseWeightNewBaseline: 'Die Zutatenmenge wurde neu berechnet',
      baseWeightRecalculated: 'Gesamtmaterial neu berechnet',
      casing: 'Wursthüllen/Naturdarm',
      categories: 'Kategorien',
      categories_plural: 'Kategorien',
      closeAndSaveModal: 'Speichern & Schließen',
      cooking: 'Zubereitung',
      copyButton: 'Kopieren',
      copyPrefix: 'Kopie',
      copyToClipboard: 'Kopieren',
      copyToClipboardSuccess: {
        description:
          'Die Deklarationsempfehlung wurde in die Zwischenablage kopiert',
        message: 'Kopieren erfolgreich',
      },
      creation: 'Herstellung',
      custom: 'Benutzerdefinierte Rezepte',
      declaration: {
        hints: {
          allergens: '* Deklaration und Allergene zu ergänzen.',
          venison:
            '** Das eingesetzte Fleisch ist entsprechend der verwendeten Tierart zu bezeichnen.',
        },
        fatType: {
          H: 'Hähnchenfett',
          K: 'Kalbsfett',
          L: 'Lammfett',
          P: 'Putenfett',
          R: 'Rinderfett',
          S: 'Speck',
          W: 'Wildfett',
        },
        loading: 'Lade QUID Berechnung...',
        meatType: {
          H: 'Hähnchen',
          K: 'Kalb',
          L: 'Lamm',
          P: 'Puten',
          R: 'Rind',
          S: 'Schweine',
          W: 'Wild',
        },
        text: {
          and: 'und',
          meat: 'fleisch',
          weightLossHint:
            '100 g des Produktes wurden aus {{ amount }} g {{ meat }} hergestellt.',
        },
      },
      deleteButton: 'Löschen',
      deleteConfirmTitle: 'Möchten Sie das Rezept wirklich löschen?',
      deleteImageContent: 'Möchten Sie das Rezeptbild wirklich löschen?',
      deleteImageTitle: 'Bild löschen',
      editButton: 'Bearbeiten',
      emailButton: 'Übertragen',
      emailLastSent: 'Zuletzt verschickt am {{ date }} durch {{ user }}',
      emailSent: 'Rezept verschickt',
      emailSuccess: {
        description: 'Das Rezept wurde erfolgreich per E-Mail gesendet.',
        message: 'Rezept gesendet',
      },
      info: 'Informationen',
      ingredient: 'Zutat',
      ingredientNoLongerAvailable: 'Diese Zutat ist nicht mehr verfügbar.',
      ingredients: 'Zutaten',
      invisibleToAnonUser:
        'Dieses Rezept ist nicht für anonyme Nutzer sichtbar',
      lastUpdate: 'Stand',
      miscellaneous: 'Sonstiges',
      number: 'Rezept-Nr.',
      nutrientInformation: 'Nährwertinformationen',
      packaging: 'Verpackung',
      printButton: 'Drucken',
      provideAllergenicSubstances: '* Deklaration und Allergene zu ergänzen.',
      publishAnonConfirmDescription:
        'Nach der Bestätigung ist dieses Rezept auch für nicht-angemeldete Nutzer sichtbar (sofern es freigegeben wurde).',
      publishAnonDescription:
        'Diese Einstellung wirkt sich nur auf bereits freigeschaltete Rezepte aus.',
      publishConfirmDescription:
        'Nach Freigabe ist das Rezept auch für RAPS Kunden sichtbar.',
      publishConfirmTitle: 'Möchten Sie dieses Rezept wirklich freigeben?',
      publishedBy: 'Freigegeben durch {{name}}',
      publishedByDetail: 'Freigabe erfolgte am {{date}}',
      quantity: 'Menge',
      rapsIngredients: 'RAPS Zutaten',
      recommendedDeclaration: 'Unverbindliche Deklarationsempfehlung',
      relativeToBaseWeightDefault: 'Bezogen auf das Gesamtmaterial',
      relativeToTotalWeightDefault: 'Bezogen auf die Gesamtmenge',
      sap: 'SAP',
      sapDescription: 'Dieses Rezept dient nur zum Import in SAP.',
      supplementaryIngredients: 'Zugabe',
      totalWeightLabel: 'Gesamtmenge',
      totalWeight: '{{weight}} $t(recipe.totalWeightLabel)',
      totalWeightRecalculated: 'Gesamtmenge neu berechnet',
      unpublishAnonConfirmDescription:
        'Nach Bestätigung ist das Rezept nur noch für angemeldete Nutzer sichtbar (sofern es freigegeben wurde).',
      unpublishConfirmDescription:
        'Nach Bestätigung ist das Rezept nur noch für Administratoren sichtbar.',
      unpublishConfirmTitle:
        'Möchten Sie die Freigabe für dieses Rezept aufheben?',
      unpublishedBy: 'Freigabe zurückgezogen durch {{name}}',
      unpublishedByDetail: 'Zurücknahme erfolgte am {{date}}',
      unpublishedYet: 'Dieses Rezept ist noch nicht freigegeben',
      uploadImageMaxSizeHint: 'Die Datei darf maximal 2MB groß sein',
      uploadImageText:
        'Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen',
      uploadImageTitle: 'Neues Bild hochladen',
      virtualIngredients: 'Weitere Zutaten',
      visibleToAnonUser: 'Dieses Rezept ist für anonyme Nutzer sichtbar',
    },
    recipeManagement: {
      title: 'Erstellte RAPS Rezepte',
    },
    recipes: {
      baseMaterial: 'Grundmaterial',
      inlayMaterial: 'Einlagematerial',
      ingredientExplanation: `Die Zutaten für ein Fleischerzeugnis können in der Regel in drei Bereiche unterteilt 
werden: Grundmaterial (z.B. Fleisch, Fett und Eis), Einlagematerial (z.B. Champignons bei einer Champignon-Lyoner) und 
Zugabe (z.B. Gewürze). In der Ansicht „Gesamtmaterial“ einer Rezeptur werden die Zutaten in diese drei Bereiche 
gegliedert und die Mengenberechnung findet auf der Basis von Grund- und Einlagematerial statt.<br />Bitte aktivieren Sie 
dieses Kästchen, wenn die Zutat zum {{material}} zählt.`,
      categories: {
        error: 'Die Kategorien konnten nicht geladen werden',
        placeholder: 'Kategorien auswählen',
        title: 'Kategorien',
      },
      createNewRecipe: 'Rezept anlegen',
      declarationExplanation: `Wird eine Zutat in einer Rezeptur verwendet, so muss sie in der Zutatenliste der Rezeptur 
aufgeführt werden. Geben Sie hier bitte an, wie die Zutat in der Zutatenliste einer Rezeptur erscheinen soll. <br />Bei 
Zutaten, die nur aus einem Bestandteil bestehen, kann einfach die Bezeichnung der Zutat verwendet werden (z.B. 
„Kartoffeln“ vgl. Produktetikett). Bei Zutaten, die aus mehreren Bestandteilen bestehen, müssen die Verkehrsbezeichnung 
und danach in Klammern in absteigender Reihenfolge alle enthaltenen Bestandteile genannt werden (z.B. „Pizzateig 
(WEIZEN-Mehl, Olivenöl, Salz)“), eine gute Informationsquelle hierfür ist die Spezifikation ihres Lieferanten. In jedem 
Fall müssen Allergene durch GROSSSCHREIBUNG deutlich gekennzeichnet werden (z.B. „WEIZEN-Mehl“ oder „Butter (u.a. MILCH)“).`,
      editRecipe: 'Rezept bearbeiten',
      editSAPRecipe: 'RAPS-Rezept bearbeiten',
      error: {
        invalidIngredients: {
          description:
            'Bitte vergewissern Sie sich, dass alle Zutaten aus den bereitgestellten Optionen ausgewählt wurden.',
          message: 'Einige Zutaten sind nicht gültig',
          undefinedId: 'Ungültige Zutaten ID.',
        },
        noDuplicates: {
          description:
            'Einem Rezept darf nicht mehrmals die selbe Zutat hinzugefügt werden.',
          message: 'Mehrfachzuordnung einer Zutat',
        },
        noIngredients: {
          description:
            'Bitte vergewissern Sie sich, dass mindestens zwei unterschiedliche Zutaten ausgewählt sind.',
          message: 'Zu wenige Zutaten',
        },
        noTitle: 'Titel darf nicht leer sein!',
        save: 'Speichern fehlgeschlagen',
      },
      explanationQuidMeatCalculation: {
        content: `Die Anteile von enthaltenem Fleisch und Fett sind wesentliche Qualitätsmerkmale eines Fleischerzeugnisses. 
Übersteigt der Fettanteil im Fleisch einen gewissen Prozentsatz, so muss das Fett in der Zutatenliste separat ausgewiesen 
werden. Zur Bestimmung der Fleisch- und Fettanteile sowie der Kennzeichnungspflicht von Fett wird die sogenannte 
QUID-Fleischberechnung durchgeführt.<br />Bitte aktivieren Sie dieses Kästchen, wenn die QUID-Fleischberechnung für diese 
Rezeptur vorgenommen werden soll. Die Berechnung der Zutatenliste erfolgt dann automatisch. Bitte beachten Sie, dass nur 
die Rohstoffe in die QUID-Fleischberechnung mit einfließen, die in RAPS-Fleischsortierung und den RAPS-Verarbeitungsstandards 
enthalten sind.`,
        title: 'Was ist die QUID-Fleischberechnung?',
      },
      explanationWeightLoss: {
        content: `Im Herstellungsprozess kann es durch die Trocknung von Fleischerzeugnissen zu einem Gewichtsverlust kommen, 
wenn das enthaltene Wasser verdunstet (z.B. bei der Herstellung von Salami).<br />Bitte geben Sie in diesem Feld den durch 
die Trocknung entstehenden prozentualen Gewichtsverlust an, falls ein solcher bei dieser Rezeptur auftritt. Der 
Gewichtsverlust wird automatisch bei der Berechnung der Nährwerte und bei einer eventuellen QUID-Fleischberechnung 
berücksichtigt.`,
        title: 'Was bedeutet Gewichtsverlust?',
      },
      isAdditiveIngredient: 'Teil des Einlagematerials?',
      isBaseIngredient: 'Teil des Grundmaterials?',
      isQuid: 'QUID-Kennzeichnungspflichtig?',
      keyValueInfoModal: {
        H_1: {
          key: 'H 1',
          value:
            'fettgewebs,- und sehnenfreies Hähnchenfleisch ohne sichtbares Fett und Haut',
        },
        H_2: {
          key: 'H 2',
          value:
            'fett,- und sehnenhaltiges Hähnchenfleisch mit 10 % sichtbarem Fett und Haut',
        },
        H_3: { key: 'H 3', value: 'Hähnchenfleisch gebaadert' },
        H_4: {
          key: 'H 4',
          value: 'Hähnchen Separatorenfleisch mit Fett und Haut',
        },
        H_5: { key: 'H 5', value: 'Hähnchenhaut mit Fett' },
        K_1: {
          key: 'K 1',
          value:
            'fettgewebs,- und sehnenfreies Kalbfleisch ohne sichtbares Fett',
        },
        K_2: {
          key: 'K 2',
          value: 'sehnenarmes Kalbfleisch mit 5 % sichtbarem Fett',
        },
        K_3: {
          key: 'K 3',
          value: 'grob entsehntes Kalbfleisch mit 10 % sichtbarem Fett',
        },
        K_4: {
          key: 'K 4',
          value: 'sehnenfreies Kalbfleisch mit 20 % sichtbarem Fett',
        },
        L_1: {
          key: 'L 1',
          value:
            'fettgewebs,- und sehnenfreies Lammfleisch ohne sichtbares Fett',
        },
        L_2: {
          key: 'L 2',
          value: 'sehnenarmes Lammfleisch mit 5 % sichtbarem Fett',
        },
        L_3: {
          key: 'L 3',
          value: 'grob entsehntes Lammfleisch mit 10 % sichtbarem Fett',
        },
        L_4: {
          key: 'L 4',
          value: 'sehnenfreies Lammfleisch mit 30 % sichtbarem Fett',
        },
        P_1: {
          key: 'P 1',
          value:
            'fettgewebs,- und sehnenfreies Putenfleisch ohne sichtbares Fett und Haut',
        },
        P_2: {
          key: 'P 2',
          value: 'sehnenarmes Putenfleisch mit 5 % sichtbarem Fett ohne Haut',
        },
        P_3: {
          key: 'P 3',
          value:
            'fett,- und sehnenhaltiges Putenfleisch mit 10 % sichtbarem Fett und Haut',
        },
        P_4: { key: 'P 4', value: 'Putenunterkeule gebaadert' },
        P_5: { key: 'P 5', value: 'Putenhaut mit Fett' },
        R_1: {
          key: 'R 1',
          value:
            'fettgewebs,- und sehnenfreies Rindfleisch ohne sichtbares Fett',
        },
        R_2: {
          key: 'R 2',
          value: 'sehnenarmes Rindfleisch mit 5 % sichtbarem Fett',
        },
        R_3: {
          key: 'R 3',
          value: 'grob entsehntes Rindfleisch mit 10 % sichtbarem Fett',
        },
        R_4: {
          key: 'R 4',
          value: 'fett und sehnenhaltiges Rindfleisch mit 15 % sichtbarem Fett',
        },
        R_5: {
          key: 'R 5',
          value: 'sehnenfreies Rindfleisch mit 30 % sichtbarem Fett',
        },
        R_6: { key: 'R 6', value: 'Rinderfleischfett' },
        S_1: {
          key: 'S 1',
          value:
            'fettgewebs,- und sehnenfreies Schweinefleisch ohne sichtbares Fett',
        },
        S_10: { key: 'S 10', value: 'mittelfette Wammen' },
        S_11: { key: 'S 11', value: 'fette Wammen' },
        S_2: {
          key: 'S 2',
          value: 'sehnenfreies Schweinefleisch mit 5 % sichtbarem Fett',
        },
        S_3: {
          key: 'S 3',
          value: 'grob entfettetes Schweinefleisch mit 10 % sichtbarem Fett',
        },
        S_4: {
          key: 'S 4',
          value: 'magereres Bauchfleisch mit 25 % sichtbarem Fett',
        },
        S_5: {
          key: 'S 5',
          value: 'fettes Bauchfleisch mit 60 % sichtbarem Fett',
        },
        S_6: { key: 'S 6', value: 'Fettbacke' },
        S_7: { key: 'S 7', value: 'Nackenspeck' },
        S_8: { key: 'S 8', value: 'Rückenspeck' },
        S_9: { key: 'S 9', value: 'Fettabschnitte' },
        UNKNOWN_1: { key: '', value: 'Schweinenacken' },
        UNKNOWN_2: { key: '', value: 'Schweineschulter' },
        UNKNOWN_3: {
          key: '',
          value: 'Schweinefettbacke, gepökelt, gekocht',
        },
        W_1: {
          key: 'W 1',
          value:
            'fettgewebs,- und sehnenfreies Wildfleisch ohne sichtbares Fett',
        },
        W_2: {
          key: 'W 2',
          value: 'sehnenarmes Wildfleisch mit 5 % sichtbarem Fett',
        },
        W_3: {
          key: 'W 3',
          value: 'grob entsehntes Wildfleisch mit 10 % sichtbarem Fett',
        },
        titleKey: 'KÜRZEL',
        titleModal: 'Übersicht',
        titleValue: 'BEZEICHNUNG',
      },
      meatProductSelection: 'Fleischerzeugnis',
      new: 'Neues Rezept anlegen',
      newSAP: 'Neues RAPS-Rezept anlegen',
      newestRecipesHeading: 'Aktuelle Rezepte',
      noNewestRecipesAvailable:
        'Um aktuelle Rezepte sehen zu können, melden Sie sich bitte an.',
      quidExplanation: `<strong>Quantitative Ingredients Declaration</strong> (Mengenkennzeichnung von Lebensmitteln 
entsprechend der Lebenmittelinformations-Verordnung (EU) Nr.1169/2011, Artikel 22 und Anhang VIII). In manchen Fällen 
ist es nach der sogenannten QUID-Kennzeichnungspflicht notwendig, dass der Anteil einer Zutat in einer Rezeptur in der 
Zutatenliste in Prozent ausgewiesen wird. Dies ist der Fall, wenn dem Kunden vermittelt wird, dass eine gewisse Zutat 
in einer Rezeptur enthalten ist (z.B. durch die Bezeichnung, Worte auf der Verpackung oder ein zugehöriges Bild), oder 
die Zutat wesentlich für die Charakterisierung einer Rezeptur ist. Beispiele sind etwa, Paprika in einer Rezeptur namens 
Paprikawürstchen; Champignons, wenn diese deutlich auf dem Rezepturbild zu erkennen sind oder Eier in Biskuit. 
Merkblätter mit genaueren Informationen zur QUID-Kennzeichnung können über die Internetseiten der 
Landesuntersuchungsämter abgerufen werden.<br />Bitte aktivieren Sie dieses Kästchen, wenn eine Kennzeichnung nach den 
QUID-Vorschriften notwendig ist.`,
      quidMeatCalculation: 'QUID-Fleischberechnung',
      title: 'Titel',
      titlePlaceholder: 'Rezepttitel',
      weightLoss: 'Gewichtsverlust',
      whatIsADeclaration: 'Was ist eine Deklaration?',
      whatIsAdditiveIngredient: 'Was ist das Einlagematerial?',
      whatIsBaseIngredient: 'Was ist das Grundmaterial?',
      whatIsQuid: 'Was ist eine QUID-Kennzeichnung?',
      zan1: 'Herstellung',
      zan1Placeholder: 'Beschreiben Sie hier die Herstellung des Gerichts.',
      zan2: 'Zubereitung',
      zan2Placeholder:
        'Beschreiben Sie hier, wie Ihr Gericht zum Verzehr zubereitet wird.',
      zan3: 'Verpackung',
      zan3Placeholder: 'Beschreiben Sie hier Hinweise zur Verpackung ',
      zan4: 'Wursthüllen/Naturdarm',
      zan4Placeholder:
        'Beschreiben Sie hier Hinweise zu Wursthüllen oder Naturdarm ',
      zan5: 'Sonstiges',
      zan5Placeholder: 'Hier können sonstige Anmerkungen hinterlegt werden ',
    },
    register: {
      acceptConditions:
        'Hiermit stimme ich der <0>Datenschutzerklärung</0> und den <1>Nutzungsbedingungen</1> zu.',
      accountNumber: 'RAPS Kundennummer',
      companyName: 'Firma',
      conditionsNotAccepted:
        'Sie müssen der Datenschutzerklärung zustimmen um sich zu registrieren',
      contactForm: {
        city: 'Ort',
        contactPerson: 'Ansprechpartner',
        houseNumber: 'Hausnummer',
        infoBox: {
          description:
            'Die Registrierung ist ausschließlich für gewerbliche Kunden möglich. <0>Kein gewerblicher Kunde?</0>',
          message: 'Kundeninformation',
        },
        infoModal: {
          customerService:
            'Gerne können Sie telefonisch über unseren Customer Service die Produkte auch als Endverbraucher bestellen: <0>0800 / 439 83 79</0>',
          intro:
            'Diese Rezeptplattform ist in ihrem vollen Umfang ausschließlich für gewerbliche Kunden zugänglich.',
          shop: 'Oder besuchen Sie uns in Kulmbach und kaufen Sie Gewürze in unserem <0>AzubiLaden „RAPSODY OF SPICES“</0>',
        },
        street: 'Straße',
        submit: 'Abschicken',
        success: {
          description:
            'Ihre Angaben wurden an unseren Kundenservice übermittelt. Wir setzen uns zeitnah mit Ihnen in Verbindung.',
          message: 'Vielen Dank!',
        },
        title: 'Kontaktformular für Neukunden',
        zip: 'Postleitzahl',
      },
      email: 'E-Mail',
      error: {
        backend: {
          alreadyRegistered: 'Dieser Nutzer ist bereits registriert',
          email: 'Diese E-Mail-Adresse wurde bereits verwendet',
          invalidCustomerOrZipCode: {
            description: `Die Kombination aus Kundennummer und Postleitzahl ist unbekannt! Bei Rückfragen, wenden Sie 
            sich per E-Mail <0>(bestellservice@raps.de)</0> oder Telefon <1>(0800 439 83 79)</1>  an unseren Kundensupport.`,
            errorDescription:
              'Die Kombination aus Kundennummer und Postleitzahl ist unbekannt!',
            errorDescriptionCustomerService:
              'Die Kombination aus Kundennummer und Postleitzahl ist entweder unbekannt oder bereits in Verwendung.',
            message: 'Ungültige Kombination!',
          },
        },
        message: 'Registrierung fehlgeschlagen',
      },
      firstName: 'Vorname',
      lastName: 'Nachname',
      newCustomerDescription:
        'Bitte nutzen Sie das <0>Neukundenformular</0>, um mit unserem Kundenservice in Kontakt zu treten.',
      newCustomerMessage: 'Neukunde bei RAPS?',
      password: 'Passwort',
      passwordInvalid: 'Das Passwort muss aus mindestens 8 Zeichen bestehen',
      repeatPassword: 'Passwort bestätigen',
      repeatPasswordWrong: 'Die Passwörter stimmen nicht überein',
      submit: 'Registrieren',
      success: {
        description:
          'Sie haben eine Bestätigungsmail erhalten. Bitte öffnen Sie den darin enthaltenen Link um Ihren Account zu aktivieren.',
        message: 'Registrierung erfolgreich!',
      },
      title: 'Registrieren',
      zipCode: 'Postleitzahl',
    },
    search: {
      action: 'Suchen',
      for: 'für',
      loadPage: 'Lade Seite',
      noResults: 'Keine Einträge vorhanden',
      noResultsFor:
        'Keine Ergebnisse gefunden für "<strong>{{searchTerm}}</strong>"',
      placeholder:
        'Rezepttitel (z.B. Salat), Rezeptnummer (z.B. 5503167) oder Zutatennummer (z.B. 1000692)',
      recipe_one: '{{count}} Rezept',
      recipe_other: '{{count}} Rezepte',
      searchFor: 'Suche nach',
      showMultiResultInfo:
        'Zeige Ergebnisse {{pageStart}}-{{pageEnd}} von {{total}}',
      showSingleResultInfo: 'Zeige Ergebnis {{num}} von {{total}}',
      showAllResults: 'Alle Ergebnisse anzeigen',
      tabHeadings: {
        categories: 'Kategorien',
        ingredients: 'Zutaten',
        myCollections: 'Meine Rezeptsammlungen',
        privateRecipes: 'Meine Rezepte',
        publicCollections: 'RAPS Rezeptsammlungen',
        rapsRecipes: 'RAPS Rezepte',
        rapsSAPRecipes: 'Erstellte RAPS Rezepte',
        recipes: 'Rezepte',
      },
    },
    shop: {
      alreadyInShoppingCart:
        'Dieses Produkt befindet sich bereits in Ihrem Warenkorb',
      choseCategory: 'Wählen Sie eine Kategorie',
      columns: {
        container: 'Gebinde',
        containerSize: 'Gebindegröße',
        foodName: 'Verkehrsbezeichnung',
        name: 'Bezeichnung',
        number: 'Artikelnummer',
      },
      explanationContainerSize: {
        content: 'Verfügbare Gebindegrößen: {{ containerSizes }}',
        title: 'Gebindegröße',
      },
      noDataContent: 'Es sind keine Daten zu der gewählten Kategorie verfügbar',
      noDataHeader: 'Es sind keine Daten verfügbar.',
      selectPlaceholder: 'Wählen Sie eine Kategorie aus',
    },
    shoppingCart: {
      addToBasket: 'Zutaten in den Warenkorb',
      addToBasketRequiresUser:
        'Das Hinzufügen von Zutaten zum Warenkorb ist nur als angemeldeter Nutzer möglich',
      addToBasketSuccess: {
        description:
          'Die Zutaten wurden erfolgreich dem Warenkorb hinzugefügt.',
        message: 'Warenkorb aktualisiert',
      },
      agb: {
        message: 'gelesen und akzeptiert',
        name: 'AGB',
        notAcceptedError: 'Bitte akzeptieren Sie unsere AGB',
      },
      amount: 'Stückzahl',
      createdOrder: 'Bestellung wurde erstellt',
      duplicateContainers: {
        description:
          'Es ist nicht erlaubt mehrfach die gleiche Gebindegröße zu verwenden.',
        message: 'Gebindegröße bereits verwendet',
      },
      emptyButton: 'Warenkorb leeren',
      emptyConfirm: 'Wollen Sie den Warenkorb wirklich leeren?',
      emptyMessage:
        'Der Warenkorb ist leer. Über ein Rezept können Sie RAPS Zutaten in den Warenkorb legen',
      messagePlaceholder: 'Ihre Nachricht an uns!',
      needed: 'Benötigt',
      orderButton: 'Bestellen',
      sum: 'Summe',
      table: {
        ingredient: 'Produkt',
        quantity: 'Menge',
      },
      title: 'Warenkorb',
      units: {
        KG: 'kg',
      },
      warning: `Die Bestellung ist verbindlich und erfolgt auf Basis der mit Ihnen vereinbarten kundenindividuellen Preise. 
      Für Neukunden gelten die zum Zeitpunkt der Bestellung gültigen Listenpreise. Für den Fall, dass abhängig von der 
      Stückzahl Rabattstaffeln Anwendung finden, werden diese entsprechend berücksichtigt.`,
    },
    termsOfUse: {
      licenses: {
        text: 'Unsere verwendeten Dritt-Lizenzen finden Sie ',
        title: 'Verwendete Lizenzen',
      },
      title: 'Rechtliche Hinweise',
    },
    update: {
      button: 'Aktualisieren',
      description:
        'Klicken Sie auf "Aktualisieren" um die neue Version anzuzeigen:',
      message: 'Aktualisierung verfügbar',
    },
    userManagement: {
      accountNumber: 'Kundennummer',
      actions: 'Aktionen',
      changeEmailDisabled:
        'Die E-Mail-Adresse kann nur geändert werden, wenn dem entsprechenden Benutzer eine Kundennummer zugeordnet ist.',
      collectionEditor: 'Verwaltung von RAPS Rezeptsammlungen',
      confirmDeleteMessage:
        'Soll der Benutzer mit der Kundennummer {{accountNumber}} wirklich gelöscht werden?',
      editMailButton: 'E-Mail ändern',
      editUserEmail: 'E-Mail-Adresse für Kundennummer {{accountNumber}} ändern',
      email: 'E-Mail',
      newEmail: 'Neue E-Mail-Adresse eingeben',
      newsAuthor: 'News-Autor',
      recipeCreator: 'Erstellung von RAPS Rezepten',
      reviewer: 'Verwaltung von Rezepten',
      transferAgent: 'Berater', // has the ability to transfer recipes & collections
      roles: 'Berechtigungen',
      sameMail:
        'Die E-Mail-Adresse ist identisch zur aktuellen E-Mail-Adresse dieses Benutzers',
      selectRole: 'Benutzerrollen auswählen',
      title: 'Benutzerverwaltung',
    },
    userRequiredInfo: {
      button: 'Ok',
      message:
        'Diese Funktionalität steht nur angemeldeten Nutzern zur Verfügung.',
    },
  },
};

export type Translations = typeof translationsDE;

export default translationsDE;
