import { gql } from '@apollo/client';
import { CORE_INGREDIENT_FIELDS } from './ingredient';

export const CORE_QUANTITY_FIELDS = gql`
  fragment CoreQuantityFields on QuantityConnection {
    items: edges {
      node {
        quantity
      }
    }
  }
`;

export const ENHANCED_QUANTITY_FIELDS = gql`
  ${CORE_INGREDIENT_FIELDS}
  fragment EnhancedQuantityFields on QuantityConnection {
    totalCount
    items: edges {
      cursor
      node {
        _id
        id
        position
        quantity
        quantityUnit
        isQuid
        stpoClass
        ingredient {
          ...CoreIngredient
          _id
          declaration
          isDeleted
          nutritionalInformation(first: 100) {
            totalCount
            items: edges {
              cursor
              node {
                _id
                id
                key
                quantity
              }
            }
          }
          shopIngredients {
            edges {
              node {
                id
                containerSize
                isDeleted
                number
              }
            }
          }
          shoppingCartItems @include(if: $withUser) {
            totalCount
            edges {
              node {
                id
                quantity
                containerSize
                extraAmount
              }
            }
          }
        }
      }
    }
  }
`;
