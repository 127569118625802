import React, { FunctionComponent } from 'react';
import type { IUserData } from '../gql/get-users-by-account-number';

interface IAutocompleteLabel {
  node: IUserData;
}

export const TransferAutocompleteLabel: FunctionComponent<
  IAutocompleteLabel
> = ({ node }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <span>{node.accountNumber}</span>
    <span>
      {node.firstName} {node.lastName}
    </span>
  </div>
);
