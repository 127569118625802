import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { InboxOutlined, PictureOutlined } from '@ant-design/icons';
import { App, Button, Modal, Upload, UploadFile } from 'antd';
import { useTranslation } from 'react-i18next';

import { UploadChangeParam } from 'antd/es/upload';
import { AuthGuard } from '../../../../../components/AuthGuard';
import { INewsItem } from '../../../../../gql/get-news';
import { IRecipe } from '../../../../../gql/get-recipe';
import { apiUrls } from '../../../../../lib/apiUrls';
import { handleError } from '../../../../../lib/handleError';

type IProps = {
  newsItem?: INewsItem;
  onImageChanged: () => Promise<void>;
  recipe?: IRecipe;
  roles: string[];
};

export const RECIPES = 'recipes';
export const NEWS_ITEMS = 'newsItems';
export const CATEGORIES = 'categories';

// overrides the default behavior of POSTing the image on upload
const setCustomRequest = () => ({});

const handleOnPreview = () => null;

const getInitialImage = (
  item: IRecipe | INewsItem,
  uploadForEntity: string,
  isImageFromCache: boolean,
): UploadFile => {
  const imageId = item.imageId ?? item._id;

  return {
    name: `${imageId}.jpg`,
    percent: 100,
    size: 0,
    status: 'done',
    type: 'image/jpeg',
    uid: '-1',
    url: apiUrls.image.url(uploadForEntity, item._id, {
      thumb: true,
      isImageFromCache,
      imageId: item.imageId,
    }),
  };
};

const UploadModal: FunctionComponent<{
  data: IRecipe | INewsItem;
  dataType: string;
  onImageChanged: () => Promise<void>;
  isUploadModalOpen: boolean;
  setIsUploadModalOpen: Dispatch<SetStateAction<boolean>>;
}> = ({
  data,
  isUploadModalOpen,
  onImageChanged,
  setIsUploadModalOpen,
  dataType,
}) => {
  const { t } = useTranslation();
  const { modal, notification } = App.useApp();

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [fromCache, setFromCache] = useState(true);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const hideUploadModal = useCallback(() => {
    setIsConfirmLoading(false);
    setIsUploadModalOpen(false);
    setIsChangesMade(false);
  }, [setIsUploadModalOpen]);

  const handleOnChange = useCallback(
    (info: UploadChangeParam) => {
      // Check if the file is being removed
      if (info.file.status === 'removed') {
        return;
      }

      // see https://stackoverflow.com/questions/66982391/upload-file-stuck-on-uploading-status-with-customrequest-ant-design-antd
      if (info.file.status === 'uploading') {
        // eslint-disable-next-line no-param-reassign
        info.file.status = 'done';
      }

      // Check if the file is bigger than 2MiB
      if ((info.file.size ?? 0) > 2 * 1024 * 1024) {
        notification.error({
          message: t('recipe.uploadImageMaxSizeHint'),
        });

        return;
      }

      // Allow to display the complete progress bar
      // eslint-disable-next-line no-param-reassign
      info.file.percent = 100;
      // Allow only one file to be uploaded
      setFileList([info.file]);
      setIsChangesMade(true);
    },
    [notification, t],
  );

  const uploadImage = useCallback(async () => {
    if (!isChangesMade) {
      hideUploadModal();

      return;
    }
    setIsConfirmLoading(true);

    const onlyDelete = fileList.length === 0 && data.hasImage;

    const upload = async () => {
      const formData = new FormData();

      if (onlyDelete) {
        formData.append('toDeleteImage', '1'); // only delete previous image
      } else {
        if (fileList[0].originFileObj !== undefined) {
          formData.append('image', fileList[0].originFileObj); // set new image
        }
        formData.append('toDeleteImage', '0'); // delete previous image
      }

      try {
        const { ok, status } = await fetch(
          apiUrls.image.update(dataType, data._id),
          {
            body: formData,
            credentials: 'same-origin',
            method: 'POST',
          },
        );

        // If the uploaded file is too large (413)
        if (!ok && status === 413) {
          notification.error({
            message: t('recipe.uploadImageMaxSizeHint'),
          });
        }

        await onImageChanged();
        hideUploadModal();
      } catch (e) {
        handleError(e);
      }
    };

    // Delete image
    if (onlyDelete) {
      // ask the user really wants to delete the image
      modal.confirm({
        cancelText: t('modal.cancel'),
        content:
          dataType === RECIPES
            ? t('recipe.deleteImageContent')
            : t('news.deleteImageContent'),
        okText: t('modal.ok'),
        onCancel: hideUploadModal,
        onOk: upload,
        title:
          dataType === RECIPES
            ? t('recipe.deleteImageTitle')
            : t('news.deleteImageTitle'),
      });
    } else {
      await upload();
      setFromCache(false);
    }
  }, [
    isChangesMade,
    fileList,
    data.hasImage,
    data._id,
    hideUploadModal,
    dataType,
    onImageChanged,
    notification,
    t,
    modal,
  ]);

  useEffect(() => {
    // set initial image if the current recipe has one
    if (data.hasImage) {
      setFileList([getInitialImage(data, dataType, fromCache)]);
    }
  }, [data, dataType, fromCache]);

  return (
    <Modal
      title={t('recipe.uploadImageTitle')}
      open={isUploadModalOpen}
      onCancel={hideUploadModal}
      okText={t('recipe.closeAndSaveModal')}
      cancelText={t('modal.cancel')}
      onOk={uploadImage}
      confirmLoading={isConfirmLoading}
    >
      <Upload.Dragger
        fileList={fileList}
        accept="image/*"
        listType="picture"
        multiple={false}
        onChange={handleOnChange}
        onPreview={handleOnPreview}
        customRequest={setCustomRequest}
        onRemove={() => {
          setFileList([]);
          setIsChangesMade(data.hasImage);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('recipe.uploadImageText')}</p>
        <p className="ant-upload-hint">{t('recipe.uploadImageMaxSizeHint')}</p>
      </Upload.Dragger>
    </Modal>
  );
};

export const UploadImageAction: FunctionComponent<IProps> = ({
  roles,
  onImageChanged,
  newsItem,
  recipe,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const onClick = useCallback(() => {
    setIsUploadModalOpen(true);
  }, []);

  const data = recipe || newsItem;

  return (
    <>
      <AuthGuard roles={roles}>
        <Button
          icon={<PictureOutlined />}
          shape="circle"
          size="large"
          onClick={onClick}
        />
      </AuthGuard>
      {isUploadModalOpen && data && (
        <UploadModal
          data={data}
          dataType={recipe ? RECIPES : NEWS_ITEMS}
          isUploadModalOpen={isUploadModalOpen}
          setIsUploadModalOpen={setIsUploadModalOpen}
          onImageChanged={onImageChanged}
        />
      )}
    </>
  );
};
