import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import { App, Checkbox, List, Tooltip } from 'antd';
import { useApolloClient } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  GET_COLLECTION_RECIPES,
  IResponse as ICollectionRecipesResponse,
  IVariables as ICollectionRecipesVariables,
} from '../../../gql/get-collection-recipes';
import { IResponse as QueryResponse } from '../../../gql/get-recipe-collections';
import {
  IContext,
  IVariables,
  UPDATE_COLLECTION_RECIPES,
} from '../../../gql/update-collection-recipes';
import { handleError } from '../../../lib/handleError';
import { ScrollableList } from '../../../lib/styles';
import { CreateCollectionAction } from '../../CreateCollectionAction';
import { loadingIcon } from '../../Loading';
import { IRecipeShape } from '../utils/IRecipeShape';

interface IListItem {
  _id: number;
  checked: boolean;
  id: string;
  name: string;
  recipeCount: number;
}

type IProps = {
  recipe: IRecipeShape;
  data?: QueryResponse;
  loading: boolean;
  isPublic: boolean;
  onAfterAddRecipe: () => Promise<void>;
  onActionTrigger: () => void;
};

const WrapWithTooltip: FunctionComponent<
  PropsWithChildren<{ showTooltip: boolean }>
> = ({ children, showTooltip }) => {
  if (!showTooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip title={<Trans i18nKey="collections.limitReached" />}>
      {children}
    </Tooltip>
  );
};

export const CollectionsList: FunctionComponent<IProps> = ({
  loading,
  data,
  recipe,
  isPublic,
  onAfterAddRecipe,
  onActionTrigger,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const dataSource =
    data &&
    data.collections.items.map<IListItem>(({ node }) => ({
      _id: node._id,
      checked: !!data.recipe.collections.items.find(
        (i) => i.node._id === node._id,
      ),
      id: node.id,
      name: node.name,
      recipeCount: node.recipes.totalCount,
    }));

  const handleOnCollectionCreated = useCallback(
    async (collectionId?: string) => {
      onActionTrigger();

      // add the recipe to the passed collectionId
      if (collectionId && data && data.recipe && data.recipe.id) {
        const addResult = await client.mutate<IContext, IVariables>({
          mutation: UPDATE_COLLECTION_RECIPES,
          variables: { id: collectionId, recipes: [data.recipe.id] },
        });

        // display a notification showing that the recipe was not added automatically
        if (addResult.errors) {
          notification.error({
            description: t('common.error.unknown.description'),
            message: t('common.error.unknown.message'),
          });
        }
      }

      await onAfterAddRecipe();
    },
    [client, data, notification, onActionTrigger, onAfterAddRecipe, t],
  );

  const searchCollectionRecipes = useCallback(
    async (item: IListItem) => {
      const query = await client.query<
        ICollectionRecipesResponse,
        ICollectionRecipesVariables
      >({
        query: GET_COLLECTION_RECIPES,
        fetchPolicy: 'network-only',
        variables: {
          collectionIriId: item.id,
          collectionNumericId: item._id,
          itemsPerPage: 100,
        },
      });

      if (query.errors) {
        throw new Error(query.errors[0].message);
      }

      if (!query.data.collection) {
        throw new Error(t('collections.retrievalFailed'));
      }

      return query.data;
    },
    [client, t],
  );

  const handleCheckboxChange = useCallback(
    async (item: IListItem, e: CheckboxChangeEvent) => {
      onActionTrigger();

      try {
        // Step 1: Get all recipes currently in the collection
        const response = await searchCollectionRecipes(item);
        const recipes = response.recipes.items.map((el) => el.node.id);
        const newRecipes = item.checked
          ? recipes.filter((el) => el !== recipe.id)
          : recipes.concat(recipe.id);

        // Step 2: Update collection to contain the new list of recipes
        await client.mutate<IContext, IVariables>({
          mutation: UPDATE_COLLECTION_RECIPES,
          variables: { id: item.id, recipes: newRecipes },
        });

        const eventAction = e.target.checked
          ? 'Rezept zur Sammlung hinzugefügt'
          : 'Rezept von Sammlung entfernt';

        if (response.collection) {
          ReactGA.gtag('event', eventAction, {
            event_category: 'Sammlungen',
            recipe_name: recipe.title, // dimension1
            collection_name: response.collection.name, // dimension4
          });
        }

        // Step 3 Reflect changes in UI
        await onAfterAddRecipe();
      } catch (error) {
        handleError(error);
      }
    },
    [
      client,
      onActionTrigger,
      onAfterAddRecipe,
      recipe.id,
      recipe.title,
      searchCollectionRecipes,
    ],
  );

  return (
    <>
      <CreateCollectionAction
        onCreated={handleOnCollectionCreated}
        inline
        isPublic={isPublic}
      />
      <ScrollableList>
        <List
          loading={{
            indicator: loadingIcon,
            spinning: loading,
          }}
          split={false}
          rowKey="id"
          dataSource={dataSource}
          renderItem={(item) => {
            const showTooltip = item.recipeCount > 99 && !item.checked;

            return (
              <List.Item>
                <WrapWithTooltip showTooltip={showTooltip}>
                  <Checkbox
                    onChange={handleCheckboxChange.bind(null, item)}
                    checked={item.checked}
                    disabled={showTooltip}
                  >
                    {item.name} ({item.recipeCount})
                  </Checkbox>
                </WrapWithTooltip>
              </List.Item>
            );
          }}
        />
      </ScrollableList>
    </>
  );
};
