import styled from '@emotion/styled';
import React from 'react';
import { maxScreenSm } from '../lib/styles';
import { NoImageIcon } from './NoImageIcon';

interface IProps {
  imageWidths: {
    xs: number;
    sm: number;
    lg: number;
  };
  imageAspectRatio: number;
}

const StyledNoImageBackground = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: ${(props) => props.imageWidths.lg * props.imageAspectRatio}px;

  ${(props) =>
    maxScreenSm(`
        height: ${props.imageWidths.sm * props.imageAspectRatio}px;
      `)};

  ${(props) =>
    maxScreenSm(`
        height: ${props.imageWidths.xs * props.imageAspectRatio}px;
    `)};
`;

export const BackgroundNoImgFixedAspectRatio: React.FunctionComponent<
  IProps
> = ({ imageAspectRatio, imageWidths }) => (
  <div>
    <StyledNoImageBackground
      imageAspectRatio={imageAspectRatio}
      imageWidths={imageWidths}
    >
      <NoImageIcon />
    </StyledNoImageBackground>
  </div>
);
