import { gql } from '@apollo/client';

export interface IContext {
  recipe: {
    id: string;
  };
}

export interface IVariables {
  input: {
    id: string;
  };
}

export const DELETE_RECIPE = gql`
  mutation deleteNewsItem($input: deleteRecipeInput!) {
    deleteRecipe(input: $input) {
      recipe {
        id
      }
    }
  }
`;
