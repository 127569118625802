/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { environmentAwareConfig } from './lib/environmentAwareConfig';

type Consent = {
  necessary: boolean;
  performance: boolean;
  functional: boolean;
  advertising: boolean;
};

export const getConsent = (): Consent =>
  (window as any)?.CookieFirst?.consent ||
  JSON.parse(Cookies.get('cookiefirst-consent') || '{}');

export const getAnalyticsConsent = (): boolean =>
  getConsent().performance || process.env.REACT_APP_DEVELOPMENT === 'true';

export const useDisableAutomaticPageView = () => {
  useEffect(() => {
    const { GA_MEASUREMENT_ID } = environmentAwareConfig;

    if (GA_MEASUREMENT_ID && getAnalyticsConsent()) {
      ReactGA.gtag('config', GA_MEASUREMENT_ID, { send_page_view: false });
    }
  }, []);
};

export function trackCustomPageView(title = document.title) {
  if (getAnalyticsConsent()) {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title,
    });
  }
}

const setAnalyticsPreferences = () => {
  const hasConsent = getAnalyticsConsent(); // The cookie might not be set the first time the hook is called

  const { GA_MEASUREMENT_ID } = environmentAwareConfig;

  if (GA_MEASUREMENT_ID && hasConsent) {
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      gtagOptions: {
        send_page_view: false, // https://github.com/codler/react-ga4/issues/27#issuecomment-1519228872
      },
    });
    ReactGA.set({ anonymizeIp: true });

    if (process.env.REACT_APP_DEVELOPMENT === 'true') {
      // eslint-disable-next-line no-console
      console.log(
        'Analytics enabled',
        `consent: ${hasConsent}`,
        `GA: ${GA_MEASUREMENT_ID}`,
      );
    }

    // we must track the initial page view manually
    trackCustomPageView();
  } else if (process.env.REACT_APP_DEVELOPMENT === 'true') {
    // eslint-disable-next-line no-console
    console.log(
      'Analytics disabled',
      `consent: ${hasConsent}`,
      `GA: ${GA_MEASUREMENT_ID}`,
    );
  }
};

export const useAnalytics = () => {
  useEffect(() => {
    // See https://support.cookiefirst.com/hc/en-us/articles/360011568738-Cookie-Banner-Public-API-documentation
    window.addEventListener('cf_consent', setAnalyticsPreferences);

    setAnalyticsPreferences();

    return () => {
      window.removeEventListener('cf_consent', setAnalyticsPreferences);
    };
  }, []);
};
