import { gql } from '@apollo/client';

export const CORE_NEWS_ITEM_FIELDS = gql`
  fragment CoreNewsItemFields on NewsItem {
    _id
    id
    content
    hasImage
    imageId
    subtitle
    title
  }
`;
