import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { DataProxy, FetchResult, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { handleError } from '../../../lib/handleError';
import {
  GET_SHOPPING_CART_ITEMS,
  IDeleteResponseSingle,
  IResponse,
  IShoppingCartItem,
} from '../../../gql/get-shopping-cart-items';
import { IIngredientRow } from '../interfaces';
import { getQuantitySum } from '../utils';
import { DELETE_SHOPPING_CART_ITEM } from '../../../gql/delete-shopping-cart-item';
import { UPDATE_SHOPPING_CART_ITEM } from '../../../gql/update-shopping-cart-item';

interface IRemoveItemAction {
  record: IIngredientRow;
  container: IShoppingCartItem;
  setQuantitySum: Dispatch<SetStateAction<number>>;
}

export const RemoveItemButton: FunctionComponent<IRemoveItemAction> = ({
  record,
  container,
  setQuantitySum,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const deleteItem = useCallback(async () => {
    setLoading(true);

    // Save the Quantity ('needed') if there are other containers
    const containerQuantity = parseFloat(container.quantity);

    if (containerQuantity > 0) {
      try {
        const existingContainers = record.containers.filter(
          (existingContainer: IShoppingCartItem) =>
            existingContainer.id !== container.id,
        );

        await client.mutate({
          mutation: UPDATE_SHOPPING_CART_ITEM,
          variables: {
            input: {
              id: existingContainers[0].id,
              quantity:
                parseFloat(existingContainers[0].quantity) + containerQuantity,
            },
          },
        });
      } catch (error) {
        handleError(error);
        setLoading(false);

        return;
      }
    }

    try {
      await client.mutate({
        mutation: DELETE_SHOPPING_CART_ITEM,
        update: (
          cache: DataProxy,
          result: FetchResult<IDeleteResponseSingle>,
        ) => {
          const cachedData = cache.readQuery<IResponse>({
            query: GET_SHOPPING_CART_ITEMS,
          });

          if (!cachedData) {
            return;
          }

          cache.writeQuery({
            data: {
              shoppingCartItems: {
                ...cachedData.shoppingCartItems,
                edges: cachedData.shoppingCartItems.edges.filter(
                  (shoppingCartItem) =>
                    !result ||
                    !result.data ||
                    shoppingCartItem.node.id !==
                      result.data.deleteShoppingCartItem.shoppingCartItem.id,
                ),
              },
            },
            query: GET_SHOPPING_CART_ITEMS,
          });
        },
        variables: {
          input: {
            id: container.id,
          },
        },
      });
    } catch (e) {
      handleError(e);
    }
    setLoading(false);

    setQuantitySum(getQuantitySum(record));
  }, [client, container.id, container.quantity, record, setQuantitySum]);

  return (
    <Button
      onClick={deleteItem}
      loading={loading}
      size="small"
      shape="circle"
      title={t('common.remove')}
      icon={<CloseOutlined />}
    />
  );
};
