import React from 'react';

const allergens = [
  'BUTTER',
  'CASHEWKERNE',
  'DINKEL',
  'EI',
  'EIER',
  'ERDN[U|Ü]SS[E]?',
  'FISCH',
  'GERSTE[N]?',
  'GLUTEN',
  'HAFER',
  'HASELN[U|Ü]SS[E]?',
  'KASCHUN[U|Ü]SS[E]?',
  'KÄSE',
  'KREBSTIERE',
  'LAKTOSE',
  'LUPINEN',
  'MACADAMIAN[U|Ü]SS[E]?',
  'MANDEL',
  'MILCH',
  'MOLKE',
  'N[U|Ü]SS[E]?',
  'PARAN[U|Ü]SS[E]?',
  'PECAN[U|Ü]SS[E]?',
  'PISTAZIEN',
  'QUEENSLANDN[U|Ü]SS[E]?',
  'ROGGEN',
  'SAHNE',
  'SCHWEFELDIOXID',
  'SELLERIE',
  'SENF',
  'SESAM',
  'SOJA',
  'SULFIT',
  'WALLN[U|Ü]SS[E]?',
  'WEICHTIERE',
  'WEIZEN',
];

const highlightRegex = new RegExp(`(${allergens.join('|')})`);

export const highlightDeclarations = (declarations: string) =>
  declarations
    .split(highlightRegex)
    .map((str, i) => (i % 2 === 1 ? <strong key={i}>{str}</strong> : str));
