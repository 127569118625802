import React from 'react';
import { Outlet } from 'react-router-dom';
import { DefaultLayout } from './DefaultLayout';
import { GoBack } from './GoBack';

export const DefaultLayoutWrapper = () => (
  <DefaultLayout>
    <GoBack />
    <Outlet />
  </DefaultLayout>
);
