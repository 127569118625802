import { gql } from '@apollo/client';

export interface IOrderInput {
  message?: string;
}

export const CREATE_USER_ORDER = gql`
  mutation createUserOrder($input: createUserOrderInput!) {
    response: createUserOrder(input: $input) {
      userOrder {
        id
      }
    }
  }
`;
