import React, { FunctionComponent, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface ICopyButton {
  name: string;
}

export const CopyButton: FunctionComponent<ICopyButton> = ({ name }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCopyClick = useCallback(() => {
    ReactGA.gtag('event', 'Rezept kopieren', {
      event_category: 'Rezeptbearbeitung',
      recipe_name: name, // dimension1
    });

    navigate('copy');
  }, [navigate, name]);

  return (
    <Button onClick={handleCopyClick} icon={<CopyOutlined />}>
      {t('recipe.copyButton')}
    </Button>
  );
};
