import React, { ReactElement } from 'react';
import { roles } from '../../lib/userRoles';
import { AuthGuard } from '../../components/AuthGuard';
import { SearchResultRecipes } from './pages/SearchResult/components/SearchResultRecipes';
import { SearchResultCollections } from './pages/SearchResult/components/SearchResultCollections';
import { SearchResultCategories } from './pages/SearchResult/components/SearchResultCategories';

export interface ISearchTab {
  path: string;
  i18nKey: string;
  countKey: string;
  component: (props: ILimitedResultProps) => ReactElement;
  roles?: string[];
  showCarousel?: boolean;
}

interface ILimitedResultProps {
  pageSize?: number;
  i18nKey?: string;
  path?: string;
  showCarousel?: boolean;
}

export const SearchResultTabs: ISearchTab[] = [
  {
    // Raps Rezepte
    path: 'raps-recipes',
    i18nKey: 'search.tabHeadings.rapsRecipes',
    countKey: 'publicRecipes',
    component: (props: ILimitedResultProps) => (
      <SearchResultRecipes {...props} />
    ),
  },
  {
    path: 'raps-sap-recipes',
    i18nKey: 'search.tabHeadings.rapsSAPRecipes',
    countKey: 'SAPRecipes',
    component: (props: ILimitedResultProps) => (
      <AuthGuard roles={[roles.creator]}>
        <SearchResultRecipes isSAP {...props} />
      </AuthGuard>
    ),
    roles: [roles.creator],
  },
  {
    path: 'recipes', // user's recipes
    i18nKey: 'search.tabHeadings.privateRecipes',
    countKey: 'ownRecipes',
    component: (props: ILimitedResultProps) => (
      <SearchResultRecipes ownedByUser {...props} />
    ),
  },
  {
    path: 'raps-collections',
    i18nKey: 'search.tabHeadings.publicCollections',
    countKey: 'publicCollections',
    component: (props: ILimitedResultProps) => (
      <SearchResultCollections {...props} />
    ),
  },
  {
    path: 'recipe-collections',
    i18nKey: 'search.tabHeadings.myCollections',
    countKey: 'ownCollections',
    component: (props: ILimitedResultProps) => (
      <SearchResultCollections hideAddCollectionButton ownedByUser {...props} />
    ),
  },
  {
    path: 'categories',
    i18nKey: 'search.tabHeadings.categories',
    countKey: 'categories',
    component: (props: ILimitedResultProps) => (
      <SearchResultCategories {...props} />
    ),
  },
];
