export const maxSearchResults = 4;

export const isRecipeNumberPattern = /^550/;

export const expandSearch = (text?: string) => {
  if (text === undefined) {
    return undefined;
  }

  const search = new Set<string>(text.split(/[\s-]/));

  return [...search].join(' ');
};
