import React from 'react';
import { PathRouteProps } from 'react-router-dom';
import { RecipeList } from '../../components/RecipeList';
import { roles } from '../../lib/userRoles';
import { RecipeCollectionList } from '../../components/RecipeCollectionList';
import { CollectionDetailList } from './pages/collection-detail-list';

interface ITab {
  path: string;
  i18nKey: string;
  countKey: string;
  element: React.ReactElement;
  children?: (PathRouteProps & { key: string })[];
  requiresUser?: boolean;
  roles?: string[];
}

export const CollapsePanels: ITab[] = [
  {
    // my recipe
    path: 'my-recipes',
    element: (
      <RecipeList ownedByUser i18nKey="collections.tabHeadings.myRecipes" />
    ),
    countKey: 'myRecipes',
    i18nKey: 'collections.tabHeadings.myRecipes',
    requiresUser: true,
  },
  {
    // my recipe collection overview
    path: 'my-recipe-collection',
    element: (
      <RecipeCollectionList
        ownedByUser
        i18nKey="collections.tabHeadings.myCollections"
      />
    ),
    children: [
      {
        path: ':collectionId',
        key: 'my-recipe-collection-detail',
        element: (
          <CollectionDetailList
            showDeleteBtn
            collectionCategory="collections.tabHeadings.myCollections"
          />
        ),
      },
    ],
    countKey: 'myCollections',
    i18nKey: 'collections.tabHeadings.myCollections',
    requiresUser: true,
  },
  {
    // Raps recipe collection
    path: 'raps-recipe-collection',
    element: (
      <RecipeCollectionList i18nKey="collections.tabHeadings.publicCollections" />
    ),
    children: [
      {
        key: 'raps-recipe-collection-detail',
        path: ':collectionId',
        element: (
          <CollectionDetailList collectionCategory="collections.tabHeadings.publicCollections" />
        ),
      },
    ],
    countKey: 'publicCollections',
    i18nKey: 'collections.tabHeadings.publicCollections',
  },
  {
    // created Raps recipes
    path: 'raps-recipes',
    element: <RecipeList isSAP i18nKey="collections.tabHeadings.rapsRecipes" />,
    countKey: 'rapsRecipes',
    i18nKey: 'collections.tabHeadings.rapsRecipes',
    requiresUser: true,
    roles: [roles.creator],
  },
];
