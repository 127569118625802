import React, { FunctionComponent } from 'react';
import { Card } from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CardColumn } from '../../../../../components/CardColumn';
import { NewsCard } from '../../../../../components/NewsCard';
import {
  GET_NEWS,
  INewsItem,
  INewsItemsResponse,
  ISearchVariables,
} from '../../../../../gql/get-news';
import { ErrorGraphQL } from '../../../../../components/ErrorGraphQL';
import { defaultGutterPixelSize } from '../../../../../lib/styles';

interface IProps {
  newsItem: INewsItem;
}

export const NewsSideBar: FunctionComponent<IProps> = ({ newsItem }) => {
  const { t } = useTranslation();

  const { error, data, loading } = useQuery<
    INewsItemsResponse,
    ISearchVariables
  >(GET_NEWS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      itemsPerPage: 5,
      orderBy: { createdAt: 'DESC' },
    },
  });

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <CardColumn>
        <Card loading />
      </CardColumn>
    );
  }

  if (!data?.response.items) {
    return null;
  }

  const newsItems = data.response.items.filter(
    (item: { cursor: string; node: INewsItem }) =>
      item.node._id !== newsItem._id,
  );

  return (
    <aside>
      <h2>{t('news.moreNews')}</h2>
      <div
        css={{
          display: 'grid',
          gap: defaultGutterPixelSize,
          gridTemplateColumns: 'repeat(auto-fill, minMax(200px, 1fr))',
          gridAutoFlow: 'dense',
        }}
      >
        {newsItems.map((item) => (
          <NewsCard key={item.node.id} item={item.node} responsive />
        ))}
      </div>
    </aside>
  );
};
