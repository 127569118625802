import styled from '@emotion/styled';
import { Button } from 'antd';
import { circleButtonStyle } from '../../../lib/styles';
import { rapsRed } from '../../../theme/customVariables';

export const OverlayActions = styled('div')`
  position: absolute;
  right: 0.8em;
  top: 0.8em;
  width: 32px;
`;

export const RelativeContainer = styled('div')`
  position: relative;
`;

export const OverlayButton = styled(Button)`
  ${circleButtonStyle};
`;

export const Description = styled.p`
  color: ${rapsRed};
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  text-align: right;
`;
