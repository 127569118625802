import { FormInstance } from 'antd';
import { IIngredientRow } from '../interfaces';
import { IShoppingCartItem } from '../../../gql/get-shopping-cart-items';

export const QUANTITY_FIELD_NAME = 'quantity';
export const CONTAINER_SIZE_FIELD_NAME = 'containerSize';
export const MINIMUM_QUANTITY = 1;

export const getQuantitySum = (record: IIngredientRow) => {
  let sum = 0;

  record.containers.forEach((container: IShoppingCartItem) => {
    sum += parseFloat(container.quantity);
  });

  return sum;
};

export const getContainerSum = (record: IIngredientRow, form: FormInstance) => {
  let sum = 0;

  record.containers.forEach((item, i: number) => {
    const quantity =
      form.getFieldValue(QUANTITY_FIELD_NAME + i) || MINIMUM_QUANTITY;
    const containerSize =
      form.getFieldValue(CONTAINER_SIZE_FIELD_NAME + i) ||
      parseFloat(item.containerSize);

    // If value came from backend it is a string. if it was changed => number
    const convertedSize =
      typeof containerSize === 'string'
        ? Number.parseFloat(
            form.getFieldValue(CONTAINER_SIZE_FIELD_NAME + i).split(' '),
          )
        : containerSize;

    sum += Number.parseFloat((quantity * convertedSize).toFixed(2));
  });

  return sum;
};

export const getIngredientUnit = (ingredient: IIngredientRow) => {
  const units = ingredient.shopIngredients.edges.map(
    (shopIngredient) => shopIngredient.node.unit,
  );

  if (!units.length) {
    return '';
  }

  return units[0];
};
