import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

interface IProps {
  additionalStyles?: SerializedStyles;
  src: string | null;
  height: number;
}

const StyledBackgroundImage = styled.div<IProps>`
  background-image: url('${(props) => props.src}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: ${(props) => props.height}px;
  ${(props) => (props.additionalStyles ? props.additionalStyles : '')};
`;

export const BackgroundImage = StyledBackgroundImage;
