// defaults override objects stored by the cache persister => so there are no defaults
export const defaults = {};

export const typeDefs = `
  type UserRole = {
    role: String!
  }

  type User {
    id: Int!
    email: String!
    firstName: String!
    lastName: String!
    userRoles: [UserRole]
  }
`;
