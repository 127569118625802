import React, { FunctionComponent, useCallback, useState } from 'react';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Form, Modal, Row, Space, App } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ReactGA from 'react-ga4';
import {
  CREATE_COLLECTION,
  IContext as ICreateCollectionContext,
  IVariables as ICreateCollectionVariables,
} from '../gql/create-collection';
import { defaultGutterPixelSize } from '../lib/styles';
import { Loading } from './Loading';

interface ICreateCollectionActionProps {
  inline: boolean;
  onCreated?: (collectionId?: string) => void;
  isPublic?: boolean;
}

interface ICollectionInline {
  onFinish: (values: { name: string }) => void;
  onCloseModal: () => void;
  disableSaveButton: boolean;
}

const CollectionInline: FunctionComponent<ICollectionInline> = ({
  onCloseModal,
  disableSaveButton,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={defaultGutterPixelSize}>
      <Col xs={24}>
        <Form.Item
          name="name"
          rules={[
            {
              message: t('collections.new.form.name.required'),
              min: 3,
              required: true,
            },
          ]}
        >
          <Input
            autoFocus
            placeholder={t('collections.new.form.name.placeholder')}
            suffix={<CloseCircleOutlined onClick={onCloseModal} />}
          />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item css={{ marginBottom: 0 }}>
          <Space
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button htmlType="reset" onClick={onCloseModal}>
              {t('common.cancel')}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={disableSaveButton}
            >
              {t('common.save')}
            </Button>
          </Space>
        </Form.Item>
      </Col>
    </Row>
  );
};

interface ICollectionModal extends ICollectionInline {
  open: boolean;
  loading: boolean;
}
const CollectionModal: FunctionComponent<ICollectionModal> = ({
  open,
  loading,
  disableSaveButton,
  onCloseModal,
}) => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  return (
    <Modal
      open={open}
      closable={false}
      onOk={() => form.submit()}
      okButtonProps={{
        disabled: disableSaveButton,
      }}
      onCancel={onCloseModal}
      title={t('collections.createNewCollection')}
      okText={t('common.save')}
      cancelText={t('common.cancel')}
    >
      <Loading spinning={loading}>
        <Form.Item
          name="name"
          rules={[
            {
              message: t('collections.new.form.name.required'),
              min: 3,
              required: true,
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input
            autoFocus
            placeholder={t('collections.new.form.name.placeholder')}
          />
        </Form.Item>
      </Loading>
    </Modal>
  );
};

export const CreateCollectionAction: FunctionComponent<
  ICreateCollectionActionProps
> = ({ inline, isPublic, onCreated }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const [form] = Form.useForm();

  const onCloseModal = useCallback(() => setShowModal(false), []);

  const onFinish = useCallback(
    async (values: { name: string }) => {
      const input = {
        isPublic: isPublic === true,
        isPublished: isPublic !== true ? undefined : false, // initially public collection will be unpublished
        isVisibleToAnon: isPublic !== true ? undefined : false, // initially public collection won't be visible to anon users
        name: values.name,
      };

      setLoading(true);

      try {
        const response = await client.mutate<
          ICreateCollectionContext,
          ICreateCollectionVariables
        >({
          mutation: CREATE_COLLECTION,
          variables: { input },
        });

        if (!response.errors) {
          notification.success({
            description: t('collections.new.success.description', values),
            message: t('collections.new.success.message'),
          });

          if (onCreated) {
            let collectionId: string | undefined;

            if (response.data) {
              collectionId = response.data.createCollection.collection.id;
            }
            onCreated(collectionId);
          }

          ReactGA.gtag('event', 'Sammlung anlegen', {
            event_category: 'Sammlungen',
            collection_name: form.getFieldValue('name'), // dimension4
          });

          form.resetFields();
          onCloseModal();
        }
      } catch (error) {
        notification.error({
          description: t('collections.new.error.description'),
          message: t('collections.new.error.message'),
        });
      }

      setLoading(false);
    },
    [client, form, isPublic, notification, onCloseModal, onCreated, t],
  );

  return (
    <>
      {(!showModal || !inline) && (
        <Button
          onClick={() => setShowModal(true)}
          block
          icon={<PlusCircleOutlined />}
        >
          {t('collections.createNewCollection')}
        </Button>
      )}

      <Form
        id="createCollectionForm"
        form={form}
        onFinish={onFinish}
        onFieldsChange={() => {
          setDisableSaveButton(
            form.getFieldsError().some((field) => field.errors.length > 0),
          );
        }}
      >
        {showModal && inline && (
          <CollectionInline
            onFinish={onFinish}
            onCloseModal={onCloseModal}
            disableSaveButton={disableSaveButton}
          />
        )}
        {!inline && (
          <CollectionModal
            open={showModal}
            loading={loading}
            onCloseModal={onCloseModal}
            onFinish={onFinish}
            disableSaveButton={loading || disableSaveButton}
          />
        )}
      </Form>
    </>
  );
};
