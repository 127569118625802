import React, { FunctionComponent, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  GET_COLLECTIONS,
  IResponse,
  IVariables,
} from '../../gql/get-collections';
import { Routes } from '../../pages/Collections/utils/RecipeRoutes';
import { ResultList } from '../../pages/Search/components/ResultList';
import { CollectionCard } from '../CollectionCard';
import { CreateCollectionAction } from '../CreateCollectionAction';
import { useRapsPagination } from '../../pages/Search/hooks/usePagination';
import { CardColumnsPlaceholder } from '../Placeholders';
import { defaultGutterPixelSize } from '../../lib/styles';
import { ErrorGraphQL } from '../ErrorGraphQL';
import { NoItemsGraphQL } from '../NoItemsGraphQL';
import { usePageTitle } from '../../hooks/usePageTitle';

type IProps = {
  hideAddCollectionButton?: boolean;
  ownedByUser?: boolean;
  pageSize?: number;
  i18nKey: string;
};

export const RecipeCollectionList: FunctionComponent<IProps> = ({
  hideAddCollectionButton,
  pageSize,
  ownedByUser = false,
  i18nKey,
}) => {
  const variables = useRapsPagination({ pageSize });
  const { t } = useTranslation();

  const { loading, data, error, refetch } = useQuery<IResponse, IVariables>(
    GET_COLLECTIONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { ...variables, ownedByUser, orderBy: { name: 'ASC' } },
    },
  );

  usePageTitle(t('pageTitles.collection', { collection: t(i18nKey) }));

  const triggerRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <Row gutter={defaultGutterPixelSize}>
        <CardColumnsPlaceholder amount={1} />
      </Row>
    );
  }

  if (!data?.response.items.length) {
    return <NoItemsGraphQL />;
  }

  const displayAddCollectionButton = !hideAddCollectionButton && ownedByUser;

  const items = data.response.items.map(({ node }) => (
    <CollectionCard
      key={node._id}
      inCarousel={false}
      item={{
        ...node,
        targetUrl: `/collections/${
          ownedByUser ? Routes.PRIVATECOLLECTIONS : Routes.RAPSCOLLECTIONS
        }/${node._id}`,
      }}
      ownedByUser={ownedByUser}
      afterPublishToggle={triggerRefetch}
    />
  ));

  return (
    <ResultList
      items={items}
      totalCount={data.response.totalCount}
      disablePagination={false}
      {...(displayAddCollectionButton && {
        actionButton: (
          <CreateCollectionAction inline={false} onCreated={triggerRefetch} />
        ),
      })}
    />
  );
};
