import React, { FunctionComponent } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Space } from 'antd';
import { Trans } from 'react-i18next';
import { disabledMenuItemStyle } from '../../lib/styles';
import {
  activeNavLinkColor,
  rapsRed,
  topBarBreakPoint,
} from '../../theme/customVariables';
import { headingColor } from '../../theme/variables';
import { AuthGuard } from '../AuthGuard';
import { UserRequiredInfo } from '../UserRequiredInfo';
import { navigationBarData } from './navigationBarData';
import { headerBarItemClass } from './styles';

const NavLink = styled(RouterNavLink)`
  color: ${headingColor};
  display: flex;

  &.active {
    color: ${activeNavLinkColor} !important;
  }
`;

const activeNavLinkStyle = {
  color: `${activeNavLinkColor} !important`,
};

const TopBar = styled.div`
  height: 100%;
  line-height: initial;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const HeaderBar = styled.div`
  ${headerBarItemClass};

  @media only screen and (max-width: ${topBarBreakPoint}) {
    display: none;
  }
`;

const cursorNotAllowedStyle = css`
  cursor: not-allowed;
  display: flex;
`;

const UserRequiredInfoContentWrapper = styled.span`
  ${cursorNotAllowedStyle};
  ${disabledMenuItemStyle};
`;

const NavigationIcon = styled.span`
  margin-inline-end: 8px;
  vertical-align: middle;
`;

export const NavigationTopBar: FunctionComponent = () => (
  <TopBar>
    <div css={{ color: rapsRed }}>
      <Trans i18nKey="brand" />
    </div>
    <HeaderBar>
      <AuthGuard>
        {(isGranted) => (
          <Space>
            {navigationBarData.map(
              ({ label, linkTo, onClick, icon, disabledWithoutUser }) =>
                isGranted || !disabledWithoutUser ? (
                  <NavLink
                    key={label}
                    to={linkTo}
                    style={({ isActive }) =>
                      isActive ? activeNavLinkStyle : {}
                    }
                    onClick={onClick}
                  >
                    <NavigationIcon>{icon}</NavigationIcon>
                    {label}
                  </NavLink>
                ) : (
                  <UserRequiredInfo key={label}>
                    <UserRequiredInfoContentWrapper>
                      <NavigationIcon>{icon}</NavigationIcon>
                      {label}
                    </UserRequiredInfoContentWrapper>
                  </UserRequiredInfo>
                ),
            )}
          </Space>
        )}
      </AuthGuard>
    </HeaderBar>
  </TopBar>
);
