import React, { FormEvent, useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, Input, InputRef, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { SearchOutlined } from '@ant-design/icons';
import { defaultSearchParam } from '../../utils/defaultSearchParam';
import { defaultGutterPixelSize } from '../../../../lib/styles';
import { SearchResultTabs } from '../../index';
import { maxSearchResults } from '../../utils';
import { usePageTitle } from '../../../../hooks/usePageTitle';

const SearchPage = () => {
  const { t } = useTranslation();

  usePageTitle(t('pageTitles.search'));
  const ref = useRef<InputRef>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = searchParams.get(defaultSearchParam) || '';

  const [searchText, setSearchText] = useState(
    searchParams.get(defaultSearchParam) || '',
  );

  const onChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value),
    [],
  );

  const submitSearch = useCallback(
    (value: string) => {
      setSearchText(value);

      if (ref.current) {
        ref.current.blur();
      }

      if (value) {
        ReactGA.gtag('event', 'Suchbegriffe', {
          event_category: 'Suchen',
          search_term: value, // dimension5
        });
      }

      setSearchParams({ [defaultSearchParam]: value });
    },
    [setSearchParams],
  );

  return (
    <>
      <div css={{ maxWidth: '900px', marginBottom: 36 }}>
        <Input.Search
          addonBefore={<SearchOutlined />}
          ref={ref}
          autoFocus
          placeholder={t('search.placeholder')}
          enterButton={t('search.action')}
          allowClear
          defaultValue={searchText}
          size="large"
          css={{
            '.ant-input-affix-wrapper': {
              minHeight: '40px',
              boxShadow: 'none',
            },
          }}
          onChange={onChange}
          onSearch={submitSearch}
          type="search"
        />
      </div>

      {searchTerm && (
        <Space
          direction="vertical"
          css={{ width: '100%', marginBottom: defaultGutterPixelSize }}
          size={defaultGutterPixelSize}
          split={<Divider css={{ marginTop: 0, marginBottom: 0 }} />}
        >
          {SearchResultTabs.filter((tab) => !tab.roles?.length).map((tab) => {
            const { component: SearchCategory, path, i18nKey } = tab;

            return (
              <section key={path} id={path.toLowerCase()}>
                <SearchCategory
                  pageSize={maxSearchResults}
                  i18nKey={i18nKey}
                  path={path}
                  showCarousel
                />
              </section>
            );
          })}
        </Space>
      )}
    </>
  );
};

export default SearchPage;
