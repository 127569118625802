import React, { FunctionComponent } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { gql, useMutation, DataProxy, FetchResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import {
  GET_SHOPPING_CART_ITEMS,
  IResponse,
  IShoppingCartItemNode,
} from '../../../gql/get-shopping-cart-items';
import { handleError } from '../../../lib/handleError';

const deleteItemCacheUpdate = (cache: DataProxy, result: FetchResult) => {
  const cachedData = cache.readQuery<IResponse>({
    query: GET_SHOPPING_CART_ITEMS,
  });

  if (!cachedData || (result.errors && result.errors.length)) {
    return;
  }

  const removedItems = cachedData.shoppingCartItems.edges.map(({ node }) => ({
    item_id: node.ingredient.number, // dimension11
    item_name: node.ingredient.name,
    quantity: node.quantity,
  }));

  cache.writeQuery({
    data: {
      shoppingCartItems: {
        ...cachedData.shoppingCartItems,
        edges: [],
        totalCount: 0,
      },
    },
    query: GET_SHOPPING_CART_ITEMS,
  });

  ReactGA.event('remove_from_cart', {
    event_category: 'Shopping',
    items: removedItems,
  });
};

interface IProps {
  records: IShoppingCartItemNode[];
}

export const DeleteAllButton: FunctionComponent<IProps> = ({ records }) => {
  const { t } = useTranslation();

  const [doDelete, { loading, error }] = useMutation(
    gql`
      mutation deleteAllShoppingCartItems{
        ${records.map(
          (record, index) => `
          delete${index}: deleteShoppingCartItem(input: {id: "${record.node.id}"}) { shoppingCartItem { id } }
        `,
        )}
      }
    `,
    { update: deleteItemCacheUpdate },
  );

  if (error) {
    handleError(error);
  }

  return (
    <Popconfirm
      placement="bottomRight"
      title={t('shoppingCart.emptyConfirm')}
      okText={t('common.yes')}
      cancelText={t('common.no')}
      onConfirm={() => doDelete()}
    >
      <Button loading={loading} icon={<DeleteOutlined />} htmlType="button">
        {t('shoppingCart.emptyButton')}
      </Button>
    </Popconfirm>
  );
};
