import React, { FunctionComponent } from 'react';
import { Row } from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  GET_CATEGORIES_TOP_LEVEL,
  IResponse,
  IVariables,
} from '../../../../../gql/get-categories-top-level';
import { useRapsPagination } from '../../../hooks/usePagination';
import { ErrorGraphQL } from '../../../../../components/ErrorGraphQL';
import { defaultGutterPixelSize } from '../../../../../lib/styles';
import { CardColumnsPlaceholder } from '../../../../../components/Placeholders';
import { SearchNoResult } from '../../../../../components/SearchNoResult';
import { CategoryCard } from '../../../../../components/CategoryCard';
import { SearchResultHeader } from '../../../../../components/SearchResultHeader';
import { SearchResultCarousel } from '../../../components/SearchResultCarousel';

interface ISearchResultCategoriesProps {
  pageSize?: number;
  i18nKey?: string;
  path?: string;
}

export const SearchResultCategories: FunctionComponent<
  ISearchResultCategoriesProps
> = ({ pageSize, i18nKey, path }) => {
  const { t } = useTranslation();
  const variables = useRapsPagination({ pageSize });

  const { data, loading, error } = useQuery<IResponse, IVariables>(
    GET_CATEGORIES_TOP_LEVEL,
    {
      fetchPolicy: 'cache-and-network',
      variables,
      skip: !variables.q,
    },
  );

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <Row gutter={defaultGutterPixelSize}>
        <CardColumnsPlaceholder amount={1} />
      </Row>
    );
  }

  if (!data?.response?.items?.length) {
    return <SearchNoResult searchTerm={variables.q} i18nKey={i18nKey} />;
  }

  const categories = data.response.items.filter(
    ({ node }) => node.recipes.totalCount > 0,
  );

  if (!categories.length) {
    return <p>{t('search.noResults')}</p>;
  }

  const items = categories.map(({ node }) => (
    <CategoryCard
      key={node._id}
      showCarousel
      item={{
        ...node,
        targetUrl: `/categories/${node._id}`,
      }}
    />
  ));

  return (
    <>
      {i18nKey && path && (
        <SearchResultHeader
          i18nKey={i18nKey}
          path={path}
          count={data.response.totalCount}
        />
      )}
      <SearchResultCarousel items={items} />
    </>
  );
};
