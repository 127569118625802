import React from 'react';
import { RecipeList } from '../../components/RecipeList';

interface ITab {
  element: React.ReactElement;
  path: string;
  i18nKey: string;
}

const ManagePublicationsPanels: ITab[] = [
  {
    element: <RecipeList revoked={false} />,
    path: 'unpublished',
    i18nKey: 'publishing.tabHeadings.unpublished',
  },
  {
    element: <RecipeList revoked />,
    path: 'revoked',
    i18nKey: 'publishing.tabHeadings.revoked',
  },
];

export default ManagePublicationsPanels;
