import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ConfigProvider } from 'antd';
import i18next from 'i18next';
import deDE from 'antd/locale/de_DE';
import { theme } from '../theme';

import 'antd/dist/reset.css';

export const ThemeAndConfigProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <ConfigProvider
    theme={theme}
    {...(i18next.language.includes('de') && { locale: deDE })} // set German or keep English as default locale
  >
    {children}
  </ConfigProvider>
);
