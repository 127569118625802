import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import {
  DUPLICATE_RECIPE_MUTATION,
  IDuplicateRecipeMutationResponse,
  IDuplicateRecipeMutationVariables,
} from '../../gql/duplicate-recipe-mutation';
import { TransferModal } from '../TransferModal';

interface ITransferRecipeModalProps {
  recipeId: number;
}

export const TransferRecipeModal: FunctionComponent<
  ITransferRecipeModalProps
> = ({ recipeId }) => {
  const { t } = useTranslation();

  const client = useApolloClient();

  const mutationHandler = useCallback(
    async (input: { userId: number }) =>
      client.mutate<
        IDuplicateRecipeMutationResponse,
        IDuplicateRecipeMutationVariables
      >({
        mutation: DUPLICATE_RECIPE_MUTATION,
        variables: {
          input: {
            recipeId,
            userId: input.userId,
          },
        },
      }),
    [client, recipeId],
  );

  return (
    <TransferModal
      modalTitle={t('modal.transferRecipe.title')}
      modalTrigger={t('modal.transferRecipe.triggerButton')}
      successMessage={t('modal.transferRecipe.success')}
      errorMessage={t('modal.transferRecipe.error')}
      mutationHandler={mutationHandler}
    />
  );
};
