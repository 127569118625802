export const makeCursorFromPage = (itemsPerPage: number, page?: number) => {
  if (!page || page <= 1) {
    return;
  }

  const indexOfLastItem = (page - 1) * itemsPerPage - 1;

  // eslint-disable-next-line consistent-return
  return window.btoa(`${indexOfLastItem}`);
};
