export function getIsoTimeString() {
  const date = new Date();
  let isoString = date.toISOString();

  // The format of isoString is "yyyy-MM-ddTHH:mm:ss.sssZ"
  // We remove the seconds and milliseconds by taking the substring up to the 16th character
  isoString = isoString.substring(0, 16);

  // Replace the "T" between the date and time with a space
  isoString = isoString.replace('T', '-');

  return isoString;
}
