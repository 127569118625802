import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from 'react';

export interface IExportIngredients {
  normalizationFactor: number;
}

export interface IExportDeclaration {
  text: string;
  weightLossHint?: string;
  nutritionalValues: {
    name: string;
    amount: string;
  }[];
}
export interface IExportState {
  ingredients: IExportIngredients;
  declaration: IExportDeclaration;
  isTotal: boolean;
}

const initialState: IExportState = {
  declaration: {
    nutritionalValues: [],
    text: '',
    weightLossHint: '',
  },
  ingredients: {
    normalizationFactor: 1,
  },
  isTotal: true,
};

export enum EXPORT_ENUM {
  SET_DECLARATION = 'SET_DECLARATION',
  SET_INGREDIENTS = 'SET_INGREDIENTS',
  SET_TOTAL = 'SET_TOTAL',
}

export type ExportActions =
  | {
      type: EXPORT_ENUM.SET_DECLARATION;
      payload: IExportDeclaration;
    }
  | {
      type: EXPORT_ENUM.SET_INGREDIENTS;
      payload: IExportIngredients;
    }
  | {
      type: EXPORT_ENUM.SET_TOTAL;
      payload: boolean;
    };

const reducer = (state: IExportState, action: ExportActions) => {
  switch (action.type) {
    case 'SET_DECLARATION': {
      return { ...state, declaration: action.payload };
    }
    case 'SET_INGREDIENTS': {
      return { ...state, ingredients: action.payload };
    }
    case 'SET_TOTAL': {
      return { ...state, isTotal: action.payload };
    }
    default:
      return state;
  }
};

export type IExportDispatch = { dispatchExport: Dispatch<ExportActions> };

export const ExportContext = createContext<IExportState & IExportDispatch>({
  ...initialState,
  dispatchExport: () => ({}),
});

export const useExportContext = () => useContext(ExportContext);

export const ExportProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({ ...state, dispatchExport: dispatch }),
    [state],
  );

  return (
    <ExportContext.Provider value={value}>{children}</ExportContext.Provider>
  );
};
