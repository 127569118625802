import { gql } from '@apollo/client';

export interface IUpdateRAPSRecipeVariables {
  input: {
    id: string;
    isSAP: boolean;
  };
}

export const UPDATE_RAPS_RECIPE = gql`
  mutation updateRAPSRecipe($input: updateRecipeInput!) {
    updateRecipe(input: $input) {
      recipe {
        id
      }
    }
  }
`;
