import { gql } from '@apollo/client';

import { IResponse as IRecipeResponse } from './get-recipe-cards';

interface IResponse {
  collection: {
    id: string;
    recipes: IRecipeResponse['response'];
  };
}

export type IContext = { response: IResponse } | null;

export interface IVariables {
  id: string;
  recipes: string[];
}

// FIXME: The recipes should not be loaded as context as they are subject to the
// default items per page limit which cannot be overridden in mutation contexts
export const UPDATE_COLLECTION_RECIPES = gql`
  mutation updateCollectionRecipes($id: ID!, $recipes: [String]!) {
    response: updateCollection(input: { id: $id, recipes: $recipes }) {
      collection {
        id
      }
    }
  }
`;
