import { notification } from 'antd';
import { t } from 'i18next';

/**
 * We do not want to handle these globally, because sometimes we want to display an error page and sometimes we want to
 * trigger notifications.
 *
 * We need to add the handled property, because otherwise the notifications are shown on every rerender.
 *
 * @param error
 */
export const handleError = (error: any) => {
  if (error.handled) {
    return;
  }

  if (error.networkError) {
    if (error.statusCode === 401) {
      // Already handled globally, see above
      return;
    }

    if (!error.networkError.statusCode) {
      notification.warning({
        description: t('common.error.noNetwork.description'),
        message: t('common.error.noNetwork.message'),
      });
      // eslint-disable-next-line no-param-reassign
      error.handled = true;

      return;
    }
  }

  notification.error({
    description: t('common.error.unknown.description'),
    message: t('common.error.unknown.message'),
  });
  // eslint-disable-next-line no-param-reassign
  error.handled = true;
};
