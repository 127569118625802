import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { StarOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useBoolean } from 'ahooks';
import { IRecipeShape } from '../utils/IRecipeShape';
import { OverlayButton } from './styles';
import { roles } from '../../../lib/userRoles';
import { AuthGuard } from '../../AuthGuard';
import {
  GET_RECIPE_COLLECTIONS,
  IResponse as QueryResponse,
  IVariables as QueryVariables,
} from '../../../gql/get-recipe-collections';
import { handleError } from '../../../lib/handleError';
import { CollectionsList } from './CollectionsList';

const PUBLIC_TAB_KEY = 'public';
const PRIVATE_TAB_KEY = 'private';

type IProps = {
  children?: (
    onClick: () => void,
    recipeHasCollections?: boolean,
  ) => ReactElement;
  recipe: IRecipeShape;
};

export const AddToCollectionAction: FunctionComponent<IProps> = ({
  children,
  recipe,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActionTab] = useState(PRIVATE_TAB_KEY);
  const [privateCollections, setPrivateCollections] = useState<QueryResponse>();
  const [publicCollections, setPublicCollections] = useState<QueryResponse>();

  const [
    showModal,
    { setTrue: setShowModalTrue, setFalse: setShowModalFalse },
  ] = useBoolean();

  const [hasCollection, setHasCollection] = useState(false);

  const getCollections = useCallback(
    async (ownedByUser: boolean) => {
      setLoading(true);

      const { data, errors } = await client.query<
        QueryResponse,
        QueryVariables
      >({
        fetchPolicy: 'network-only',
        query: GET_RECIPE_COLLECTIONS,
        variables: {
          ownedByUser,
          recipe: recipe.id,
        },
      });

      if (errors) {
        handleError(errors);
      }

      setLoading(false);

      if (ownedByUser) {
        setPrivateCollections(data);
      } else {
        setPublicCollections(data);
      }

      setHasCollection(!!recipe?.collections?.totalCount);
    },
    [client, recipe?.collections?.totalCount, recipe.id],
  );

  const openModal = useCallback(async () => {
    setShowModalTrue();
    await getCollections(true);
  }, [getCollections, setShowModalTrue]);

  const handleTabsChange = useCallback(
    async (tab: string) => {
      setActionTab(tab);
      await getCollections(tab === PRIVATE_TAB_KEY);
    },
    [getCollections],
  );

  const renderACollectionlist = useCallback(
    (ownedByUser: boolean) => (
      <CollectionsList
        recipe={recipe}
        data={ownedByUser ? privateCollections : publicCollections}
        onAfterAddRecipe={() => getCollections(ownedByUser)}
        isPublic={!ownedByUser}
        loading={loading}
        onActionTrigger={() => {
          // displaying loading spinner when an action is triggered
          setLoading(true);
        }}
      />
    ),
    [getCollections, loading, privateCollections, publicCollections, recipe],
  );

  const items = useMemo(
    () => [
      {
        label: t('collections.public'),
        key: PUBLIC_TAB_KEY,
        children: renderACollectionlist(false),
      },
      {
        label: t('collections.private'),
        key: PRIVATE_TAB_KEY,
        children: renderACollectionlist(true),
      },
    ],
    [renderACollectionlist, t],
  );

  return (
    <>
      <Modal
        open={showModal}
        onCancel={setShowModalFalse}
        title={t('collections.addToRecipeCollection')}
        footer={
          <Button type="primary" onClick={setShowModalFalse}>
            {t('collections.closeAndSaveModal')}
          </Button>
        }
      >
        <AuthGuard roles={[roles.collectionEditor]}>
          {(isGranted) =>
            isGranted && !!recipe.number ? (
              <Tabs
                items={items}
                activeKey={activeTab}
                onChange={handleTabsChange}
                animated
              />
            ) : (
              renderACollectionlist(true)
            )
          }
        </AuthGuard>
      </Modal>
      {children ? (
        children(openModal, hasCollection)
      ) : (
        <OverlayButton
          onClick={openModal}
          shape="circle"
          type="primary"
          ghost={!hasCollection}
          icon={<StarOutlined />}
        />
      )}
    </>
  );
};
