import styled from '@emotion/styled';
import { Card, Carousel } from 'antd';
import React, { FunctionComponent } from 'react';
import { ApolloError } from '@apollo/client';
import { CardColumn } from '../../../components/CardColumn';
import { NewsCard } from '../../../components/NewsCard';
import { rapsRed } from '../../../theme/customVariables';
import { IHomePageData } from '../../../gql/get-home-page';

const CustomCarousel = styled(Carousel)`
  &.slick-slider .slick-list .slick-slide {
    pointer-events: auto;
  }

  .slick-track {
    display: flex;
  }

  .slick-dots > li,
  .slick-dots > li.slick-active {
    width: 27px;
  }

  .slick-dots > li > button,
  .slick-dots > li.slick-active > button {
    height: 8px;
    width: 27px;
    background-color: ${rapsRed};
    margin-right: 5px;
  }
  .slick-dots {
    bottom: -16px;
  }
`;

interface INewsCarousel {
  data?: IHomePageData['news'];
  loading?: boolean;
  error?: ApolloError;
}

export const NewsCarousel: FunctionComponent<INewsCarousel> = ({
  data,
  error,
}) => {
  // const [orientation, setOrientation] = useState(0);
  //
  // useEffect(() => {
  //   const onResize = () => setOrientation((prev) => prev + 1);
  //
  //   window.addEventListener('resize', onResize, false);
  //
  //   return () => {
  //     window.removeEventListener('resize', onResize, false);
  //   };
  // }, []);

  if (error) {
    return <p>Error :(</p>;
  }

  if (!data || !data.items[0]) {
    return (
      <CardColumn>
        <Card loading />
      </CardColumn>
    );
  }

  const atLeast3Elements = data.items.length > 3;

  return (
    <CustomCarousel
      draggable
      variableWidth
      autoplaySpeed={5000}
      autoplay={atLeast3Elements}
      infinite={atLeast3Elements}
      centerMode={atLeast3Elements}
      dots={atLeast3Elements}
    >
      {data.items.map(({ node }) => (
        <CardColumn key={node._id}>
          <NewsCard key={node._id} item={node} responsive={false} />
        </CardColumn>
      ))}
    </CustomCarousel>
  );
};
