// Round to 0.1 gram
const roundSafe = (num: number): number =>
  Math.round((num + Number.EPSILON) * 10000) / 10000;

const determineBestPossibility = (
  quantity: number,
  possibilities: number[][],
) => {
  if (!possibilities.length) {
    return null;
  }

  // Sort by smallest container amount
  const sortedByContainerAmount = possibilities.sort(
    (a, b) => a.length - b.length,
  );

  let bestPossibility = sortedByContainerAmount[0];

  let bestDelta = Number.POSITIVE_INFINITY;

  sortedByContainerAmount.forEach((possibility: number[]) => {
    const delta = roundSafe(
      possibility.reduce((acc, cur) => acc + cur, 0) - quantity,
    );

    if (delta >= 0 && delta < bestDelta) {
      bestPossibility = possibility;
      bestDelta = delta;
    }
  });

  return bestPossibility;
};

/**
 * Containers need to be sorted descending by size
 */
const generateContainerSizePossibilities = (
  quantity: number,
  container: number[],
) => {
  const possibilities: number[][] = [];

  // Break if smallest container is bigger than needed
  if (container[container.length - 1] > quantity) {
    possibilities.push([container[container.length - 1]]);
  }

  // @ts-ignore
  for (let i = 0; i < container.length; i += 1) {
    const currPossibility: number[] = [];

    // Perfect fit
    if (container[i] === quantity) {
      possibilities.push([container[i]]);

      return possibilities;
    }

    if (container[i] < quantity) {
      // Add previous
      if (i > 0) {
        possibilities.push([container[i - 1]]);
      }

      currPossibility.push(container[i]);
      let freeSpace: number = quantity - container[i];

      // fill up current possibility
      while (freeSpace > 0) {
        // Add Overflow to possibilities
        if (freeSpace - container[i] < 0) {
          possibilities.push(currPossibility.concat([container[i]]));
          break;
        }
        currPossibility.push(container[i]);
        freeSpace -= container[i];
      }

      // if not last size => Search for smaller amounts
      if (freeSpace > 0 && i < container.length - 1) {
        const containerTmp = [...container];
        const childPossibilities = generateContainerSizePossibilities(
          freeSpace,
          containerTmp.slice(i + 1),
        );

        childPossibilities.forEach((childPossibility: number[]) => {
          possibilities.push(currPossibility.concat(childPossibility));
        });
      }
      possibilities.push(currPossibility);
    }
  }

  return possibilities;
};

export const findBestContainerSizesForQuantity = (
  quantity: number,
  containerSizes: number[],
) => {
  // containersizes-length == 0 should never happen
  if (!containerSizes.length) {
    return null;
  }

  const sortedDescending = containerSizes.sort((a, b) => b - a);

  const possibilities = generateContainerSizePossibilities(
    quantity,
    sortedDescending,
  );

  return determineBestPossibility(quantity, possibilities);
};
