import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Col } from 'antd';

export const CardColumn: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  // Consider using lg={8} in favor of md and xl
  <Col xs={24} sm={12} md={8} xl={6} css={{ marginBottom: 20 }}>
    {children}
  </Col>
);
