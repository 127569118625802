import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  DragOutlined,
  NumberOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Table,
  Form,
  App,
} from 'antd';
import { DataProxy, FetchResult, useQuery, useMutation } from '@apollo/client';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../../components/DefaultLayout';
import { ErrorUnknownPage } from '../../components/ErrorUnknownPage';
import { LoadingPage } from '../../components/LoadingPage';
import { MessagePage } from '../../components/MessagePage';
import { CREATE_USER_ORDER, IOrderInput } from '../../gql/create-user-order';
import {
  GET_SHOPPING_CART_ITEMS,
  IIngredient,
  IResponse,
  IShoppingCartItem,
} from '../../gql/get-shopping-cart-items';
import { defaultGutterPixelSize } from '../../lib/styles';
import { BigGreyHeading } from '../Recipes/pages/RecipeDetail/components/styles';
import { DeleteAllButton } from './components/DeleteAllButton';
import { DeleteIngredientButton } from './components/DeleteIngredientButton';
import { QuantityForm } from './components/QuantityForm';
import { getWordbreakCss } from '../../lib/css';
import { externalRoutes } from '../../routes/external';
import { usePageTitle } from '../../hooks/usePageTitle';

export interface IIngredientRow extends IIngredient {
  containers: IShoppingCartItem[];
}

const getNodeId = (data: IIngredientRow) => data.id;

const productColumn = (ingredient: IIngredientRow) => (
  <span css={getWordbreakCss()}>
    {ingredient.name} ({ingredient.number})
  </span>
);

const OverFlowContainer = styled('div')`
  overflow-inline: auto;
`;

const tableRowNoWrap = css`
  .ant-table-body {
    overflow-inline: auto;
  }

  .ant-table-tbody > tr > td {
    border-color: #a71930;
    vertical-align: top;
  }
  .ant-table {
    background: none;
  }
  margin-block-end: 1.5rem;
`;

const ShoppingCartForm: FunctionComponent<{ data: IResponse }> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [doCreate, { loading: loadingCreateOrder }] =
    useMutation<IOrderInput>(CREATE_USER_ORDER);

  const onFinish = useCallback(
    async (values: { message: string }) => {
      await doCreate({
        update: (cache: DataProxy, result: FetchResult) => {
          const cachedData = cache.readQuery<IResponse>({
            query: GET_SHOPPING_CART_ITEMS,
          });

          if (!cachedData || (result.errors && result.errors.length)) {
            return;
          }

          cache.writeQuery({
            query: GET_SHOPPING_CART_ITEMS,
            data: {
              shoppingCartItems: {
                ...cachedData.shoppingCartItems,
                edges: [],
                totalCount: 0,
              },
            },
          });

          ReactGA.event({
            action: 'Bestellen',
            category: 'Shopping',
          });
        },
        variables: {
          input: {
            message: values.message,
          },
        },
      });

      notification.success({
        message: t('shoppingCart.createdOrder'),
      });

      navigate('/');
    },
    [doCreate, navigate, notification, t],
  );

  // FIXME: this could be done in a better way
  // Group containers by ingredients
  const ingredients = useMemo(() => {
    const items: IIngredientRow[] = [];

    data.shoppingCartItems.edges.forEach(({ node }) => {
      let existingIngredient = items.find(
        (ingredient) => ingredient.id === node.ingredient.id,
      );

      if (!existingIngredient) {
        existingIngredient = {
          ...node.ingredient,
          containers: [],
        };
        items.push(existingIngredient);
      }

      existingIngredient.containers.push(node);
    });

    return items;
  }, [data.shoppingCartItems.edges]);

  return (
    <DefaultLayout>
      <BigGreyHeading>{t('shoppingCart.title')}</BigGreyHeading>

      <Row gutter={defaultGutterPixelSize * 1.5}>
        <Col lg={12} style={{ inlineSize: '100%' }}>
          <OverFlowContainer>
            <Table<IIngredientRow>
              scroll={{ scrollToFirstRowOnChange: true, x: true }}
              dataSource={ingredients}
              pagination={false}
              rowKey={getNodeId}
              css={tableRowNoWrap}
              columns={[
                {
                  key: 'quantity',
                  title: t('shoppingCart.table.quantity'),
                  render: (
                    _: string,
                    record: IIngredientRow,
                    index: number,
                  ) => <QuantityForm record={record} rowIndex={index} />,
                },
                {
                  key: 'name',
                  title: t('shoppingCart.table.ingredient'),
                  render: productColumn,
                },
                {
                  key: 'actions',
                  render: (text: string, record: IIngredientRow) => (
                    <DeleteIngredientButton record={record} />
                  ),
                },
              ]}
            />
          </OverFlowContainer>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: defaultGutterPixelSize,
              paddingInline: defaultGutterPixelSize,
            }}
          >
            <span>
              <NumberOutlined /> {t('shoppingCart.amount')}
            </span>
            <span>
              <DragOutlined /> {t('shop.columns.containerSize')}
            </span>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBlock: defaultGutterPixelSize,
            }}
          >
            <DeleteAllButton records={data.shoppingCartItems.edges} />
          </div>
        </Col>

        <Col lg={12} style={{ inlineSize: '100%' }}>
          <Form id="shoppingCartOrderForm" onFinish={onFinish}>
            <Form.Item name="message" initialValue="" valuePropName="value">
              <Input.TextArea
                rows={4}
                placeholder={t('shoppingCart.messagePlaceholder')}
              />
            </Form.Item>

            <Alert
              message={t('shoppingCart.warning')}
              type="warning"
              showIcon
              css={{ marginBlockEnd: defaultGutterPixelSize }}
            />

            <Form.Item
              name="agbAccepted"
              rules={[
                {
                  required: true,
                  message: t('shoppingCart.agb.notAcceptedError'),
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                <a
                  href={externalRoutes.termsAndConditions}
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  {t('shoppingCart.agb.name')}
                </a>{' '}
                {t('shoppingCart.agb.message')}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingCreateOrder}
              >
                {t('shoppingCart.orderButton')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </DefaultLayout>
  );
};

const ShoppingCart = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery<IResponse>(
    GET_SHOPPING_CART_ITEMS,
    {
      fetchPolicy: 'network-only',
    },
  );

  usePageTitle(t('pageTitles.shoppingCart'));

  if (error) {
    return <ErrorUnknownPage />;
  }

  if (loading || !data?.shoppingCartItems?.edges) {
    return <LoadingPage />;
  }

  if (!data.shoppingCartItems.edges.length) {
    return (
      <MessagePage
        icon={<ShoppingCartOutlined />}
        message={t('shoppingCart.title')}
        description={t('shoppingCart.emptyMessage')}
      />
    );
  }

  return <ShoppingCartForm data={data} />;
};

export default ShoppingCart;
