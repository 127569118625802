import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessagePage } from '../../components/MessagePage';

export const ErrorFallbackPage = () => {
  const { t } = useTranslation();

  return (
    <MessagePage
      message={t('common.error.fallback.title')}
      description={t('common.error.fallback.description')}
    />
  );
};
