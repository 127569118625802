import { gql } from '@apollo/client';

interface ICollection {
  totalCount: number;
  items: Array<{
    node: {
      _id: number;
      id: string;
      name: string;
      recipes: {
        totalCount: number;
      };
    };
  }>;
}

interface IRecipe {
  _id: number;
  id: string;
  title: string;
  collections: ICollection;
}

export interface IResponse {
  recipe: IRecipe;
  collections: ICollection;
}

export interface IVariables {
  recipe: string;
  ownedByUser: boolean;
}

export const ITEMS_PER_PAGE = 100;

export const GET_RECIPE_COLLECTIONS = gql`
  query recipeCollections($recipe: ID!, $ownedByUser: Boolean!) {
    recipe(id: $recipe) {
      _id
      id
      title
      collections(exists: { user: $ownedByUser }, first: ${ITEMS_PER_PAGE}) {
        totalCount
        items: edges {
          node {
            _id
            id
            name
          }
        }
      }
    }
    collections(exists: { user: $ownedByUser }, first: ${ITEMS_PER_PAGE}) {
      totalCount
      items: edges {
        node {
          _id
          id
          name
          recipes {
            totalCount
          }
        }
      }
    }
  }
`;
