import React from 'react';
import { CardColumn } from '../CardColumn';
import { CardPlaceholder } from './CardPlaceholder';

interface ICardColumnsPlaceholder {
  amount?: number;
}
export const CardColumnsPlaceholder: React.FunctionComponent<
  ICardColumnsPlaceholder
> = ({ amount = 1 }) => {
  const items = Array.from({ length: amount }, (_, index) => (
    <CardColumn key={index}>
      <CardPlaceholder />
    </CardColumn>
  ));

  return <>{items}</>;
};
