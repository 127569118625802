import styled from '@emotion/styled';
import { Button } from 'antd';
import React from 'react';
import { StarOutlined } from '@ant-design/icons';
import { IRecipe } from '../../gql/get-recipe';
import { circleButtonStyle } from '../../lib/styles';
import { AuthGuard } from '../AuthGuard';
import { AddToCollectionAction } from '../RecipeCard/components/AddToCollectionAction';
import { UserRequiredInfo } from '../UserRequiredInfo';
import { BackgroundImage } from './components/BackgroundImage';
import { OverlayActions } from './components/OverlayActions';
import { AddToCollectionButton } from './styles';
import CoverImageSmall from './images/CoverImageSmall.png';
import CoverImageBig from './images/CoverImageBig.png';

const DisabledButton = styled(Button)`
  ${circleButtonStyle};
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
`;

interface IProps {
  imageUrl?: string;
  recipe?: IRecipe;
  hasAdditionalStyle?: boolean;
  thumb?: boolean;
}

export const CoverImage: React.FunctionComponent<IProps> = ({
  imageUrl,
  recipe,
  hasAdditionalStyle = false,
  thumb = false,
}) => {
  let imageUri: string;

  // no image
  if (imageUrl == null) {
    imageUri = thumb ? CoverImageSmall : CoverImageBig;
  } else {
    imageUri = imageUrl;
  }

  return (
    <AuthGuard>
      {(isGranted) => (
        <BackgroundImage
          imageUrl={imageUri}
          hasAdditionalStyle={hasAdditionalStyle}
        >
          {recipe && !recipe.isSAP && (
            <OverlayActions>
              {isGranted ? (
                <AddToCollectionAction recipe={recipe}>
                  {(onClick, recipeHasCollections) => (
                    <AddToCollectionButton
                      onClick={onClick}
                      shape="circle"
                      type="primary"
                      size="large"
                      ghost={!recipeHasCollections}
                      icon={<StarOutlined />}
                    />
                  )}
                </AddToCollectionAction>
              ) : (
                <UserRequiredInfo cursor="pointer">
                  <DisabledButton
                    ghost
                    shape="circle"
                    size="large"
                    icon={<StarOutlined />}
                  />
                </UserRequiredInfo>
              )}
            </OverlayActions>
          )}
        </BackgroundImage>
      )}
    </AuthGuard>
  );
};
