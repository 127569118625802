import i18n from 'i18next';
import { DEFAULT_LANGUAGE } from './constants';

function getLocale() {
  return i18n.language || DEFAULT_LANGUAGE;
}

const convertToDate = (date: string | number | Date) =>
  date instanceof Date ? date : new Date(date);

export const formatDateTime = (
  date: string | number | Date,
  options?: Intl.DateTimeFormatOptions,
) =>
  Intl.DateTimeFormat(getLocale(), {
    dateStyle: 'medium',
    timeStyle: 'medium',
    ...options,
  }).format(convertToDate(date)); // doesn't generate seconds yet

export const formatDateTimeShort = (
  date: string | number | Date,
  options?: Intl.DateTimeFormatOptions,
) =>
  Intl.DateTimeFormat(getLocale(), {
    dateStyle: 'medium',
    ...options,
  }).format(convertToDate(date));

const defaultFormatOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
};

// https://stackoverflow.com/a/2901298
export const formatNumber = (
  number: number,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(getLocale(), {
    style: 'decimal',
    ...defaultFormatOptions,
    ...options,
  }).format(number);

export const formatWeight = (
  number: number | string | bigint,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(getLocale(), {
    style: 'unit',
    unit: 'kilogram',
    ...defaultFormatOptions,
    ...options,
  }).format(
    Number(typeof number === 'string' ? number.replace(',', '.') : number),
  );

export const formatPercent = (
  number: number | string,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(getLocale(), {
    style: 'unit',
    unit: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(
    Number(typeof number === 'string' ? number.replace(',', '.') : number),
  );
