import React from 'react';
import './lib/polyfills';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Global } from '@emotion/react';
import { App as AntdApp } from 'antd';
import i18next from 'i18next';
import * as serviceWorker from './serviceWorker';
import { i18n } from './lib/i18n';
import { App } from './App';
import { handleServiceWorkerUpdate } from './lib/handleServiceWorkerUpdate';
import { sentrySetup } from './lib/sentry';
import { apolloClient } from './lib/apollo';
import { globalStyles } from './lib/styles';
import { ThemeAndConfigProvider } from './provider/ThemeAndConfigProvider';
import { ErrorFallbackPage } from './pages/ErrorFallback';
import { polyfillNumberFormat } from './lib/polyfills-intl';

sentrySetup();
const container = document.getElementById('root');
const root = createRoot(container!);

Promise.all([i18n, apolloClient]).then(async ([, client]) => {
  await polyfillNumberFormat(i18next.language as 'en' | 'de');

  root.render(
    <ApolloProvider client={client}>
      <ThemeAndConfigProvider>
        <BrowserRouter>
          <Global styles={globalStyles} />

          <AntdApp
            css={{
              height: '100%',
              minHeight: '100%',
            }}
          >
            <ErrorBoundary fallback={() => <ErrorFallbackPage />}>
              <App />
            </ErrorBoundary>
          </AntdApp>
        </BrowserRouter>
      </ThemeAndConfigProvider>
    </ApolloProvider>,
  );
});

serviceWorker.register({ onUpdate: handleServiceWorkerUpdate });
