import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from '../../../components/CategoryCard';
import { ICategory } from '../../../gql/get-categories-top-level';
import { ResponsiveCardGrid } from '../../../components/ResponsiveCardGrid';

interface IProps {
  categories: ICategory[];
}

export const CategoryGrid: FunctionComponent<IProps> = ({ categories }) => {
  const { t } = useTranslation();

  const categoriesWithRecipes = categories.filter(
    (category) => category.recipes.totalCount > 0,
  );

  if (!categoriesWithRecipes.length) {
    return <p>{t('search.noResults')}</p>;
  }

  return (
    <ResponsiveCardGrid>
      {categoriesWithRecipes.map((category) => (
        <CategoryCard
          key={category._id}
          item={{
            ...category,
            targetUrl: `/categories/${category._id}`,
          }}
        />
      ))}
    </ResponsiveCardGrid>
  );
};
