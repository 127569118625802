import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rapsRed } from '../../../../../theme/customVariables';
import { textColor } from '../../../../../theme/variables';

// CSS Hack to add margin-top to columns when items are wrapped by flex container
export const containerCss = css('margin-top: -2.5rem');
export const columnsCss = css('margin-top: 2.5rem');

export const FlexedDiv = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const FloatingButton = styled('div')`
  position: absolute;
  align-self: flex-end;
  bottom: 0.8em;
  right: 0.8em;
`;

export const SeparatorDiv = styled('div')`
  margin-right: 4px;
  &::after {
    content: ',';
  }
`;

export const RowContainerDiv = styled('div')`
  margin-top: 15px;
`;

export const RelativeContainer = styled('div')`
  position: relative;
`;

export const TextUpperCase = styled('span')`
  text-transform: uppercase;
`;

export const TextUpperCaseBold = styled('span')`
  text-transform: uppercase;
  font-weight: 900;
`;

export const SmallRedHeading = styled('h4')`
  color: ${rapsRed};
  font-weight: 900;
  text-transform: uppercase;
`;

export const BigGreyHeading = styled('h2')`
  color: ${textColor};
  text-transform: uppercase;
`;

export const SmallGreyHeading = styled('h4')`
  color: ${textColor};
  font-weight: 900;
  text-transform: uppercase;
`;

export const greyAndBold = css`
  font-weight: 900;
  color: ${textColor};
`;
