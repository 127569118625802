import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface IScrollToTop {
  alwaysScroll?: boolean;
}
export const ScrollToTop: FunctionComponent<
  PropsWithChildren<IScrollToTop>
> = ({ children, alwaysScroll = false }) => {
  const location = useLocation();
  const [, setCurrentLocation] = useState(location);

  useEffect(() => {
    setCurrentLocation((prevState) => {
      if (prevState !== location || alwaysScroll) {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return location;
      }

      return prevState;
    });
  }, [alwaysScroll, location]);

  return <>{children}</>;
};
