import React, { Fragment, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { App } from 'antd';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import {
  IQuantity,
  IRelation,
  IUserRecipeBaseWeight,
} from '../../gql/get-recipe';
import { apiUrls } from '../../lib/apiUrls';
import { RECIPES } from '../../pages/Recipes/pages/RecipeDetail/components/UploadImageAction';
import { AuthGuard } from '../AuthGuard';
import { CoverImage } from '../CoverImage';
import { FlexCard, FlexCardMeta } from '../FlexCard';
import { AddToCollectionAction } from './components/AddToCollectionAction';
import { SelectImageCollectionButton } from './components/SelectImageCollectionButton';
import {
  Description,
  OverlayActions,
  OverlayButton,
  RelativeContainer,
} from './components/styles';
import { IRecipeShape } from './utils/IRecipeShape';
import { SwipeableLink } from '../SwipeableLink';
import { Truncate } from '../Truncate';
import { whiteBlur } from '../../theme/variables';
import { formatWeight } from '../../lib/helpers';

type IRequiredItemProps = IRecipeShape & { targetUrl: string } & {
  isSAP?: boolean;
} & {
  deleteAction?: (recipeIriId: string) => Promise<void>;
};

interface IProps {
  item: IRequiredItemProps;
  inCarousel?: boolean;
}

const StyledRelativeContainer = styled(RelativeContainer)`
  height: 100%;
`;

const getBaseWeight = (baseWeight?: IRelation<IUserRecipeBaseWeight>) => {
  if (baseWeight != null && baseWeight.items.length > 0) {
    return baseWeight.items[0].node.baseWeight;
  }

  return null;
};

const getQuantities = (
  quantities: IRelation<IQuantity>,
  number: string | undefined,
) => {
  if (quantities && number === null) {
    return quantities.items.reduce(
      (acc, { node }) => acc + parseFloat(node.quantity),
      0,
    );
  }

  return null;
};

export const RecipeCard: React.FunctionComponent<IProps> = ({
  item,
  inCarousel,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const {
    _id,
    id,
    title,
    targetUrl,
    hasImage,
    imageId,
    deleteAction,
    userWeights,
    quantities,
    number,
    isSAP,
  } = item;

  const trackGAEvent = useCallback(() => {
    ReactGA.gtag('event', 'Rezeptkachel angeklickt', {
      event_category: 'Rezept',
      recipe_name: title, // dimension1
      recipe_id: number || _id,
    });
  }, [_id, number, title]);

  const onButtonClick = useCallback(() => {
    modal.confirm({
      cancelText: t('modal.cancel'),
      content: t('collections.removePrompt'),
      okText: t('modal.ok'),
      onOk: async () => {
        if (deleteAction) {
          await deleteAction(id);
        }
      },
      title: t('collections.removePromptTitle'),
    });
  }, [deleteAction, id, modal, t]);

  const individualQuantity = useMemo(
    () =>
      formatWeight(
        getBaseWeight(userWeights) || getQuantities(quantities, number) || 100,
        { minimumFractionDigits: 2, maximumFractionDigits: 2 },
      ),
    [number, quantities, userWeights],
  );

  return (
    <StyledRelativeContainer>
      <SwipeableLink to={targetUrl} onClick={trackGAEvent}>
        <FlexCard
          cover={
            <CoverImage
              {...(hasImage
                ? {
                    imageUrl: apiUrls.image.url(RECIPES, _id, {
                      thumb: true,
                      imageId,
                    }),
                  }
                : { thumb: true })}
            />
          }
        >
          <FlexCardMeta
            title={<Truncate lines={inCarousel ? 1 : 2}>{title}</Truncate>}
            description={<Description>{individualQuantity}</Description>}
          />
        </FlexCard>
      </SwipeableLink>

      {!isSAP && (
        <AuthGuard>
          <Fragment>
            <SelectImageCollectionButton hasImage={hasImage} recipeIri={id} />
            <OverlayActions>
              {deleteAction ? (
                <OverlayButton
                  onClick={onButtonClick}
                  shape="circle"
                  type="primary"
                  ghost
                  icon={<DeleteOutlined />}
                  style={{
                    background: whiteBlur,
                  }}
                />
              ) : (
                <AddToCollectionAction recipe={item} />
              )}
            </OverlayActions>
          </Fragment>
        </AuthGuard>
      )}
    </StyledRelativeContainer>
  );
};
