import { gql } from '@apollo/client';

import { IResponse as IRecipeResponse } from './get-recipe-cards';
import { CORE_CATEGORY_FIELDS } from './fragments/category';

export interface ICategory {
  _id: number;
  id: string;
  name: string;
  hasImage: boolean;
  recipes: IRecipeResponse['response'];
  children: IResponse['response'];
  parent: ICategory | null;
}

export interface IResponse {
  response: {
    totalCount: number;
    items: Array<{
      node: ICategory;
    }>;
  };
}

export interface IVariables {
  q?: string;
  parentExists?: boolean;
  itemsPerPage?: number;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
}

export const GET_CATEGORIES_TOP_LEVEL = gql`
  ${CORE_CATEGORY_FIELDS}
  query categoriesTopLevel(
    $q: String
    $cursor: String
    $parentExists: Boolean
    $orderBy: CategoryFilter_orderBy
    $itemsPerPage: Int
  ) {
    response: categories(
      name: $q
      recipes_quantities_ingredient_name: $q
      exists: { parent: $parentExists }
      orderBy: [$orderBy]
      first: $itemsPerPage
      after: $cursor
    ) {
      ...CoreCategoryFields
    }
  }
`;
