import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DefaultLayout } from '../../components/DefaultLayout';
import { defaultBottomMargin } from '../../lib/styles';
import {
  BigGreyHeading,
  TextUpperCase,
} from '../Recipes/pages/RecipeDetail/components/styles';
import { NewsCarousel } from './components/NewsCarousel';
import { PopularRecipes } from './components/PopularRecipes';
import { GET_HOME_PAGE_DATA, IHomePageData } from '../../gql/get-home-page';
import { usePageTitle } from '../../hooks/usePageTitle';

const HomeSection = styled.section`
  ${defaultBottomMargin};
`;

const HomeHeadingGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Home = () => {
  const { t } = useTranslation();

  usePageTitle(t('pageTitles.home'));

  const { loading, error, data } = useQuery<IHomePageData>(GET_HOME_PAGE_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <DefaultLayout>
      <HomeSection>
        <HomeHeadingGroup>
          <Link to="/news">
            <BigGreyHeading>
              <TextUpperCase>{t('common.news')}</TextUpperCase>
            </BigGreyHeading>
          </Link>
        </HomeHeadingGroup>

        <Row gutter={16}>
          <Col>
            <NewsCarousel loading={loading} error={error} data={data?.news} />
          </Col>
        </Row>
      </HomeSection>

      <section>
        <BigGreyHeading>{t('recipes.newestRecipesHeading')}</BigGreyHeading>
        <PopularRecipes
          loading={loading}
          error={error}
          data={data?.popularRecipes}
        />
      </section>
    </DefaultLayout>
  );
};
