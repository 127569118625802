import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { ApolloError, useApolloClient } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { CheckCircleOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/es/form';
import { DefaultLayout } from '../../components/DefaultLayout';
import { MessagePage } from '../../components/MessagePage';
import { RapsInfoAlert } from '../../components/RapsInfoAlert';
import { apiUrls } from '../../lib/apiUrls';
import { defaultGutterPixelSize } from '../../lib/styles';
import { darkLinkColor } from '../../theme/variables';
import { BigGreyHeading } from '../Recipes/pages/RecipeDetail/components/styles';
import { CREATE_USER } from '../../gql/create-user';
import { usePageTitle } from '../../hooks/usePageTitle';

const AlertLink = styled(Link)`
  color: ${darkLinkColor};
  font-weight: bold;

  &:hover {
    color: ${darkLinkColor};
  }
`;

const FormCol = styled(Col)`
  width: 100%;
`;

type RegisterFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordRepeated: string;
  accountNumber: string;
  zipCode: string;
};

const Register = () => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [customerNumberAndZipError, setCustomerNumberAndZipError] =
    useState(false);
  const [success, setSuccess] = useState(false);
  const [form] = Form.useForm<RegisterFormValues>();

  usePageTitle(t('pageTitles.register'));

  const onFinish = useCallback(
    async (values: RegisterFormValues) => {
      setLoading(true);

      try {
        const input = {
          ...values,
          repeatPassword: undefined,
          acceptConditions: undefined,
          clientMutationId: 'register',
        };

        await client.mutate({
          mutation: CREATE_USER,
          variables: { input },
        });

        setSuccess(true);
        setCustomerNumberAndZipError(false);
        ReactGA.event({
          action: 'Registrieren',
          category: 'Registrierung',
        });
      } catch (apiError) {
        setLoading(false);

        if (apiError instanceof ApolloError && apiError.message) {
          if (apiError.message.includes('email')) {
            form.setFields([
              { name: 'email', errors: [t('register.error.backend.email')] },
            ]);
          }

          // Already registered
          if (apiError.message.includes('verwendet')) {
            form.setFields([
              {
                name: 'accountNumber',
                errors: [t('register.error.backend.alreadyRegistered')],
              },
            ]);
          }

          // Unknown Customer
          if (apiError.message.includes('Combination')) {
            const errors = [''];

            form.setFields([
              {
                name: 'accountNumber',
                value: values.accountNumber,
                errors,
              },
              { name: 'zipCode', value: values.zipCode, errors },
            ]);

            setCustomerNumberAndZipError(true);
          } else {
            setCustomerNumberAndZipError(false);
          }

          // send error to customer support
          await fetch(apiUrls.registerError, {
            body: JSON.stringify({
              ...values,
              accountNumberError: apiError?.message.includes('accountNumber')
                ? t(
                    'register.error.backend.invalidCustomerOrZipCode.errorDescriptionCustomerService',
                  )
                : '/',
              emailError: apiError?.message.includes('email')
                ? t('register.error.backend.email')
                : '/',
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          });
        }

        setLoading(false);
      }
    },
    [client, form, t],
  );

  if (success) {
    return (
      <MessagePage
        message={t('register.success.message')}
        description={t('register.success.description')}
        icon={<CheckCircleOutlined />}
      />
    );
  }

  return (
    <DefaultLayout withoutAnonymousUserNotice>
      <Row justify="center">
        <Col xs={24} lg={12}>
          <RapsInfoAlert
            closable={false}
            message={t('register.newCustomerMessage')}
            showIcon
            description={
              <Trans
                i18nKey="register.newCustomerDescription"
                components={[
                  <AlertLink to="/contact-form" key="link-to-contact-form">
                    &nbsp;
                  </AlertLink>,
                ]}
              />
            }
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={12} lg={10}>
          <BigGreyHeading>{t('register.title')}</BigGreyHeading>

          <Form
            id="registerForm"
            onFinish={onFinish}
            form={form}
            layout="vertical"
          >
            <Row gutter={defaultGutterPixelSize}>
              <FormCol sm={24} lg={12}>
                <Form.Item
                  label={t('register.firstName')}
                  name="firstName"
                  rules={[
                    {
                      message: t('common.error.inputRequired'),
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </FormCol>
              <FormCol sm={24} lg={12}>
                <Form.Item
                  label={t('register.lastName')}
                  name="lastName"
                  rules={[
                    {
                      message: t('common.error.inputRequired'),
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </FormCol>
            </Row>

            <Form.Item
              label={t('register.email')}
              name="email"
              rules={[
                {
                  message: t('common.error.inputRequired'),
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input type="email" autoComplete="username" />
            </Form.Item>

            <Row gutter={defaultGutterPixelSize}>
              <FormCol sm={24} lg={12}>
                <Form.Item
                  label={t('register.password')}
                  name="password"
                  rules={[
                    {
                      max: 4096,
                      message: t('register.passwordInvalid'),
                      min: 8,
                      required: true,
                    },
                  ]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </FormCol>

              <FormCol sm={24} lg={12}>
                <Form.Item
                  label={t('register.repeatPassword')}
                  name="repeatPassword"
                  rules={[
                    {
                      validator: async (rule: RuleObject, value: string) => {
                        if (value && value !== form.getFieldValue('password')) {
                          // antd way to fail validation. see https://4x.ant.design/components/form/#Custom-validator-not-working
                          throw new Error(t('register.repeatPasswordWrong'));
                        }
                      },
                    },
                    {
                      message: t('common.error.inputRequired'),
                      required: true,
                    },
                  ]}
                >
                  <Input.Password autoComplete="new-password" />
                </Form.Item>
              </FormCol>
            </Row>

            <Row gutter={defaultGutterPixelSize}>
              <FormCol sm={24} lg={12}>
                <Form.Item
                  label={t('register.accountNumber')}
                  name="accountNumber"
                  rules={[
                    {
                      message: t('common.error.inputRequired'),
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </FormCol>
              <FormCol sm={24} lg={12}>
                <Form.Item
                  label={t('register.zipCode')}
                  name="zipCode"
                  rules={[
                    {
                      message: t('common.error.inputRequired'),
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </FormCol>
            </Row>
            {customerNumberAndZipError && (
              <Alert
                type="error"
                showIcon
                message={t(
                  'register.error.backend.invalidCustomerOrZipCode.message',
                )}
                description={
                  <Trans
                    i18nKey="register.error.backend.invalidCustomerOrZipCode.description"
                    components={[
                      <a href="mailto:bestellservice@raps.de" key="raps-email">
                        &nbsp;
                      </a>,
                      <a href="tel:0800 439 83 79" key="raps-tel">
                        &nbsp;
                      </a>,
                    ]}
                  />
                }
                style={{ marginBottom: defaultGutterPixelSize }}
              />
            )}

            <Form.Item
              name="acceptConditions"
              rules={[
                {
                  message: t('register.conditionsNotAccepted'),
                  required: true,
                  type: 'boolean',
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                <Trans
                  i18nKey="register.acceptConditions"
                  components={[
                    <Link to="/privacy-policy" key="link-to-privacy-policy" />,
                    <Link to="/terms-of-use" key="link-to-terms-of-use" />,
                  ]}
                />
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                {t('register.submit')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </DefaultLayout>
  );
};

export default Register;
