import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import { Popover, Table } from 'antd';
import { RightOutlined, WarningOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ColumnsType } from 'antd/es/table';
import { IQuantity, IRecipe } from '../../../../../gql/get-recipe';
import { rapsRed } from '../../../../../theme/customVariables';

import { ReactComponent as RapsLogo } from '../../../../../assets/img/raps-logo.svg';

import {
  formatQuantity,
  getSelectableIds,
} from '../../../../../lib/recipeCalculations';
import { greyAndBold, TextUpperCase } from './styles';
import { getWordbreakCss } from '../../../../../lib/css';
import { defaultGutterPixelSize } from '../../../../../lib/styles';
import {
  RECIPE_ACTION_ENUMS,
  RecipeTableType,
  useRecipeDetailContext,
} from '../../../../../provider/RecipeDetailProvider';

const PRODUCT_PAGE_URI = 'https://product-information.raps.com/produkte';

const GreyTextUpperCase = styled(TextUpperCase)`
  ${greyAndBold};
`;

const DisabledTableRow = styled.tr`
  filter: grayscale(1);
`;
const sharedOnCell = (record: IQuantity) => {
  if (record.header) {
    return { colSpan: 0 };
  }

  return {};
};

const makeTableColumns = (
  normalizationFactor: number,
): ColumnsType<IQuantity> => [
  {
    dataIndex: 'quantity',
    title: <GreyTextUpperCase>{i18n.t('recipe.quantity')}</GreyTextUpperCase>,
    colSpan: 2,
    render: (_, record: IQuantity) => {
      if (record.header) {
        return record.header;
      }

      return formatQuantity(record, normalizationFactor);
    },
    onHeaderCell: () => ({ style: { textAlign: 'center' } }),
    onCell: (record: IQuantity) => {
      if (record.header) {
        return {
          colSpan: 3,
          style: {
            fontWeight: 'bold',
            textAlign: 'left',
          },
        };
      }

      return {
        style: {
          textAlign: 'right',
        },
      };
    },
  },
  {
    title: 'unit',
    colSpan: 0,
    dataIndex: 'quantityUnit',
    width: 24,
    onCell: sharedOnCell,
  },
  {
    dataIndex: ['ingredient', 'name'], // path "ingredient.name"
    title: <GreyTextUpperCase>{i18n.t('recipe.ingredient')}</GreyTextUpperCase>,
    render: (text: string, record: IQuantity) => {
      const { ingredient } = record;

      if (record.header || !ingredient) {
        return null;
      }

      let url = '';

      const isRapsIngredient = ingredient && !ingredient.isVirtual;
      const isDeleted = isRapsIngredient && ingredient.isDeleted;

      const withNumber = isRapsIngredient && !ingredient.isVirtual;

      if (isRapsIngredient && ingredient.shopIngredients?.edges.length) {
        const { number } = ingredient.shopIngredients?.edges[0]?.node || '';

        url = `${PRODUCT_PAGE_URI}/${number}`;
      }

      const children = (
        <span>
          {isRapsIngredient && (
            <RapsLogo
              fill={rapsRed}
              height={20}
              width={15}
              css={{
                marginInlineEnd: defaultGutterPixelSize / 4,
                verticalAlign: 'text-bottom',
              }}
            />
          )}

          {withNumber ? `${text} (${ingredient.number})` : text}
        </span>
      );

      if (url && !isDeleted) {
        return (
          <a
            target="_blank"
            href={url}
            css={{
              ...getWordbreakCss(),
              display: 'grid',
              gridTemplateColumns: '1fr 25px',
              gap: defaultGutterPixelSize / 2,
              color: 'inherit',
            }}
            rel="noreferrer"
          >
            {children}
            <RightOutlined role="presentation" />
          </a>
        );
      }

      return <span css={getWordbreakCss()}>{children}</span>;
    },
    onCell: sharedOnCell,
  },
];

interface IRecipeIngredientsTable {
  recipe: IRecipe;
  dataSource: IQuantity[];
  tableId: RecipeTableType;
  footer: (data: readonly IQuantity[]) => ReactElement;
  selectedIds: React.Key[];
  normalizationFactor: number;
}

export const RecipeIngredientsTable: FunctionComponent<
  IRecipeIngredientsTable
> = ({
  recipe,
  dataSource,
  footer,
  normalizationFactor,
  selectedIds,
  tableId,
}) => {
  const { t } = useTranslation();
  const { dispatch } = useRecipeDetailContext();

  const selectableIdsAmount = useMemo(
    () => getSelectableIds(dataSource).size,
    [dataSource],
  );

  return (
    <Table<IQuantity>
      columns={makeTableColumns(normalizationFactor)}
      dataSource={dataSource}
      components={{
        body: {
          row: ({ children, className, ...otherProps }: any) => {
            const props = {
              className: className
                .replace('ant-table-row-selected', '') // remove row selected class
                .trimEnd(),
              ...otherProps,
            };

            const ingredient = recipe.quantities.items.find(
              ({ node }) => node._id === otherProps['data-row-key'],
            )?.node.ingredient;

            if (ingredient && ingredient.isDeleted && !ingredient.isVirtual) {
              return (
                <Popover content={t('recipe.ingredientNoLongerAvailable')}>
                  <DisabledTableRow {...props}>
                    <td className="ant-table-selection-column ant-table-row-cell-break-word">
                      <WarningOutlined />
                    </td>

                    {children.slice(1)}
                  </DisabledTableRow>
                </Popover>
              );
            }

            return <tr {...props}>{children}</tr>;
          },
        },
      }}
      footer={footer}
      bordered
      pagination={false}
      size="small"
      rowKey="_id"
      rowSelection={{
        columnWidth: 32,
        selections: false,
        selectedRowKeys: selectedIds,
        getCheckboxProps: (record) => {
          const disabled = !!(
            record.ingredient?.isVirtual ||
            record.ingredient?.isDeleted ||
            record.header
          );

          return {
            disabled,
            name: record.id,
            ...(disabled && { style: { display: 'none' } }), // hide disabled checkboxes
          };
        },
        // this will be merged with rowSelection props in RecipeIngredientsTable
        onChange: (selectedKeys, selectedRows) => {
          const selectedRapsRows = selectedRows.filter((row) => {
            if (!row.ingredient) {
              return false;
            }

            return !(row.ingredient.isVirtual || row.ingredient.isDeleted);
          });

          const noSelection = selectedRapsRows.length === 0;

          if (noSelection) {
            dispatch({
              type: RECIPE_ACTION_ENUMS.SELECT_ALL_ROWS,
              payload: { cartIds: [], tableId },
            });

            return;
          }

          const everythingSelected =
            selectedRapsRows.length === selectableIdsAmount;

          if (everythingSelected) {
            const allQuantitiesKeys = dataSource.map((item) => item._id);

            dispatch({
              type: RECIPE_ACTION_ENUMS.SELECT_ALL_ROWS,
              payload: { cartIds: allQuantitiesKeys, tableId },
            });
          } else {
            dispatch({
              type: RECIPE_ACTION_ENUMS.UPDATE_ROW_SELECTION,
              payload: {
                cartIds: selectedRows.map((row) => row._id),
                tableId,
              },
            });
          }
        },
      }}
    />
  );
};
