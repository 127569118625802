import { gql } from '@apollo/client';
import { ICollection } from './get-collections';

export interface IDuplicateCollectionMutationVariables {
  input: {
    collectionId: number;
    userId: number;
    ownerId: number;
  };
}

export interface IDuplicateCollectionMutationResponse {
  duplicateCollection: {
    _id: ICollection['_id'];
  };
}

export const DUPLICATE_COLLECTION_MUTATION = gql`
  mutation duplicateCollection($input: duplicateCollectionInput!) {
    duplicateCollection(input: $input) {
      collection {
        id
        user {
          _id
        }
      }
    }
  }
`;
