import React, { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { App, Button } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactGA from 'react-ga4';
import { CopyOutlined } from '@ant-design/icons';
import { CardActionsWrapper } from '../../../../../../components/CardActionsWrapper';
import type { IRecipe } from '../../../../../../gql/get-recipe';

interface ICopyClipboardButton {
  recipe: IRecipe;
  declarationText: string;
}

export const CopyClipboardButton: FunctionComponent<ICopyClipboardButton> = ({
  recipe,
  declarationText,
}) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  return (
    <CardActionsWrapper>
      <CopyToClipboard
        text={declarationText}
        onCopy={(_, result: boolean) => {
          if (result) {
            ReactGA.gtag('event', 'Deklaration kopieren', {
              event_category: 'Rezeptansicht',
              recipe_name: recipe.title, // dimension1
            });

            notification.success({
              description: t('recipe.copyToClipboardSuccess.description'),
              message: t('recipe.copyToClipboardSuccess.message'),
            });
          }
        }}
      >
        <Button icon={<CopyOutlined />} block>
          {t('recipe.copyToClipboard')}
        </Button>
      </CopyToClipboard>
    </CardActionsWrapper>
  );
};
