import React, { FunctionComponent } from 'react';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RecipeCard } from '../../../components/RecipeCard';
import { CardPlaceholder } from '../../../components/Placeholders';
import { ResponsiveCardGrid } from '../../../components/ResponsiveCardGrid';
import { ErrorGraphQL } from '../../../components/ErrorGraphQL';
import { NoItemsGraphQL } from '../../../components/NoItemsGraphQL';
import { IHomePageData } from '../../../gql/get-home-page';

interface IPopularRecipes {
  data?: IHomePageData['popularRecipes'];
  loading?: boolean;
  error?: ApolloError;
}
export const PopularRecipes: FunctionComponent<IPopularRecipes> = ({
  loading,
  error,
  data,
}) => {
  const { t } = useTranslation();

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    // FIXME: When trying to use 2 or more columns, React 17 is bitchy about React not being in the placeholder component. Use <CardColumnsPlaceholder n={2} /> with React 18
    return (
      <ResponsiveCardGrid>
        <CardPlaceholder />
      </ResponsiveCardGrid>
    );
  }

  if (!data?.items.length) {
    return <NoItemsGraphQL message={t('recipes.noNewestRecipesAvailable')} />;
  }

  return (
    <ResponsiveCardGrid>
      {data.items.map(({ node }) => (
        <RecipeCard
          key={node._id}
          item={{
            ...node,
            targetUrl: `/recipes/${node._id}`,
          }}
        />
      ))}
    </ResponsiveCardGrid>
  );
};
