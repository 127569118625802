import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { circleButtonStyle } from '../../lib/styles';

export const baseStyle = css`
  width: 100%;
  height: 200px;
  overflow: hidden;
  text-align: center;
`;

export const AddToCollectionButton = styled(Button)`
  ${circleButtonStyle};
`;
