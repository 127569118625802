import { css } from '@emotion/react';
import React from 'react';
import { maxScreenSm } from '../lib/styles';
import { BackgroundImage } from './BackgroundImage';

interface IProps {
  imageWidths: {
    xs: number;
    sm: number;
    lg: number;
  };
  src: string | null;
  imageAspectRatio: number;
}

export const BackgroundImgFixedAspectRatio: React.FunctionComponent<IProps> = ({
  imageAspectRatio,
  imageWidths,
  src,
}) => (
  <BackgroundImage
    src={src}
    height={imageWidths.lg * imageAspectRatio}
    additionalStyles={css`
      ${maxScreenSm(`
        height: ${imageWidths.sm * imageAspectRatio}px;
      `)};

      ${maxScreenSm(`
      height: ${imageWidths.xs * imageAspectRatio}px;
    `)};
    `}
  />
);
