import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { IResponse } from '../gql/get-user';

export const UserRequired: FunctionComponent<
  PropsWithChildren<{ user: IResponse | undefined; redirectionPath?: string }>
> = ({ children, user, redirectionPath = '/' }) => {
  if (!user || !user.loggedInUser) {
    return <Navigate to={redirectionPath} />;
  }

  return <>{children}</>;
};
