import type { TFunction } from 'i18next';
import React from 'react';
import {
  defaultBigEights,
  ENERGY_KCAL,
  ENERGY_KJ,
  IBigEightsItem,
  KJ_2_KCAL_FACTOR,
} from '../components/NutritionalInformationTable/constants';
import type { IQuantity, IRecipe } from '../gql/get-recipe';
import {
  filterWaterIngredients,
  IIngredient,
} from '../pages/Recipes/pages/RecipeDetail/util';
import { formatNumber } from './helpers';

export const calculateGrossWeight = (recipe: IRecipe) =>
  recipe.quantities.items.reduce(
    (acc, { node }) => acc + parseFloat(node.quantity),
    0,
  );

/**
 * @return List of ingredient 3-tuples ordered by their proportion (descending)
 */
const calcIngredientList = (recipe: IRecipe) => {
  const grossWeight = calculateGrossWeight(recipe);

  const ingredients = recipe.quantities.items.reduce<IIngredient[]>(
    (acc, { node }) => {
      if (node.ingredient?.declaration) {
        let name = node.ingredient.declaration;

        if (node.ingredient.meatClassification === 'W') {
          name = name.replace(/\*(?!\*)/, '**');
        }

        return [
          ...acc,
          {
            name,
            number: node.ingredient.number,
            isQuid: node.isQuid,
            quantity: (parseFloat(node.quantity) / grossWeight) * 100,
          },
        ];
      }

      return acc;
    },
    [],
  );

  // Merge duplicates
  const aggregated: IIngredient[] = [];

  ingredients.forEach((ingredient) => {
    const existing = aggregated.findIndex((a) => a.name === ingredient.name);

    if (existing === -1) {
      aggregated.push(ingredient);

      return;
    }
    aggregated[existing].quantity += ingredient.quantity;
  });

  return filterWaterIngredients(aggregated, recipe).sort(
    (a, b) => b.quantity - a.quantity,
  );
};

export const formattedIngredientList = (recipe: IRecipe) =>
  calcIngredientList(recipe)
    .map(
      ({ isQuid, quantity, name }: IIngredient) =>
        `${isQuid ? `${quantity.toFixed(1).replace('.', ',')}% ` : ''}${name}`,
    )
    .join(', ');

/**
 * @param recipe
 * @return A list of tuples where each tuple is of the form
 *  ['LRA_NUTRTIONS_<KEY>', 123.45]
 */
export const extractNormalizedNutritionalInformation = (recipe: IRecipe) =>
  recipe.quantities.items.map(({ node: q }) => {
    const factor = parseFloat(q.quantity) / calculateGrossWeight(recipe);

    return !q.ingredient
      ? []
      : q.ingredient.nutritionalInformation.items.map<[string, number]>(
          ({ node: i }) => [i.key, parseFloat(i.quantity) * factor],
        );
  });

/**
 * Sum over the list of tuples returned by extractNormalizedNutritionalInformation
 * while grouping by nutrition key.
 *
 * @return object BigEights as expected by NutritionalInformation component.
 */
export const calcBigEights = (recipe: IRecipe): IBigEightsItem[] => {
  const normalizedInformation = extractNormalizedNutritionalInformation(recipe);
  const weightLossFactor =
    recipe.weightLoss >= 100 ? 0 : 100 / (100 - recipe.weightLoss);
  let reducedInformationWithWeightLoss = {};

  const reducedInformation = normalizedInformation.reduce((acc, info) => {
    info.forEach(([key, value]) => {
      acc[key] = acc[key] ? acc[key] + value : value;
    });

    return acc;
  }, {});

  reducedInformationWithWeightLoss = Object.entries<number>(
    reducedInformation,
  ).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value * weightLossFactor,
    }),
    {},
  );

  return defaultBigEights.map<IBigEightsItem>((item) => {
    if (item.key === ENERGY_KCAL) {
      return {
        ...item,
        value: formatNumber(
          reducedInformationWithWeightLoss[ENERGY_KJ] * KJ_2_KCAL_FACTOR,
        ),
      };
    }

    return {
      ...item,
      value: reducedInformationWithWeightLoss[item.key]
        ? formatNumber(reducedInformationWithWeightLoss[item.key])
        : 0,
    };
  });
};

export const containsAllergicSubstances = (declaration: string) =>
  /[\w\s]\*(?!\*)/.test(declaration);

export const containsVenisonSubstances = (declaration: string) =>
  /[\w\s]\*{2}(?!\*)/.test(declaration);

export const getSupplementaryInfoList = (text: string, t: TFunction) => {
  const textBlocks: string[] = [];

  if (containsAllergicSubstances(text)) {
    textBlocks.push(t('recipe.declaration.hints.allergens'));
  }

  if (containsVenisonSubstances(text)) {
    textBlocks.push(t('recipe.declaration.hints.venison'));
  }

  return textBlocks;
};

export const generateAdditionalDeclarationText = (
  recipe: IRecipe,
  bigEights: IBigEightsItem[],
  declaration: { text: string; weightLossHint?: string },
  t: TFunction,
) => {
  const { title, weightLoss } = recipe;

  let weightLossHint: string | string[] = [];

  if (declaration.weightLossHint) {
    weightLossHint = declaration.weightLossHint;
  } else if (weightLoss > 0) {
    weightLossHint = `${t('nutritionalInformation.weightLossInfo.description', {
      weightLoss: `${weightLoss}`,
    })}\n`;
  }

  const nutritionInfo = Object.values(bigEights)
    .map(
      ({ key, value, unit }) =>
        `${t(`nutritionalInformation.${key}`)}: ${value} ${unit}`,
    )
    .join('\n');

  return [
    title.trim(),
    `${t('recipe.ingredients')}: \n${declaration.text}`,
    getSupplementaryInfoList(declaration.text, t).join('\n'),
    t('nutritionalInformation.header'),
    weightLossHint,
    nutritionInfo,
  ]
    .flat()
    .join('\n\n');
};

const calcBaseIngredientsGrossWeight = (baseIngredients: IQuantity[]) =>
  baseIngredients.reduce((acc, { quantity }) => acc + parseFloat(quantity), 0);

const getTargetIngredientWeight = (
  baseIngredients: IQuantity[],
  relativeTo: IQuantity['id'] | 'all',
) => parseFloat(baseIngredients.find((i) => i.id === relativeTo)!.quantity);

export const calcNormalizationFactor = (
  baseIngredients: IQuantity[],
  relativeTo: IQuantity['id'] | 'all',
  baseWeight?: number,
) => {
  const norm =
    relativeTo === 'all'
      ? calcBaseIngredientsGrossWeight(baseIngredients)
      : getTargetIngredientWeight(baseIngredients, relativeTo);

  return (baseWeight || 0) / norm;
};

export const countRecipeBaseIngredients = (recipe: IRecipe): number =>
  recipe.quantities.items.filter((q) => !!q.node.stpoClass).length;

// Group "Grundmaterial" first, then "Einlagematerial"
const posOffset = (q: IQuantity) => {
  if (q.stpoClass === 'G') {
    return -1000;
  }

  return q.stpoClass === 'E' ? -100 : 0;
};

export const extractItems = (
  recipe: IRecipe,
  onlyBaseIngredients?: boolean,
) => {
  const { items } = recipe.quantities;

  return items
    .reduce<IQuantity[]>((acc, quantity) => {
      if (
        !!quantity.node.ingredient &&
        (onlyBaseIngredients === undefined ||
          (onlyBaseIngredients && quantity.node.stpoClass) ||
          (!onlyBaseIngredients && !quantity.node.stpoClass))
      ) {
        acc.push(quantity.node);
      }

      return acc;
    }, [])
    .sort(
      (q1, q2) => posOffset(q1) + q1.position - (posOffset(q2) + q2.position),
    );
};

export const formatQuantity = (
  record: IQuantity,
  normalizationFactor: number,
) => {
  const { quantity } = record;

  const normalizedQuantity =
    parseFloat(quantity) * (Math.round(normalizationFactor * 10000) / 10000);

  return formatNumber(normalizedQuantity);
};

export const getSelectableIds = (dataSource: IQuantity[]) =>
  dataSource.reduce<Set<React.Key>>((acc, item) => {
    if (
      item.ingredient &&
      !item.ingredient.isVirtual &&
      !item.ingredient.isDeleted
    ) {
      acc.add(item._id);
    }

    return acc;
  }, new Set([]));
