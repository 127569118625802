import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { FunctionComponent, ReactNode } from 'react';

import { DefaultLayout } from './DefaultLayout';

interface IMessagePage {
  message: string;
  description: string;
  icon?: ReactNode;
}
export const MessagePage: FunctionComponent<IMessagePage> = ({
  message,
  description,
  icon = <ExclamationCircleOutlined />,
}) => (
  <DefaultLayout center withoutAnonymousUserNotice>
    <h1>
      {icon} {message}
    </h1>
    <p>{description}</p>
  </DefaultLayout>
);
