import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface INoItemsGraphQL {
  message?: string;
}

export const NoItemsGraphQL: FunctionComponent<INoItemsGraphQL> = ({
  message,
}) => {
  const { t } = useTranslation();

  return (
    <Typography.Paragraph>
      {message || t('common.noItems')}
    </Typography.Paragraph>
  );
};
