import { TFunction } from 'i18next';
import {
  calcBigEights,
  calcNormalizationFactor,
  calculateGrossWeight,
  countRecipeBaseIngredients,
  extractItems,
  formattedIngredientList,
} from '../../../lib/recipeCalculations';
import { quidCalculation } from '../../Recipes/pages/RecipeDetail/util/quidCalculation';
import {
  IExportDeclaration,
  IExportIngredients,
} from '../../../context/ExportContext';
import {
  IExportData,
  prepareRecipeForExport,
} from '../../../lib/prepareRecipeForExport';
import { IRecipe } from '../../../gql/get-recipe';
import { IRecipe as IImageRecipe } from '../../../gql/get-recipe-cards';

interface IRecipeExportData {
  [key: string]: IExportData[];
}

interface ICollectionData {
  title: string;
  category: string;
  recipeCount: number;
}

export interface ICollectionExportData {
  collectionData: ICollectionData & {
    imageId: number | null;
  };
  recipes: IRecipeExportData;
}

export function prepareRecipesForExport(
  collectionRecipes: { cursor: string; node: IRecipe }[],
  collectionImageRecipe: IImageRecipe | null,
  collectionData: ICollectionData,
  t: TFunction,
) {
  let lowestImageId: number | null = null;
  let firstRecipeTitle: string | null = null;
  const recipeExportData: IRecipeExportData = {};

  collectionRecipes.forEach(({ node: recipe }) => {
    const grossWeight = calculateGrossWeight(recipe);
    const bigEights = calcBigEights(recipe);

    const declaration: IExportDeclaration = {
      ...(recipe.applyQuidMeatCalculation
        ? quidCalculation(grossWeight, recipe, t)
        : { text: formattedIngredientList(recipe) }),
      nutritionalValues: Object.values(bigEights).map(
        ({ key, value, unit }) => ({
          amount: `${value} ${unit}`,
          name: t(`nutritionalInformation.${key}`),
        }),
      ),
    };
    const ingredientQuantities = recipe.quantities.items.map(
      ({ node: quantityNode }) => quantityNode,
    );
    const baseIngredients = extractItems(recipe, true);
    const hasBaseMode = countRecipeBaseIngredients(recipe) === 0;
    const baseWeight =
      recipe.userWeights != null && recipe.userWeights.items.length > 0
        ? recipe.userWeights.items[0].node.baseWeight
        : 100;
    const ingredients: IExportIngredients = {
      normalizationFactor: calcNormalizationFactor(
        hasBaseMode ? ingredientQuantities : baseIngredients,
        'all',
        baseWeight,
      ),
    };
    const preparedRecipe = prepareRecipeForExport(
      recipe,
      declaration,
      ingredients,
      hasBaseMode,
      t,
    );

    const recipeLeafCategories = recipe.categories.items.filter(
      ({ node }) => node.children.totalCount === 0,
    );

    const categoryTitle =
      recipeLeafCategories.length > 0
        ? recipeLeafCategories[0].node.name
        : t('recipe.custom');
    let categoryRecipes =
      recipeExportData[categoryTitle] != null
        ? recipeExportData[categoryTitle]
        : null;

    if (categoryRecipes == null) {
      categoryRecipes = [];
    }

    categoryRecipes.push(preparedRecipe);
    recipeExportData[categoryTitle] = categoryRecipes;

    if (collectionImageRecipe != null && lowestImageId === null) {
      lowestImageId = collectionImageRecipe._id;
    } else {
      const isImageNotAlreadySet =
        lowestImageId === null && firstRecipeTitle === null;
      const isCurrentBefore =
        firstRecipeTitle !== null &&
        recipe.title.localeCompare(firstRecipeTitle) < 0;

      if (recipe.hasImage && (isImageNotAlreadySet || isCurrentBefore)) {
        lowestImageId = recipe._id;
        firstRecipeTitle = recipe.title;
      }
    }
  });

  const exportData: ICollectionExportData = {
    collectionData: {
      ...collectionData,
      imageId: lowestImageId,
    },
    recipes: recipeExportData,
  };

  return exportData;
}
