import React, { FunctionComponent, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Col, Divider, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApolloQueryResult } from '@apollo/client';
import { AuthGuard } from '../../../../../components/AuthGuard';
import { IRecipe } from '../../../../../gql/get-recipe';
import {
  formatDateTime,
  formatDateTimeShort,
} from '../../../../../lib/helpers';
import {
  defaultBottomMargin,
  defaultGutterPixelSize,
} from '../../../../../lib/styles';
import { roles } from '../../../../../lib/userRoles';
import { PublishRecipeAction } from './PublishRecipeAction';
import { RecipeAnonVisibilityAction } from './RecipeAnonVisibilityAction';

const PublishInfoCard = styled(Card)`
  ${defaultBottomMargin};

  .ant-card-body {
    padding: 24px 32px;
  }
`;

const InfoCardMeta = styled(Card.Meta)`
  .ant-card-meta-detail > .ant-card-meta-title {
    white-space: normal;
  }
`;

type IProps = {
  recipe: IRecipe;
  onPublishStateChanged: () => Promise<ApolloQueryResult<unknown>>;
};

export const RecipePublishInfo: FunctionComponent<IProps> = ({
  recipe,
  onPublishStateChanged,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const handlePublishStateChange = useCallback(async () => {
    setLoading(true);
    await onPublishStateChanged();
    setLoading(false);
  }, [onPublishStateChanged]);

  // Display information dialog only for RAPS recipes, not user-created ones
  if (recipe.user && !recipe.isSAP) {
    return null;
  }

  let title: string;
  let description: string | undefined;

  if (recipe.publishedBy && recipe.publishedAt) {
    const titleKey = recipe.isPublished
      ? 'recipe.publishedBy'
      : 'recipe.unpublishedBy';

    const descriptionKey = recipe.isPublished
      ? 'recipe.publishedByDetail'
      : 'recipe.unpublishedByDetail';

    title = t(titleKey, {
      name: `${recipe.publishedBy.firstName} ${recipe.publishedBy.lastName}`,
    });
    description = t(descriptionKey, {
      date: formatDateTime(recipe.publishedAt),
    });
  } else {
    title = t('recipe.unpublishedYet');
  }

  return (
    <AuthGuard roles={[roles.reviewer, roles.creator]}>
      <PublishInfoCard>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {recipe.author && (
            <InfoCardMeta
              title={t('recipe.author')}
              description={recipe.author}
            />
          )}

          {recipe.isSAP ? (
            <>
              <InfoCardMeta
                title={t('recipe.sap')}
                description={t('recipe.sapDescription')}
              />
              {recipe.emailSentBy && recipe.emailSentAt && (
                <InfoCardMeta
                  style={{ marginTop: '1.5rem' }}
                  title={t('recipe.emailSent')}
                  description={t('recipe.emailLastSent', {
                    date: formatDateTimeShort(recipe.emailSentAt),
                    user: `${recipe.emailSentBy.firstName} ${recipe.emailSentBy.lastName}.`,
                  })}
                />
              )}
            </>
          ) : (
            <AuthGuard roles={[roles.reviewer]}>
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                split={<Divider />}
              >
                <Row
                  justify="space-between"
                  align="top"
                  gutter={defaultGutterPixelSize}
                  wrap={false}
                >
                  <Col flex="auto">
                    <InfoCardMeta title={title} description={description} />
                  </Col>
                  <Col flex="70px" style={{ textAlign: 'right' }}>
                    <PublishRecipeAction
                      loading={loading}
                      recipe={recipe}
                      onPublishStateChanged={handlePublishStateChange}
                    />
                  </Col>
                </Row>

                <Row
                  justify="space-between"
                  align="top"
                  gutter={defaultGutterPixelSize}
                  wrap={false}
                >
                  <Col flex="auto">
                    <InfoCardMeta
                      title={
                        recipe.isVisibleToAnon
                          ? t('recipe.visibleToAnonUser')
                          : t('recipe.invisibleToAnonUser')
                      }
                      description={t('recipe.publishAnonDescription')}
                    />
                  </Col>
                  <Col flex="70px" style={{ textAlign: 'right' }}>
                    <RecipeAnonVisibilityAction
                      loading={loading}
                      recipe={recipe}
                      onAnonVisibilityChanged={handlePublishStateChange}
                    />
                  </Col>
                </Row>
              </Space>
            </AuthGuard>
          )}
        </Space>
      </PublishInfoCard>
    </AuthGuard>
  );
};
