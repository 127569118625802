import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { CollectionDetailContext } from '../context/CollectionDetailContext';
import { LoggedInUserContext } from '../context/LoggedInUserContext';
import { ICollection } from '../gql/get-collection-recipes';
import { IVariables, UPDATE_COLLECTION } from '../gql/update-collection';
import { IResponse, IUser } from '../gql/get-user';
import { roles } from '../lib/userRoles';

interface ICollectionDetailProviderProps {
  collection: ICollection | null;
  imageRecipeCount: number;
}

const hasUserRole = (user: IUser, role: string) =>
  user.userRoles.findIndex((r) => r.role === role) !== -1;

export const CollectionDetailProvider: FunctionComponent<
  PropsWithChildren<ICollectionDetailProviderProps>
> = ({ children, collection, imageRecipeCount }) => {
  const { t } = useTranslation();
  const [runCollectionUpdate, { loading }] =
    useMutation<IVariables>(UPDATE_COLLECTION);
  const [collectionImageRecipeIri, setCollectionImageRecipeIri] = useState<
    string | null
  >(null);
  const [canUserEditCollection, setCanUserEditCollection] =
    useState<boolean>(false);

  const loggedInUser: IResponse | null = useContext(LoggedInUserContext);

  const updateCollectionImage = useCallback(
    async (recipeIri: string) => {
      try {
        await runCollectionUpdate({
          awaitRefetchQueries: true,
          refetchQueries: ['collectionRecipes'],
          variables: {
            input: {
              collectionImageRecipe: recipeIri,
              id: collection?.id,
            },
          },
        });

        if (collectionImageRecipeIri !== recipeIri) {
          notification.info({
            description: t('collections.image.change.success.description'),
            message: t('collections.image.change.success.message'),
          });
          setCollectionImageRecipeIri(recipeIri);
        } else {
          notification.info({
            description: t('collections.image.change.reset.description'),
            message: t('collections.image.change.reset.message'),
          });
        }
      } catch (e) {
        notification.error({
          description: t('common.error.unknown.description'),
          message: t('common.error.unknown.message'),
        });
      }
    },
    [collectionImageRecipeIri, collection, runCollectionUpdate, t],
  );

  useEffect(() => {
    if (collection?.collectionImageRecipe != null && !loading) {
      setCollectionImageRecipeIri(collection.collectionImageRecipe.id);

      const isCollectionEditor =
        loggedInUser != null &&
        hasUserRole(loggedInUser.loggedInUser, roles.collectionEditor);

      const canEdit = collection.user != null || isCollectionEditor;

      setCanUserEditCollection(canEdit);
    }
  }, [collection, loading, loggedInUser]);

  const value = useMemo(
    () => ({
      canUserEditCollection,
      collectionImageRecipeIri,
      imageRecipeCount,
      isLoading: loading,
      updateCollectionImage,
    }),
    [
      canUserEditCollection,
      collectionImageRecipeIri,
      imageRecipeCount,
      loading,
      updateCollectionImage,
    ],
  );

  return (
    <CollectionDetailContext.Provider value={value}>
      {children}
    </CollectionDetailContext.Provider>
  );
};
