import React, { FunctionComponent, PropsWithChildren, useId } from 'react';
import { defaultGutterPixelSize } from '../lib/styles';

export const ResponsiveCardGrid: FunctionComponent<
  PropsWithChildren<{
    minCardSize?: number;
  }>
> = ({ children, minCardSize = 260 }) => {
  const id = useId();

  return (
    <div
      id={`card-grid-${id}`}
      css={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${minCardSize}px, 1fr))`,
        justifyItems: 'stretch',
        gap: defaultGutterPixelSize,
      }}
    >
      {children}
    </div>
  );
};
