import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, App } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useCallback } from 'react';

interface IProps {
  onConfirmDelete: () => Promise<void>;
  modalTitle: string;
  circle?: boolean;
}

export const DeleteButton: FunctionComponent<IProps> = ({
  circle,
  onConfirmDelete,
  modalTitle,
}) => {
  const { t } = useTranslation();
  const { modal, notification } = App.useApp();

  const openDeleteModal = useCallback(() => {
    modal.confirm({
      cancelText: t('common.no'),
      okText: t('common.yes'),
      onOk: async () => {
        try {
          await onConfirmDelete();
        } catch (e) {
          notification.error({
            description: t('common.error.unknown.description'),
            message: t('common.error.unknown.message'),
          });
        }
      },
      title: modalTitle,
      icon: <QuestionCircleOutlined />,
    });
  }, [modal, modalTitle, notification, onConfirmDelete, t]);

  return (
    <Button
      onClick={openDeleteModal}
      icon={<DeleteOutlined />}
      title={t('recipe.deleteButton')}
      {...(circle && { shape: 'circle' })}
    >
      {!circle && t('recipe.deleteButton')}
    </Button>
  );
};
