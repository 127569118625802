import { ApolloClient } from '@apollo/client';
import { handleError } from './handleError';
import { fetchLogout } from './handleFetch';

export const handleExpiredToken = async (
  client: ApolloClient<object>,
  expired = true,
  reload = false,
) => {
  await fetchLogout();

  try {
    await client.cache.reset();
  } catch (err) {
    handleError(err);
  }

  if (expired) {
    window.sessionStorage.setItem('reload', 'true');
  }

  window.localStorage.removeItem('apollo-cache-persist');

  if (reload) {
    window.location.reload();
  }
};
