import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { primaryColor } from '../../theme/variables';
import { LoggedInUserContext } from '../../context/LoggedInUserContext';
import { MessagePage } from '../../components/MessagePage';
import { DefaultLayout } from '../../components/DefaultLayout';
import { usePageTitle } from '../../hooks/usePageTitle';

const RapsLink = styled(Link)`
  color: ${primaryColor};
  font-weight: bold;
`;
const Error404Page = () => {
  const { t } = useTranslation();

  usePageTitle(t('pageTitles.error404'));
  const user = useContext(LoggedInUserContext);

  if (user) {
    return (
      <MessagePage
        message={t('common.error.404.message')}
        description={t('common.error.404.description')}
      />
    );
  }

  return (
    <DefaultLayout center withoutAnonymousUserNotice>
      <p>{t('common.error.404.anonymousUser.sorryText')}</p>
      <p>
        {t('common.error.404.anonymousUser.directionText')}{' '}
        <RapsLink to="/">
          {t('common.error.404.anonymousUser.startPage')}
        </RapsLink>{' '}
        {t('common.error.404.anonymousUser.or')}{' '}
        <RapsLink to="/login">{t('header.login')}</RapsLink>.
      </p>
    </DefaultLayout>
  );
};

export default Error404Page;
