import { Card, Skeleton } from 'antd';
import React from 'react';

export const CardPlaceholder: React.FunctionComponent = () => (
  <Card
    cover={
      <Skeleton.Image
        active
        css={{
          width: '100%',
          '& >.ant-skeleton-image': {
            width: '100%',
            height: '200px',
          },
        }}
      />
    }
  >
    <Skeleton paragraph={{ rows: 1 }} css={{ height: '82px' }}></Skeleton>
  </Card>
);
