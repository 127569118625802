import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import { useResponsive } from 'ahooks';
import { Row } from 'antd';
import {
  GET_RECIPE_CARDS,
  IResponse,
  IVariables,
} from '../../../../../gql/get-recipe-cards';
import {
  IPaginationProps,
  useRapsPagination,
} from '../../../hooks/usePagination';
import { isRecipeNumberPattern } from '../../../utils';
import { ErrorGraphQL } from '../../../../../components/ErrorGraphQL';
import { defaultGutterPixelSize } from '../../../../../lib/styles';
import { CardColumnsPlaceholder } from '../../../../../components/Placeholders';
import { SearchNoResult } from '../../../../../components/SearchNoResult';
import { RecipeCard } from '../../../../../components/RecipeCard';
import { SearchResultHeader } from '../../../../../components/SearchResultHeader';
import { SearchResultCarousel } from '../../../components/SearchResultCarousel';
import { ResultList } from '../../../components/ResultList';

interface ISearchResultRecipesProps {
  isSAP?: boolean;
  ownedByUser?: boolean;
  revoked?: boolean;
  pageSize?: number;
  i18nKey?: string;
  path?: string;
  showCarousel?: boolean;
}

interface IGetQueryVariable extends ISearchResultRecipesProps {
  paginationProps: IPaginationProps;
}
const getQueryVariables = ({
  isSAP,
  ownedByUser,
  revoked,
  paginationProps,
}: IGetQueryVariable): IVariables => {
  const { itemsPerPage, q, cursor } = paginationProps;

  const queryVariables: IVariables = {
    cursor,
    isSAP: Boolean(isSAP),
    itemsPerPage,
    orderBy: { title: 'ASC' },
    isPublished: revoked === true ? false : undefined,
    ownedByUser: revoked !== undefined ? false : ownedByUser,
    wasPublishedInPast: revoked === false ? false : undefined,
  };

  // if the query is parsable to an int
  // use it to query the recipes
  if (q && !Number.isNaN(parseInt(q, 10))) {
    if (isRecipeNumberPattern.test(q)) {
      queryVariables.number = q;
    } else {
      queryVariables.ingredientNumber = q;
    }
  } else {
    queryVariables.q = q;
  }

  return queryVariables;
};

export const SearchResultRecipes: FunctionComponent<
  ISearchResultRecipesProps
> = ({
  pageSize,
  revoked,
  isSAP = false,
  ownedByUser = false,
  i18nKey,
  path,
  showCarousel = false,
}) => {
  const paginationProps = useRapsPagination({ pageSize });
  const responsive = useResponsive();

  const variables = getQueryVariables({
    isSAP,
    ownedByUser,
    revoked,
    paginationProps,
  });

  const term = variables.q || variables.number || variables.ingredientNumber;
  const skip = !term;

  const { data, loading, error } = useQuery<IResponse, IVariables>(
    GET_RECIPE_CARDS,
    {
      fetchPolicy: 'cache-and-network',
      variables,
      skip,
    },
  );

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <Row gutter={defaultGutterPixelSize}>
        <CardColumnsPlaceholder amount={responsive.xl ? 2 : 1} />
      </Row>
    );
  }

  if (!data?.response?.items.length) {
    return <SearchNoResult searchTerm={term} i18nKey={i18nKey} />;
  }

  const items = data.response.items.map(({ node }) => (
    <RecipeCard
      key={node._id}
      inCarousel
      item={{
        ...node,
        isSAP: variables.isSAP,
        targetUrl: `/recipes/${node._id}`,
      }}
    />
  ));

  return (
    <>
      {i18nKey && path && (
        <SearchResultHeader
          i18nKey={i18nKey}
          path={path}
          count={data.response.totalCount}
        />
      )}

      {showCarousel ? (
        <SearchResultCarousel items={items} />
      ) : (
        <ResultList items={items} totalCount={data?.response.totalCount} />
      )}
    </>
  );
};
