import React, { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

interface IBreadcrumbItem {
  text: string;
  href: string;
}

interface IBreadcrumbProps {
  items: IBreadcrumbItem[];
}

export const Breadcrumbs: React.FunctionComponent<IBreadcrumbProps> = ({
  items,
}) => {
  const breadcrumbItems = useMemo(() => {
    const clonedItems = [...items];

    const lastItem = clonedItems.splice(-1, 1)[0];

    return [
      ...clonedItems.map(({ href, text }) => ({
        title: <Link to={href}>{text}</Link>,
        key: href,
      })),
      {
        title: lastItem.text,
        key: lastItem.href,
      },
    ];
  }, [items]);

  return <Breadcrumb separator=" > " items={breadcrumbItems} />;
};
