import { gql } from '@apollo/client';
import { IRecipe } from './get-recipe';
import { ENHANCED_RECIPE_FIELDS } from './fragments/recipe';
import { ENHANCED_QUANTITY_FIELDS } from './fragments/quantities';

export interface IResponse {
  response: {
    totalCount: number;
    isSAP: boolean;
    items: Array<{
      cursor: string;
      node: IRecipe;
    }>;
  };
}

export interface IVariables {
  collectionId?: number;
  cursor?: string;
  hasImage?: boolean;
  itemsPerPage: number;
  q?: string;
  number?: string;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
  ownedByUser?: boolean;
  wasPublishedInPast?: boolean;
  isPublished?: boolean;
  isVisibleToAnon?: boolean;
  ingredientNumber?: string;
  isSAP: boolean;
  withUser: boolean;
}

export const GET_FULL_RECIPES = gql`
  ${ENHANCED_RECIPE_FIELDS}
  ${ENHANCED_QUANTITY_FIELDS}
  query fullRecipes(
    $q: String
    $number: String
    $hasImage: Boolean
    $itemsPerPage: Int!
    $cursor: String
    $ownedByUser: Boolean
    $orderBy: RecipeFilter_orderBy
    $wasPublishedInPast: Boolean
    $isPublished: Boolean
    $isVisibleToAnon: Boolean
    $ingredientNumber: String
    $collectionId: Int
    $isSAP: Boolean
    $withUser: Boolean = true
  ) {
    response: recipes(
      title: $q
      number: $number
      hasImage: $hasImage
      first: $itemsPerPage
      after: $cursor
      orderBy: [$orderBy]
      isPublished: $isPublished
      isVisibleToAnon: $isVisibleToAnon
      quantities_ingredient_number: $ingredientNumber
      collections_id: $collectionId
      exists: {
        user: $ownedByUser
        publishedAt: $wasPublishedInPast
        isSAP: $isSAP
      }
    ) {
      totalCount
      items: edges {
        cursor
        node {
          ...EnhancedRecipeFields
          publishedBy {
            firstName
            lastName
          }
          collections(exists: { user: true }) {
            totalCount
          }
          userWeights @include(if: $withUser) {
            totalCount
            items: edges {
              node {
                baseWeight
              }
            }
          }
          quantities(first: 100) {
            ...EnhancedQuantityFields
          }
        }
      }
    }
  }
`;
