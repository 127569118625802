import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DataProxy, FetchResult, useApolloClient } from '@apollo/client';
import {
  GET_SHOPPING_CART_ITEMS,
  ICreateResponse,
  IResponse,
  IShoppingCartItem,
} from '../../../gql/get-shopping-cart-items';
import { handleError } from '../../../lib/handleError';
import { IIngredientRow } from '../interfaces';
import { CREATE_SHOPPING_CART_ITEM } from '../../../gql/create-shopping-cart-item';

interface IAddItemButton {
  record: IIngredientRow;
}

export const AddItemButton: FunctionComponent<IAddItemButton> = ({
  record,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [addedLoading, setAddedLoading] = useState(false);

  const addItem = useCallback(async () => {
    setAddedLoading(true);
    const existingContainerSizes = record.containers.map(
      (container: IShoppingCartItem) => container.containerSize,
    );

    // Find unused containerSizes
    const containerSizes = record.shopIngredients.edges
      .reduce<number[]>((acc, edge) => {
        const { containerSize } = edge.node;

        if (!existingContainerSizes.includes(containerSize)) {
          return [...acc, Number.parseFloat(containerSize)];
        }

        return acc;
      }, [])
      .sort((a: number, b: number) => a - b);

    try {
      await client.mutate({
        mutation: CREATE_SHOPPING_CART_ITEM,
        update: (cache: DataProxy, result: FetchResult<ICreateResponse>) => {
          const cachedData = cache.readQuery<IResponse>({
            query: GET_SHOPPING_CART_ITEMS,
          });

          if (!cachedData || !result || !result.data) {
            return;
          }

          const { edges } = cachedData.shoppingCartItems;

          const newEdge = {
            __typename: 'ShoppingCartItemEdge',
            node: {
              ...result.data.response.shoppingCartItem,
              __typename: 'ShoppingCartItem',
              ingredient: record,
            },
          };

          cache.writeQuery({
            query: GET_SHOPPING_CART_ITEMS,
            data: {
              shoppingCartItems: {
                ...cachedData.shoppingCartItems,
                edges: [...edges, newEdge],
              },
            },
          });
        },
        variables: {
          input: {
            containerSize: containerSizes[0],
            extraAmount: 1,
            ingredient: record.id,
            quantity: 0,
          },
        },
      });
    } catch (e) {
      handleError(e);
    }

    setAddedLoading(false);
  }, [client, record]);

  return (
    <Button
      onClick={addItem}
      loading={addedLoading}
      size="small"
      shape="circle"
      icon={<PlusOutlined aria-label={t('common.add')} />}
      title={t('common.add')}
    />
  );
};
