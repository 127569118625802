import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { RecipeCard } from '../../../../components/RecipeCard';
import {
  GET_COLLECTION_RECIPES,
  IResponse as ICollectionRecipesResponse,
  IResponse,
  IVariables as ICollectionRecipesVariables,
  IVariables,
} from '../../../../gql/get-collection-recipes';
import {
  IContext,
  IVariables as IUpdateCollectionRecipesVariables,
  UPDATE_COLLECTION_RECIPES,
} from '../../../../gql/update-collection-recipes';
import { CollectionDetailProvider } from '../../../../provider/CollectionDetailProvider';
import { ResultList } from '../../../Search/components/ResultList';
import { CollectionTitle } from './components/CollectionTitle';
import { useRapsPagination } from '../../../Search/hooks/usePagination';
import { Loading } from '../../../../components/Loading';
import { NoItemsGraphQL } from '../../../../components/NoItemsGraphQL';
import { ErrorGraphQL } from '../../../../components/ErrorGraphQL';

type IProps = {
  collectionCategory: string;
  showDeleteBtn?: boolean;
};

export const CollectionDetailList: FunctionComponent<IProps> = ({
  collectionCategory,
  showDeleteBtn = false,
}) => {
  const client = useApolloClient();
  const { cursor, itemsPerPage } = useRapsPagination();
  const { collectionId } = useParams();

  const { loading, error, data, refetch } = useQuery<IResponse, IVariables>(
    GET_COLLECTION_RECIPES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        collectionIriId: `/api/collections/${collectionId}`,
        collectionNumericId: parseInt(collectionId!, 10),
        cursor,
        itemsPerPage,
        orderBy: { title: 'ASC' },
      },
    },
  );

  const imageRecipeCount = useMemo(
    () =>
      data != null
        ? data.recipes.items.filter(({ node: recipe }) => recipe.hasImage)
            .length
        : 0,
    [data],
  );

  const deleteRecipe = useCallback(
    async (recipeIriId: string) => {
      const { collection } = data || {};

      if (collection && collection.id && collection._id) {
        // Step 1: Get all recipes currently in the collection
        const query = await client.query<
          ICollectionRecipesResponse,
          ICollectionRecipesVariables
        >({
          fetchPolicy: 'network-only',
          query: GET_COLLECTION_RECIPES,
          variables: {
            collectionIriId: collection.id,
            collectionNumericId: collection._id,
            itemsPerPage: 100,
          },
        });

        if (query.errors) {
          throw new Error(query.errors[0].message);
        }

        if (!query.data.collection) {
          throw new Error(
            `Could not retrieve collection with id ${collection.id}`,
          );
        }

        // Step 2: Remove passed recipeIri from collection and update collection
        const recipeIriIds = query.data.recipes.items.reduce<string[]>(
          (acc, item) => {
            if (item.node.id === recipeIriId) {
              return acc;
            }

            return [...acc, item.node.id];
          },
          [],
        );

        if (query.data.recipes.items.length === recipeIriIds.length) {
          return;
        }

        await client.mutate<IContext, IUpdateCollectionRecipesVariables>({
          mutation: UPDATE_COLLECTION_RECIPES,
          variables: { id: collection.id, recipes: recipeIriIds },
        });
        await refetch();
      }
    },
    [client, data, refetch],
  );

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return <Loading />;
  }

  if (!data?.collection || !data.recipes.items) {
    return <NoItemsGraphQL />;
  }

  const items = data.recipes.items.map(({ node }) => (
    <RecipeCard
      key={node._id}
      item={{
        ...node,
        isSAP: false,
        targetUrl: `/recipes/${node._id}`,
        ...(showDeleteBtn && { deleteAction: deleteRecipe }),
      }}
    />
  ));

  return (
    <CollectionDetailProvider
      imageRecipeCount={imageRecipeCount}
      collection={data.collection}
    >
      <CollectionTitle
        collection={data.collection}
        collectionId={collectionId!}
        collectionCategory={collectionCategory}
        isPrivate={showDeleteBtn}
        itemSize={data.recipes.items.length}
        onTitleChange={refetch}
      />

      <ResultList items={items} totalCount={data.recipes.totalCount} />
    </CollectionDetailProvider>
  );
};
