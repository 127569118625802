import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RapsInfoAlert } from './RapsInfoAlert';

const CallToAction = styled.div`
  text-transform: uppercase;
`;

export const AnonymousUserNotice: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Link to="/login">
      <RapsInfoAlert
        message={t('anonymousUserNotice.notice')}
        description={
          <CallToAction>{t('anonymousUserNotice.callToAction')}</CallToAction>
        }
      />
    </Link>
  );
};
