import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Col } from 'antd';
import { dinOffcPro } from '../theme/customVariables';
import {
  disabledColor,
  screenMd,
  screenSm,
  screenXs,
} from '../theme/variables';

export const defaultBottomMargin = css`
  margin-bottom: 1.5rem;
`;

export const defaultActionButtonMargin = css`
  margin-bottom: 0.5em;

  button:not(:last-of-type) {
    margin-right: 0.25em;
  }
`;

export const SmallButtonCol = styled(Col)`
  margin-bottom: 0.75em;
`;

export const defaultGutterPixelSize = 16;

export const maxScreen = (width: string, styles: string) =>
  `@media (max-width: ${width}) { ${styles} }`;

export const maxScreenXs = (styles: string) => maxScreen(screenXs, styles);
export const maxScreenSm = (styles: string) => maxScreen(screenSm, styles);

export const globalStyles = css`
  @font-face {
    font-family: '${dinOffcPro}';
    font-weight: 400;
    font-display: swap;
    src:
      url('/assets/fonts/DINOffcPro.woff2') format('woff2'),
      url('/assets/fonts/DINOffcPro.ttf') format('truetype');
  }

  @font-face {
    font-family: '${dinOffcPro}';
    font-weight: 900;
    font-display: swap;
    src:
      url('/assets/fonts/DINOffcPro-Bold.woff2') format('woff2'),
      url('/assets/fonts/DINOffcPro-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: '${dinOffcPro}';
    font-weight: 200;
    font-display: swap;
    src:
      url('/assets/fonts/DINOffcPro-Light.woff2') format('woff2'),
      url('/assets/fonts/DINOffcPro-Light.ttf') format('truetype');
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-wrap: break-word;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 24px;
  }

  #root {
    height: 100%;
  }

  // avoid overlaying navbar
  .cookiefirst-root > span {
    @media only screen and (max-width: ${screenMd}) {
      bottom: 75px;
    }
  }

  .ant-upload-list-item-card-actions > a[title='Download file'] {
    display: none;
  }

  .ant-table-body tr td {
    background-color: ${'orange'} !important;
  }
  .ant-btn-icon-only > .ant-btn-icon {
    display: block;
  }
`;

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 0 48px;

  &.ant-layout-content {
    min-height: initial;
  }

  ${maxScreenSm(`
    padding-inline: ${defaultGutterPixelSize}px;
  `)};
`;

export const slightShadow = css`
  box-shadow: 0 0 5px #bbb;
`;

export const disabledMenuItemStyle = css`
  color: ${disabledColor};
`;

export const ScrollableList = styled('div')`
  max-height: 45vh;
  margin-top: 20px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

export const tableRowNoWrap = css`
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    white-space: nowrap;
  }

  .ant-table-body {
    overflow-x: auto;
  }
`;

export const circleButtonStyle = css`
  border-width: 2px;
`;
