import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  App,
  AutoComplete,
  AutoCompleteProps,
  Button,
  Input,
  Modal,
} from 'antd';
import { FetchResult, useApolloClient, useQuery } from '@apollo/client';
import { SnippetsOutlined } from '@ant-design/icons';
import {
  GET_USERS_BY_ACCOUNT_NUMBER,
  IUserData,
  IGetUsersByAccountVariables,
  IGetUsersByAccountResponse,
} from '../gql/get-users-by-account-number';
import { TransferAutocompleteLabel } from './TransferAutoCompleteLabel';
import {
  GET_USER,
  type IResponse as ICurrentUserResponse,
} from '../gql/get-user';

interface ITransferModalProps {
  modalTitle: string;
  modalTrigger: string;
  mutationHandler?: (input: {
    userId: number;
    ownerId: number;
  }) => Promise<FetchResult | null>;
  successMessage?: string;
  errorMessage?: string;
}

export const TransferModal: FunctionComponent<ITransferModalProps> = ({
  modalTitle,
  modalTrigger,
  mutationHandler,
  successMessage,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const { data: currentUser } = useQuery<ICurrentUserResponse>(GET_USER);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [inputValue, setInputValue] = useState<string>('');

  const [selectedUser, setSelectedUser] = useState<IUserData | null>(null);
  const [searchResults, setSearchResults] = useState<Map<
    IUserData['accountNumber'],
    IUserData
  > | null>(null);

  const [selectOptions, setSelectOptions] = useState<
    AutoCompleteProps['options']
  >([]);

  const client = useApolloClient();

  const resetModal = useCallback(() => {
    setOpen(false);
    setConfirmLoading(false);
    setSelectedUser(null);
    setSearchResults(null);
    setInputValue('');
    setSelectOptions([]);
  }, []);

  const showModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSearch = useCallback(
    async (value: string) => {
      if (!value) {
        setSelectOptions([]);
        setSelectedUser(null);
        setSearchResults(null);

        return;
      }

      // ignore non-numeric characters
      if (/\D/.test(value)) {
        return;
      }

      const { data: userData, error } = await client.query<
        IGetUsersByAccountResponse,
        IGetUsersByAccountVariables
      >({
        query: GET_USERS_BY_ACCOUNT_NUMBER,
        fetchPolicy: 'network-only',
        variables: {
          accountNumber: value,
        },
      });

      if (error) {
        notification.error({
          message: t('modal.transferModal.usersFetchError'),
        });
      }

      if (currentUser && userData) {
        // Filter out the current user
        const users = userData.users.edges
          .map(({ node }) => node)
          .filter((dbUser) => dbUser._id !== currentUser.loggedInUser.id);

        setSearchResults(
          new Map(users.map((node) => [node.accountNumber, node])),
        );
        setSelectOptions(
          users.map((node) => ({
            value: node.accountNumber,
            label: <TransferAutocompleteLabel node={node} />,
          })),
        );
      }
    },
    [client, currentUser, notification, t],
  );

  const onSelect = useCallback(
    (value: IUserData['accountNumber']) => {
      if (value != null && searchResults != null) {
        setSelectedUser(searchResults.get(value) ?? null);
      }
    },
    [searchResults],
  );

  const handleOk = useCallback(async () => {
    setConfirmLoading(true);

    if (selectedUser != null && mutationHandler && currentUser) {
      try {
        const res = await mutationHandler({
          userId: Number(selectedUser._id),
          ownerId: currentUser.loggedInUser.id,
        });

        if (res == null || res.errors) {
          if (errorMessage) {
            notification.error({
              message: errorMessage,
            });
          }
          setOpen(false);

          return;
        }

        if (successMessage) {
          notification.success({
            message: successMessage,
          });
        }
      } catch (e) {
        notification.error({
          message: errorMessage,
        });
      }
    }
    resetModal();
  }, [
    selectedUser,
    mutationHandler,
    currentUser,
    resetModal,
    successMessage,
    errorMessage,
    notification,
  ]);

  // Remove non-numeric characters from input
  const handleInputChange = useCallback((value: string) => {
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters

    setInputValue(numericValue);
  }, []);

  return (
    <>
      <Button onClick={showModal} icon={<SnippetsOutlined />}>
        {modalTrigger}
      </Button>
      <Modal
        title={modalTitle}
        open={open}
        okButtonProps={{ disabled: selectedUser == null }}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={resetModal}
      >
        <AutoComplete
          style={{ width: '100%' }}
          popupMatchSelectWidth
          options={selectOptions}
          onSelect={onSelect}
          onSearch={handleSearch}
          value={inputValue}
          onChange={handleInputChange}
        >
          <Input
            addonBefore={t('modal.transferModal.addonBefore')}
            placeholder={t('modal.transferModal.placeholder')}
            suffix={
              selectedUser != null ? (
                <span
                  style={{ color: 'grey' }}
                >{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
              ) : null
            }
          />
        </AutoComplete>
      </Modal>
    </>
  );
};
