import React, { FunctionComponent, useCallback } from 'react';
import styled from '@emotion/styled';
import { Button, Card, Row, Space } from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CardColumn } from '../../../../components/CardColumn';
import { DefaultLayout } from '../../../../components/DefaultLayout';
import { NewsCard } from '../../../../components/NewsCard';
import {
  GET_NEWS,
  INewsItemsResponse,
  ISearchVariables,
} from '../../../../gql/get-news';
import { defaultBottomMargin } from '../../../../lib/styles';
import { NewsCrudBar } from '../NewsDetail/components/NewsCrudBar';
import {
  BigGreyHeading,
  TextUpperCase,
} from '../../../Recipes/pages/RecipeDetail/components/styles';
import { usePageTitle } from '../../../../hooks/usePageTitle';

const LoadMoreButton = styled(Button)`
  display: block;
  margin: auto;
`;

const MarginNewsCard = styled(NewsCard)`
  ${defaultBottomMargin};
`;

const NewsItemLoader: FunctionComponent = () => {
  const { t } = useTranslation();

  const { loading, error, data, fetchMore } = useQuery<
    INewsItemsResponse,
    ISearchVariables
  >(GET_NEWS, {
    fetchPolicy: 'cache-and-network',
    variables: { itemsPerPage: 5, orderBy: { createdAt: 'DESC' } },
  });

  usePageTitle(t('pageTitles.newsOverview'));

  const loadMore = useCallback(async () => {
    if (data?.response) {
      await fetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            ...prev,
            response: {
              ...prev.response,
              items: [
                ...prev.response.items,
                ...fetchMoreResult.response.items,
              ],
            },
          };
        },
        variables: {
          cursor: data.response.items[data.response.items.length - 1].cursor,
          itemsPerPage: 5,
          orderBy: { createdAt: 'DESC' },
        },
      });
    }
  }, [data, fetchMore]);

  if (!data || !data.response || !data.response.items[0]) {
    return (
      <CardColumn>
        <Card loading />
      </CardColumn>
    );
  }

  if (error) {
    return <p>Error :(</p>;
  }

  return (
    <>
      <Space wrap>
        {data.response.items.map(({ node }) => (
          <CardColumn key={node.id}>
            <MarginNewsCard item={node} />
          </CardColumn>
        ))}
      </Space>
      {data.response.items.length !== data.response.totalCount && (
        <LoadMoreButton loading={loading} onClick={loadMore}>
          {t('common.loadMore')}
        </LoadMoreButton>
      )}
    </>
  );
};

const NewsOverview: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <BigGreyHeading>
        <TextUpperCase>{t('common.news')}</TextUpperCase>
      </BigGreyHeading>
      <NewsCrudBar path="create" />
      <Row gutter={16}>
        <NewsItemLoader />
      </Row>
    </DefaultLayout>
  );
};

export default NewsOverview;
