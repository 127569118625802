import { gql } from '@apollo/client';

export const CORE_RECIPE_FIELDS = gql`
  fragment CoreRecipeFields on Recipe {
    _id
    id
    title
    number
    hasImage
    imageId
    collections(exists: { user: true }) {
      totalCount
    }
  }
`;

export const ENHANCED_RECIPE_FIELDS = gql`
  ${CORE_RECIPE_FIELDS}

  fragment EnhancedRecipeFields on Recipe {
    ...CoreRecipeFields
    quantity
    quantityUnit
    weightLoss
    applyQuidMeatCalculation
    ZAN1
    ZAN2
    ZAN3
    ZAN4
    ZAN5
    imageId
    author
    user {
      _id
    }
    isMeatProduct
    isPublished
    isSAP
    isVisibleToAnon
    publishedAt
    categories(first: 100) {
      totalCount
      items: edges {
        cursor
        node {
          _id
          id
          name
          children {
            totalCount
          }
        }
      }
    }
  }
`;
