import React, { useState, FunctionComponent, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  onClick?: VoidFunction;
}

export const SwipeableLink: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  to,
  onClick,
}) => {
  const [mouseX, setMouseX] = useState(0);

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    setMouseX(event.screenX);
  };

  const onInternalClick = (event: React.MouseEvent) => {
    const delta = Math.abs(event.screenX - mouseX);

    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event
      event.preventDefault();
    } else {
      onClick?.();
    }

    setMouseX(0);
  };

  return (
    <Link to={to} onMouseDown={onMouseDown} onClick={onInternalClick}>
      {children}
    </Link>
  );
};
