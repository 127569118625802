import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { App, Switch } from 'antd';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRecipe } from '../../../../../gql/get-recipe';
import { apiUrls } from '../../../../../lib/apiUrls';
import { handleError } from '../../../../../lib/handleError';

type IProps = {
  loading: boolean;
  recipe: IRecipe;
  onPublishStateChanged: () => Promise<unknown>;
};

export const PublishRecipeAction: FunctionComponent<IProps> = ({
  recipe,
  loading,
  onPublishStateChanged,
}) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const [isPublished, setIsPublished] = useState(recipe.isPublished === true);

  const handlePublishToggle = useCallback(
    (published: boolean) => {
      modal.confirm({
        cancelText: t('common.no'),
        content: published
          ? t('recipe.publishConfirmDescription')
          : t('recipe.unpublishConfirmDescription'),
        okText: t('common.yes'),
        onOk: async () => {
          const recipeId = recipe._id;

          try {
            await fetch(apiUrls.publishRecipe(recipeId), {
              body: JSON.stringify({ isPublished: published }),
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'PUT',
            });

            setIsPublished((prevState) => !prevState);
            await onPublishStateChanged();
          } catch (e) {
            handleError(e);
          }
        },
        title: published
          ? t('recipe.publishConfirmTitle')
          : t('recipe.unpublishConfirmTitle'),
      });
    },
    [modal, onPublishStateChanged, recipe._id, t],
  );

  return (
    <Switch
      checked={isPublished}
      disabled={loading}
      onChange={handlePublishToggle}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
    />
  );
};
