import React from 'react';
import styled from '@emotion/styled';
import { Col, Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { grey } from '@ant-design/colors';
import { activeNavLinkColor } from '../theme/customVariables';
import {
  headingColor,
  layoutFooterBackground,
  screenMd,
} from '../theme/variables';
import { LayoutContainer } from './LayoutContainer';

const NavLink = styled(RouterNavLink)`
  color: ${headingColor};

  &.active {
    color: ${activeNavLinkColor};
  }
`;

const FooterNav = styled('nav')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  padding-top: 8px;

  @media only screen and (min-width: ${screenMd}) {
    padding-top: 0;
  }
`;

export const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout.Footer
      id="footer"
      style={{
        background: layoutFooterBackground,
        paddingBlock: 24,
        paddingInline: 0,
        minHeight: 'min-content',
      }}
    >
      <LayoutContainer>
        <Row justify="space-between">
          <Col xs={24} md={6} css={{ color: grey[6] }}>
            &copy; RAPS GmbH &amp; Co. KG
          </Col>
          <Col>
            <FooterNav id="footer-nav">
              <NavLink to="/faq">{t('faq.shortTitle')}</NavLink>
              <NavLink to="/contact">{t('contact.title')}</NavLink>
              <NavLink to="/imprint">{t('imprint.title')}</NavLink>
              <NavLink to="/privacy-policy">{t('privacyPolicy.title')}</NavLink>
              <NavLink to="/terms-of-use">{t('termsOfUse.title')}</NavLink>
            </FooterNav>
          </Col>
        </Row>
      </LayoutContainer>
    </Layout.Footer>
  );
};
