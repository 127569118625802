import { gql } from '@apollo/client';

export const CORE_CATEGORY_FIELDS = gql`
  fragment CoreCategoryFields on CategoryConnection {
    totalCount
    items: edges {
      cursor
      node {
        _id
        id
        name
        hasImage
        recipes(first: 12) {
          totalCount
          items: edges {
            cursor
            node {
              _id
              id
              hasImage
              imageId
            }
          }
        }
      }
    }
  }
`;
