export const ENERGY_KCAL = 'LRA_NUTRTIONS_KCAL';
export const ENERGY_KJ = 'LRA_NUTRTIONS_KJ';
export const ENERGY_FAT = 'LRA_NUTRITIONS_FAT';
export const KCAL_2_KJ_FACTOR = 4.1858;
export const KJ_2_KCAL_FACTOR = 0.2389;

export interface IBigEightsItem {
  key: string;
  unit: string;
  value?: string | number;
}

export const defaultBigEights: IBigEightsItem[] = [
  {
    key: ENERGY_KJ,
    unit: 'kJ',
  },
  {
    key: ENERGY_KCAL,
    unit: 'kcal',
  },
  {
    key: ENERGY_FAT,
    unit: 'g',
  },
  {
    key: 'LRA_NUTRITIONS_SATURATED_FA',
    unit: 'g',
  },
  {
    key: 'LRA_NUTRTIONS_CARBOHYDRATES',
    unit: 'g',
  },
  {
    key: 'LRA_NUTRTIONS_SUGARS',
    unit: 'g',
  },
  {
    key: 'LRA_NUTRTIONS_PROTEINE',
    unit: 'g',
  },
  {
    key: 'LRA_NUTRITIONS_SALT',
    unit: 'g',
  },
];
