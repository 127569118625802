import React from 'react';

export interface ICollectionDetailContext {
  canUserEditCollection: boolean;
  collectionImageRecipeIri: string | null;
  imageRecipeCount: number;
  isLoading: boolean;
  updateCollectionImage: (recipeIri: string) => void;
}

export const CollectionDetailContext =
  React.createContext<ICollectionDetailContext>({
    canUserEditCollection: false,
    collectionImageRecipeIri: null,
    imageRecipeCount: 0,
    isLoading: false,
    updateCollectionImage: () => ({}),
  });
