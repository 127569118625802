import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface IProps {
  onGoBackClick?: () => void;
  sticky?: boolean;
}

export const GoBack: FunctionComponent<IProps> = ({ onGoBackClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    if (onGoBackClick) {
      onGoBackClick();

      return;
    }

    navigate(-1);
  }, [navigate, onGoBackClick]);

  return (
    <div>
      <Button
        type="link"
        onClick={onClick}
        icon={<LeftOutlined css={{ fontSize: '0.85em' }} />}
        css={{ paddingInline: 0 }}
      >
        {t('common.goBack')}
      </Button>
    </div>
  );
};
