import React, { FunctionComponent, PropsWithChildren } from 'react';
import { CSSObject } from '@emotion/styled';
import { getEllipsisCss } from '../lib/css';

interface ITruncate {
  lines?: number;
  styles?: CSSObject;
}

export const Truncate: FunctionComponent<PropsWithChildren<ITruncate>> = ({
  children,
  styles = {},
  lines = 1,
}) => {
  const css = { ...getEllipsisCss(lines), ...styles };

  return (
    <div css={css} {...(typeof children === 'string' && { title: children })}>
      {children}
    </div>
  );
};
