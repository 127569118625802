import React, { FunctionComponent, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { faBookmark as faBookmarkInactive } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkActive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { CollectionDetailContext } from '../../../context/CollectionDetailContext';
import { primaryColor } from '../../../theme/variables';

const SelectImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0.8rem;
`;

const BookmarkButton = styled(Button)`
  padding: 0;

  &.ant-btn-background-ghost.ant-btn-link {
    color: ${primaryColor};

    &[disabled] {
      border-color: transparent;
      cursor: default;

      &:hover {
        color: ${primaryColor};
      }
    }
  }
`;

interface IProps {
  hasImage: boolean;
  recipeIri: string;
}

export const SelectImageCollectionButton: FunctionComponent<IProps> = ({
  hasImage,
  recipeIri,
}) => {
  const {
    canUserEditCollection,
    collectionImageRecipeIri,
    imageRecipeCount,
    isLoading,
    updateCollectionImage,
  } = useContext(CollectionDetailContext);

  const handleBookmarkButtonClick = useCallback(() => {
    updateCollectionImage(recipeIri);
  }, [updateCollectionImage, recipeIri]);

  if (
    !canUserEditCollection ||
    (collectionImageRecipeIri == null && !isLoading) ||
    !hasImage
  ) {
    return null;
  }

  return (
    <SelectImageWrapper>
      <BookmarkButton
        type="link"
        onClick={handleBookmarkButtonClick}
        disabled={imageRecipeCount === 1 || isLoading}
      >
        <FontAwesomeIcon
          icon={
            collectionImageRecipeIri === recipeIri
              ? faBookmarkActive
              : faBookmarkInactive
          }
          size="2x"
        />
      </BookmarkButton>
    </SelectImageWrapper>
  );
};
