import React from 'react';
import { useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { Alert } from 'antd';
import { TFunction } from 'i18next';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import {
  GET_CATEGORIES,
  ICategory,
  IResponseSingle,
  IVariablesSingle,
} from '../../gql/get-category';
import { BigGreyHeading } from '../Recipes/pages/RecipeDetail/components/styles';
import { CategoryDetail } from './pages/category-detail';
import { CategoryGrid } from './components/CategoryGrid';
import { NoItemsGraphQL } from '../../components/NoItemsGraphQL';
import { usePageTitle } from '../../hooks/usePageTitle';

const BreadcrumbWrapper = styled('div')`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

const getBreadcrumbList = (category: ICategory, t: TFunction) => {
  const parents = [];
  let currentParent: ICategory | null = category;

  while (currentParent != null) {
    parents.push({
      href: `/categories/${currentParent._id}`,
      text: currentParent.name,
    });
    currentParent = currentParent.parent;
  }

  if (parents.length) {
    parents.push({
      href: '/categories',
      text: t('common.categories'),
    });
  }

  return parents.reverse();
};

const Categories = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  const { error, loading, data } = useQuery<IResponseSingle, IVariablesSingle>(
    GET_CATEGORIES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: `/api/categories/${id}`,
        orderBy: { name: 'ASC' },
      },
    },
  );

  const category = data?.response?.name;

  usePageTitle(
    t('pageTitles.category', { category: category || '' }),
    !!category,
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Error"
        description={error.message}
      />
    );
  }

  if (!data?.response) {
    return <NoItemsGraphQL />;
  }

  const { children, name } = data.response;

  return (
    <>
      <BreadcrumbWrapper>
        <Breadcrumbs items={getBreadcrumbList(data.response, t)} />
      </BreadcrumbWrapper>
      {
        // Either Show Sub-Categories or Recipes
        children.totalCount ? (
          <>
            <BigGreyHeading>{name}</BigGreyHeading>
            <CategoryGrid categories={children.items.map((c) => c.node)} />
          </>
        ) : (
          <CategoryDetail id={id!} />
        )
      }
    </>
  );
};

export default Categories;
