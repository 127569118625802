import React, { FunctionComponent, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Layout, notification } from 'antd';
import { useApolloClient, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_USER } from '../gql/get-user';
import { handleExpiredToken } from '../lib/handleExpiredToken';
import { containerStyle } from '../lib/styles';
import { AnonymousUserNotice } from './AnonymousUserNotice';
import { Footer } from './Footer';
import { Header } from './Header';
import { NavigationBottomBar } from './Navigation/NavigationBottomBar';

interface IProps {
  children: React.ReactNode;
  center?: boolean;
  additionalContentStyle?: string;
  withoutAnonymousUserNotice?: boolean;
}

const contentPaddingTop = '24px';
const contentStyle = css`
  padding-bottom: ${contentPaddingTop};
  padding-top: ${contentPaddingTop};
`;

const centerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledLayout = styled(Layout)`
  min-height: 100%;
  height: 100%;
`;

const ScrollableDiv = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`;

const StyledLayoutContent = styled(Layout.Content)`
  ${containerStyle};
  ${contentStyle};
`;

const InnerStyledLayoutContent = styled.div<IProps>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  ${(props) => props.center && centerStyle};
  ${(props) => props.additionalContentStyle};
`;

const checkExpiration = (timestamp: number) => {
  const expiredDate = new Date(timestamp * 1000);
  const currentDate = new Date();

  const diff = expiredDate.getTime() - currentDate.getTime();

  return diff <= 0;
};

export const DefaultLayout: FunctionComponent<IProps> = ({
  children,
  center,
  additionalContentStyle,
  withoutAnonymousUserNotice,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  const loggedInUser = !loading && !error && data && data.loggedInUser;

  useEffect(() => {
    if (loggedInUser && checkExpiration(loggedInUser.cookieExpires)) {
      handleExpiredToken(client, true).then(() => {
        window.location.href = '/';
      });
    }
  }, [client, loggedInUser]);

  useEffect(() => {
    window.onload = async () => {
      const reload = window.sessionStorage.getItem('reload');

      if (reload) {
        window.sessionStorage.removeItem('reload');

        notification.warning({
          description: t('common.error.401.description'),
          duration: 8,
          message: t('common.error.401.message'),
        });
      }
    };
  }, [t]);

  return (
    <StyledLayout>
      <Header loggedInUser={loggedInUser} />

      <ScrollableDiv>
        <StyledLayoutContent id="main">
          <InnerStyledLayoutContent
            center={center}
            additionalContentStyle={additionalContentStyle}
          >
            {!loading &&
              !error &&
              !loggedInUser &&
              !withoutAnonymousUserNotice && <AnonymousUserNotice />}
            {children}
          </InnerStyledLayoutContent>
        </StyledLayoutContent>
        <Footer />
      </ScrollableDiv>

      <NavigationBottomBar />
    </StyledLayout>
  );
};
