import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';
import {
  defaultGutterPixelSize,
  disabledMenuItemStyle,
} from '../../../lib/styles';
import { textColor } from '../../../theme/variables';

interface IProps {
  label: string;
  icon: React.ReactNode;
  linkTo: string;
}

const LinkLabel = styled('span')`
  font-size: 0.9em;
`;

const DisabledNavLink = styled(NavLink)`
  color: ${textColor};
  display: block;
  width: 100%;
  text-align: center;
  cursor: not-allowed;
  ${disabledMenuItemStyle};
  padding-block: ${defaultGutterPixelSize / 2}px;
`;

const onClick: MouseEventHandler = (e) => {
  e.preventDefault();
};

export const ButtonLinkColDisabled: React.FunctionComponent<IProps> = ({
  label,
  icon,
  linkTo,
}) => (
  <DisabledNavLink to={linkTo} onClick={onClick}>
    <div>{icon}</div>
    <div>
      <LinkLabel>{label}</LinkLabel>
    </div>
  </DisabledNavLink>
);
