import React, { FunctionComponent, useCallback, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { IRecipe } from '../../../../../gql/get-recipe';
import { apiUrls } from '../../../../../lib/apiUrls';
import { handleError } from '../../../../../lib/handleError';

const { confirm } = Modal;

interface IProps {
  loading: boolean;
  recipe: IRecipe;
  onAnonVisibilityChanged: () => Promise<unknown>;
}

export const RecipeAnonVisibilityAction: FunctionComponent<IProps> = ({
  loading,
  recipe,
  onAnonVisibilityChanged,
}) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(recipe.isVisibleToAnon === true);

  const changeRecipeAnonVisibility = useCallback(async () => {
    try {
      await fetch(apiUrls.changeRecipeAnonVisibility(recipe._id), {
        body: JSON.stringify({ isVisibleToAnon: !isVisible }),
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
      });

      setIsVisible((prevState) => !prevState);
      await onAnonVisibilityChanged();
    } catch (e) {
      handleError(e);
    }
  }, [isVisible, onAnonVisibilityChanged, recipe]);

  const handleAnonVisibilityToggle = useCallback(async () => {
    confirm({
      cancelText: t('common.no'),
      content: isVisible
        ? t('recipe.unpublishAnonConfirmDescription')
        : t('recipe.publishAnonConfirmDescription'),
      okText: t('common.yes'),
      onOk: changeRecipeAnonVisibility,
      title: isVisible
        ? t('recipe.unpublishConfirmTitle')
        : t('recipe.publishConfirmTitle'),
    });
  }, [changeRecipeAnonVisibility, isVisible, t]);

  return (
    <Switch
      checked={isVisible}
      disabled={loading}
      onChange={handleAnonVisibilityToggle}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
    />
  );
};
