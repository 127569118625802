import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

function ArrayPrototypeAt() {
  if (![].at) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.at = function at(pos) {
      return this.slice(pos, pos + 1)[0];
    };
  }
}

ArrayPrototypeAt();

export {};
