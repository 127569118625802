import React, { FunctionComponent } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const CreateRecipeButton: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Link to="/recipes/create">
      <Button icon={<PlusCircleOutlined />}>
        {t('collections.addNewRecipe')}
      </Button>
    </Link>
  );
};
