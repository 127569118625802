import React, { FunctionComponent } from 'react';
import { Button, Form, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface IUpdateActions {
  isUpdating: boolean;
  resetAllFields: VoidFunction;
}
export const UpdateActions: FunctionComponent<IUpdateActions> = ({
  isUpdating,
  resetAllFields,
}) => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  return (
    <Space>
      <Button
        type="primary"
        shape="circle"
        icon={<CheckOutlined aria-label={t('common.update')} />}
        size="small"
        loading={isUpdating}
        title={t('common.update')}
        onClick={() => form.submit()}
      />
      <Button
        shape="circle"
        icon={<CloseOutlined aria-label={t('common.reset')} />}
        htmlType="reset"
        size="small"
        title={t('common.reset')}
        onClick={resetAllFields}
      />
    </Space>
  );
};
