import { gql } from '@apollo/client';

export const CREATE_SHOPPING_CART_ITEM = gql`
  mutation createShoppingCartItem($input: createShoppingCartItemInput!) {
    response: createShoppingCartItem(input: $input) {
      shoppingCartItem {
        id
        containerSize
        amount
        extraAmount
        quantity
      }
    }
  }
`;
