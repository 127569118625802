import { gql } from '@apollo/client';

export interface IContext {
  collection: {
    id: string;
  };
}

export interface IVariables {
  input: {
    id: string;
  };
}

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($input: deleteCollectionInput!) {
    deleteCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
