import { CSSObject } from '@emotion/styled';

export const getWordbreakCss = (): CSSObject => ({
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  hyphens: 'auto',
});

export const getEllipsisCss = (lines = 1): CSSObject =>
  lines <= 1
    ? {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
    : {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        /* autoprefixer: off */
        display: '-webkit-box', // keep - essential for the clamping
        WebkitBoxOrient: 'vertical', // keep - essential for the clamping
        WebkitLineClamp: lines, // keep - essential for the clamping
        /* autoprefixer: on */
      };
