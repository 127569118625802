import { gql } from '@apollo/client';

export interface IContext {
  createCollection: {
    collection: {
      id: string;
    };
  };
}

export interface IVariables {
  input: {
    name: string;
    isPublic?: boolean;
    isPublished?: boolean | null;
    isVisibleToAnon?: boolean | null;
  };
}

export const CREATE_COLLECTION = gql`
  mutation createCollection($input: createCollectionInput!) {
    createCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
