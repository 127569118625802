import React, { FunctionComponent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Truncate } from '../Truncate';
import { ICollection } from '../../gql/get-collections';
import { apiUrls } from '../../lib/apiUrls';
import { GET_USER, IResponse, IUser } from '../../gql/get-user';
import { roles } from '../../lib/userRoles';
import { RECIPES } from '../../pages/Recipes/pages/RecipeDetail/components/UploadImageAction';
import { CoverImage } from '../CoverImage';
import { FlexCard, FlexCardMeta } from '../FlexCard';
import { SwipeableLink } from '../SwipeableLink';
import { PublishAction } from './components/PublishAction';

type IRequiredItemProps = { targetUrl: string } & ICollection;

const hasUserRole = (user: IUser, role: string) =>
  user.userRoles.findIndex((r) => r.role === role) !== -1;

export interface ICollectionCardProps {
  item: IRequiredItemProps;
  ownedByUser: boolean;
  afterPublishToggle: () => Promise<void>;
  inCarousel?: boolean;
}

export const CollectionCard: FunctionComponent<ICollectionCardProps> = ({
  afterPublishToggle,
  item,
  inCarousel,
  ownedByUser,
}) => {
  const { t } = useTranslation();
  const { name, targetUrl, collectionImageRecipe, recipes } = item;
  const { data, loading } = useQuery<IResponse>(GET_USER);

  const imageUrl = useMemo(
    () =>
      collectionImageRecipe != null &&
      apiUrls.image.url(RECIPES, collectionImageRecipe._id, {
        thumb: true,
        imageId: recipes.items.find(
          ({ node }) => node._id === collectionImageRecipe._id,
        )?.node.imageId,
      }),
    [collectionImageRecipe, recipes.items],
  );

  const CollectionActionsForUser = useMemo(() => {
    if (
      ownedByUser ||
      !data ||
      !data.loggedInUser ||
      !hasUserRole(data.loggedInUser, roles.collectionEditor)
    ) {
      return [];
    }

    const { _id, isPublished, isVisibleToAnon } = item;

    return [
      <PublishAction
        key="publish"
        context="global"
        isPublished={!!isPublished}
        collectionId={_id}
        onPublishedCollection={afterPublishToggle}
        confirmPublishMessage="collections.publishConfirmDescription"
        confirmUnpublishMessage="collections.unpublishConfirmDescription"
        tooltipTitle="collections.publishGlobalTooltipTitle"
        tooltipContent="collections.publishGlobalTooltipContent"
      />,
      <PublishAction
        key="publishAnon"
        context="anon"
        isPublished={!!isVisibleToAnon}
        collectionId={_id}
        onPublishedCollection={afterPublishToggle}
        confirmPublishMessage="collections.publishAnonConfirmDescription"
        confirmUnpublishMessage="collections.unpublishAnonConfirmDescription"
        tooltipTitle="collections.publishAnonTooltipTitle"
        tooltipContent="collections.publishAnonTooltipContent"
      />,
    ];
  }, [afterPublishToggle, data, item, ownedByUser]);

  return (
    <SwipeableLink to={targetUrl}>
      <FlexCard
        actions={CollectionActionsForUser}
        cover={<CoverImage {...(imageUrl ? { imageUrl } : { thumb: true })} />}
        loading={loading}
      >
        <FlexCardMeta
          title={<Truncate lines={inCarousel ? 1 : 2}>{name}</Truncate>}
          description={t('search.recipe', {
            count: item.recipes.totalCount || 0,
          })}
        />
      </FlexCard>
    </SwipeableLink>
  );
};
