import React, { FunctionComponent, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { IRecipe } from '../../../../../../gql/get-recipe';

interface IEditButton {
  recipe: IRecipe;
}
export const EditButton: FunctionComponent<IEditButton> = ({ recipe }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditClick = useCallback(() => {
    ReactGA.gtag('event', 'Rezept bearbeiten', {
      event_category: 'Rezeptbearbeitung',
      recipe_name: recipe.title, // dimension1
    });

    if (recipe.user?._id || recipe.isSAP) {
      // Own recipe: Edit
      navigate('edit');
    } else {
      // RAPS recipe: Copy
      navigate('copy');
    }
  }, [navigate, recipe.isSAP, recipe.title, recipe.user?._id]);

  return (
    <Button onClick={handleEditClick} icon={<EditOutlined />}>
      {t('recipe.editButton')}
    </Button>
  );
};
