import { useSearchParams } from 'react-router-dom';
import { PaginationProps } from 'antd';
import { useCallback } from 'react';
import { defaultSearchParam } from '../utils/defaultSearchParam';
import { makeCursorFromPage } from '../../../lib/pagination';
import { expandSearch } from '../utils';

export const defaultParams = {
  pageParam: 'page',
  pageSizeParam: 'itemsPerPage',
};

export const defaultPageSize = 12;

type RapsPagination = {
  pageParam?: string;
  pageSizeParam?: string;
  pageSize?: number;
};

export type IPaginationProps = Required<
  Pick<PaginationProps, 'current' | 'onChange' | 'hideOnSinglePage'>
> & { q?: string; itemsPerPage: number; cursor?: string };

export const useRapsPagination = (
  options: RapsPagination = {},
): IPaginationProps => {
  const {
    pageParam = defaultParams.pageParam,
    pageSizeParam = defaultParams.pageSizeParam,
    pageSize: pageSizeOverwrite,
  } = options;
  const [searchParams, setSearchParams] = useSearchParams();

  const current = parseInt(searchParams.get(pageParam) || '', 10) || 1;
  const pageSize =
    pageSizeOverwrite ||
    parseInt(searchParams.get(pageSizeParam) || '', 10) ||
    defaultPageSize;

  // Reflects page change in the URL by pushing corresponding query params
  const onChange = useCallback(
    (newPage: number, newPageSize: number) => {
      searchParams.set(pageParam, newPage.toString());

      if (pageSize === defaultPageSize || pageSize === pageSizeOverwrite) {
        searchParams.delete(pageSizeParam);
      } else {
        searchParams.set(pageSizeParam, newPageSize.toString());
      }

      setSearchParams(searchParams);
    },
    [
      pageParam,
      pageSize,
      pageSizeOverwrite,
      pageSizeParam,
      searchParams,
      setSearchParams,
    ],
  );

  return {
    cursor: makeCursorFromPage(pageSize, current),
    current,
    hideOnSinglePage: true,
    itemsPerPage: pageSize,
    onChange,
    [defaultSearchParam]: expandSearch(
      searchParams.get(defaultSearchParam) || undefined,
    ),
  };
};
