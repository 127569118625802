import { gql } from '@apollo/client';
import { IRecipe } from './get-recipe';

export interface IDuplicateRecipeMutationVariables {
  input: {
    recipeId: number;
    userId: number;
  };
}

export interface IDuplicateRecipeMutationResponse {
  duplicateRecipe: {
    _id: IRecipe['_id'];
    user: IRecipe['user'];
  };
}

export const DUPLICATE_RECIPE_MUTATION = gql`
  mutation duplicateRecipe($input: duplicateRecipeInput!) {
    duplicateRecipe(input: $input) {
      recipe {
        _id
        user {
          _id
        }
      }
    }
  }
`;
