import { IRecipe } from '../../../../../gql/get-recipe';

export interface IIngredient {
  number?: string;
  quantity: number;
  isQuid?: boolean;
  name: string;
}

const waterNumbers = ['5000175', '5000268', '5000272', '5000349'];

export const filterWaterIngredients = (
  ingredients: IIngredient[],
  recipe: IRecipe,
) => {
  /**
   * RVV-390:
   * Only filter water ingredients for
   * meat-recipes.
   */
  if (
    (recipe.isMeatProduct !== null && !recipe.isMeatProduct) ||
    (recipe.categories.totalCount > 0 &&
      !recipe.categories.items.map((c) => c.node._id.toString()).includes('01'))
  ) {
    return ingredients;
  }

  return ingredients
    .map((ingredient) => {
      let { quantity } = ingredient;

      // Take out Water-Ingredients if weight loss is too big
      if (ingredient.number && waterNumbers.includes(ingredient.number)) {
        quantity -= recipe.weightLoss;

        return {
          ...ingredient,
          quantity,
        };
      }

      return ingredient;
    })
    .filter(
      (ingredient) =>
        !(
          ingredient.number &&
          waterNumbers.includes(ingredient.number) &&
          ingredient.quantity < 5
        ),
    );
};

export const formatIngredient = ({ isQuid, quantity, name }: IIngredient) =>
  `${isQuid ? `${quantity.toFixed(1).replace('.', ',')}% ` : ''}${name}`;
