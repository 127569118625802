import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Col, Row, theme } from 'antd';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthGuard } from '../../../../components/AuthGuard';
import { BackgroundImage } from '../../../../components/BackgroundImage';
import { CardColumn } from '../../../../components/CardColumn';
import { DefaultLayout } from '../../../../components/DefaultLayout';
import { GoBack } from '../../../../components/GoBack';
import { NoImageIcon } from '../../../../components/NoImageIcon';
import {
  GET_NEWS_ITEM,
  INewsItemResponse,
  ISearchVariables,
} from '../../../../gql/get-news-item';
import { apiUrls } from '../../../../lib/apiUrls';
import { defaultBottomMargin } from '../../../../lib/styles';
import { roles } from '../../../../lib/userRoles';
import {
  FloatingButton,
  RelativeContainer,
} from '../../../Recipes/pages/RecipeDetail/components/styles';
import {
  NEWS_ITEMS,
  UploadImageAction,
} from '../../../Recipes/pages/RecipeDetail/components/UploadImageAction';
import { NewsCrudBar } from './components/NewsCrudBar';
import { NewsSideBar } from './components/NewsSideBar';
import { ErrorGraphQL } from '../../../../components/ErrorGraphQL';
import { NoItemsGraphQL } from '../../../../components/NoItemsGraphQL';
import { usePageTitle } from '../../../../hooks/usePageTitle';

const NoImageWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 400px;
  text-align: center;
  width: 100%;
`;

const NewsDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { token } = theme.useToken();
  const [isImageFromCache, setIsImageFromCache] = useState(true);

  const { loading, error, data, refetch } = useQuery<
    INewsItemResponse,
    ISearchVariables
  >(GET_NEWS_ITEM, {
    fetchPolicy: 'cache-and-network',
    variables: { id: `/api/news_items/${id}` },
  });

  const newsTitle = data?.response?.title;

  usePageTitle(
    t('pageTitles.newsDetail', { title: newsTitle ?? '' }),
    !!newsTitle,
  );

  const handleOnAfterActionPreformed = useCallback(async () => {
    setIsImageFromCache(false);
    await refetch();
  }, [refetch, setIsImageFromCache]);

  if (error) {
    return (
      <DefaultLayout>
        <ErrorGraphQL />
      </DefaultLayout>
    );
  }

  if (loading && !data) {
    return (
      <DefaultLayout>
        <CardColumn>
          <Card loading />
        </CardColumn>
      </DefaultLayout>
    );
  }

  if (!data?.response) {
    return (
      <DefaultLayout>
        <NoItemsGraphQL />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <GoBack />

      <NewsCrudBar chosenItem={data.response} path="../create" />
      <Row gutter={32}>
        <Col xl={18} md={16} xs={24}>
          <RelativeContainer
            css={{
              marginBottom: 24,
              borderRadius: token.borderRadius,
              overflow: 'hidden',
            }}
          >
            {data.response.hasImage ? (
              <BackgroundImage
                className={defaultBottomMargin.styles}
                src={apiUrls.image.url(NEWS_ITEMS, data.response._id, {
                  thumb: false,
                  isImageFromCache,
                  imageId: data.response.imageId,
                })}
                height={400}
              />
            ) : (
              <NoImageWrapper>
                <NoImageIcon />
              </NoImageWrapper>
            )}
            <AuthGuard roles={[roles.newsAuthor]}>
              <FloatingButton>
                <UploadImageAction
                  newsItem={data.response}
                  roles={[roles.newsAuthor]}
                  onImageChanged={handleOnAfterActionPreformed}
                />
              </FloatingButton>
            </AuthGuard>
          </RelativeContainer>

          <h2>{data.response.title}</h2>
          <p>{data.response.subtitle}</p>

          <div dangerouslySetInnerHTML={{ __html: data.response.content }} />
        </Col>

        <Col xl={6} md={8} xs={24}>
          <NewsSideBar newsItem={data.response} />
        </Col>
      </Row>
    </DefaultLayout>
  );
};

export default NewsDetailPage;
