import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import {
  DUPLICATE_COLLECTION_MUTATION,
  IDuplicateCollectionMutationResponse,
  IDuplicateCollectionMutationVariables,
} from '../../gql/duplicate-collection-mutation';
import { TransferModal } from '../TransferModal';

interface ITransferMyCollectionModalProps {
  collectionId: number;
  collectionName: string;
}

export const TransferMyCollectionModal: FunctionComponent<
  ITransferMyCollectionModalProps
> = ({ collectionId, collectionName }) => {
  const { t } = useTranslation();

  const client = useApolloClient();

  const mutationHandler = useCallback(
    async (input: { ownerId: number; userId: number }) =>
      client.mutate<
        IDuplicateCollectionMutationResponse,
        IDuplicateCollectionMutationVariables
      >({
        mutation: DUPLICATE_COLLECTION_MUTATION,
        variables: {
          input: {
            userId: input.userId,
            ownerId: input.ownerId,
            collectionId,
          },
        },
      }),
    [client, collectionId],
  );

  return (
    <TransferModal
      modalTitle={t('modal.transferMyCollection.title', {
        name: collectionName,
      })}
      modalTrigger={t('modal.transferMyCollection.triggerButton')}
      successMessage={t('modal.transferMyCollection.success')}
      errorMessage={t('modal.transferMyCollection.error')}
      mutationHandler={mutationHandler}
    />
  );
};
