const baseUrl = '/api';

interface IImageUrlOptions {
  thumb?: boolean;
  isImageFromCache?: boolean;
  imageId?: string;
}

function imageUrl(entityName: string, id: number, opts?: IImageUrlOptions) {
  const {
    thumb = false,
    isImageFromCache = true,
    imageId,
  } = opts || {
    thumb: false,
    isImageFromCache: true,
    imageId: undefined,
  };

  const params = {
    ...(thumb && { thumb: 'true' }),
    ...(imageId && { id: imageId }),
    ...(!isImageFromCache && { t: new Date().getTime().toString() }),
  };
  const searchParams = new URLSearchParams(params);

  return `${baseUrl}/${entityName}/${id}/image?${searchParams.toString()}`;
}

export const apiUrls = {
  changeRecipeAnonVisibility: (id: number) =>
    `${baseUrl}/recipes/${id}/anon-visibility`,
  checkCollectionExportReady: (filename: string) =>
    `${baseUrl}/collections/export/check/${filename}`,
  contactForm: `${baseUrl}/contact-form`,
  emailRecipe: `${baseUrl}/recipes/export/email`,
  exportCollection: (filename: string) =>
    `${baseUrl}/collections/export/pdf/${filename}`,
  exportRecipe: (filename: string) =>
    `${baseUrl}/recipes/export/pdf/${filename}`,
  forgotPassword: `${baseUrl}/forgot-password`,
  graphql: `${baseUrl}/graphql`,
  image: {
    update: (entityName: string, id: number) =>
      `${baseUrl}/${entityName}/${id}/update/image`,
    url: imageUrl,
  },
  login: `${baseUrl}/login`,
  logout: `${baseUrl}/logout`,
  publishCollection: (id: number) => `${baseUrl}/collections/${id}/publish`,
  publishRecipe: (id: number) => `${baseUrl}/recipes/${id}/publish`,
  registerError: `${baseUrl}/register-error`,
  resetPassword: `${baseUrl}/reset-password`,
  users: {
    activate: (token: string) => `${baseUrl}/users/activate/${token}`,
  },
};
