import React from 'react';
import styled from '@emotion/styled';
import { topBarBreakPoint } from '../../../theme/customVariables';
import { layoutBodyBackground } from '../../../theme/variables';
import { UserRequiredInfo } from '../../UserRequiredInfo';

import { slightShadow } from '../../../lib/styles';
import { AuthGuard } from '../../AuthGuard';
import { navigationBarData } from '../navigationBarData';
import { ButtonLinkCol } from './ButtonLinkCol';
import { ButtonLinkColDisabled } from './ButtonLinkColDisabled';

const NavBar = styled('nav')`
  position: sticky;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: ${layoutBodyBackground};
  z-index: 500;
  ${slightShadow};
  @media only screen and (min-width: ${topBarBreakPoint}) {
    display: none;
  }
`;

export const NavigationBottomBar = () => (
  <AuthGuard>
    {(isGranted) => (
      <NavBar
        id="mobile-nav-bar"
        style={{
          padding: `0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)`, // iOS 11.2+ - ensure safe areas for OS elements
        }}
      >
        {navigationBarData.map((pageLink) =>
          isGranted || !pageLink.disabledWithoutUser ? (
            <ButtonLinkCol key={pageLink.label} {...pageLink} />
          ) : (
            <UserRequiredInfo
              key={pageLink.label}
              containerStyle={{ width: '100%' }}
            >
              <ButtonLinkColDisabled key={pageLink.label} {...pageLink} />
            </UserRequiredInfo>
          ),
        )}
      </NavBar>
    )}
  </AuthGuard>
);
