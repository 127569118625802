import React, { FunctionComponent, useCallback, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  IContext as IUpdateCollectionContext,
  IVariables as IUpdateCollectionVariables,
  UPDATE_COLLECTION,
} from '../../../../../gql/update-collection';
import { handleError } from '../../../../../lib/handleError';

type IProps = {
  collectionId: string;
  title: string;
  onTitleChange?: () => void;
};

export const EditButton: FunctionComponent<IProps> = ({
  collectionId,
  title,
  onTitleChange,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [collectionNameInput, setCollectionNameInput] = useState(title);

  const handleEditCollection = useCallback(async () => {
    setIsOkButtonLoading(true);

    try {
      const response = await client.mutate<
        IUpdateCollectionContext,
        IUpdateCollectionVariables
      >({
        mutation: UPDATE_COLLECTION,
        variables: {
          input: {
            id: `/api/collections/${collectionId}`,
            name: collectionNameInput,
          },
        },
      });

      if (!response.errors && onTitleChange) {
        onTitleChange();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setIsEditModalVisible(false);
      setIsOkButtonLoading(false);
    }
  }, [client, collectionId, collectionNameInput, onTitleChange]);

  const onClick = useCallback(() => {
    setIsEditModalVisible(true);
  }, []);

  const onChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setCollectionNameInput(e.currentTarget.value);
  }, []);

  const onCancel = useCallback(() => {
    setIsEditModalVisible(false);
  }, []);

  return (
    <>
      <Button
        onClick={onClick}
        icon={<EditOutlined />}
        title={t('common.edit')}
      >
        {t('common.edit')}
      </Button>
      <Modal
        maskClosable={false}
        title={t('collections.editTitle')}
        open={isEditModalVisible}
        onOk={handleEditCollection}
        okButtonProps={{ loading: isOkButtonLoading }}
        onCancel={onCancel}
      >
        <Input onChange={onChange} value={collectionNameInput} />
      </Modal>
    </>
  );
};
