import React, { FunctionComponent, ReactElement } from 'react';
import { PaginatedResultList } from './PaginatedResultList';
import { CardPlaceholder } from '../../../components/Placeholders';

interface IProps {
  actionButton?: ReactElement;
  disablePagination?: boolean;
  totalCount?: number;
  items?: React.ReactElement[];
}

export const ResultList: FunctionComponent<IProps> = ({
  actionButton,
  disablePagination,
  items,
  totalCount,
}) => (
  <PaginatedResultList
    totalCount={totalCount ?? 0}
    itemLength={items?.length ?? 0}
    actionButton={actionButton}
    disablePagination={disablePagination}
  >
    {items || (
      <>
        <CardPlaceholder />
        <CardPlaceholder />
      </>
    )}
  </PaginatedResultList>
);
