import React, { FunctionComponent, useCallback } from 'react';
import styled from '@emotion/styled';
import { Col, Row, Space } from 'antd';
import { useApolloClient, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthGuard } from '../../../../../components/AuthGuard';
import { DeleteButton } from '../../../../../components/DeleteButton';
import { BreakableTitle } from '../../../../../components/BreakableTitle';
import {
  DELETE_COLLECTION,
  IContext as IDeleteCollectionContext,
  IVariables as IDeleteCollectionVariables,
} from '../../../../../gql/delete-collection';
import { handleError } from '../../../../../lib/handleError';
import {
  defaultActionButtonMargin,
  defaultGutterPixelSize,
} from '../../../../../lib/styles';
import { roles } from '../../../../../lib/userRoles';
import { Routes } from '../../../utils/RecipeRoutes';
import { EditButton } from './EditButton';
import { PrintButton } from './PrintButton';
import { ICollection } from '../../../../../gql/get-collection-recipes';
import { usePageTitle } from '../../../../../hooks/usePageTitle';
import { TransferMyCollectionModal } from '../../../../../components/TransferMyCollectionModal';
import { GET_USER } from '../../../../../gql/get-user';

type IProps = {
  collection: ICollection;
  collectionId: string;
  collectionCategory: string;
  isPrivate: boolean;
  itemSize: number;
  onTitleChange?: () => void;
};

const MarginBottomCol = styled(Col)`
  ${defaultActionButtonMargin};
`;

export const CollectionTitle: FunctionComponent<IProps> = ({
  collection,
  collectionId,
  collectionCategory,
  isPrivate,
  onTitleChange,
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useQuery(GET_USER);

  const { name: title, collectionImageRecipe } = collection;

  usePageTitle(
    t('pageTitles.collection', { collection: title ?? '' }),
    !!title,
  );

  const handleConfirmDelete = useCallback(async () => {
    try {
      const response = await client.mutate<
        IDeleteCollectionContext,
        IDeleteCollectionVariables
      >({
        mutation: DELETE_COLLECTION,
        variables: {
          input: {
            id: `/api/collections/${collectionId}`,
          },
        },
      });

      if (!response.errors) {
        let pathname = `/collections/${Routes.RAPSCOLLECTIONS}`;

        if (isPrivate) {
          pathname = `/collections/${Routes.PRIVATECOLLECTIONS}`;
        }
        navigate(pathname);
      }
    } catch (e) {
      handleError(e);
    }
  }, [client, collectionId, isPrivate, navigate]);

  return (
    <Row gutter={defaultGutterPixelSize} justify="space-between">
      <Col>
        <BreakableTitle>
          {t('collections.detailHeading')}{' '}
          {title ? <strong>{title}</strong> : <span>&hellip;</span>}
        </BreakableTitle>
      </Col>

      <MarginBottomCol>
        <Space wrap>
          <AuthGuard roles={[roles.collectionEditor]}>
            {(isGranted) =>
              isGranted || isPrivate ? (
                <>
                  <EditButton
                    collectionId={collectionId}
                    title={title}
                    onTitleChange={onTitleChange}
                  />
                  <DeleteButton
                    onConfirmDelete={handleConfirmDelete}
                    modalTitle={t('collections.deleteConfirmTitle')}
                  />
                </>
              ) : null
            }
          </AuthGuard>

          <AuthGuard roles={[roles.transferAgent]}>
            {collection?.user?._id === data?.loggedInUser?.id && (
              <TransferMyCollectionModal
                collectionId={collection._id}
                collectionName={title}
              />
            )}
          </AuthGuard>

          <PrintButton
            collectionId={collectionId}
            title={title}
            collectionCategory={t(collectionCategory)}
            collectionImageRecipe={collectionImageRecipe}
          />
        </Space>
      </MarginBottomCol>
    </Row>
  );
};
