import React from 'react';
import { useTranslation } from 'react-i18next';

import { MessagePage } from './MessagePage';

export const ErrorUnknownPage = () => {
  const { t } = useTranslation();

  return (
    <MessagePage
      message={t('common.error.unknown.message')}
      description={t('common.error.unknown.description')}
    />
  );
};
