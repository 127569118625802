import React, { FunctionComponent, useCallback } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Card, Col, Row, Space } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthGuard } from '../../../../../components/AuthGuard';
import { DeleteButton } from '../../../../../components/DeleteButton';
import { IVariables } from '../../../../../gql/delete-collection';
import {
  DELETE_NEWS_ITEM,
  IDeleteNewsItemResponse,
} from '../../../../../gql/delete-news-item';
import {
  GET_NEWS,
  INewsItem,
  INewsItemsResponse,
} from '../../../../../gql/get-news';
import { handleError } from '../../../../../lib/handleError';
import {
  defaultBottomMargin,
  defaultGutterPixelSize,
} from '../../../../../lib/styles';
import { roles } from '../../../../../lib/userRoles';

const NewsActionCard = styled(Card)`
  ${defaultBottomMargin};
`;

interface IProps {
  chosenItem?: INewsItem;
  path: string;
}

export const NewsCrudBar: FunctionComponent<IProps> = ({
  chosenItem,
  path,
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { id } = useParams();

  const onCreateClick = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  const onEditClick = useCallback(() => {
    navigate(path, {
      state: {
        newsItem: chosenItem,
      },
    });
  }, [chosenItem, navigate, path]);

  const onConfirmDelete = useCallback(async () => {
    try {
      const res = await client.mutate<IDeleteNewsItemResponse, IVariables>({
        mutation: DELETE_NEWS_ITEM,
        update: (cache) => {
          const cachedData = cache.readQuery<INewsItemsResponse>({
            query: GET_NEWS,
            variables: { itemsPerPage: 5, orderBy: { createdAt: 'DESC' } },
          });

          if (cachedData) {
            const items = cachedData.response.items.filter(
              (item) => item.node.id !== `/api/news_items/${id}`,
            );

            cache.writeQuery({
              data: { response: { ...cachedData.response, items } },
              query: GET_NEWS,
              variables: { itemsPerPage: 5, orderBy: { createdAt: 'DESC' } },
            });
          }
        },
        variables: {
          input: {
            id: `/api/news_items/${id}`,
          },
        },
      });

      if (!res.errors && res.data?.deleteNewsItem.newsItem.id) {
        navigate('/news');
      }
    } catch (e) {
      handleError(e);
    }
  }, [client, navigate, id]);

  return (
    <AuthGuard roles={[roles.newsAuthor]}>
      <NewsActionCard>
        <Row
          justify={{ xs: 'start', md: 'end' }}
          gutter={defaultGutterPixelSize}
        >
          <Col>
            <Space wrap>
              <Button icon={<PlusOutlined />} onClick={onCreateClick}>
                {t('recipe.addButton')}
              </Button>
              {chosenItem && (
                <Button icon={<EditOutlined />} onClick={onEditClick}>
                  {t('recipe.editButton')}
                </Button>
              )}
              {chosenItem && id && (
                <DeleteButton
                  circle={false}
                  modalTitle={t('news.deleteConfirmNews')}
                  onConfirmDelete={onConfirmDelete}
                />
              )}
            </Space>
          </Col>
        </Row>
      </NewsActionCard>
    </AuthGuard>
  );
};
