import { gql } from '@apollo/client';
import { IRecipe } from './get-recipe';
import { IResponse as IRecipeResponse } from './get-recipe-cards';
import { CORE_USER_WEIGHTS } from './fragments/user-weights';
import { CORE_QUANTITY_FIELDS } from './fragments/quantities';
import { CORE_RECIPE_FIELDS } from './fragments/recipe';

export interface ICollection {
  _id: number;
  id: string;
  name: string;
  isPublished: boolean | null;
  isVisibleToAnon: boolean | null;
  collectionImageRecipe: IRecipe | null;
  recipes: IRecipeResponse['response'];
}

export interface IResponse {
  response: {
    totalCount: number;
    items: Array<{
      cursor: string;
      node: ICollection;
    }>;
  };
}

export interface IVariables {
  cursor?: string;
  itemsPerPage: number;
  ownedByUser: boolean;
  q?: string;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
}

export const GET_COLLECTIONS = gql`
  ${CORE_RECIPE_FIELDS}
  ${CORE_USER_WEIGHTS}
  ${CORE_QUANTITY_FIELDS}
  query collections(
    $q: String
    $ownedByUser: Boolean!
    $itemsPerPage: Int!
    $orderBy: CollectionFilter_orderBy
    $cursor: String
  ) {
    response: collections(
      name: $q
      recipes_quantities_ingredient_name: $q
      exists: { user: $ownedByUser }
      first: $itemsPerPage
      after: $cursor
      orderBy: [$orderBy]
    ) {
      totalCount
      items: edges {
        cursor
        node {
          _id
          id
          name
          isPublished
          isVisibleToAnon
          collectionImageRecipe {
            _id
            id
          }
          recipes(first: 12) {
            totalCount
            items: edges {
              cursor
              node {
                ...CoreRecipeFields
                isSAP
                quantities {
                  ...CoreQuantityFields
                }
                userWeights {
                  ...CoreUserWeights
                }
              }
            }
          }
        }
      }
    }
  }
`;
