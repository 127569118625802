import React, {
  CSSProperties,
  FunctionComponent,
  PropsWithChildren,
  useState,
} from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IOpenModalStyleProps {
  cursor?: 'not-allowed' | 'pointer';
}

const openModalStyle = ({ cursor }: IOpenModalStyleProps) => {
  if (cursor && cursor === 'pointer') {
    return css`
      cursor: pointer;
      * {
        cursor: pointer;
      }
    `;
  }

  return css`
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  `;
};

const OpenModalButton = styled.span<IOpenModalStyleProps>`
  ${openModalStyle};
`;

interface IProps {
  cursor?: 'not-allowed' | 'pointer';
  message?: string;
  containerStyle?: CSSProperties;
}

export const UserRequiredInfo: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  cursor,
  message,
  containerStyle,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        open={showModal}
        closable={false}
        footer={
          <Button type="primary" onClick={() => setShowModal(false)}>
            {t('userRequiredInfo.button')}
          </Button>
        }
      >
        {message || t('userRequiredInfo.message')}
      </Modal>
      <OpenModalButton
        cursor={cursor}
        onClick={() => setShowModal(true)}
        style={containerStyle}
      >
        {children}
      </OpenModalButton>
    </>
  );
};
