import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { IQuantity, IRecipe } from '../gql/get-recipe';
import { extractItems } from '../lib/recipeCalculations';

const createCustomWeights = (recipe: IRecipe) => {
  if (recipe.userWeights != null && recipe.userWeights.items.length > 0) {
    return recipe.userWeights.items[0].node.baseWeight;
  }

  if (recipe.number === null) {
    return recipe.quantities.items.reduce(
      (acc, { node }) => acc + parseFloat(node.quantity),
      0,
    );
  }

  return 100;
};

const separateIngredientsByMode = (
  recipe: IRecipe,
  state: Pick<
    IRecipeIngredientsState,
    'relativeTo' | 'relativeToSelectValue' | 'mode'
  >,
): Pick<
  IRecipeIngredientsState,
  | 'additionalIngredients'
  | 'baseIngredients'
  | 'relativeTo'
  | 'relativeToSelectValue'
> => {
  const { mode, relativeTo, relativeToSelectValue } = state;

  const baseIngredients = extractItems(
    recipe,
    mode === 'base' ? true : undefined,
  );
  const additionalIngredients =
    mode === 'base' ? extractItems(recipe, false) : [];

  const isRelativeToAll =
    relativeToSelectValue !== 'all' &&
    !baseIngredients.find((item) => item.id === relativeToSelectValue);

  return {
    additionalIngredients,
    baseIngredients,
    relativeTo: isRelativeToAll ? 'all' : relativeTo,
    relativeToSelectValue: isRelativeToAll ? 'all' : relativeToSelectValue,
  };
};

const lazyInitWithProps =
  (recipe: IRecipe, defaultDisplayMode?: DisplayMode) =>
  (state: IRecipeIngredientsState): IRecipeIngredientsState => {
    const weight = createCustomWeights(recipe);
    const newIngredients = separateIngredientsByMode(recipe, state);

    return {
      ...state,
      ...newIngredients,
      ...(defaultDisplayMode && { mode: defaultDisplayMode }),
      baseWeight: weight,
      targetWeight: weight,
    };
  };

export type DisplayMode = 'base' | 'total';

export type RecipeTableType = 'base' | 'additional';

export interface IRecipeIngredientsState {
  additionalIngredients: IQuantity[];
  addCartItemsMutationIsPending: boolean;
  updateUserWeights: boolean;
  baseIngredients: IQuantity[];
  baseWeight?: number;
  mode: DisplayMode;
  relativeTo: IQuantity['id'] | 'all';
  relativeToSelectValue: IQuantity['id'] | 'all';
  selectedRowIds: Map<RecipeTableType, React.Key[]>;

  targetWeight?: number;
}

export enum RECIPE_ACTION_ENUMS {
  INIT = 'INIT',
  CLEAR_ROW_SELECTION = 'CLEAR_ROW_SELECTION',
  SELECT_ALL_ROWS = 'SELECT_ALL_ROWS',
  UPDATE_ROW_SELECTION = 'UPDATE_ROW_SELECTION',
  SET_MODE = 'SET_MODE',
  UPDATE_TARGET_WEIGHT = 'UPDATE_TARGET_WEIGHT',
  UPDATE_BASE_WEIGHT = 'UPDATE_BASE_WEIGHT',
  UPDATE_RELATIVE_TO_SELECT_VALUE = 'UPDATE_RELATIVE_TO_SELECT_VALUE',
  UPDATE_USER_WEIGHTS = 'UPDATE_USER_WEIGHTS',
}

export type RecipeIngredientsActions =
  | {
      type: RECIPE_ACTION_ENUMS.INIT;
      payload: { recipe: IRecipe } & Pick<
        IRecipeIngredientsState,
        | 'additionalIngredients'
        | 'baseIngredients'
        | 'mode'
        | 'relativeTo'
        | 'relativeToSelectValue'
      >;
    }
  | {
      type: RECIPE_ACTION_ENUMS.UPDATE_BASE_WEIGHT;
      payload: Pick<
        IRecipeIngredientsState,
        'baseWeight' | 'relativeTo' | 'updateUserWeights'
      >;
    }
  | {
      type:
        | RECIPE_ACTION_ENUMS.SELECT_ALL_ROWS
        | RECIPE_ACTION_ENUMS.UPDATE_ROW_SELECTION;
      payload: { cartIds: React.Key[]; tableId: RecipeTableType };
    }
  | {
      type: RECIPE_ACTION_ENUMS.SET_MODE;
      payload: IRecipeIngredientsState['mode'];
    }
  | {
      type: RECIPE_ACTION_ENUMS.UPDATE_TARGET_WEIGHT;
      payload: IRecipeIngredientsState['targetWeight'];
    }
  | {
      type: RECIPE_ACTION_ENUMS.UPDATE_RELATIVE_TO_SELECT_VALUE;
      payload: IRecipeIngredientsState['relativeToSelectValue'];
    }
  | {
      type: RECIPE_ACTION_ENUMS.UPDATE_USER_WEIGHTS;
      payload: IRecipeIngredientsState['updateUserWeights'];
    }
  | {
      type: RECIPE_ACTION_ENUMS.CLEAR_ROW_SELECTION;
    };

const reducer = (
  state: IRecipeIngredientsState,
  action: RecipeIngredientsActions,
): IRecipeIngredientsState => {
  switch (action.type) {
    case RECIPE_ACTION_ENUMS.INIT: {
      const { recipe, ...rest } = action.payload;

      return { ...state, ...separateIngredientsByMode(recipe, rest) };
    }
    case RECIPE_ACTION_ENUMS.UPDATE_BASE_WEIGHT: {
      return { ...state, ...action.payload };
    }

    case RECIPE_ACTION_ENUMS.SELECT_ALL_ROWS:
    case RECIPE_ACTION_ENUMS.UPDATE_ROW_SELECTION: {
      const { tableId, cartIds } = action.payload;

      const ids = new Map(state.selectedRowIds);

      ids.set(tableId, cartIds); // only update ids for the specific table

      return { ...state, selectedRowIds: ids };
    }

    case RECIPE_ACTION_ENUMS.CLEAR_ROW_SELECTION: {
      return {
        ...state,
        selectedRowIds: new Map([
          ['base', []],
          ['additional', []],
        ]),
      };
    }

    case RECIPE_ACTION_ENUMS.UPDATE_RELATIVE_TO_SELECT_VALUE: {
      return { ...state, relativeToSelectValue: action.payload };
    }

    case RECIPE_ACTION_ENUMS.SET_MODE: {
      return { ...state, mode: action.payload };
    }

    case RECIPE_ACTION_ENUMS.UPDATE_TARGET_WEIGHT: {
      return { ...state, targetWeight: action.payload };
    }

    case RECIPE_ACTION_ENUMS.UPDATE_USER_WEIGHTS: {
      return { ...state, updateUserWeights: action.payload };
    }

    default:
      return state;
  }
};

export type IRecipeDetailDispatch = {
  dispatch: Dispatch<RecipeIngredientsActions>;
};

const initialState: IRecipeIngredientsState = {
  addCartItemsMutationIsPending: false,
  additionalIngredients: [],
  baseIngredients: [],
  baseWeight: 100,
  mode: 'total',
  relativeTo: 'all',
  relativeToSelectValue: 'all',
  targetWeight: 100,
  updateUserWeights: false,
  selectedRowIds: new Map([
    ['base', []],
    ['additional', []],
  ]),
};

export const RecipeDetailContext = createContext<
  IRecipeIngredientsState & IRecipeDetailDispatch
>({
  ...initialState,
  dispatch: () => ({}),
});
export const useRecipeDetailContext = () => useContext(RecipeDetailContext);

interface IRecipeDetailProviderProps {
  recipe: IRecipe;
  defaultDisplayMode?: DisplayMode;
}

export const RecipeDetailProvider: FunctionComponent<
  PropsWithChildren<IRecipeDetailProviderProps>
> = ({ children, recipe, defaultDisplayMode }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    lazyInitWithProps(recipe, defaultDisplayMode),
  );

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <RecipeDetailContext.Provider value={value}>
      {children}
    </RecipeDetailContext.Provider>
  );
};
