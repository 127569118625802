import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import { useResponsive } from 'ahooks';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  GET_RECIPE_CARDS,
  IResponse,
  IVariables,
} from '../../gql/get-recipe-cards';
import { isRecipeNumberPattern } from '../../pages/Search/utils';
import { ResultList } from '../../pages/Search/components/ResultList';
import { RecipeCard } from '../RecipeCard';
import {
  IPaginationProps,
  useRapsPagination,
} from '../../pages/Search/hooks/usePagination';
import { CardColumnsPlaceholder } from '../Placeholders';
import { defaultGutterPixelSize } from '../../lib/styles';
import { ErrorGraphQL } from '../ErrorGraphQL';
import { NoItemsGraphQL } from '../NoItemsGraphQL';
import { usePageTitle } from '../../hooks/usePageTitle';

interface IRecipeList {
  isSAP?: boolean;
  ownedByUser?: boolean;
  revoked?: boolean;
  pageSize?: number;
  i18nKey?: string;
}

interface IGetQueryVariable extends IRecipeList {
  paginationProps: IPaginationProps;
}
const getQueryVariables = ({
  isSAP,
  ownedByUser,
  revoked,
  paginationProps,
}: IGetQueryVariable): IVariables => {
  const { itemsPerPage, q, cursor } = paginationProps;

  const queryVariables: IVariables = {
    cursor,
    isSAP: Boolean(isSAP),
    itemsPerPage,
    orderBy: { title: 'ASC' },
    isPublished: revoked === true ? false : undefined,
    ownedByUser: revoked !== undefined ? false : ownedByUser,
    wasPublishedInPast: revoked === false ? false : undefined,
  };

  // if the query is parsable to an int
  // use it to query the recipes
  if (q && !Number.isNaN(parseInt(q, 10))) {
    if (isRecipeNumberPattern.test(q)) {
      queryVariables.number = q;
    } else {
      queryVariables.ingredientNumber = q;
    }
  } else {
    queryVariables.q = q;
  }

  return queryVariables;
};

export const RecipeList: FunctionComponent<IRecipeList> = ({
  pageSize,
  revoked,
  isSAP = false,
  ownedByUser = false,
  i18nKey,
}) => {
  const { t } = useTranslation();
  const paginationProps = useRapsPagination({ pageSize });
  const responsive = useResponsive();

  const variables = getQueryVariables({
    isSAP,
    ownedByUser,
    revoked,
    paginationProps,
  });

  const { data, loading, error } = useQuery<IResponse, IVariables>(
    GET_RECIPE_CARDS,
    {
      fetchPolicy: 'cache-and-network',
      variables,
    },
  );

  usePageTitle(
    t(
      revoked === undefined
        ? 'pageTitles.collection'
        : 'pageTitles.managedCollection',
      { collection: t(i18nKey!) },
    ),
  );

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <Row gutter={defaultGutterPixelSize}>
        <CardColumnsPlaceholder amount={responsive.xl ? 2 : 1} />
      </Row>
    );
  }

  if (!data?.response?.items.length) {
    return <NoItemsGraphQL />;
  }

  const items = data.response.items.map(({ node }) => (
    <RecipeCard
      key={node._id}
      inCarousel={false}
      item={{
        ...node,
        isSAP: variables.isSAP,
        targetUrl: `/recipes/${node._id}`,
      }}
    />
  ));

  return (
    <ResultList
      items={items}
      disablePagination={false}
      totalCount={data.response.totalCount}
    />
  );
};
