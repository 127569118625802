import { gql } from '@apollo/client';
import { INewsItem } from './get-news';
import { CORE_NEWS_ITEM_FIELDS } from './fragments/news-item';

export interface ISearchVariables {
  id: string;
}

export interface INewsItemResponse {
  response: INewsItem | null;
}

export const GET_NEWS_ITEM = gql`
  ${CORE_NEWS_ITEM_FIELDS}
  query newsItem($id: ID!) {
    response: newsItem(id: $id) {
      ...CoreNewsItemFields
    }
  }
`;
