import { gql } from '@apollo/client';

import { IResponse as IRecipeResponse } from './get-recipe-cards';
import { CORE_RECIPE_FIELDS } from './fragments/recipe';
import { CORE_USER_WEIGHTS } from './fragments/user-weights';
import { CORE_QUANTITY_FIELDS } from './fragments/quantities';

interface ICollection {
  _id: number;
  id: string;
  name: string;
}

// Instead of HTTP 404, GraphQL response is set to null
export interface IResponse {
  response: ICollection | null;
  recipes: IRecipeResponse['response'];
}

export interface IVariables {
  baseId: string;
  cursor?: string;
  isSAP: boolean;
  itemsPerPage: number;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
  uriId: string;
  userExists: boolean;
}

export const GET_CATEGORY_RECIPES = gql`
  ${CORE_RECIPE_FIELDS}
  ${CORE_USER_WEIGHTS}
  ${CORE_QUANTITY_FIELDS}
  query categoryRecipes(
    $uriId: ID!
    $baseId: String
    $itemsPerPage: Int!
    $cursor: String
    $isSAP: Boolean
    $userExists: Boolean
  ) {
    response: category(id: $uriId) {
      _id
      id
      name
    }
    recipes(
      first: $itemsPerPage
      after: $cursor
      categories_id: $baseId
      orderBy: { title: "ASC" }
      exists: { user: $userExists, isSAP: $isSAP }
    ) {
      totalCount
      items: edges {
        cursor
        node {
          ...CoreRecipeFields
          quantities {
            ...CoreQuantityFields
          }
          userWeights {
            ...CoreUserWeights
          }
        }
      }
    }
  }
`;
