import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Alert, AlertProps } from 'antd';
import { rapsRed } from '../theme/customVariables';
import { textColorDark } from '../theme/variables';

const RapsAlert = styled(Alert)`
  background-color: ${rapsRed};
  border: 0;
  margin-bottom: 16px;

  .ant-alert-message,
  .ant-alert-icon,
  .ant-alert-description,
  h6 {
    color: ${textColorDark};
  }
`;

export const RapsInfoAlert: FunctionComponent<AlertProps> = (props) => (
  <RapsAlert {...props} type="info" />
);
