import { gql } from '@apollo/client';

import { IResponse } from './get-categories-top-level';
import { CORE_CATEGORY_FIELDS } from './fragments/category';

export interface ICategory {
  _id: number;
  id: string;
  name: string;
  children: IResponse['response'];
  parent: ICategory | null;
}

export interface IVariablesSingle {
  id: string;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
}

export interface IResponseSingle {
  response: ICategory;
}

export const GET_CATEGORIES = gql`
  ${CORE_CATEGORY_FIELDS}
  query category($id: ID!, $orderBy: CategoryFilter_orderBy) {
    response: category(id: $id) {
      _id
      id
      name
      parent {
        _id
        id
        name
        parent {
          _id
          id
          name
        }
      }
      children(orderBy: [$orderBy]) {
        ...CoreCategoryFields
      }
    }
  }
`;
