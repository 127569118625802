import { gql } from '@apollo/client';

export const UPDATE_SHOPPING_CART_ITEM = gql`
  mutation updateShoppingCartItem($input: updateShoppingCartItemInput!) {
    updateShoppingCartItem(input: $input) {
      shoppingCartItem {
        id
        quantity
      }
    }
  }
`;
