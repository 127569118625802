import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface ISearchNoResult {
  searchTerm?: string;
  i18nKey?: string;
}
export const SearchNoResult: FunctionComponent<ISearchNoResult> = ({
  searchTerm,
  i18nKey,
}) => {
  const { t } = useTranslation();

  if (!searchTerm || !i18nKey) {
    return null;
  }

  return (
    <div>
      <h4>
        <strong>{t(i18nKey)}</strong>
      </h4>
      <Trans i18nKey="search.noResultsFor" values={{ searchTerm }} />
    </div>
  );
};
