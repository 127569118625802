import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { App, Form, InputNumber, Select } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { DragOutlined, NumberOutlined } from '@ant-design/icons';
import { gql, useApolloClient } from '@apollo/client';
import {
  IShopIngredientItem,
  IShoppingCartItem,
} from '../../../gql/get-shopping-cart-items';
import { handleError } from '../../../lib/handleError';
import { IIngredientRow } from '../interfaces';
import { RemoveItemButton } from './RemoveItemButton';
import {
  CONTAINER_SIZE_FIELD_NAME,
  getContainerSum,
  getIngredientUnit,
  getQuantitySum,
  MINIMUM_QUANTITY,
  QUANTITY_FIELD_NAME,
} from '../utils';
import { SummaryTable } from './SummaryTable';
import { UpdateActions } from './UpdateActions';
import { AddItemButton } from './AddItemButton';

const FormWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const WideCell = styled('td')`
  text-align: center;
  padding-right: 2rem;
`;

interface IQuantityProps {
  record: IIngredientRow;
  rowIndex: number;
}

type QuantityFormValues = {
  [key: string]: string;
};

export const QuantityForm: FunctionComponent<IQuantityProps> = ({
  record,
  rowIndex,
}) => {
  const [form] = Form.useForm<QuantityFormValues>();
  const client = useApolloClient();
  const id = useId();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [showUpdateActions, setShowUpdateActions] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [quantitySum, setQuantitySum] = useState(getQuantitySum(record));
  const [containerSum, setContainerSum] = useState(
    getContainerSum(record, form),
  );

  const ingredientUnit = useMemo(
    () => t(`shoppingCart.units.${getIngredientUnit(record)}`),
    [t, record],
  );

  const sizes = useMemo(
    () =>
      record.shopIngredients.edges
        .map((edge: IShopIngredientItem) =>
          Number.parseFloat(edge.node.containerSize),
        )
        .sort((a, b) => a - b),
    [record.shopIngredients.edges],
  );

  const resetAllFields = useCallback(() => {
    form.resetFields();
    setContainerSum(getContainerSum(record, form));
    setShowUpdateActions(false);
  }, [form, record]);

  const onFinish = useCallback(
    async (values: QuantityFormValues) => {
      setIsUpdating(true);

      const val = Object.values(values);
      const items: {
        amount: number;
        containerSize: string;
        extraAmount: number;
        id: string;
      }[] = [];

      // Transform fields to ShopItems
      for (let i = 0; i < val.length; i += 1) {
        if ((i + 1) % 2 === 0) {
          const recordData = record.containers[(i - 1) / 2];
          const amount = parseInt(val[i - 1] as string, 10);
          let extraAmount =
            amount - (recordData.amount - recordData.extraAmount);

          extraAmount = extraAmount > 0 ? extraAmount : 0;
          const containerSize =
            typeof val[i] === 'string' ? val[i].split(' ')[0] : val[i];

          const containerExists = items.some(
            (item) =>
              item.containerSize.toString() === containerSize.toString(),
          );

          if (containerExists) {
            notification.error({
              description: t('shoppingCart.duplicateContainers.description'),
              message: t('shoppingCart.duplicateContainers.message'),
            });

            resetAllFields();
            setIsUpdating(false);

            return;
          }

          items.push({
            amount,
            containerSize,
            extraAmount,
            id: recordData.id,
          });
        }
      }

      try {
        await client.mutate({
          mutation: gql`mutation updateShoppingCartItem{
            ${items.map(
              (item, index) => `
              update${index}: updateShoppingCartItem(input: {
                id: "${item.id}",
                amount: ${item.amount},
                containerSize: "${item.containerSize}",
                extraAmount: ${item.extraAmount},
              }) { shoppingCartItem { id amount containerSize } }
            `,
            )}
          }`,
        });
      } catch (error) {
        handleError(error);
      }

      setShowUpdateActions(false);

      setIsUpdating(false);
    },
    [client, notification, record.containers, resetAllFields, t],
  );

  // initial calculation of sums
  useEffect(() => resetAllFields(), [resetAllFields]);

  return (
    <FormWrapper>
      <Form
        id={`quantityForm${id}`}
        form={form}
        onFinish={onFinish}
        onFieldsChange={() => {
          setContainerSum(getContainerSum(record, form));
          setShowUpdateActions(form.isFieldsTouched());
        }}
      >
        <table>
          <tbody>
            {
              // header
              rowIndex === 0 && (
                <tr css={{ textAlign: 'center' }}>
                  <td css={{ paddingRight: '1.5rem' }}>
                    <NumberOutlined />
                  </td>
                  <td css={{ paddingRight: '1rem' }}>
                    <DragOutlined />
                  </td>
                  <td />
                </tr>
              )
            }
            {
              // form rows
              record.containers.map(
                (container: IShoppingCartItem, i: number) => (
                  <tr key={container.id}>
                    <td css={{ paddingRight: '1.5rem' }}>
                      <Form.Item
                        name={QUANTITY_FIELD_NAME + i}
                        css={{ marginBottom: 0 }}
                        initialValue={container.amount}
                      >
                        <InputNumber
                          size="small"
                          css={{ width: '50px' }}
                          min={MINIMUM_QUANTITY}
                          inputMode="decimal"
                        />
                      </Form.Item>
                    </td>
                    <td style={{ paddingRight: '1rem' }}>
                      <Form.Item
                        name={CONTAINER_SIZE_FIELD_NAME + i}
                        css={{ marginBottom: 0 }}
                        initialValue={`${Number.parseFloat(
                          container.containerSize,
                        )} ${ingredientUnit}`}
                      >
                        <Select size="small">
                          {sizes.map((size) => (
                            <Select.Option key={`${i}-${size}`} value={size}>
                              {`${size} ${ingredientUnit}`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      {record.containers.length > 1 && (
                        <RemoveItemButton
                          record={record}
                          container={container}
                          setQuantitySum={setQuantitySum}
                        />
                      )}
                    </td>
                  </tr>
                ),
              )
            }
            {
              // handle update (confirm / reset)
              showUpdateActions && (
                <tr>
                  <WideCell colSpan={3}>
                    <UpdateActions
                      isUpdating={isUpdating}
                      resetAllFields={resetAllFields}
                    />
                  </WideCell>
                </tr>
              )
            }
            {
              // add new items
              sizes.length > record.containers.length && !showUpdateActions && (
                <tr>
                  <WideCell
                    colSpan={2}
                    css={{ textAlign: 'center', paddingRight: '2rem' }}
                  >
                    <AddItemButton record={record} />
                  </WideCell>
                </tr>
              )
            }
          </tbody>
        </table>
        <SummaryTable
          containerSize={record.containers.length}
          containerSum={containerSum}
          quantitySum={quantitySum}
          ingredientUnit={ingredientUnit}
        />
      </Form>
    </FormWrapper>
  );
};
