import styled from '@emotion/styled';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { headingColor, primaryColor } from '../../../theme/variables';
import { defaultGutterPixelSize } from '../../../lib/styles';

interface IProps {
  label: string;
  icon: React.ReactNode;
  linkTo: string;
}

const LinkLabel = styled('span')`
  font-size: 0.9em;
`;

const ButtonNavLink = styled(NavLink)`
  color: ${headingColor};
  display: block;
  width: 100%;
  text-align: center;
  padding-block: ${defaultGutterPixelSize / 2}px;

  &.active {
    color: ${primaryColor};
  }
`;

export const ButtonLinkCol: React.FunctionComponent<IProps> = ({
  label,
  icon,
  linkTo,
}) => (
  <ButtonNavLink to={linkTo}>
    <div>{icon}</div>
    <div>
      <LinkLabel>{label}</LinkLabel>
    </div>
  </ButtonNavLink>
);
