import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useResponsive } from 'ahooks';
import { CoverImage } from '../../../../../components/CoverImage';
import { IRecipe } from '../../../../../gql/get-recipe';
import { apiUrls } from '../../../../../lib/apiUrls';
import {
  defaultBottomMargin,
  defaultGutterPixelSize,
} from '../../../../../lib/styles';
import { roles } from '../../../../../lib/userRoles';
import { textColor } from '../../../../../theme/variables';
import { RecipeTitle } from './RecipeTitle';
import {
  columnsCss,
  containerCss,
  FlexedDiv,
  FloatingButton,
  RelativeContainer,
  RowContainerDiv,
  SeparatorDiv,
  TextUpperCase,
  TextUpperCaseBold,
} from './styles';
import { RECIPES, UploadImageAction } from './UploadImageAction';

interface IProps {
  recipe: IRecipe;
  onAfterActionPreformed: () => Promise<unknown>;
}

const RecipeDetailHeading = styled(Card.Meta)`
  .ant-card-meta-detail > .ant-card-meta-title {
    margin-bottom: 0;
  }
`;

const SummaryCard = styled(Card)`
  ${defaultBottomMargin};
  overflow: hidden;

  .ant-card-body {
    padding: 24px 32px;
  }
`;

const ContainerRow = styled(Row)`
  ${containerCss};
`;

const ContentCol = styled(Col)`
  ${columnsCss};
`;

const CardDescription: FunctionComponent<{ recipe: IRecipe }> = ({
  recipe,
}) => {
  // Only show specific category
  const categories = useMemo(
    () =>
      recipe.categories.items.filter(
        ({ node }) => node.children.totalCount === 0,
      ),
    [recipe.categories.items],
  );

  return (
    <FlexedDiv>
      {categories.map(({ node }, index) => (
        <FlexedDiv key={node.id}>
          <Link to={`/categories/${node._id}`}>
            <TextUpperCaseBold>{node.name}</TextUpperCaseBold>
          </Link>
          {index + 1 !== categories.length && <SeparatorDiv />}
        </FlexedDiv>
      ))}
    </FlexedDiv>
  );
};

const Cover: FunctionComponent<IProps> = ({
  recipe,
  onAfterActionPreformed,
}) => {
  const [isImageFromCache, setIsImageFromCache] = useState(false);

  return (
    <RelativeContainer>
      <CoverImage
        recipe={recipe}
        {...(recipe.hasImage && {
          imageUrl: apiUrls.image.url(RECIPES, recipe._id, {
            thumb: false,
            isImageFromCache,
            imageId: recipe.imageId,
          }),
        })}
        hasAdditionalStyle
      />
      <FloatingButton>
        <UploadImageAction
          onImageChanged={async () => {
            setIsImageFromCache(false);
            await onAfterActionPreformed();
          }}
          recipe={recipe}
          roles={[roles.reviewer]}
        />
      </FloatingButton>
    </RelativeContainer>
  );
};

export const RecipeSummaryCard: FunctionComponent<IProps> = ({
  recipe,
  onAfterActionPreformed,
}) => {
  const { t } = useTranslation();
  const responsive = useResponsive();

  const { number, categories } = recipe;
  const showDetailRow = categories.items.length > 0 || number !== null;

  return (
    <SummaryCard
      cover={
        <Cover
          recipe={recipe}
          onAfterActionPreformed={onAfterActionPreformed}
        />
      }
    >
      <RecipeTitle recipe={recipe} />

      {showDetailRow && (
        <RowContainerDiv>
          <ContainerRow justify="space-between" gutter={defaultGutterPixelSize}>
            {categories.items.length > 0 && (
              <ContentCol>
                <RecipeDetailHeading
                  title={
                    <TextUpperCase style={{ color: textColor }}>
                      {t('recipe.categories', {
                        count: categories.items.length,
                      })}
                    </TextUpperCase>
                  }
                  description={<CardDescription recipe={recipe} />}
                />
              </ContentCol>
            )}
            {number && (
              <ContentCol
                xs={24}
                lg={3}
                {...(responsive.lg && { css: { textAlign: 'right' } })}
              >
                <RecipeDetailHeading
                  title={
                    <TextUpperCase style={{ color: textColor }}>
                      {t('recipe.number')}
                    </TextUpperCase>
                  }
                  description={
                    <TextUpperCaseBold style={{ color: textColor }}>
                      {number}
                    </TextUpperCaseBold>
                  }
                />
              </ContentCol>
            )}
          </ContainerRow>
        </RowContainerDiv>
      )}
    </SummaryCard>
  );
};
