import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { ICategory } from '../../gql/get-categories-top-level';
import { apiUrls } from '../../lib/apiUrls';
import {
  CATEGORIES,
  RECIPES,
} from '../../pages/Recipes/pages/RecipeDetail/components/UploadImageAction';
import { CoverImage } from '../CoverImage';
import { FlexCard, FlexCardMeta } from '../FlexCard';
import { SwipeableLink } from '../SwipeableLink';
import { Truncate } from '../Truncate';

type IRequiredItemProps = { targetUrl: string } & ICategory;

export type IProps = {
  item: IRequiredItemProps;
  showCarousel?: boolean;
};

export const CategoryCard: FunctionComponent<IProps> = ({
  item,
  showCarousel,
}) => {
  const { t } = useTranslation();

  const { name, targetUrl, id, recipes, _id, hasImage } = item;

  let imageUrl: string | null;

  if (hasImage) {
    imageUrl = apiUrls.image.url(CATEGORIES, _id);
  } else {
    const recipe = recipes.items.find((r) => r.node.hasImage);

    imageUrl = recipe
      ? apiUrls.image.url(RECIPES, recipe.node._id, {
          thumb: true,
          imageId: recipe.node.imageId,
        })
      : null;
  }

  // Click event to track the clicked category in GA
  const handleClick = useCallback(() => {
    ReactGA.gtag('event', 'Kategorien/Subkategorien von Rezepten angeklickt', {
      event_category: 'Kategorien',
      category_name: name, // dimension13
      category_id: id, // dimension12
    });
  }, [id, name]);

  return (
    <SwipeableLink to={targetUrl} onClick={handleClick}>
      <FlexCard
        cover={<CoverImage {...(imageUrl ? { imageUrl } : { thumb: true })} />}
      >
        <FlexCardMeta
          title={<Truncate lines={showCarousel ? 1 : 2}>{name}</Truncate>}
          description={t('search.recipe', {
            count: item.recipes.totalCount || 0,
          })}
        />
      </FlexCard>
    </SwipeableLink>
  );
};
