import { gql } from '@apollo/client';

export interface IShopIngredientItem {
  node: {
    containerSize: string;
    unit: string;
  };
}

export interface IShopIngredientResponse {
  edges: IShopIngredientItem[];
}

export interface IShoppingCartItem {
  id: string;
  quantity: string;
  containerSize: string;
  amount: number;
  extraAmount: number;
  ingredient: IIngredient;
  __typename?: string;
}

export interface IIngredient {
  id: string;
  name: string;
  number: number;
  shopIngredients: IShopIngredientResponse;
}

export interface IShoppingCartItemNode {
  node: IShoppingCartItem;
  __typename?: string;
}

export interface IResponse {
  shoppingCartItems: {
    edges: IShoppingCartItemNode[];
  };
}

export interface IDeleteResponseSingle {
  deleteShoppingCartItem: {
    shoppingCartItem: {
      id: string;
    };
  };
}

export interface IDeleteResponse {
  deleteShoppingCartItem: {
    [key: string]: {
      shoppingCartItem: {
        id: string;
      };
    };
  };
}

export interface ICreateResponse {
  response: {
    shoppingCartItem: Omit<IShoppingCartItem, 'ingredient'>;
  };
}

export const GET_SHOPPING_CART_ITEMS = gql`
  query shoppingCartItems {
    shoppingCartItems {
      edges {
        node {
          id
          amount
          extraAmount
          quantity
          containerSize
          ingredient {
            id
            name
            number
            shopIngredients {
              edges {
                node {
                  id
                  containerSize
                  unit
                }
              }
            }
          }
        }
      }
    }
  }
`;
