import { gql } from '@apollo/client';

export const CORE_USER_WEIGHTS = gql`
  fragment CoreUserWeights on UserRecipeBaseWeightConnection {
    items: edges {
      node {
        baseWeight
      }
    }
  }
`;
