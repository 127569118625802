import React, { type FunctionComponent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd/es/collapse/Collapse';
import { GET_USER } from '../../gql/get-user';
import {
  GET_COLLECTIONS_COUNTS,
  IResponse as ICountResponse,
} from '../../gql/get-collections-counts';
import { AuthGuard } from '../../components/AuthGuard';
import { UserRequired } from '../../components/UserRequired';
import { CollapsePanels } from './config';
import { usePageTitle } from '../../hooks/usePageTitle';

const CollectionsOverview: FunctionComponent<{ isGranted: boolean }> = ({
  isGranted,
}) => {
  const { t } = useTranslation();
  const { data } = useQuery(GET_USER);
  const { data: counts } = useQuery<ICountResponse>(GET_COLLECTIONS_COUNTS, {
    fetchPolicy: 'network-only',
  });

  usePageTitle(t('pageTitles.collections'));

  const items: CollapseProps['items'] = useMemo(
    () =>
      CollapsePanels.map((panel) => {
        const PanelContent = () =>
          panel.requiresUser ? (
            <UserRequired user={data}>{panel.element}</UserRequired>
          ) : (
            panel.element
          );

        const header = counts
          ? `${t(panel.i18nKey)} (${
              counts?.[panel.countKey]?.totalCount ?? ''
            })`
          : t(panel.i18nKey);

        return {
          key: panel.path,
          label: header,
          collapsible: panel.requiresUser && !isGranted ? 'disabled' : 'header',
          children: panel.roles ? (
            <AuthGuard roles={panel.roles} key={`${panel.path}-guard`}>
              <PanelContent />
            </AuthGuard>
          ) : (
            <PanelContent />
          ),
        };
      }),
    [counts, data, isGranted, t],
  );

  return <Collapse accordion items={items} />;
};

export default () => (
  <AuthGuard>
    {(isGranted) => <CollectionsOverview isGranted={isGranted} />}
  </AuthGuard>
);
