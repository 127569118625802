import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';

const DEFAULT_ERROR_MSG = 'Error 😓';

interface IErrorGraphQL {
  message?: string;
}

export const ErrorGraphQL: FunctionComponent<IErrorGraphQL> = ({
  message = DEFAULT_ERROR_MSG,
}) => <Typography.Paragraph>{message}</Typography.Paragraph>;
