import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import { defaultBottomMargin } from '../lib/styles';
import { DefaultLayout } from './DefaultLayout';
import { Loading } from './Loading';

const MarginLoading = styled(Loading)`
  ${defaultBottomMargin};
`;

interface IProps {
  text?: string;
}

export const LoadingPage: FunctionComponent<IProps> = ({ text }) => (
  <DefaultLayout center withoutAnonymousUserNotice>
    <MarginLoading />
    {text && <Typography.Text>{text}</Typography.Text>}
  </DefaultLayout>
);
