import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface ISummaryTable {
  quantitySum: number;
  containerSum: number;
  containerSize: number;
  ingredientUnit: string;
}

export const SummaryTable: FunctionComponent<ISummaryTable> = ({
  quantitySum,
  containerSum,
  ingredientUnit,
  containerSize,
}) => {
  const { t } = useTranslation();

  return (
    <table style={{ marginTop: '0.5rem' }}>
      <tbody>
        <tr>
          <td>{t('shoppingCart.sum')}:</td>
          <td
            style={{
              paddingRight: containerSize > 1 ? '3rem' : '1rem',
              textAlign: 'right',
            }}
          >
            {containerSum} {ingredientUnit}
          </td>
        </tr>

        {quantitySum > 0 && (
          <tr>
            <td>{t('shoppingCart.needed')}:</td>
            <td
              style={{
                paddingRight: containerSize > 1 ? '3rem' : '1rem',
                textAlign: 'right',
              }}
            >
              {quantitySum} {ingredientUnit}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
