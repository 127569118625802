import React, { FunctionComponent, useCallback } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import { App, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { prepareRecipeForExport } from '../../../../../../lib/prepareRecipeForExport';
import { apiUrls } from '../../../../../../lib/apiUrls';
import { HttpError } from '../../../../../../lib/HttpError';
import { downloadPdf } from '../../../../../../lib/downloadPdf';
import { IRecipe } from '../../../../../../gql/get-recipe';
import { useExportContext } from '../../../../../../context/ExportContext';
import { createPdfFileName } from '../../../../../../lib/pdf';

interface IPrintButton {
  recipe: IRecipe;
}
export const PrintButton: FunctionComponent<IPrintButton> = ({ recipe }) => {
  const { t } = useTranslation();

  const { notification } = App.useApp();

  const { declaration, ingredients, isTotal } = useExportContext();

  const handlePrintClick = useCallback(async () => {
    const exportData = prepareRecipeForExport(
      recipe,
      declaration,
      ingredients,
      isTotal,
      t,
    );

    const fileNameNumber = recipe.number
      ? `${recipe.number}`
      : t('recipe.label');

    const fileName = createPdfFileName([fileNameNumber, recipe.title], 'pdf');

    try {
      const response = await fetch(apiUrls.exportRecipe(fileName), {
        body: JSON.stringify(exportData),
        method: 'POST',
      });

      if (!response.ok) {
        throw new HttpError(response);
      }

      const url = await response.text();

      downloadPdf(recipe.title, url);
    } catch (error) {
      if (error instanceof HttpError && error.status === 401) {
        notification.error({
          description: t('login.error.description'),
          message: t('login.error.message'),
        });
      } else {
        notification.error({
          description: t('common.error.unknown.description'),
          message: t('login.error.message'),
        });
      }
    }
  }, [declaration, ingredients, isTotal, notification, recipe, t]);

  return (
    <Button onClick={handlePrintClick} icon={<PrinterOutlined />}>
      {t('recipe.printButton')}
    </Button>
  );
};
