import React, { FunctionComponent } from 'react';
import { Alert } from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../components/Loading';
import {
  GET_CATEGORIES_TOP_LEVEL,
  IResponse,
  IVariables,
} from '../../../gql/get-categories-top-level';
import { BigGreyHeading } from '../../Recipes/pages/RecipeDetail/components/styles';
import { CategoryGrid } from './CategoryGrid';
import { NoItemsGraphQL } from '../../../components/NoItemsGraphQL';
import { usePageTitle } from '../../../hooks/usePageTitle';

const TopLevelCategories: FunctionComponent = () => {
  const { t } = useTranslation();
  const { error, loading, data } = useQuery<IResponse, IVariables>(
    GET_CATEGORIES_TOP_LEVEL,
    { variables: { parentExists: false }, fetchPolicy: 'cache-and-network' },
  );

  usePageTitle(t('pageTitles.categories'));

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Error"
        description={error.message}
      />
    );
  }

  if (loading && !data) {
    return <Loading />;
  }

  if (!data?.response.items) {
    return <NoItemsGraphQL />;
  }

  return (
    <>
      <BigGreyHeading>{t('common.categories')}</BigGreyHeading>
      <CategoryGrid categories={data.response.items.map((c) => c.node)} />
    </>
  );
};

export default TopLevelCategories;
