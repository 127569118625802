import React from 'react';
import {
  TagsOutlined,
  StarOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { t } from 'i18next';
import ReactGA from 'react-ga4';
import { css } from '@emotion/react';

const style = css({
  fontSize: '1.3em',
});

export const navigationBarData = [
  {
    icon: <TagsOutlined css={style} />,
    label: t('common.categories'),
    linkTo: '/categories',
  },
  {
    icon: <StarOutlined css={style} />,
    label: t('common.collections'),
    linkTo: '/collections',
  },
  {
    disabledWithoutUser: true,
    icon: <ShoppingOutlined css={style} />,
    label: t('common.shop'),
    linkTo: '/shop',
  },
  {
    disabledWithoutUser: true,
    icon: <ShoppingCartOutlined css={style} />,
    label: t('shoppingCart.title'),
    linkTo: '/shopping-cart',
  },
  {
    disabledWithoutUser: true,
    icon: <SearchOutlined css={style} />,
    label: t('common.search'),
    linkTo: '/search',
    onClick: () =>
      ReactGA.event({
        action: 'Suchen',
        category: 'Suchen',
      }),
  },
];
