import { gql } from '@apollo/client';

export interface IContext {
  id: string;
}

export interface IVariables {
  input: {
    id: string;
    name?: string;
    isPublic?: boolean;
    isPublished?: boolean;
    isVisibleToAnon?: boolean;
    recipes?: string[];
    collectionImageRecipe?: string;
  };
}

export const UPDATE_COLLECTION = gql`
  mutation updateCollection($input: updateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
