import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import {
  faEye,
  faFileAlt,
  faFileImport,
  faSignOutAlt,
  faUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginOutlined, FormOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Popover, Space } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useResponsive } from 'ahooks';
import { handleExpiredToken } from '../../lib/handleExpiredToken';
import { roles } from '../../lib/userRoles';
import { primaryColor } from '../../theme/variables';
import { AuthGuard } from '../AuthGuard';
import { headerBarItemClass } from './styles';

const UserNavLink = styled(NavLink)`
  padding-left: 1.5em;
  display: flex;
  align-items: center;
`;

const MenuItemTextWrapper = styled.span`
  color: rgb(108, 111, 112);
  cursor: pointer;
  padding-left: 0.5em;
`;

const menuItemStyle = {
  padding: '0.5em',
};

export const iconStyle = {
  color: primaryColor,
  display: 'inline',
  width: '1em',
};
const userIconStyle = {
  marginRight: '0.5em',
};

const UsernameButton = styled.div`
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
`;

const HeaderBar = styled.div`
  ${headerBarItemClass};
`;

interface IMenuItemLink {
  icon: IconDefinition;
  linkTo: string;
  label: string;
}

const MenuItemText: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <MenuItemTextWrapper>{children}</MenuItemTextWrapper>
);

const MenuItemLink: FunctionComponent<IMenuItemLink> = ({
  icon,
  linkTo,
  label,
}) => (
  <div style={menuItemStyle}>
    <Link to={linkTo}>
      <FontAwesomeIcon icon={icon} style={iconStyle} />
      <MenuItemText>{label}</MenuItemText>
    </Link>
  </div>
);

interface IProps {
  loggedInUser?: {
    firstName: string;
    lastName: string;
  };
}

const UserMenu = () => {
  const client = useApolloClient();
  const location = useLocation();
  const { t } = useTranslation();

  const onLogout = useCallback(async () => {
    const isRoot = location.pathname === '/';

    await handleExpiredToken(client, false, isRoot);

    if (!isRoot) {
      window.location.href = '/';
    }
  }, [client, location.pathname]);

  return (
    <>
      <div key="logout" onClick={onLogout} style={menuItemStyle}>
        <FontAwesomeIcon icon={faSignOutAlt} style={iconStyle} />
        <MenuItemText>{t('common.logout')}</MenuItemText>
      </div>
      <MenuItemLink
        label={t('common.myRecipes')}
        icon={faFileAlt}
        linkTo="/recipes/own"
      />
      <AuthGuard roles={[roles.creator]}>
        <MenuItemLink
          icon={faFileImport}
          linkTo="/manage-recipes"
          label={t('recipeManagement.title')}
        />
      </AuthGuard>
      <AuthGuard roles={[roles.reviewer]}>
        <MenuItemLink
          label={t('publishing.title')}
          icon={faEye}
          linkTo="/manage-publications"
        />
      </AuthGuard>
      <AuthGuard roles={[roles.admin]}>
        <MenuItemLink
          icon={faUser}
          linkTo="/manage-users"
          label={t('userManagement.title')}
        />
      </AuthGuard>
    </>
  );
};

export const UserTopBar: FunctionComponent<IProps> = ({ loggedInUser }) => {
  const responsive = useResponsive();
  const { t } = useTranslation();

  // logged in state
  if (loggedInUser) {
    const fullName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;

    return (
      <Popover placement="bottomRight" trigger="click" content={UserMenu}>
        <UsernameButton role="button">
          {responsive.lg ? (
            <Space>
              <span>{fullName}</span>
              <Avatar icon={<UserOutlined />} />
            </Space>
          ) : (
            <Avatar icon={<UserOutlined title={fullName} />} />
          )}
        </UsernameButton>
      </Popover>
    );
  }

  // logged out state
  return (
    <HeaderBar>
      <UserNavLink to="/login">
        <LoginOutlined css={userIconStyle} />
        {t('header.login')}
      </UserNavLink>
      {responsive.sm && (
        <UserNavLink to="/register">
          <FormOutlined css={userIconStyle} />
          {t('header.register')}
        </UserNavLink>
      )}
    </HeaderBar>
  );
};
