import React, { FunctionComponent, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { baseStyle } from '../styles';

interface IProps {
  imageUrl: string;
  hasAdditionalStyle: boolean;
}

const additionalOptionalStyle = css`
  height: 260px;

  @media (min-width: 768px) {
    height: 400px;
  }

  @media (max-width: 420px) {
    height: 200px;
  }
`;

const StyledBackgroundImage = styled.div<IProps>`
  ${baseStyle};
  background-size: cover;
  background-position: center center;
  background-image: url('${(props) => props.imageUrl}');
  position: relative;
  ${(props) => props.hasAdditionalStyle && additionalOptionalStyle};
`;

export const BackgroundImage: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  imageUrl,
  hasAdditionalStyle = false,
}) => (
  <StyledBackgroundImage
    imageUrl={imageUrl}
    hasAdditionalStyle={hasAdditionalStyle}
  >
    {children}
  </StyledBackgroundImage>
);
