export interface IIngredient {
  quantity: number;
  name: string;
  id: string;
  isQuid: boolean;
  stpoClass?: string;
}

export const createQuantityObjectsInput = (
  recipe: string,
  ingredients: IIngredient[],
) => {
  if (ingredients.length > 0) {
    return ingredients.reduce(
      (accum, ingredient, index) => ({
        ...accum,
        [`input${index + 1}`]: {
          ingredient: ingredient.id,
          isQuid: ingredient.isQuid,
          position: index + 1,
          quantity: ingredient.quantity,
          quantityUnit: 'kg',
          recipe,
          stpoClass: ingredient.stpoClass,
        },
      }),
      {},
    );
  }

  return {};
};

export const formatNewLines = (text?: string) =>
  text ? text.replace(/\\n/g, '\n').trim() : '';

export const checkForSingleIngredient = (
  ingredients: IIngredient[],
): boolean => {
  if (ingredients.length < 2) return true;

  for (let i = 0; i < ingredients.length; i += 1) {
    for (let j = i + 1; j < ingredients.length; j += 1) {
      if (ingredients[i].id !== ingredients[j].id) {
        return false;
      }
    }
  }

  return true;
};

export const checkForSameMaterialIngredients = (
  ingredients: IIngredient[],
): boolean => {
  let hasOneIngredientOnly = true;

  for (let i = 0; i < ingredients.length; i += 1) {
    for (let j = i + 1; j < ingredients.length; j += 1) {
      if (ingredients[i].id === ingredients[j].id) {
        if (ingredients[i].stpoClass === ingredients[j].stpoClass) {
          return true;
        }
      } else {
        hasOneIngredientOnly = false;
      }
    }
  }

  return hasOneIngredientOnly;
};
