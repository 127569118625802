import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  App,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
} from 'antd';
import { useApolloClient } from '@apollo/client';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { RedoOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/es/select';
import { AuthGuard } from '../../../../../components/AuthGuard';
import {
  EXPORT_ENUM,
  useExportContext,
} from '../../../../../context/ExportContext';
import {
  CREATE_USER_RECIPE_BASE_WEIGHT,
  IVariables as ICreateBaseWeightVariables,
} from '../../../../../gql/create-user-recipe-base-weight';
import { IQuantity, IRecipe } from '../../../../../gql/get-recipe';
import {
  IVariables as IUpdateBaseWeightVariables,
  UPDATE_USER_RECIPE_BASE_WEIGHT,
} from '../../../../../gql/update-user-recipe-base-weight';
import { handleError } from '../../../../../lib/handleError';
import { IUser } from '../../../../../gql/get-user';
import {
  calcNormalizationFactor,
  countRecipeBaseIngredients,
} from '../../../../../lib/recipeCalculations';
import {
  defaultBottomMargin,
  defaultGutterPixelSize,
} from '../../../../../lib/styles';
import { screenLgMin } from '../../../../../theme/variables';
import {
  AddToCartButton,
  IShoppingCartItemInput,
} from './Buttons/AddToCartButton';
import { UserRequiredInfo } from '../../../../../components/UserRequiredInfo';
import { RecipeIngredientsTable } from './RecipeIngredientsTable';
import { CardActionsWrapper } from '../../../../../components/CardActionsWrapper';
import { formatWeight } from '../../../../../lib/helpers';
import {
  RECIPE_ACTION_ENUMS,
  DisplayMode,
  IRecipeIngredientsState,
  useRecipeDetailContext,
} from '../../../../../provider/RecipeDetailProvider';

const applyNewBasisStyle = css`
  width: 100%;
  @media only screen and (min-width: ${screenLgMin}) {
    width: auto;
  }
`;

const ApplyNewBasisButton = styled(Button)`
  ${applyNewBasisStyle}
`;

const dummyDataSet = {
  id: '',
  isQuid: false,
  position: 0,
  quantity: '',
  quantityUnit: '',
  stpoClass: null,
};

const insertIntermediateHeadings = (
  datasource: IQuantity[],
  mode: IRecipeIngredientsState['mode'],
  t: TFunction,
) => {
  if (mode !== 'base') {
    return datasource;
  }

  const dataWithHeadings = [
    {
      ...dummyDataSet,
      _id: -1,
      header: t('recipe.baseMaterialHeading'), // Grundmaterial
    },
    ...datasource,
  ];
  const stopClassIndex = dataWithHeadings.findIndex(
    ({ stpoClass }) => stpoClass === 'E',
  );

  // Einlagematerial
  if (stopClassIndex !== -1) {
    dataWithHeadings.splice(stopClassIndex, 0, {
      ...dummyDataSet,
      _id: -2,
      header: t('recipe.additiveMaterialHeading'),
    });
  }

  return dataWithHeadings;
};

const getCartItemsFromSelectedRows = (
  allSelectedIds: React.Key[],
  ingredients: IQuantity[],
  normalizationFactor: number,
) =>
  allSelectedIds.reduce((acc, key) => {
    const item =
      ingredients.find((ingredient) => ingredient._id === key) ||
      ({} as IQuantity);

    if (
      item.ingredient &&
      !item.ingredient.isVirtual &&
      !item.ingredient.isDeleted &&
      item.ingredient.shopIngredients?.edges.find((i) => !i.node.isDeleted) // Only add ingredients which are still available
    ) {
      return [
        ...acc,
        {
          ingredient: item.ingredient,
          quantity: (normalizationFactor * parseFloat(item.quantity)).toFixed(
            2,
          ),
        },
      ];
    }

    return acc;
  }, [] as IShoppingCartItemInput[]);

type RecipeIngredientsCardProps = {
  defaultDisplayMode?: DisplayMode;
  recipe: IRecipe;
  currentUser?: IUser | null;
};

export const RecipeIngredientsCard: FunctionComponent<
  RecipeIngredientsCardProps
> = ({ recipe, currentUser }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  // each recipe has exactly one user_recipe_base_weights id value, e.g.:
  // /api/user_recipe_base_weights/64
  const [userWeightsNodeId, setUserWeightsNodeId] = useState<
    string | undefined
  >(
    recipe.userWeights?.items.length
      ? recipe.userWeights?.items[0].node.id
      : undefined,
  );

  const { dispatchExport } = useExportContext();

  const {
    mode,
    baseIngredients,
    baseWeight,
    additionalIngredients,
    relativeTo,
    relativeToSelectValue,
    selectedRowIds,
    targetWeight,
    updateUserWeights,
    dispatch,
  } = useRecipeDetailContext();

  const normalizationFactor = calcNormalizationFactor(
    baseIngredients,
    relativeTo,
    baseWeight,
  );
  const isBaseModeDisabled = countRecipeBaseIngredients(recipe) === 0;

  const handleTargetWeightChange = useCallback(
    (value: number | string | null) => {
      const weight =
        value != null
          ? Number(Math.max(Number.parseFloat(value.toString()), 0).toFixed(2))
          : 0;

      dispatch({
        type: RECIPE_ACTION_ENUMS.UPDATE_TARGET_WEIGHT,
        payload: weight > 0 ? weight : undefined,
      });
    },
    [dispatch],
  );

  const updateBaseWeightState = useCallback(() => {
    dispatch({
      type: RECIPE_ACTION_ENUMS.UPDATE_BASE_WEIGHT,
      payload: {
        baseWeight: targetWeight,
        relativeTo: relativeToSelectValue,
        updateUserWeights: false,
      },
    });

    ReactGA.gtag('event', 'Rezeptmenge neu berechnen', {
      event_category: 'Rezeptansicht',
      recipe_name: recipe.title, // dimension1
    });

    dispatchExport({
      type: EXPORT_ENUM.SET_INGREDIENTS,
      payload: {
        normalizationFactor: calcNormalizationFactor(
          baseIngredients,
          relativeToSelectValue,
          targetWeight,
        ),
      },
    });

    notification.info({
      description: t('recipe.baseWeightNewBaseline'),
      message:
        mode === 'total'
          ? t('recipe.totalWeightRecalculated')
          : t('recipe.baseWeightRecalculated'),
    });
  }, [
    baseIngredients,
    dispatch,
    dispatchExport,
    mode,
    notification,
    recipe.title,
    relativeToSelectValue,
    t,
    targetWeight,
  ]);

  const applyNewBasisOfComputation = useCallback(async () => {
    if (targetWeight) {
      try {
        if (currentUser == null) {
          updateBaseWeightState();

          return;
        }

        if (userWeightsNodeId) {
          const input = {
            baseWeight: targetWeight,
            id: userWeightsNodeId,
          };

          dispatch({
            type: RECIPE_ACTION_ENUMS.UPDATE_USER_WEIGHTS,
            payload: true,
          });

          // Update UserRecipeBaseWeight
          const response = await client.mutate<IUpdateBaseWeightVariables>({
            mutation: UPDATE_USER_RECIPE_BASE_WEIGHT,
            variables: { input },
          });

          if (response.errors) {
            dispatch({
              type: RECIPE_ACTION_ENUMS.UPDATE_USER_WEIGHTS,
              payload: false,
            });
            handleError(response.errors);
          }

          if (response.data) {
            updateBaseWeightState();
          }
        } else {
          const input = {
            baseWeight: targetWeight,
            recipe: recipe.id,
          };

          dispatch({
            type: RECIPE_ACTION_ENUMS.UPDATE_USER_WEIGHTS,
            payload: true,
          });
          // Create UserRecipeBaseWeight
          const response = await client.mutate<ICreateBaseWeightVariables>({
            mutation: CREATE_USER_RECIPE_BASE_WEIGHT,
            variables: { input },
          });

          if (response.errors) {
            dispatch({
              type: RECIPE_ACTION_ENUMS.UPDATE_USER_WEIGHTS,
              payload: false,
            });
            handleError(response.errors);
          }

          if (response.data) {
            setUserWeightsNodeId(
              response.data.response.userRecipeBaseWeight.id,
            );
            updateBaseWeightState();
          }
        }
      } catch (error) {
        // FIXME: notification inside handleError function throws error:
        // [antd: notification] Static function can not consume context like dynamic theme. Please use 'App' component instead.
        handleError(error);
      }
    }
  }, [
    client,
    currentUser,
    dispatch,
    recipe.id,
    targetWeight,
    updateBaseWeightState,
    userWeightsNodeId,
  ]);

  const tableFooter = useCallback(
    (isTotal: boolean) => (data: readonly IQuantity[]) => {
      const quantities: IQuantity[] = isTotal
        ? additionalIngredients.concat(baseIngredients)
        : data.filter((d) => !d.header);

      const sum = quantities.reduce(
        (acc, { quantity }) => acc + parseFloat(quantity) * normalizationFactor,
        0,
      );

      const sumWeight = formatWeight(sum);

      return (
        <div css={{ padding: 8 }}>
          <strong>
            {isTotal || mode === 'total'
              ? t('recipe.totalWeight', { weight: sumWeight })
              : t('recipe.baseWeight', { weight: sumWeight })}
          </strong>
        </div>
      );
    },
    [additionalIngredients, baseIngredients, mode, normalizationFactor, t],
  );
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      const newMode = e.target.value;

      // update state
      dispatch({
        type: RECIPE_ACTION_ENUMS.SET_MODE,
        payload: newMode,
      });

      dispatch({
        type: RECIPE_ACTION_ENUMS.INIT,
        payload: {
          recipe,
          relativeTo,
          relativeToSelectValue,
          mode: newMode,
          additionalIngredients,
          baseIngredients,
        },
      });

      dispatchExport({
        type: EXPORT_ENUM.SET_TOTAL,
        payload: newMode === 'total',
      });

      ReactGA.gtag('event', 'Ansicht Gesamtmasse/Gesamtmaterial wechseln', {
        event_category: 'Rezeptansicht',
        recipe_name: recipe.title, // dimension1
      });
    },
    [
      additionalIngredients,
      baseIngredients,
      dispatch,
      dispatchExport,
      recipe,
      relativeTo,
      relativeToSelectValue,
    ],
  );

  useEffect(() => {
    dispatchExport({
      type: EXPORT_ENUM.SET_INGREDIENTS,
      payload: {
        normalizationFactor: calcNormalizationFactor(
          baseIngredients,
          relativeTo,
          baseWeight,
        ),
      },
    });
  }, [baseIngredients, baseWeight, dispatchExport, relativeTo]);

  const allSelectedIds = useMemo(
    () => [...selectedRowIds.values()].flat(),
    [selectedRowIds],
  );

  return (
    <AuthGuard>
      {(isGranted) => {
        const key = recipe._id + String(isGranted);
        const actions = isGranted
          ? [
              <CardActionsWrapper key={key}>
                <AddToCartButton
                  buttonProps={{
                    block: true,
                    disabled: allSelectedIds.length === 0,
                    type: 'primary',
                  }}
                  onAdded={() => {
                    notification.success({
                      description: t(
                        'shoppingCart.addToBasketSuccess.description',
                      ),
                      message: t('shoppingCart.addToBasketSuccess.message'),
                    });

                    dispatch({ type: RECIPE_ACTION_ENUMS.CLEAR_ROW_SELECTION });
                  }}
                  recipe={recipe}
                  records={getCartItemsFromSelectedRows(
                    allSelectedIds,
                    [...baseIngredients, ...additionalIngredients],
                    normalizationFactor,
                  )}
                />
              </CardActionsWrapper>,
            ]
          : [
              <CardActionsWrapper key={key}>
                <UserRequiredInfo
                  cursor="pointer"
                  message={t('shoppingCart.addToBasketRequiresUser')}
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    block
                    disabled={allSelectedIds.length === 0}
                    icon={<ShoppingCartOutlined />}
                  >
                    {t('shoppingCart.addToBasket')}
                  </Button>
                </UserRequiredInfo>
              </CardActionsWrapper>,
            ];

        return (
          <Card css={defaultBottomMargin} actions={actions}>
            <>
              <Row justify="center" style={{ marginBottom: 36 }}>
                <Radio.Group value={mode} onChange={onChange}>
                  <Radio.Button value="total">
                    {t('recipe.totalWeightLabel')}
                  </Radio.Button>
                  <Radio.Button value="base" disabled={isBaseModeDisabled}>
                    {t('recipe.baseWeightLabel')}
                  </Radio.Button>
                </Radio.Group>
              </Row>

              <Form id="computeIngredientWeightForm">
                <Row gutter={defaultGutterPixelSize}>
                  <Col xs={18} lg={7}>
                    <Form.Item style={{ marginBottom: 8 }}>
                      <InputNumber
                        onChange={handleTargetWeightChange}
                        addonAfter="kg"
                        value={targetWeight}
                        decimalSeparator=","
                        precision={2}
                        inputMode="numeric"
                        css={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={18} lg={13}>
                    <Form.Item style={{ marginBottom: 8 }}>
                      <Select
                        value={relativeToSelectValue}
                        onChange={(e: SelectValue) => {
                          if (e) {
                            dispatch({
                              type: RECIPE_ACTION_ENUMS.UPDATE_RELATIVE_TO_SELECT_VALUE,
                              payload: e.toString(),
                            });
                          }
                        }}
                      >
                        <Select.Option key="all">
                          {mode === 'total'
                            ? t('recipe.relativeToTotalWeightDefault')
                            : t('recipe.relativeToBaseWeightDefault')}
                        </Select.Option>
                        {baseIngredients.map(({ id, ingredient }) => (
                          <Select.Option key={id}>
                            {ingredient!.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={6} lg={4}>
                    <Form.Item style={{ margin: 0 }}>
                      <ApplyNewBasisButton
                        htmlType="submit"
                        type="primary"
                        onClick={applyNewBasisOfComputation}
                        disabled={
                          (!!targetWeight &&
                            targetWeight === baseWeight &&
                            relativeToSelectValue === relativeTo) ||
                          updateUserWeights
                        }
                      >
                        <RedoOutlined />
                      </ApplyNewBasisButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Space
                direction="vertical"
                size="large"
                style={{ width: '100%' }}
              >
                <RecipeIngredientsTable
                  recipe={recipe}
                  tableId="base"
                  dataSource={insertIntermediateHeadings(
                    baseIngredients,
                    mode,
                    t,
                  )}
                  selectedIds={selectedRowIds.get('base') || []}
                  normalizationFactor={normalizationFactor}
                  footer={tableFooter(false)}
                />

                {
                  // Zugabe
                  additionalIngredients.length > 0 && (
                    <div id="additionalIngredients">
                      <h3>{t('recipe.supplementaryIngredients')}</h3>

                      <RecipeIngredientsTable
                        recipe={recipe}
                        tableId="additional"
                        dataSource={additionalIngredients}
                        normalizationFactor={normalizationFactor}
                        selectedIds={selectedRowIds.get('additional') || []}
                        footer={tableFooter(true)}
                      />
                    </div>
                  )
                }
              </Space>
            </>
          </Card>
        );
      }}
    </AuthGuard>
  );
};
