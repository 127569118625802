import React, { FunctionComponent, PropsWithChildren } from 'react';

export const CardActionsWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <div
    css={{
      marginInlineStart: 24,
      marginInlineEnd: 24,
    }}
  >
    {children}
  </div>
);
