import React, { ReactElement, useContext } from 'react';
import styled from '@emotion/styled';
import { Card, Col, Row } from 'antd';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from '../../../../components/DefaultLayout';
import { ErrorUnknownPage } from '../../../../components/ErrorUnknownPage';
import { LoadingPage } from '../../../../components/LoadingPage';
import { LoggedInUserContext } from '../../../../context/LoggedInUserContext';
import { GET_RECIPE } from '../../../../gql/get-recipe';
import {
  defaultBottomMargin,
  defaultGutterPixelSize,
} from '../../../../lib/styles';
import { formatNewLines } from '../../utils';
import { RecipeIngredientsCard } from './components/RecipeIngredientsCard';
import { RecipeNutritionInformation } from './components/RecipeNutritionInformation';
import { RecipePublishInfo } from './components/RecipePublishInfo';
import { RecipeSummaryCard } from './components/RecipeSummaryCard';
import { SmallRedHeading } from './components/styles';
import { ExportProvider } from '../../../../context/ExportContext';
import { GoBack } from '../../../../components/GoBack';
import Error404Page from '../../../Error404';
import { RecipeDetailProvider } from '../../../../provider/RecipeDetailProvider';
import { usePageTitle } from '../../../../hooks/usePageTitle';

const ZANRow = styled(Row)`
  margin-bottom: 1.5rem;
  width: 100%;
`;

type Mapping = [string, string, ((f: string) => string[])?];

// Index 0: key in the recipe object
// Index 1: Title of the card
// Index 2: Optional formatter for list items (accepts string, returns array)
const mapping: Mapping[] = [
  ['ZAN1', 'recipe.creation'],
  ['ZAN2', 'recipe.cooking'],
  ['ZAN3', 'recipe.packaging'],
  ['ZAN4', 'recipe.casing'],
  ['ZAN5', 'recipe.miscellaneous'],
];

const RecipeDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useContext(LoggedInUserContext);

  const { loading, error, data, refetch } = useQuery(GET_RECIPE, {
    fetchPolicy: 'cache-and-network',
    variables: { id: `/api/recipes/${id}`, withUser: !!user },
  });

  const title = data?.response?.title;
  const number = data?.response?.number;

  usePageTitle(
    t('pageTitles.recipeDetail', {
      title: title ?? '',
      number: number ?? '',
    }),
    !!title && !!number,
  );

  if (error) {
    return <ErrorUnknownPage />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (!data || data.response === null) {
    return <Error404Page />;
  }

  const { response: recipe } = data;

  return (
    <ExportProvider>
      <DefaultLayout>
        <GoBack />
        <RecipeSummaryCard recipe={recipe} onAfterActionPreformed={refetch} />
        <RecipePublishInfo recipe={recipe} onPublishStateChanged={refetch} />

        <Row gutter={defaultGutterPixelSize}>
          <Col xs={24} md={12} css={defaultBottomMargin}>
            <RecipeDetailProvider recipe={recipe}>
              <RecipeIngredientsCard
                recipe={recipe}
                currentUser={user?.loggedInUser}
              />
            </RecipeDetailProvider>
          </Col>
          <Col xs={24} md={12}>
            {mapping.reduce<ReactElement[]>((elements, [key, i18nKey]) => {
              if (!recipe[key] || /^\s*$/.test(recipe[key])) {
                return elements;
              }

              elements.push(
                <ZANRow key={key}>
                  <Card css={{ width: '100%' }}>
                    <SmallRedHeading>{t(i18nKey)}</SmallRedHeading>
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {formatNewLines(recipe[key])}
                    </span>
                  </Card>
                </ZANRow>,
              );

              return elements;
            }, [])}
            <ZANRow>
              <RecipeNutritionInformation recipe={recipe} />
            </ZANRow>
          </Col>
        </Row>
      </DefaultLayout>
    </ExportProvider>
  );
};

export default RecipeDetail;
