import type { TFunction } from 'i18next';
import type {
  IExportDeclaration,
  IExportIngredients,
} from '../context/ExportContext';
import type { IIngredient, IRecipe } from '../gql/get-recipe';
import { formatNewLines } from '../pages/Recipes/utils';
import {
  formatDateTimeShort,
  formatPercent,
  formatWeight,
  formatNumber,
} from './helpers';
import { getSupplementaryInfoList } from './recipeCalculations';

interface IIngredientRow {
  amount: string;
  percent: string;
  name: string;
  isVirtual: boolean;
  stpoClass: string | null;
}

interface IIngredientData {
  data: IIngredientRow[];
  total?: {
    amount: string;
    percent?: string;
  };
}

export interface IExportData {
  category: string | null;
  title: string;
  recipe: {
    id: number;
    custom: boolean;
    number: string | undefined;
    printDate: string;
    hasImage: boolean;
    creation: string | null;
    imageId: string | undefined;
    ingredients: IIngredientData;
    baseIngredients: IIngredientData | null;
    categories?: string;
    additiveIngredients: IIngredientData | null;
    supplementaryIngredients: IIngredientData | null;
    isSAP?: boolean;
    isTotal: boolean;
    preparation: string | null;
    sausageCasings: string | null;
    other: string | null;
    packaging: string | null;
    recommendedDeclaration: {
      supplementaryInfo: string[];
      weightLossHint?: string;
      ingredients: string | null;
      nutritionalValues: Array<{
        name: string;
        amount: string;
      }>;
    };
    weightLoss: number | null;
  };
}

const recipeDefaults = {
  additiveIngredients: null,
  baseIngredients: null,
  creation: null,
  custom: false,
  isTotal: true,
  other: null,
  packaging: null,
  preparation: null,
  sausageCasings: null,
  supplementaryIngredients: null,
};

const percentDefaultOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const prepareRecipeForExport = (
  recipe: IRecipe,
  exportDeclaration: IExportDeclaration,
  exportIngredients: IExportIngredients,
  exportIsTotal: boolean,
  t: TFunction,
  exportVirtualNumber = false,
): IExportData => {
  const exportData: IExportData = {
    category: null,
    recipe: {
      ...recipeDefaults,
      hasImage: recipe.hasImage,
      id: recipe._id,
      imageId: recipe.imageId,
      ingredients: {
        data: [],
        total: {
          amount: formatWeight(0),
          percent: formatPercent(100),
        },
      },
      isSAP: recipe.isSAP,
      number: recipe.number,
      printDate: formatDateTimeShort(new Date()),
      recommendedDeclaration: {
        supplementaryInfo: getSupplementaryInfoList(exportDeclaration.text, t),
        weightLossHint: exportDeclaration.weightLossHint || '',
        ingredients: null,
        nutritionalValues: [],
      },
      weightLoss: recipe.weightLoss,
    },
    title: recipe.title,
  };

  if (recipe.isSAP) {
    exportData.category = `${t('recipe.categories', {
      count: recipe.categories.items.length,
    })} | ${recipe.categories.items
      .map((category) => category.node.name)
      .join(' , ')}`;
  } else {
    const categories = recipe.categories.items.filter(
      ({ node }) => node.children?.totalCount === 0,
    );

    if (categories.length) {
      exportData.category = `Kategorie | ${categories[0].node.name}`;
    }
  }

  const totalQuantity = recipe.quantities.items.reduce<number>(
    (prev, curr) =>
      prev +
      parseFloat(curr.node.quantity) * exportIngredients.normalizationFactor,
    0,
  );

  const getIngredientName = (ingredient: IIngredient | undefined) => {
    if (ingredient == null) {
      return 'N/A';
    }

    let { name } = ingredient;

    if (!ingredient.isVirtual || exportVirtualNumber) {
      name += ` (${ingredient.number})`;
    }

    return name;
  };

  const ingredients = recipe.quantities.items.map(
    ({ node: { ingredient, quantity, stpoClass } }) => ({
      amount: `${parseFloat(quantity) * exportIngredients.normalizationFactor}`,
      isVirtual: ingredient ? ingredient.isVirtual : true,
      name: getIngredientName(ingredient),
      percent: formatNumber(
        ((parseFloat(quantity) * exportIngredients.normalizationFactor) /
          totalQuantity) *
          100,
        percentDefaultOptions,
      ),
      stpoClass,
    }),
  );

  if (!exportIsTotal) {
    exportData.recipe.isTotal = false;

    let baseAndAdditive = ingredients.filter(
      (ingredient) => ingredient.stpoClass !== null,
    );

    const baseAndAdditiveQuantity = baseAndAdditive.reduce(
      (prev, curr) => prev + parseFloat(curr.amount),
      0,
    );

    baseAndAdditive = baseAndAdditive.map((ingredient) => ({
      ...ingredient,
      percent: formatNumber(
        (parseFloat(ingredient.amount) * 100) / totalQuantity,
        percentDefaultOptions,
      ),
    }));

    // BaseIngredients
    exportData.recipe.baseIngredients = {
      data: baseAndAdditive
        .filter((ingredient) => ingredient.stpoClass === 'G')
        .map((ingredient) => ({
          ...ingredient,
          amount: formatWeight(ingredient.amount),
        })),
      total: {
        amount: formatWeight(baseAndAdditiveQuantity),
        percent: formatPercent(100),
      },
    };

    // AdditiveIngredients
    const additiveIngredients = baseAndAdditive
      .filter((ingredient) => ingredient.stpoClass === 'E')
      .map((ingredient) => ({
        ...ingredient,
        amount: formatWeight(ingredient.amount),
      }));

    if (additiveIngredients.length) {
      exportData.recipe.additiveIngredients = {
        data: additiveIngredients,
      };
    }

    // Supplementary
    let supplementaryIngredients = ingredients.filter(
      (ingredient) => ingredient.stpoClass === null,
    );

    if (supplementaryIngredients.length) {
      const percentage = exportData.recipe.baseIngredients.data
        .concat(
          exportData.recipe.additiveIngredients
            ? exportData.recipe.additiveIngredients.data
            : [],
        )
        .reduce(
          (acc, { percent }) => acc + parseFloat(percent.replace(',', '.')),
          0,
        );

      exportData.recipe.baseIngredients.total!.percent =
        formatPercent(percentage);

      supplementaryIngredients = supplementaryIngredients.map((ingredient) => {
        const percent = (parseFloat(ingredient.amount) / totalQuantity) * 100;

        return {
          ...ingredient,
          percent:
            percent < 0.05
              ? `≈ ${formatNumber(0.1, percentDefaultOptions)}`
              : formatNumber(percent, percentDefaultOptions),
          amount: formatWeight(ingredient.amount),
        };
      });

      exportData.recipe.supplementaryIngredients = {
        data: supplementaryIngredients,
        total: {
          amount: formatWeight(totalQuantity),
          percent: formatPercent(100),
        },
      };
    }
  } else {
    exportData.recipe.ingredients!.data = ingredients.map((ingredient) => ({
      ...ingredient,
      amount: formatWeight(ingredient.amount),
    }));
  }

  if (recipe.quantities.items.length) {
    exportData.recipe.ingredients!.total!.amount = formatWeight(totalQuantity);
  }

  exportData.recipe.recommendedDeclaration.ingredients = exportDeclaration.text;

  exportData.recipe.recommendedDeclaration.nutritionalValues =
    exportDeclaration.nutritionalValues;

  if (recipe.ZAN1 != null) {
    exportData.recipe.creation = formatNewLines(recipe.ZAN1);
  }

  if (recipe.ZAN2 != null) {
    exportData.recipe.preparation = formatNewLines(recipe.ZAN2);
  }

  if (recipe.ZAN3 != null) {
    exportData.recipe.packaging = formatNewLines(recipe.ZAN3);
  }

  if (recipe.ZAN4 != null) {
    exportData.recipe.sausageCasings = formatNewLines(recipe.ZAN4);
  }

  if (recipe.ZAN5 != null) {
    exportData.recipe.other = formatNewLines(recipe.ZAN5);
  }

  return exportData;
};
