import { gql } from '@apollo/client';
import { IUser } from './get-user';
import { IRecipe } from './get-recipe-cards';
import { CORE_USER_WEIGHTS } from './fragments/user-weights';
import { CORE_QUANTITY_FIELDS } from './fragments/quantities';
import { CORE_RECIPE_FIELDS } from './fragments/recipe';

export interface ICollection {
  _id: number;
  id: string;
  name: string;
  collectionImageRecipe: IRecipe | null;
  user: IUser | null;
}

export interface ICollectionRecipe {
  cursor: string;
  node: IRecipe;
}

// Instead of HTTP 404, GraphQL response is set to null
export interface IResponse {
  collection: ICollection | null;
  recipes: {
    items: ICollectionRecipe[];
    totalCount: number;
  };
}

export interface IVariables {
  cursor?: string;
  collectionIriId: string;
  collectionNumericId: number;
  itemsPerPage: number;
  orderBy?: {
    [field: string]: 'ASC' | 'DESC';
  };
}

export const GET_COLLECTION_RECIPES = gql`
  ${CORE_USER_WEIGHTS}
  ${CORE_QUANTITY_FIELDS}
  ${CORE_RECIPE_FIELDS}
  query collectionRecipes(
    $collectionIriId: ID!
    $itemsPerPage: Int!
    $cursor: String
    $collectionNumericId: Int
    $orderBy: RecipeFilter_orderBy
  ) {
    collection(id: $collectionIriId) {
      _id
      id
      name
      collectionImageRecipe {
        _id
        id
      }
      user {
        _id
      }
    }
    recipes(
      first: $itemsPerPage
      after: $cursor
      collections_id: $collectionNumericId
      orderBy: [$orderBy]
      exists: { isSAP: false }
    ) {
      totalCount
      items: edges {
        cursor
        node {
          ...CoreRecipeFields
          quantities {
            ...CoreQuantityFields
          }
          userWeights {
            ...CoreUserWeights
          }
        }
      }
    }
  }
`;
