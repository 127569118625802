import { useLayoutEffect } from 'react';
import { trackCustomPageView, useDisableAutomaticPageView } from '../analytics';

export const usePageTitle = (title?: string, hasDynamicPart?: boolean) => {
  useDisableAutomaticPageView();

  useLayoutEffect(() => {
    // case undefined: no dynamic part, always set the title
    // case hasDynamicPart === true: dynamic part (e.g. API data) is ready, set the title
    const isFinal = hasDynamicPart === undefined || hasDynamicPart;

    if (title && isFinal && document.title !== title) {
      document.title = title;

      trackCustomPageView(title);
    }
  }, [hasDynamicPart, title]);
};
