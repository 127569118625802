import React, { lazy, Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@apollo/client';
import ReactGA from 'react-ga4';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Loading } from './components/Loading';
import { ScrollToTop } from './components/ScrollToTop';
import { LoggedInUserProvider } from './context/LoggedInUserContext';
import { useAnalytics } from './analytics';
import { GET_USER, IResponse } from './gql/get-user';
import { Home } from './pages/Home';
import { UserRequired } from './components/UserRequired';
import { DefaultLayoutWrapper } from './components/DefaultLayoutWrapper';
import ManagePublicationsPanels from './pages/ManagePublications/config';

import { CollapsePanels } from './pages/Collections/config';
import NewsDetailPage from './pages/News/pages/NewsDetail';
import NewsOverview from './pages/News/pages/NewsOverview';
import TopLevelCategories from './pages/Categories/components/TopLevelCategories';
import CollectionsOverview from './pages/Collections';
import Categories from './pages/Categories';
import RecipeDetail from './pages/Recipes/pages/RecipeDetail';
import SearchPage from './pages/Search/pages/Search';
import ShoppingCart from './pages/ShoppingCart';
import ActivatePage from './pages/Activate';
import Login from './pages/Login';
import Register from './pages/Register';
import ImprintPage from './pages/Imprint';

const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const SearchResultPage = lazy(
  () => import('./pages/Search/pages/SearchResult'),
);

const RecipeCreation = lazy(() => import('./pages/Recipes/pages/RecipeCreate'));
const RecipeCopy = lazy(() => import('./pages/Recipes/pages/RecipeCopy'));
const RecipeEdit = lazy(() => import('./pages/Recipes/pages/RecipeEdit'));
const RecipeOwnPage = lazy(() => import('./pages/Recipes/pages/RecipeOwn'));

const Shop = lazy(() => import('./pages/Shop'));

const NewsCreatePage = lazy(() => import('./pages/News/pages/NewsCreate'));

const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const Licenses = lazy(() => import('./pages/Licenses'));
const FAQ = lazy(() => import('./pages/FAQ'));

const ManagePublications = lazy(() => import('./pages/ManagePublications'));
const ManageUsers = lazy(() => import('./pages/ManageUsers'));
const ManageRecipesPage = lazy(() => import('./pages/ManageRecipes'));

const Contact = lazy(() => import('./pages/Contact'));
const ContactForm = lazy(() => import('./pages/ContactForm'));

const Error404Page = lazy(() => import('./pages/Error404'));

const routes = (user: IResponse | undefined) => [
  <Route index key="home" element={<Home />} />,
  <Route path="login" key="login" element={<Login />} />,
  <Route path="faq" key="faq" element={<FAQ />} />,
  <Route path="contact" key="contact" element={<Contact />} />,
  <Route path="licenses" key="licenses" element={<Licenses />} />,
  <Route path="imprint" key="imprint" element={<ImprintPage />} />,
  <Route path="terms-of-use" key="terms" element={<TermsOfUse />} />,
  <Route path="privacy-policy" key="privacy" element={<PrivacyPolicyPage />} />,

  <Route path="categories" key="categories" element={<DefaultLayoutWrapper />}>
    <Route element={<TopLevelCategories />} index />
    <Route path=":id" key="categories" element={<Categories />} />
  </Route>,

  <Route
    path="collections"
    key="collections"
    element={<DefaultLayoutWrapper />}
  >
    <Route element={<CollectionsOverview />} index />
    {CollapsePanels.map(({ path, element, children }) => {
      if (children?.length) {
        return (
          <Route key={path} path={path}>
            <Route element={element} index />
            {children.map((route) => (
              <Route {...route} key={route.key} />
            ))}
          </Route>
        );
      }

      return <Route key={path} path={path} element={element} />;
    })}
  </Route>,

  <Route path="news" key="news">
    <Route element={<NewsOverview />} index />
    <Route
      path="create"
      element={
        <UserRequired user={user}>
          <NewsCreatePage />
        </UserRequired>
      }
    />
    <Route path=":id" element={<NewsDetailPage />} />
  </Route>,

  <Route path="recipes" key="recipes">
    <Route
      path="create"
      element={
        <UserRequired user={user}>
          <RecipeCreation />
        </UserRequired>
      }
    />
    <Route
      path="own"
      element={
        <UserRequired user={user}>
          <RecipeOwnPage />
        </UserRequired>
      }
    />
    <Route path=":id">
      <Route element={<RecipeDetail />} index />
      <Route
        path="copy"
        element={
          <UserRequired user={user}>
            <RecipeCopy />
          </UserRequired>
        }
      />
      <Route
        path="edit"
        element={
          <UserRequired user={user}>
            <RecipeEdit />
          </UserRequired>
        }
      />
    </Route>
  </Route>,

  <Route path="search" key="search" element={<DefaultLayoutWrapper />}>
    <Route
      element={
        <UserRequired user={user}>
          <SearchPage />
        </UserRequired>
      }
      index
    />
    <Route
      path=":resultId"
      key="searchResult"
      element={
        <UserRequired user={user}>
          <SearchResultPage />
        </UserRequired>
      }
    />
  </Route>,

  <Route
    path="shop"
    key="shop"
    element={
      <UserRequired user={user}>
        <Shop />
      </UserRequired>
    }
  />,

  <Route
    path="shopping-cart"
    key="shopping-cart"
    element={
      <UserRequired user={user}>
        <ShoppingCart />
      </UserRequired>
    }
  />,

  <Route
    path="manage-recipes"
    key="manage-recipes"
    element={
      <UserRequired user={user}>
        <ManageRecipesPage />
      </UserRequired>
    }
  />,

  <Route
    path="manage-publications"
    key="manage-publications"
    element={<DefaultLayoutWrapper />}
  >
    <Route
      element={
        <UserRequired user={user}>
          <ManagePublications />
        </UserRequired>
      }
      index
    />
    {ManagePublicationsPanels.map(({ element, path }) => (
      <Route
        key={path}
        path={path}
        element={<UserRequired user={user}>{element}</UserRequired>}
      />
    ))}
  </Route>,

  <Route
    path="manage-users"
    key="manage-users"
    element={
      <UserRequired user={user}>
        <ManageUsers />
      </UserRequired>
    }
  />,

  <Route
    path="activate/:activationToken"
    element={<ActivatePage />}
    key="activate"
  />,
  <Route path="register" key="register" element={<Register />} />,
  <Route path="contact-form" key="contact-form" element={<ContactForm />} />,

  <Route
    path="forgot-password"
    key="forgot-password"
    element={<ForgotPassword />}
  />,

  <Route
    path="reset-password/:passwordResetToken"
    key="reset-password"
    element={<ResetPassword />}
  />,

  <Route path="*" key="errorPage" element={<Error404Page />} />,
];

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SuspenseRoute = () => (
  <Suspense fallback={<Loading />}>
    <Outlet />
  </Suspense>
);

export const App = () => {
  const { loading, data: user } = useQuery<IResponse>(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  useAnalytics();

  useEffect(() => {
    if (user) {
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_properties
      ReactGA.set({
        user_properties: {
          logged_in: user.loggedInUser ? 'ja' : 'nein', // dimension3
          ...(user.loggedInUser?.accountNumber && {
            // log account number
            account_number: user.loggedInUser.accountNumber, // dimension14
          }),
        },
      });
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ScrollToTop>
      <LoggedInUserProvider value={user!}>
        <SentryRoutes>
          <Route element={<SuspenseRoute />}>{routes(user)}</Route>
        </SentryRoutes>
      </LoggedInUserProvider>
    </ScrollToTop>
  );
};
