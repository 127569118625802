import React, { FunctionComponent, useCallback, useState } from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { gql, FetchResult, useApolloClient } from '@apollo/client';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { IShopIngredient } from '../../../../../../gql/get-product-categories';
import { IIngredient, IRecipe } from '../../../../../../gql/get-recipe';
import { handleError } from '../../../../../../lib/handleError';
import { findBestContainerSizesForQuantity } from '../../../../../../lib/containerSize';

export interface IShoppingCartItemInput {
  ingredient: IIngredient;
  quantity: string;
}

interface INewShoppingCartItem {
  ingredient: string;
  containerSize: string;
  amount: number;
  extraAmount: number;
  quantity: string;
}

interface IPrevItem {
  id: string;
  quantity: number;
  ingredientId: string;
  containerSize: string;
}

interface IProps {
  buttonProps: ButtonProps;
  recipe: IRecipe;
  records: IShoppingCartItemInput[];
  onAdded: () => void;
}

const createCartItems = (
  record: IShoppingCartItemInput,
  previouslyAdded: IPrevItem[],
) => {
  const { ingredient } = record;

  let quantity = Number.parseFloat(record.quantity);

  const added = previouslyAdded.filter(
    (prev) => prev.ingredientId === ingredient.id,
  );

  if (added.length > 0) {
    // Add up added quantity from cache
    added.forEach((item) => {
      quantity += item.quantity;
    });
  } else {
    // Add up old quantity
    ingredient.shoppingCartItems.edges.forEach((item) => {
      quantity += Number.parseFloat(item.node.quantity);
    });
  }

  const containerSizes = ingredient.shopIngredients?.edges
    .map((shopIngredient: { node: IShopIngredient }) =>
      Number.parseFloat(shopIngredient.node.containerSize),
    )
    .filter((containerSize) => containerSize > 0);

  if (!containerSizes) {
    return [];
  }

  if (quantity === 0) {
    return [];
  }

  const bestPossibility = findBestContainerSizesForQuantity(
    quantity,
    containerSizes,
  );

  if (!bestPossibility) {
    handleError({
      message: 'Could not find container-possibility for ShoppingCartItem',
    });

    return [];
  }

  // Adds quantity to the item. if there is enough quantity left equal to the containerSize * amount
  const filledQuantity = (
    remainingQuanitity: number,
    size: number,
    containerAmount: number,
  ) =>
    remainingQuanitity > 0
      ? `${
          remainingQuanitity > size * containerAmount
            ? size * containerAmount
            : remainingQuanitity
        }`
      : '0';

  let leftQuantity = quantity;
  let lastSize = bestPossibility[0];
  let amount = 0;
  // Group by container size and fill up with quantity
  const newCartItems: INewShoppingCartItem[] = [];

  bestPossibility.forEach((item) => {
    if (item !== lastSize) {
      newCartItems.push({
        amount,
        containerSize: `${lastSize}`,
        extraAmount: 0,
        ingredient: ingredient.id,
        quantity: filledQuantity(leftQuantity, lastSize, amount),
      });
      leftQuantity -= lastSize * amount;
      lastSize = item;
      amount = 0;
    }
    amount += 1;
  });

  if (amount > 0) {
    newCartItems.push({
      amount,
      containerSize: `${lastSize}`,
      extraAmount: 0,
      ingredient: ingredient.id,
      quantity: filledQuantity(leftQuantity, lastSize, amount),
    });
  }

  // Now we add the extra ingredients added by user
  ingredient.shoppingCartItems.edges.forEach((item) => {
    const extraAmount = item.node.extraAmount ?? 0;

    if (extraAmount <= 0) return;

    const existingItem = newCartItems.find((newItem) => {
      const newContainerSize = parseFloat(newItem.containerSize).toFixed(2);
      const oldContainerSize = parseFloat(item.node.containerSize).toFixed(2);

      return newContainerSize === oldContainerSize;
    });

    if (existingItem == null) {
      newCartItems.push({
        amount: extraAmount,
        containerSize: item.node.containerSize,
        extraAmount,
        ingredient: ingredient.id,
        quantity: '0',
      });
    } else {
      existingItem.amount += extraAmount;
      existingItem.extraAmount = extraAmount;
    }
  });

  return newCartItems;
};

const removeDuplicates = (records: IShoppingCartItemInput[]) => {
  const uniqueRecords: IShoppingCartItemInput[] = [];

  records.forEach((record) => {
    const existingIndex = uniqueRecords.findIndex(
      (r) => r.ingredient.number === record.ingredient.number,
    );

    if (existingIndex !== -1) {
      uniqueRecords[existingIndex].quantity = `${
        Number.parseFloat(uniqueRecords[existingIndex].quantity) +
        Number.parseFloat(record.quantity)
      }`;
    } else {
      uniqueRecords.push(record);
    }
  });

  return uniqueRecords;
};
const findItemsToDelete = (
  records: IProps['records'],
  previouslyAdded: IPrevItem[],
) => {
  let toDelete: Array<{ node: { id: string; quantity: string } }> = [];

  removeDuplicates(records).forEach((element) => {
    // Take items in cache if existing
    const prevAdded = previouslyAdded
      .filter((prev) => prev.ingredientId === element.ingredient.id)
      .map((prev) => ({
        node: { id: prev.id, quantity: `${prev.quantity}` },
      }));

    if (prevAdded.length) {
      toDelete = toDelete.concat(prevAdded);
    } else {
      toDelete = toDelete.concat(element.ingredient.shoppingCartItems.edges);
    }
  });

  return toDelete;
};

const getNotRecentlyAdded = (
  createdItems: FetchResult,
  newCartItems: INewShoppingCartItem[],
  previouslyAdded: IPrevItem[],
  toDelete: Array<{ node: { id: string; quantity: string } }>,
) => {
  // Extract returned ids
  const newIds = Object.keys(createdItems.data!).map(
    (key) => createdItems.data![key].shoppingCartItem.id,
  );

  // Find those that were not added this step
  const notAddedRecently = previouslyAdded.filter(
    (prev) => !toDelete.find((del) => del.node.id === prev.id),
  );

  // Add the newly created items
  newCartItems.forEach((item, i) => {
    let existing = previouslyAdded.find(
      (prev) =>
        prev.ingredientId === item.ingredient &&
        prev.containerSize === item.containerSize,
    );

    if (!existing) {
      existing = {
        containerSize: item.containerSize,
        id: newIds[i],
        ingredientId: item.ingredient,
        quantity: Number.parseFloat(item.quantity),
      };
      notAddedRecently.push(existing);

      return;
    }
    existing.id = newIds[i];
    existing.quantity = Number.parseFloat(item.quantity);
    notAddedRecently.push(existing);
  });

  return notAddedRecently;
};

export const AddToCartButton: FunctionComponent<IProps> = ({
  buttonProps,
  records,
  onAdded,
  recipe,
}) => {
  const client = useApolloClient();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [previouslyAdded, setPreviouslyAdded] = useState<IPrevItem[]>([]);

  const onClick = useCallback(async () => {
    setLoading(true);

    let newCartItems: INewShoppingCartItem[] = [];

    removeDuplicates(records).forEach((record) => {
      newCartItems = newCartItems.concat(
        createCartItems(record, previouslyAdded),
      );
    });

    try {
      const toDelete = findItemsToDelete(records, previouslyAdded);

      // Delete all old ones if necessary
      if (toDelete.length > 0) {
        await client.mutate({
          mutation: gql`mutation deleteOldShoppingCartItems{
            ${toDelete.map(
              (record, index) => `
             delete${index}: deleteShoppingCartItem(input: {
               id: "${record.node.id}",
             }) { shoppingCartItem { id } }
           `,
            )}
          }`,
        });
      }

      // Create new ones
      if (newCartItems.length > 0) {
        const createdItems = await client.mutate({
          mutation: gql`mutation createShoppingCartItems{
            ${newCartItems.map(
              (record, index) => `
              create${index}: createShoppingCartItem(input: {
                ingredient: "${record.ingredient}",
                amount: ${record.amount},
                extraAmount: ${record.extraAmount},
                containerSize: "${record.containerSize}",
                quantity: "${record.quantity}", 
              }) { shoppingCartItem { id } }
              `,
            )}
          }`,
        });

        setPreviouslyAdded((prevState) =>
          getNotRecentlyAdded(createdItems, newCartItems, prevState, toDelete),
        );
      }

      ReactGA.gtag('event', 'Produkt aus Rezept Warenkorb hinzufügen', {
        event_category: 'Shopping',
        recipe_name: recipe.title, // dimension1
        ingredient_name: `${records.map(
          (item) => `${item.ingredient.name}, `,
        )}`, // dimension6
      });

      onAdded();
    } catch (error) {
      handleError(error);
    }

    setLoading(false);
  }, [client, onAdded, previouslyAdded, recipe.title, records]);

  return (
    <Button
      {...buttonProps}
      loading={loading}
      icon={<ShoppingCartOutlined />}
      htmlType="button"
      onClick={onClick}
      disabled={records.length === 0}
    >
      {t('shoppingCart.addToBasket')}
    </Button>
  );
};
