import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Card, Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { NutritionalInformationTable } from '../../../../../components/NutritionalInformationTable';
import { IRecipe } from '../../../../../gql/get-recipe';
import { highlightDeclarations } from '../../../../../lib/highlightDeclarations';
import {
  calcBigEights,
  calculateGrossWeight,
  containsAllergicSubstances,
  containsVenisonSubstances,
  formattedIngredientList,
  generateAdditionalDeclarationText,
} from '../../../../../lib/recipeCalculations';
import { SmallGreyHeading, SmallRedHeading } from './styles';
import {
  EXPORT_ENUM,
  useExportContext,
} from '../../../../../context/ExportContext';
import { DeclarationType, quidCalculation } from '../util/quidCalculation';
import { CopyClipboardButton } from './Buttons/CopyClipboardButton';

interface IProps {
  recipe: IRecipe;
}

export const RecipeNutritionInformation: FunctionComponent<IProps> = ({
  recipe,
}) => {
  const { t } = useTranslation();
  const { dispatchExport } = useExportContext();

  const grossWeight = useMemo(() => calculateGrossWeight(recipe), [recipe]);
  const bigEights = useMemo(() => calcBigEights(recipe), [recipe]);

  const [declaration] = useState<DeclarationType>(() =>
    recipe.applyQuidMeatCalculation
      ? quidCalculation(grossWeight, recipe, t)
      : {
          text: formattedIngredientList(recipe),
        },
  );

  useEffect(() => {
    dispatchExport({
      type: EXPORT_ENUM.SET_DECLARATION,
      payload: {
        nutritionalValues: Object.values(bigEights).map(
          ({ key, value, unit }) => ({
            amount: `${value} ${unit}`,
            name: t(`nutritionalInformation.${key}`),
          }),
        ),
        text: declaration.text,
        weightLossHint: declaration.weightLossHint,
      },
    });
  }, [
    bigEights,
    declaration.text,
    declaration.weightLossHint,
    dispatchExport,
    recipe.applyQuidMeatCalculation,
    t,
  ]);

  return (
    <Card
      css={{ width: '100%' }}
      actions={[
        <CopyClipboardButton
          key={recipe._id}
          recipe={recipe}
          declarationText={generateAdditionalDeclarationText(
            recipe,
            bigEights,
            declaration,
            t,
          )}
        />,
      ]}
    >
      <SmallRedHeading css={{ marginBottom: 16 }}>
        {t('recipe.recommendedDeclaration')}
      </SmallRedHeading>

      <Space direction="vertical" css={{ width: '100%' }} split={<Divider />}>
        <div>
          <SmallGreyHeading>{t('recipe.ingredients')}</SmallGreyHeading>
          <p>{highlightDeclarations(declaration.text)}</p>
          {/* Add disclaimer if at least one ingredient contains allergenic substances */}
          {declaration.weightLossHint && (
            <p>
              <em>{declaration.weightLossHint}</em>
            </p>
          )}
          {containsAllergicSubstances(declaration.text) && (
            <div>
              <em>{t('recipe.declaration.hints.allergens')}</em>
            </div>
          )}
          {containsVenisonSubstances(declaration.text) && (
            <div>
              <em>{t('recipe.declaration.hints.venison')}</em>
            </div>
          )}
        </div>
        <NutritionalInformationTable
          bigEights={bigEights}
          weightLoss={recipe.weightLoss}
        />
      </Space>
    </Card>
  );
};
