import { rapsRed } from './customVariables';

// -------- Colors -----------
export const primaryColor = rapsRed;

// Base Scaffolding Variables

// Base background color for most components
export const componentBackground = '#fff';

// Font
export const headingColor = '#000000'; // rgba('#000', 0.85);
export const textColor = '#6c6f70'; // rgba('#000', 0.65);
export const textColorDark = 'rgba(255,255,255, 0.85)';

// LINK
export const linkColor = primaryColor;

export const darkLinkColor = componentBackground;

// Disabled states
export const disabledColor = 'rgba(0, 0 ,0, 0.25)';

export const whiteBlur = 'rgba(255, 255, 255, 0.2)';

// Media queries breakpoints
// Extra small screen / phone
export const screenXs = '480px';

// Small screen / tablet
export const screenSm = '576px';

// Medium screen / desktop
export const screenMd = '768px';

// Large screen / wide desktop
export const screenLg = '992px';
export const screenLgMin = screenLg;

// Layout
export const layoutBodyBackground = '#f0f2f5';
export const layoutFooterBackground = '#dadbdb';
export const layoutImageHeightNoUnit = 50;
export const layoutImageWidthNoUnit = 39;
