import React, { FunctionComponent, useCallback, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { css } from '@emotion/react';
import { Card } from 'antd';
import { INewsItem } from '../gql/get-news';
import { apiUrls } from '../lib/apiUrls';
import { maxScreenSm, maxScreenXs } from '../lib/styles';
import { NEWS_ITEMS } from '../pages/Recipes/pages/RecipeDetail/components/UploadImageAction';
import { BackgroundImage } from './BackgroundImage';
import { BackgroundImgFixedAspectRatio } from './BackgroundImageFixedAspectRatio';
import { BackgroundNoImgFixedAspectRatio } from './BackgroundNoImageFixedAspectRatio';
import { NoImageIcon } from './NoImageIcon';
import { SwipeableLink } from './SwipeableLink';
import { Truncate } from './Truncate';

const imageWidths = {
  lg: 400,
  sm: 350,
  xs: 300,
};

export const imageAspectRatio = 0.7;

const hidden = css`
  overflow: hidden;
`;

export const fixedStyle = css`
  width: ${imageWidths.lg}px;
  margin: 8px;

  ${maxScreenSm(`
    width: ${imageWidths.sm}px;
  `)};

  ${maxScreenXs(`
    width: ${imageWidths.xs}px;
  `)};
`;

interface IProps {
  item: INewsItem;
  responsive?: boolean;
  className?: string;
}

export const NewsCard: FunctionComponent<IProps> = ({
  className,
  item,
  responsive,
}) => {
  const trackGAEvent = useCallback(() => {
    ReactGA.gtag('event', 'News angeklickt', {
      event_category: 'News',
      news_title: item.title, // dimension7
      news_id: item._id, // dimension8
    });
  }, [item._id, item.title]);

  const cardCover = useMemo(() => {
    if (responsive) {
      return item.hasImage ? (
        <BackgroundImage
          src={apiUrls.image.url(NEWS_ITEMS, item._id, {
            imageId: item.imageId,
          })}
          height={200}
        />
      ) : (
        <div css={{ padding: 24, textAlign: 'center' }}>
          <NoImageIcon />
        </div>
      );
    }

    return item.hasImage ? (
      <BackgroundImgFixedAspectRatio
        src={apiUrls.image.url(NEWS_ITEMS, item._id, {
          imageId: item.imageId,
        })}
        imageAspectRatio={imageAspectRatio}
        imageWidths={imageWidths}
      />
    ) : (
      <BackgroundNoImgFixedAspectRatio
        imageAspectRatio={imageAspectRatio}
        imageWidths={imageWidths}
      />
    );
  }, [item._id, item.hasImage, item.imageId, responsive]);

  return (
    <SwipeableLink to={`/news/${item._id}`}>
      <Card
        className={className}
        cover={cardCover}
        onClick={trackGAEvent}
        css={!responsive ? [hidden, fixedStyle] : [hidden]}
      >
        <Card.Meta
          title={item.title}
          description={
            <Truncate lines={responsive ? 2 : 1}>{item.subtitle}</Truncate>
          }
        />
      </Card>
    </SwipeableLink>
  );
};
