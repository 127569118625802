import styled from '@emotion/styled';
import { Card } from 'antd';

export const FlexCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .ant-card-body {
    flex-grow: 1;
  }
`;

export const FlexCardMeta = styled(Card.Meta)`
  height: 100%;

  .ant-card-meta-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    > .ant-card-meta-title {
      flex-grow: 1;
      padding-bottom: 0.5rem;
      white-space: normal;
    }
  }
`;
