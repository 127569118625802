import { gql } from '@apollo/client';

export interface IDeleteNewsItemResponse {
  deleteNewsItem: {
    newsItem: {
      id: string;
    };
  };
}

export const DELETE_NEWS_ITEM = gql`
  mutation deleteNewsItem($input: deleteNewsItemInput!) {
    deleteNewsItem(input: $input) {
      newsItem {
        id
      }
    }
  }
`;
