import React, { useCallback, useState } from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Row } from 'antd';
import { useApolloClient } from '@apollo/client';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { AuthGuard } from '../../components/AuthGuard';
import { DefaultLayout } from '../../components/DefaultLayout';
import { apiUrls } from '../../lib/apiUrls';
import { handleResponse } from '../../lib/handleFetch';
import { GET_USER, IUser } from '../../gql/get-user';
import { HttpError } from '../../lib/HttpError';
import { usePageTitle } from '../../hooks/usePageTitle';

type LoginUserData = { user: IUser; cookieExpires: string };

const Login: React.FunctionComponent = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  usePageTitle(t('pageTitles.login'));

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = useCallback(
    async (values: { username: string; password: string }) => {
      setIsLoading(true);

      try {
        const data = await fetch(apiUrls.login, {
          body: JSON.stringify(values),
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        }).then((res) => handleResponse<LoginUserData>(res));

        if (data) {
          client.writeQuery({
            query: GET_USER,
            data: {
              loggedInUser: {
                cookieExpires: data.cookieExpires,
                ...data.user,
                __typename: 'User',
                userRoles: data.user.userRoles.map((role: object) => ({
                  ...role,
                  __typename: 'UserRole',
                })),
              },
            },
          });

          // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#login
          ReactGA.event('login');
          navigate('/');
        }
      } catch (error) {
        const description =
          error instanceof HttpError && error.status === 401
            ? t('login.error.description')
            : t('common.error.unknown.description');

        notification.error({
          message: t('login.error.message'),
          description,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [client, navigate, notification, t],
  );

  return (
    <AuthGuard>
      {(isGranted) =>
        isGranted ? (
          <Navigate to="/" />
        ) : (
          <DefaultLayout withoutAnonymousUserNotice>
            <Row justify="center">
              <Col xs={24} sm={12} lg={6}>
                <Form onFinish={onFinish}>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: t('login.email.error') },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      type="email"
                      placeholder={t('login.email.placeholder')}
                      autoComplete="username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t('login.password.error'),
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder={t('login.password.placeholder')}
                      autoComplete="current-password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={isLoading}
                    >
                      {t('login.loginButton')}
                    </Button>
                  </Form.Item>
                </Form>
                <Row justify="space-between" wrap>
                  <Link to="/register">{t('login.registerLink')}</Link>
                  <Link to="/forgot-password">
                    {t('login.forgotPasswordLink')}
                  </Link>
                </Row>
              </Col>
            </Row>
          </DefaultLayout>
        )
      }
    </AuthGuard>
  );
};

export default Login;
