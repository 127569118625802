import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { onError } from '@apollo/client/link/error';
import { notification } from 'antd';
import { t } from 'i18next';
import { apiUrls } from './apiUrls';
import { GET_USER, IResponse } from '../gql/get-user';
import { handleExpiredToken } from './handleExpiredToken';
import { typeDefs } from './linkState';

const cache = new InMemoryCache();

const setup = async () => {
  let client: ApolloClient<object>;

  const errorLink = onError(({ response, graphQLErrors, networkError }) => {
    const onlySubresourceAccessDenied =
      graphQLErrors &&
      graphQLErrors.every(
        (error) =>
          error.message.startsWith('Access Denied') &&
          error.path &&
          error.path.length > 1,
      );

    if (onlySubresourceAccessDenied && !networkError) {
      // see https://www.apollographql.com/docs/react/data/error-handling/#ignoring-errors
      // @ts-ignore
      response.errors = null;

      return;
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (error.message.startsWith('Access Denied')) {
          // Check if currently a user is logged in.
          let isAnonymousUser = false;

          try {
            // readQuery only reads from cache, never performs network request.
            const userData = client.readQuery<IResponse>({
              query: GET_USER,
            });

            if (!userData || userData.loggedInUser === null) {
              isAnonymousUser = true;
            }
          } catch (err) {
            /**/
          }

          if (isAnonymousUser) {
            // Currently no user is logged in.
            // Display appropriate message.

            notification.warning({
              description: t('common.error.401.anonymousUser.description'),
              message: t('common.error.401.anonymousUser.message'),
            });
          } else {
            // According to the cache, there is currently a user logged in.
            // Most probably his token expired, and he needs to log in again.
            handleExpiredToken(client, true, true);
          }

          // eslint-disable-next-line no-param-reassign
          (error as any).handled = true;
        }

        // eslint-disable-next-line no-console
        console.warn(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
        );
      });
    }

    if (networkError && (networkError as any).statusCode === 401) {
      handleExpiredToken(client, true, true);
      // eslint-disable-next-line no-param-reassign
      (networkError as any).handled = true;
    }
  });

  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    trigger: 'write',
  });

  client = new ApolloClient({
    link: from([errorLink, new HttpLink({ uri: apiUrls.graphql })]),
    cache,
    resolvers: {},
    typeDefs,
    name: 'Raps',
    version: '1.0',
  });

  return client;
};

export const apolloClient = setup();
