import { getIsoTimeString } from './date';

export const createPdfFileName = (parts: string[], fileType?: 'pdf') => {
  const dateTimeString = getIsoTimeString();
  const fileName = [...parts, dateTimeString]
    .join(' ')
    .toLowerCase()
    .replace(/[\s:]+/g, '-')
    .replace(/[;,!?.&@#$%^*(){}+_"'\\/]+/g, '')
    .replace(/-+/g, '-');
  const encodedFileName = encodeURIComponent(fileName);

  return fileType ? `${encodedFileName}.${fileType}` : encodedFileName;
};
