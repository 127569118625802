import { gql } from '@apollo/client';

export interface IResponse {
  ownCollections: {
    totalCount: number;
  };
  publicCollections: {
    totalCount: number;
  };
  ownRecipes: {
    totalCount: number;
  };
  rapsRecipes: {
    totalCount: number;
  };
}

export const GET_COLLECTIONS_COUNTS = gql`
  query collectionResultsCounts {
    myRecipes: recipes(exists: { user: true, isSAP: false }) {
      totalCount
    }
    myCollections: collections(exists: { user: true }) {
      totalCount
    }
    publicCollections: collections(exists: { user: false }) {
      totalCount
    }
    rapsRecipes: recipes(exists: { user: false, isSAP: true }) {
      totalCount
    }
  }
`;
