import { gql } from '@apollo/client';
import { CORE_NEWS_ITEM_FIELDS } from './fragments/news-item';
import { INewsItem } from './get-news';
import {
  ICategory,
  IQuantity,
  IRelation,
  IUserRecipeBaseWeight,
} from './get-recipe';
import { IUser } from './get-user';
import { CORE_RECIPE_FIELDS } from './fragments/recipe';

export interface IHomePageData {
  news: {
    totalCount: number;
    items: Array<{
      cursor: string;
      node: INewsItem;
    }>;
  };
  popularRecipes: {
    totalCount: number;
    items: Array<{
      cursor: string;
      node: {
        _id: number;
        id: string;
        number?: string;
        title: string;
        hasImage: boolean;
        imageId?: string;
        quantity?: string;
        quantityUnit?: string;
        weightLoss: number;
        applyQuidMeatCalculation: boolean;
        ZAN1?: string;
        ZAN2?: string;
        ZAN3?: string;
        ZAN4?: string;
        ZAN5?: string;
        author: string | null;
        categories: IRelation<ICategory>;
        emailSentBy: {
          firstName: string;
          lastName: string;
        } | null;
        emailSentAt: string | null;
        quantities: IRelation<IQuantity>;
        userWeights?: IRelation<IUserRecipeBaseWeight>;
        user?: IUser;
        isMeatProduct: boolean | null;
        isPublished: boolean | null;
        isSAP?: boolean;
        isVisibleToAnon: boolean | null;
        publishedBy: {
          firstName: string;
          lastName: string;
        } | null;
        publishedAt: string | null;
        collections?: {
          totalCount: number;
        };
      };
    }>;
  };
}
export const GET_HOME_PAGE_DATA = gql`
  ${CORE_NEWS_ITEM_FIELDS}
  ${CORE_RECIPE_FIELDS}
  query newAndPopularRecipes {
    news: newsItems(first: 5, orderBy: [{ createdAt: "DESC" }]) {
      totalCount
      items: edges {
        cursor
        node {
          ...CoreNewsItemFields
        }
      }
    }
    popularRecipes: recipes(
      first: 12
      orderBy: [{ title: "ASC" }]
      collections_name: "Beliebt"
      exists: { isSAP: false, collections_user: false }
    ) {
      totalCount
      items: edges {
        cursor
        node {
          ...CoreRecipeFields
          userWeights(exists: { user: true }) {
            totalCount
            items: edges {
              node {
                baseWeight
              }
            }
          }
        }
      }
    }
  }
`;
