import { gql } from '@apollo/client';

export interface IUserData {
  _id: number;
  email: string;
  accountNumber: string;
  firstName: string;
  lastName: string;
}

export interface IGetUsersByAccountVariables {
  accountNumber: string;
}

export interface IGetUsersByAccountResponse {
  users: {
    edges: { node: IUserData }[];
  };
}

export const GET_USERS_BY_ACCOUNT_NUMBER = gql`
  query usersByAccountNumber($accountNumber: String!) {
    users(accountNumber: $accountNumber) {
      edges {
        node {
          _id
          email
          accountNumber
          firstName
          lastName
        }
      }
    }
  }
`;
