import React, { FunctionComponent, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Row } from 'antd';
import {
  GET_COLLECTIONS,
  IResponse,
  IVariables,
} from '../../../../../gql/get-collections';
import { Routes } from '../../../../Collections/utils/RecipeRoutes';
import { useRapsPagination } from '../../../hooks/usePagination';
import { defaultGutterPixelSize } from '../../../../../lib/styles';
import { ErrorGraphQL } from '../../../../../components/ErrorGraphQL';
import { CardColumnsPlaceholder } from '../../../../../components/Placeholders';
import { SearchNoResult } from '../../../../../components/SearchNoResult';
import { CollectionCard } from '../../../../../components/CollectionCard';
import { SearchResultHeader } from '../../../../../components/SearchResultHeader';
import { SearchResultCarousel } from '../../../components/SearchResultCarousel';
import { ResultList } from '../../../components/ResultList';

type ISearchResultCollectionsProps = {
  hideAddCollectionButton?: boolean;
  ownedByUser?: boolean;
  pageSize?: number;
  i18nKey?: string;
  path?: string;
  showCarousel?: boolean;
};

export const SearchResultCollections: FunctionComponent<
  ISearchResultCollectionsProps
> = ({
  pageSize,
  ownedByUser = false,
  i18nKey,
  path,
  showCarousel = false,
}) => {
  const variables = useRapsPagination({ pageSize });

  const { loading, data, error, refetch } = useQuery<IResponse, IVariables>(
    GET_COLLECTIONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: { ...variables, ownedByUser, orderBy: { name: 'ASC' } },
      skip: !variables.q,
    },
  );

  const triggerRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <Row gutter={defaultGutterPixelSize}>
        <CardColumnsPlaceholder amount={1} />
      </Row>
    );
  }

  if (!data?.response.items.length) {
    return <SearchNoResult searchTerm={variables.q} i18nKey={i18nKey} />;
  }

  // The "Create Recipe Collection" Button should only be displayed if:
  // - The hideAddCollectionButton prop is not set to true,
  // - and the current RecipeCollectionList is user-owned

  const items = data.response.items.map(({ node }) => (
    <CollectionCard
      key={node._id}
      inCarousel
      item={{
        ...node,
        targetUrl: `/collections/${
          ownedByUser ? Routes.PRIVATECOLLECTIONS : Routes.RAPSCOLLECTIONS
        }/${node._id}`,
      }}
      ownedByUser={ownedByUser}
      afterPublishToggle={triggerRefetch}
    />
  ));

  return (
    <>
      {i18nKey && path && (
        <SearchResultHeader
          i18nKey={i18nKey}
          path={path}
          count={data.response.totalCount}
        />
      )}
      {showCarousel ? (
        <SearchResultCarousel items={items} />
      ) : (
        <ResultList items={items} totalCount={data?.response.totalCount} />
      )}
    </>
  );
};
