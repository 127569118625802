import React, { FunctionComponent, useMemo } from 'react';
import { Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { IBigEightsItem } from './constants';
import { SmallGreyHeading } from '../../pages/Recipes/pages/RecipeDetail/components/styles';

interface IProps {
  bigEights: IBigEightsItem[];
  weightLoss: number;
}

type NutritionData = Omit<IBigEightsItem, 'key'> & {
  key: number;
  name: string;
};

const columns: ColumnsType<NutritionData> = [
  {
    dataIndex: 'name',
    key: 'name',
  },
  {
    dataIndex: 'value',
    key: 'value',
    align: 'right',
  },
  { dataIndex: 'unit', key: 'unit' },
];

export const NutritionalInformationTable: FunctionComponent<IProps> = ({
  bigEights,
  weightLoss,
}) => {
  const { t } = useTranslation();

  const datasource = useMemo<NutritionData[]>(
    () =>
      bigEights.map((item: IBigEightsItem, index) => ({
        key: index,
        name: t(`nutritionalInformation.${item.key}`),
        unit: item.unit,
        value: item.value,
      })),
    [bigEights, t],
  );

  return (
    <Space
      direction="vertical"
      size="middle"
      id="nutritionalInformation"
      css={{ width: '100%' }}
    >
      <SmallGreyHeading>{t('nutritionalInformation.header')}</SmallGreyHeading>
      <Table<NutritionData>
        dataSource={datasource}
        columns={columns}
        showHeader={false}
        size="small"
        bordered
        pagination={false}
      />
      <div>
        <InfoCircleOutlined style={{ marginInlineEnd: 8 }} />
        {t('nutritionalInformation.weightLossInfo.description', {
          weightLoss,
        })}
      </div>
    </Space>
  );
};
