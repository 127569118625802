import { TFunction } from 'i18next';
import { filterWaterIngredients, formatIngredient, IIngredient } from '.';
import { ENERGY_FAT } from '../../../../../components/NutritionalInformationTable/constants';
import { IRecipe } from '../../../../../gql/get-recipe';
import { formatNumber, formatPercent } from '../../../../../lib/helpers';

const LIMITS = {
  LIMIT_15: 15,
  LIMIT_25: 25,
  LIMIT_30: 30,
};

interface IMeatIngredient {
  fat: number;
  id: number;
  leanMeat: number;
  meatCategory: string;
  quantity: number;
}

interface IFatInformation {
  quantity: number;
  name: string;
}

interface IMeatInfo {
  fat: number;
  leanMeat: number;
}

interface IDetailedMeatInformation {
  meatCategory: string;
  meatProportion: number;
  leanMeatProportion: number;
  percentage: number;
}

/**
 * Groups ingredients by name
 */
const groupNoMeatIngredients = (ingredients: IIngredient[]) => {
  const grouped: IIngredient[] = [];

  ingredients.forEach((ingredient) => {
    const existing = grouped.findIndex((g) => g.name === ingredient.name);

    if (existing === -1) {
      grouped.push(ingredient);

      return;
    }
    grouped[existing].quantity += ingredient.quantity;
  });

  return grouped;
};

/**
 * Groups ingredients by meat category (Step 5,6,7)
 */
const groupMeatIngredients = (meatIngredients: IMeatIngredient[]) => {
  const grouped: IMeatIngredient[] = [];

  meatIngredients.forEach((ingredient) => {
    const existing = grouped.findIndex(
      (g) => g.meatCategory === ingredient.meatCategory,
    );

    if (existing === -1) {
      grouped.push(ingredient);

      return;
    }

    grouped[existing] = {
      ...grouped[existing],
      fat: grouped[existing].fat + ingredient.fat,
      leanMeat: grouped[existing].leanMeat + ingredient.leanMeat,
      quantity: grouped[existing].quantity + ingredient.quantity,
    };
  });

  return grouped;
};

/**
 * This method divides the recipe ingredients in meat and non meat ingredients.
 */
const extractRecipeIngredients = (
  recipe: IRecipe,
  grossWeight: number,
): {
  meatIngredients: IMeatIngredient[];
  noMeatIngredients: IIngredient[];
  meatInfo: IMeatInfo;
} => {
  const meatInfo = {
    fat: 0,
    leanMeat: 0,
  };
  const meatIngredients: IMeatIngredient[] = [];
  const noMeatIngredients: IIngredient[] = [];

  recipe.quantities.items
    // Filter those with meatClassification but no Fat-Nutrition
    .filter(({ node }) =>
      // TODO: Test
      node.ingredient!.meatClassification
        ? node.ingredient!.nutritionalInformation.items.find(
            (niItems) => niItems.node.key === ENERGY_FAT,
          ) !== null
        : true,
    )
    .forEach(({ node }) => {
      // Non-Meat ingredient
      if (!node.ingredient!.meatClassification) {
        const quantityAmount = (Number(node.quantity) / grossWeight) * 100;

        noMeatIngredients.push({
          isQuid: node.isQuid,
          name: node.ingredient!.declaration || '',
          number: node.ingredient!.number,
          quantity: quantityAmount,
        });

        return;
      }

      const niItem = node.ingredient!.nutritionalInformation.items.find(
        (niItems) => niItems.node.key === ENERGY_FAT,
      );

      // Calc fat proportion (Step 1b)
      const fat = (Number(node.quantity) * Number(niItem!.node.quantity)) / 100;
      // Calc lean meat  proportion (Step 1c)
      const leanMeat = Number(node.quantity) - fat;

      // Calc total proportion of fat (Step 3)
      meatInfo.fat += fat; // Value 3
      // Calc total proportion of lean meat (Step 4)
      meatInfo.leanMeat += leanMeat; // Value 4

      meatIngredients.push({
        fat,
        id: node._id,
        leanMeat,
        meatCategory: node.ingredient!.meatClassification![0],
        quantity: Number(node.quantity),
      });
    });

  return {
    meatInfo,
    meatIngredients: groupMeatIngredients(meatIngredients),
    noMeatIngredients: filterWaterIngredients(
      groupNoMeatIngredients(noMeatIngredients),
      recipe,
    ),
  };
};

/**
 * Determine the recipe limit for a certain meat type
 *
 * @param {IMeatIngredient[]} groupedMeatIngredients  Array of different meatTypes and their aggregated values
 */
const determineRecipeLimit = (
  groupedMeatIngredients: IMeatIngredient[],
): number => {
  const meatCategories = groupedMeatIngredients.map(
    (item) => item.meatCategory,
  );

  if (meatCategories.length === 1) {
    // eslint-disable-next-line default-case
    switch (meatCategories[0]) {
      case 'H':
      case 'P':
        return LIMITS.LIMIT_15;
      case 'K':
      case 'L':
      case 'R':
      case 'W':
        return LIMITS.LIMIT_25;
      case 'S':
        return LIMITS.LIMIT_30;
    }
  } else if (meatCategories.includes('H') && meatCategories.includes('P')) {
    return LIMITS.LIMIT_15;
  }

  return LIMITS.LIMIT_25;
};

const getFullMeatProportion = (
  meatInfo: IMeatInfo,
  weightLoss: number,
  recipeLimit: number,
) => {
  const totalPercentageFat = (meatInfo!.fat / weightLoss) * 100; // Value 6
  const totalPercentageLeanMeat = (meatInfo!.leanMeat / weightLoss) * 100; // Value 7
  const totalMeatPercentage = totalPercentageFat + totalPercentageLeanMeat; // value 9
  const totalFatInTotalMeat = (totalPercentageFat / totalMeatPercentage) * 100; // Value 10

  return {
    totalFatInTotalMeat,
    totalMeatPercentage,
    /**
     * Step 15a
     * Determines how the meatIngredients will be sorted and which property will be displayed
     */

    meatPercentage:
      totalFatInTotalMeat <= recipeLimit
        ? totalMeatPercentage
        : totalPercentageLeanMeat +
          (totalPercentageLeanMeat * recipeLimit) / (100 - recipeLimit),
  };
};

/**
 * Step 15b
 * Delivers a more detailed version of the meat information if there is more than one kind of meat used in the recipe
 *
 * @param {IMeatIngredient[]} meatIngredients
 * @param {number} weightLoss
 * @param {number} recipeLimit
 * @param {number} totalFatInTotalMeat
 * @param {number} totalMeatPercentage
 * @param {number} meatPercentage
 */
const getDetailedMeatInformation = (
  meatIngredients: IMeatIngredient[],
  weightLoss: number,
  recipeLimit: number,
  totalFatInTotalMeat: number,
  totalMeatPercentage: number,
  meatPercentage: number,
) => {
  /**
   * Calculates the proportions per meat type
   */
  const meatProportionPerMeatType = meatIngredients.map((item) => ({
    // 12: Magerfleischanteil pro Fleischart
    leanMeatProportion: (item.leanMeat / weightLoss) * 100,
    meatCategory: item.meatCategory,
    // 11: Fleischanteil pro Fleischart
    meatProportion: (item.quantity / weightLoss) * 100,
  }));

  // Adds the percentage depending on the sort criterion
  const mappedIngredients =
    totalFatInTotalMeat <= recipeLimit
      ? meatProportionPerMeatType.map((item) => ({
          ...item,
          percentage: item.meatProportion,
        }))
      : meatProportionPerMeatType.map((item) => ({
          ...item,
          percentage:
            (item.meatProportion * meatPercentage) / totalMeatPercentage,
        }));

  return mappedIngredients.sort((a, b) => b.percentage - a.percentage);
};

/**
 *  Check if a fat value is over the recipe limit
 */
const getFatInformation = (
  meatIngredients: IMeatIngredient[],
  recipeLimit: number,
  totalFatInTotalMeat: number,
  t: TFunction,
): IFatInformation | null => {
  if (totalFatInTotalMeat < recipeLimit || meatIngredients.length === 0) {
    return null;
  }

  const excessAmountFat = totalFatInTotalMeat - recipeLimit;

  const maxFatItem = meatIngredients.reduce((acc, curr) =>
    acc.fat > curr.fat ? acc : curr,
  );

  return {
    name: `${t(`recipe.declaration.fatType.${maxFatItem.meatCategory}`)}${
      maxFatItem.meatCategory === 'W' ? '*' : ''
    }`,
    quantity: excessAmountFat,
  };
};

/**
 * Creates a readable text for multiple meat declarations
 */
function getMeatDeclarationText(
  meatTypes: IDetailedMeatInformation[],
  t: TFunction,
): string {
  return meatTypes
    .reduce<string[]>((acc, curr, idx, arr) => {
      const meatCategory = t(
        `recipe.declaration.meatType.${curr.meatCategory}`,
      );
      const declarationHint = curr.meatCategory === 'W' ? ' **' : '';

      if (idx === arr.length - 1) {
        return [
          ...acc,
          ...[meatCategory, t('recipe.declaration.text.meat'), declarationHint],
        ];
      }

      if (idx === arr.length - 2) {
        return [
          ...acc,
          ...[
            meatCategory,
            declarationHint,
            `- ${t('recipe.declaration.text.and')} `,
          ],
        ];
      }

      return [...acc, ...[meatCategory, declarationHint, '-, ']];
    }, [])
    .join('');
}

/**
 * Concatenates all information of the meat declaration
 */
const getFullMeatDeclaration = (
  meatDeclarationText: string,
  meatPercentage: number,
  detailedMeatInformation: IDetailedMeatInformation[],
  t: TFunction,
) => {
  const percentage =
    meatPercentage > 0 && meatPercentage <= 100
      ? formatPercent(meatPercentage, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })
      : '';

  const declaration = `${percentage}${meatDeclarationText}`;

  if (detailedMeatInformation.length > 1 && meatPercentage <= 100) {
    // If there are multiple meat-types in the recipe, show their respective percentage
    const detailedInformation = ` (${detailedMeatInformation
      .map((info) => {
        const percent = formatNumber(info.percentage, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
        const meatCategory = t(
          `recipe.declaration.meatType.${info.meatCategory}`,
        );
        const meat = t('recipe.declaration.text.meat');
        const declarationHint = info.meatCategory === 'W' ? ' **' : '';

        return `${percent}% ${meatCategory}${meat}${declarationHint}`;
      })
      .join(', ')})`;

    return `${declaration}${detailedInformation}`;
  }

  return declaration;
};

export type DeclarationType = {
  text: string;
  weightLossHint?: string;
};

/**
 * Calculates all values for the quid meat declaration regarding
 * the concept: "QUID-Fleischberechnung bei der Erstellung von Deklarationen"
 */

export const quidCalculation = (
  grossWeight: number,
  recipe: IRecipe,
  t: TFunction,
): DeclarationType => {
  const { noMeatIngredients, meatIngredients, meatInfo } =
    extractRecipeIngredients(recipe, grossWeight);

  const recipeLimit = determineRecipeLimit(meatIngredients);
  // Calculate the weight loss (Step 8) (Value 5)
  const weightLoss =
    Number(grossWeight) -
    (Number(grossWeight) * Number(recipe.weightLoss)) / 100;

  const { totalFatInTotalMeat, meatPercentage, totalMeatPercentage } =
    getFullMeatProportion(meatInfo, weightLoss, recipeLimit); // Step 9, 10, 13 , 14 & 15a

  const detailedMeatInformation = getDetailedMeatInformation(
    meatIngredients,
    weightLoss,
    recipeLimit,
    totalFatInTotalMeat,
    totalMeatPercentage,
    meatPercentage,
  ); // Step 11 & 12

  const fatInformation = getFatInformation(
    meatIngredients,
    recipeLimit,
    totalFatInTotalMeat,
    t,
  ); // Step 15c

  const meatDeclarationText = getMeatDeclarationText(
    detailedMeatInformation,
    t,
  );

  const weightLossHint =
    weightLoss > 0 && meatPercentage >= 100.5
      ? `${t('recipe.declaration.text.weightLossHint', {
          amount: formatNumber(meatPercentage, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          meat: meatDeclarationText,
        })}`
      : '';

  // Create Array to sort with No-Meat Ingredients
  const elementsToSort = noMeatIngredients.map((i) => ({
    proportion: i.quantity,
    text: formatIngredient(i),
  }));

  // Add meat-information
  if (meatDeclarationText !== '') {
    elementsToSort.push({
      proportion: meatPercentage,
      text: getFullMeatDeclaration(
        meatDeclarationText,
        meatPercentage,
        detailedMeatInformation,
        t,
      ),
    });
  }

  // Add the fat-information
  if (fatInformation) {
    elementsToSort.push({
      proportion: fatInformation.quantity,
      text: formatIngredient({
        ...fatInformation,
        isQuid: false,
      }),
    });
  }

  const declaration = elementsToSort
    .sort((a, b) => b.proportion - a.proportion)
    .map((e) => e.text)
    .join(', ');

  return {
    text: declaration,
    weightLossHint,
  };
};
