import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { App, Col, Popover, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { apiUrls } from '../../../lib/apiUrls';
import { handleError } from '../../../lib/handleError';

export interface IProps {
  context: 'global' | 'anon';
  isPublished: boolean;
  collectionId: number;
  confirmPublishMessage: string;
  confirmUnpublishMessage: string;
  tooltipTitle: string;
  tooltipContent: string;
  onPublishedCollection: () => Promise<void>;
}

export const PublishAction: FunctionComponent<IProps> = ({
  context,
  isPublished,
  collectionId,
  tooltipTitle,
  tooltipContent,
  confirmUnpublishMessage,
  confirmPublishMessage,
  onPublishedCollection,
}) => {
  const { modal } = App.useApp();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(isPublished);
  const preventDefault = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onChange = useCallback(
    (published: boolean) => {
      modal.confirm({
        cancelText: t('common.no'),
        title: published
          ? t('collections.publishConfirmTitle')
          : t('collections.unpublishConfirmTitle'),
        content: t(published ? confirmPublishMessage : confirmUnpublishMessage),
        okText: t('common.yes'),
        onOk: async () => {
          const payload =
            context === 'global'
              ? { isPublished: published }
              : { isVisibleToAnon: published };

          try {
            await fetch(apiUrls.publishCollection(collectionId), {
              body: JSON.stringify(payload),
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'PUT',
            });
            await onPublishedCollection();
            setChecked((prevState) => !prevState);
          } catch (e) {
            handleError(e);
          }
        },
      });
    },
    [
      modal,
      collectionId,
      confirmPublishMessage,
      confirmUnpublishMessage,
      context,
      onPublishedCollection,
      t,
    ],
  );

  return (
    <Row gutter={16} onClick={preventDefault}>
      <Col xs={12}>
        <div>
          <Switch
            checked={checked}
            onChange={onChange}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </div>
      </Col>
      <Col xs={12} style={{ marginTop: 3 }}>
        <Popover
          title={t(tooltipTitle)}
          content={<div style={{ maxWidth: 200 }}>{t(tooltipContent)}</div>}
          trigger="click"
        >
          <InfoCircleOutlined style={{ fontSize: 18 }} />
        </Popover>
      </Col>
    </Row>
  );
};
