import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { i18n } from './i18n';

const reload = (registration: ServiceWorkerRegistration) => () => {
  if (registration.waiting) {
    registration.waiting.postMessage('skipWaiting');
    window.location.reload();
  }
};

export const handleServiceWorkerUpdate = async (
  registration: ServiceWorkerRegistration,
) => {
  const t = await i18n;
  const btn = (
    <Button
      htmlType="button"
      disabled={!registration.waiting}
      onClick={reload(registration)}
    >
      {t('update.button')}
    </Button>
  );

  const icon = <ReloadOutlined />;

  notification.open({
    btn,
    description: t('update.description'),
    duration: null,
    icon,
    message: t('update.message'),
  });
};
