import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { AuthGuard } from '../../../../components/AuthGuard';
import { CreateRecipeButton } from '../../../../components/CreateRecipeButton';
import { BreakableTitle } from '../../../../components/BreakableTitle';
import { RecipeCard } from '../../../../components/RecipeCard';
import {
  GET_CATEGORY_RECIPES,
  IResponse,
  IVariables,
} from '../../../../gql/get-category-recipes';
import { ResultList } from '../../../Search/components/ResultList';
import { textColor } from '../../../../theme/variables';
import { useRapsPagination } from '../../../Search/hooks/usePagination';
import { defaultGutterPixelSize } from '../../../../lib/styles';
import { CardColumnsPlaceholder } from '../../../../components/Placeholders';
import { ErrorGraphQL } from '../../../../components/ErrorGraphQL';
import { NoItemsGraphQL } from '../../../../components/NoItemsGraphQL';

const CategorySpan = styled('span')`
  text-transform: uppercase;
  color: ${textColor};
`;

export const CategoryDetail: FunctionComponent<{ id: string }> = ({ id }) => {
  const { itemsPerPage, cursor } = useRapsPagination();
  const { t } = useTranslation();

  const { data, loading, error } = useQuery<IResponse, IVariables>(
    GET_CATEGORY_RECIPES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        baseId: id!,
        cursor,
        isSAP: false,
        itemsPerPage,
        orderBy: {
          name: 'ASC',
        },
        uriId: `/api/categories/${id}`,
        userExists: false,
      },
    },
  );

  const isCached = data?.response && data?.recipes;

  if (error) {
    return <ErrorGraphQL />;
  }

  if (loading && !data) {
    return (
      <Row gutter={defaultGutterPixelSize}>
        <CardColumnsPlaceholder amount={2} />
      </Row>
    );
  }

  if (!data?.recipes.items) {
    return <NoItemsGraphQL />;
  }

  const items = data.recipes.items.map(({ node }) => (
    <RecipeCard
      key={node._id}
      item={{
        ...node,
        isSAP: false,
        targetUrl: `/recipes/${node._id}`,
      }}
    />
  ));

  return (
    <>
      <BreakableTitle>
        <CategorySpan>
          {t('categories.detailHeading')}{' '}
          {isCached && data?.response?.name ? (
            <strong>{data.response.name}</strong>
          ) : (
            <span>&hellip;</span>
          )}
        </CategorySpan>
      </BreakableTitle>

      <ResultList
        items={items}
        totalCount={data.recipes.totalCount}
        actionButton={
          <AuthGuard>
            <CreateRecipeButton />
          </AuthGuard>
        }
      />
    </>
  );
};
