import { gql } from '@apollo/client';

export const DELETE_SHOPPING_CART_ITEM = gql`
  mutation deleteShoppingCartItem($input: deleteShoppingCartItemInput!) {
    deleteShoppingCartItem(input: $input) {
      shoppingCartItem {
        id
      }
    }
  }
`;
