import { gql } from '@apollo/client';
import { IUserRecipeBaseWeight } from './get-recipe';

export interface IVariables {
  input: {
    recipe: string;
    baseWeight: string;
  };
  response: {
    userRecipeBaseWeight: IUserRecipeBaseWeight;
  };
}

export const CREATE_USER_RECIPE_BASE_WEIGHT = gql`
  mutation createUserRecipeBaseWeight(
    $input: createUserRecipeBaseWeightInput!
  ) {
    response: createUserRecipeBaseWeight(input: $input) {
      userRecipeBaseWeight {
        id
        baseWeight
        user {
          id
        }
        recipe {
          id
        }
      }
    }
  }
`;
