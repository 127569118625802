import { gql } from '@apollo/client';
import { IRecipeInput } from './create-recipe';

export interface IVariables {
  input: {
    id: string;
    recipe?: IRecipeInput;
    baseWeight: string;
  };
}

export const UPDATE_USER_RECIPE_BASE_WEIGHT = gql`
  mutation updateUserRecipeBaseWeight(
    $input: updateUserRecipeBaseWeightInput!
  ) {
    updateUserRecipeBaseWeight(input: $input) {
      userRecipeBaseWeight {
        id
      }
    }
  }
`;
