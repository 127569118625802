import { gql } from '@apollo/client';
import { IShopIngredient } from './get-product-categories';
import { ENHANCED_RECIPE_FIELDS } from './fragments/recipe';
import { ENHANCED_QUANTITY_FIELDS } from './fragments/quantities';

export interface IRelation<P> {
  totalCount: number;
  items: Array<{
    cursor: string;
    node: P;
  }>;
}

export interface ICategory {
  _id: number;
  id: string;
  name: string;
  children: {
    totalCount: number;
  };
}

export interface INutritionalInformation {
  _id: number;
  id: string;
  key: string;
  quantity: string;
}

export interface IShoppingCartItem {
  id: string;
  quantity: string;
  containerSize: string;
  extraAmount: number;
}

export interface IIngredient {
  _id: number;
  id: string;
  name: string;
  number: string;
  declaration?: string;
  isVirtual: boolean;
  isDeleted: boolean;
  shopIngredients:
    | {
        edges: Array<{ node: IShopIngredient }>;
      }
    | undefined;
  shoppingCartItems: {
    __typename: string;
    totalCount: number;
    edges: Array<{ node: IShoppingCartItem }>;
  };
  nutritionalInformation: IRelation<INutritionalInformation>;
  meatClassification: string | null;
}

export interface IQuantity {
  _id: number;
  id: string;
  position: number;
  quantity: string;
  quantityUnit: string;
  ingredient?: IIngredient;
  isQuid: boolean;
  stpoClass: string | null;

  header?: string;
}

interface IUser {
  _id: number;
  __typename: string;
}

interface IPublishUser {
  firstName: string;
  lastName: string;
}

interface ICollectionCount {
  totalCount: number;
}

export interface IRecipe {
  _id: number;
  id: string;
  number?: string;
  title: string;
  hasImage: boolean;
  imageId?: string;
  quantity?: string;
  quantityUnit?: string;
  weightLoss: number;
  applyQuidMeatCalculation: boolean;
  ZAN1?: string;
  ZAN2?: string;
  ZAN3?: string;
  ZAN4?: string;
  ZAN5?: string;
  author: string | null;
  categories: IRelation<ICategory>;
  emailSentBy: IPublishUser | null;
  emailSentAt: string | null;
  quantities: IRelation<IQuantity>;
  userWeights?: IRelation<IUserRecipeBaseWeight>;
  user?: IUser;
  isMeatProduct: boolean | null;
  isPublished: boolean | null;
  isSAP?: boolean;
  isVisibleToAnon: boolean | null;
  publishedBy: IPublishUser | null;
  publishedAt: string | null;
  collections?: ICollectionCount;
}

export interface IUserRecipeBaseWeight {
  id?: string;
  baseWeight: number;
  user?: string;
  recipe?: string;
}

export interface IResponse {
  response: IRecipe;
}

export interface IVariables {
  id?: string;
}

export const GET_RECIPE = gql`
  ${ENHANCED_RECIPE_FIELDS}
  ${ENHANCED_QUANTITY_FIELDS}
  query recipe($id: ID!, $withUser: Boolean = true) {
    response: recipe(id: $id) {
      ...EnhancedRecipeFields
      emailSentAt @include(if: $withUser)
      emailSentBy @include(if: $withUser) {
        firstName
        lastName
      }
      publishedBy @include(if: $withUser) {
        firstName
        lastName
      }
      userWeights(first: 1) @include(if: $withUser) {
        items: edges {
          node {
            id
            baseWeight
          }
        }
      }
      quantities(first: 100) {
        ...EnhancedQuantityFields
      }
    }
  }
`;
