import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrls } from './apiUrls';
import { HttpError } from './HttpError';

export const handleResponse = async <T>(
  response: Response,
): Promise<T | undefined> => {
  if (response.ok) {
    if (response.status === 204) {
      return undefined;
    }

    const data = await response.json();

    return data as T;
  }

  throw new HttpError(response);
};

export const handleAxiosResponse = (axiosResponse: AxiosResponse) => {
  if (axiosResponse.status === 204) {
    return null;
  }

  return axiosResponse.data.json();
};

export const fetchLogout = async () => {
  try {
    await fetch(apiUrls.logout, { method: 'POST', credentials: 'same-origin' });
  } catch (e) {
    Sentry.captureMessage('Error during logout');
  }
};
