import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { maxSearchResults } from '../pages/Search/utils';
import { defaultGutterPixelSize } from '../lib/styles';

export const SearchResultHeader: React.FunctionComponent<{
  i18nKey: string;
  path: string;
  count: number;
}> = ({ i18nKey, path, count }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: defaultGutterPixelSize,
        marginBottom: defaultGutterPixelSize,
      }}
    >
      <div>
        <strong>{t(i18nKey)}</strong>
        {count > 0 && ` (${count})`}
      </div>
      {count > maxSearchResults && (
        <Button
          type="primary"
          onClick={() => {
            navigate({
              pathname: path,
              search: location.search,
            });
          }}
        >
          {t('search.showAllResults')}
        </Button>
      )}
    </div>
  );
};
