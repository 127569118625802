type Config = {
  GA_MEASUREMENT_ID?: string;
  sentryEnv: string;
};

const getConfig = (): Config => {
  let sentryEnv: string;
  let GA_MEASUREMENT_ID: string | undefined;

  const hostname = window?.location?.hostname;

  switch (hostname) {
    case 'raps-develop.k8s-dev.incloud.de':
      sentryEnv = 'develop';
      break;
    case 'raps-review.k8s-dev.incloud.de':
      sentryEnv = 'review';
      break;
    case 'localhost':
      sentryEnv = 'localhost';
      // GA_MEASUREMENT_ID = 'G-58NDNVHYS9';
      break;
    default:
      sentryEnv = 'raps-master';
      GA_MEASUREMENT_ID = 'G-58NDNVHYS9'; // prod GA4 account
  }

  return {
    sentryEnv,
    GA_MEASUREMENT_ID,
  };
};

export const environmentAwareConfig = getConfig();
