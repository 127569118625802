import * as Sentry from '@sentry/react';

import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { environmentAwareConfig } from './environmentAwareConfig';

const isLocalDevelopment = process.env.REACT_APP_DEVELOPMENT === 'true';

export const sentrySetup = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: environmentAwareConfig.sentryEnv,
    enabled: !isLocalDevelopment,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE || 'git',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),

      Sentry.httpClientIntegration({
        failedRequestTargets: [/api\/.*/],
      }),
      Sentry.extraErrorDataIntegration(),
    ],
  });
};
